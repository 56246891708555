<template>
  <div id="viewStart">
    <ComponentTopBar />
    <ComponentStart />
    <ComponentMovie />
    <ComponentShow />
    <ComponentEpisodes />
    <ComponentAbout />
    <ComponentAccount />
    <ComponentPerson />
    <ComponentOptionsBar />
    <ComponentLists />
    <ComponentFeatured />
    <ComponentInstall />

    <div id="bottomBar">
      <span id="bottomBarPrevious" v-on:click="previous()">❮</span>
      <span id="bottomBarSearch" v-on:click="displaySearchBar()">§</span>
    </div>
  </div>
</template>

<script>
import ComponentStart from '@/components/ComponentStart.vue'
import ComponentMovie from '@/components/ComponentMovie.vue'
import ComponentShow from '@/components/ComponentShow.vue'
import ComponentEpisodes from '@/components/ComponentEpisodes.vue'
import ComponentAbout from '@/components/ComponentAbout.vue'
import ComponentTopBar from '@/components/ComponentTopBar.vue'
import ComponentAccount from '@/components/ComponentAccount.vue'
import ComponentPerson from '@/components/ComponentPerson.vue'
import ComponentOptionsBar from '@/components/ComponentOptionsBar.vue'
import ComponentLists from '@/components/ComponentLists.vue'
import ComponentFeatured from '@/components/ComponentFeatured.vue'
import ComponentInstall from '@/components/ComponentInstall.vue'
import router from '../router'

export default {
  name: 'viewStart',
  components: {
      ComponentStart,
      ComponentMovie,
      ComponentShow,
      ComponentEpisodes,
      ComponentAbout,
      ComponentTopBar,
      ComponentAccount,
      ComponentPerson,
      ComponentOptionsBar,
      ComponentLists,
      ComponentFeatured,
      ComponentInstall,
  },
    setup() {
      
        function previous()
        {
            console.log("previous")
            
            router.back()
        }

        function displaySearchBar()
        {
            //elements
            let searchBar = document.getElementById("searchBar")
            let componentStart = document.getElementById("componentStart")
            let searchBarInput = document.getElementById("searchBarInput")
            let topBarSearch = document.getElementById("topBarSearch")
            
            //display elements
            if(componentStart.style.display == "" || componentStart.style.display == "none" || 
            searchBar.style.display == "" || searchBar.style.display == "none")
            {
              componentStart.style.display = "block"
              searchBar.style.display = "block"
              topBarSearch.style.fontWeight = "bold"
              topBarSearch.style.opacity = "1"

              //focus search bar
              setTimeout(() => {searchBarInput.disabled = false; searchBarInput.focus()}, 0)
            }
            else if(searchBar.style.display == "block") 
            { 
              searchBar.style.display = "none" 
              topBarSearch.style.fontWeight = "normal"
              topBarSearch.style.opacity = "0.3"
            }
        }

      return {
        previous,
        displaySearchBar
      }
    }
}
</script>

<style scoped>
/*** elements ***/

/*** ids ***/
#viewStart { height: auto; margin: 0px; padding: 0px; cursor: default; background-color: black;} /* #0c0c0c */
#bottomBar { position: fixed; z-index: 2; display: none; visibility: hidden; }
/* #topBarPrevious { display: none;} */

/*** classes ***/

  /*** mobile ***/
  @media screen and (max-width: 1100px) {
    #viewStart { height: auto; background-color: black; overflow-y: hidden; overflow-x: hidden;} /* #0c0c0c */
    #bottomBarPrevious 
    {
      position: fixed; 
      display: block; 
      bottom: 12px; 
      right: 20px; 
      font-size: 38px; 
      font-weight: normal; 
      z-index: 10; 
      opacity: 1;
      user-select: none;
      text-shadow: 2px 1px black; 
      color: white;
    }
    #bottomBarSearch 
    {
      position: fixed; 
      display: block; 
      bottom: 18px; 
      right: calc(60px - 4px); 
      font-size: 34px; 
      font-weight: bold; 
      z-index: 10; 
      opacity: 1;
      user-select: none;
      text-shadow: 2px 1px black; 
      color: white;
    }
    #bottomBar { display: block; visibility: visible; }
  }
</style>
