<template>
  <div id="componentPerson">
    <div id="" v-if="selectedPersonPoster">
        <img id="personPoster" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + selectedPersonPoster" />
    </div>

    <!-- <div v-if="selectedPerson">
        <p id="personNameTop" class="personText" v-if="selectedPerson.data.name">{{selectedPerson.data.name}}</p>
    </div> -->

      <div v-if="selectedPerson" id="personCategories">
          <h3 id="personFullHistoryTitle" class="personSectionTitle" v-on:click="displayPersonSection('FullHistory')">History</h3>
          <span class="dotChar">∙</span> 
          <h3 id="personBiographyTitle" class="personSectionTitle" v-on:click="displayPersonSection('Biography')">Bio</h3>
          <span class="dotChar">∙</span> 
          <!-- <h3 id="personKnownFromTitle" class="personSectionTitle" v-on:click="displayPersonSection('KnownFrom')">known from</h3> -->
          <!-- <span class="dotChar">∙</span>  -->
          <h3 id="personLatestTitle" class="personSectionTitle" v-on:click="displayPersonSection('Latest')">Latest</h3>
          <span class="dotChar">∙</span> 
          <h3 id="personTopTitle" class="personSectionTitle" v-on:click="displayPersonSection('Top')">Top</h3>
          <span class="dotChar">∙</span> 
          <h3 id="personUnreleasedTitle" class="personSectionTitle" v-on:click="displayPersonSection('Unreleased')">Unreleased</h3>
      </div>
      
        <div v-if="selectedPerson" id="personInfo">
            
            <!-- section info text -->
            <!-- <div id="personSectionInfoText" class="personSection"> -->
                <!-- <p id="personNameTop" class="personText" v-if="selectedPerson.data.name">{{selectedPerson.data.name}}</p> -->
                <!-- <p id="personAge" class="personText" v-if="selectedPerson.data.birthday">{{parseInt(2022 - selectedPerson.data.birthday.substr(0, 4))}} Years Old</p> -->
                <!-- <p id="personBornPlace" class="personText" v-if="selectedPerson.data.place_of_birth">From {{selectedPerson.data.place_of_birth}}</p> -->
                <!-- <p id="personName" class="personText">{{selectedPerson.name}}</p> -->
                <!-- <p id="personBornDate" class="personText" v-if="selectedPerson.data.birthday">{{selectedPerson.data.birthday}}</p> -->
                <!-- <p id="personActedIn" class="personText" v-if="selectedPerson.data.combined_credits.cast.length != 0">Acted in {{totalMoviesAndShows(selectedPerson.data.combined_credits).actor.movies}} Movies and {{totalMoviesAndShows(selectedPerson.data.combined_credits).actor.shows}} Shows</p> -->
                <!-- <p id="personProduced" class="personText" v-if="selectedPerson.data.combined_credits.crew.length != 0">Produced {{totalMoviesAndShows(selectedPerson.data.combined_credits).producer.movies}} Movies and {{totalMoviesAndShows(selectedPerson.data.combined_credits).producer.shows}} Shows</p> -->
            <!-- </div> -->

            <!-- section biography -->
            <div  id="personSectionBiography" class="personSection">
                <!-- <p id="personName" class="personText">{{selectedPerson.name}}</p> -->
                <p id="personNameTop" class="personText" v-if="selectedPerson.data.name">{{selectedPerson.data.name}}</p>
                <p id="personAge" class="personText" v-if="selectedPerson.data.birthday">Age · {{parseInt(new Date().getFullYear() - selectedPerson.data.birthday.substr(0, 4))}}</p>
                <p id="personBornPlace" class="personText" v-if="selectedPerson.data.place_of_birth">From · {{selectedPerson.data.place_of_birth.replaceAll(', ', ' · ')}}</p>
                <!-- <p id="personActedIn" class="personText" v-if="selectedPerson.data.combined_credits.cast.length != 0">Actor · {{totalMoviesAndShows(selectedPerson.data.combined_credits).actor.movies}} Movies · {{totalMoviesAndShows(selectedPerson.data.combined_credits).actor.shows}} Shows</p> -->
                <!-- <p id="personProduced" class="personText" v-if="selectedPerson.data.combined_credits.crew.length != 0">Producer · {{totalMoviesAndShows(selectedPerson.data.combined_credits).producer.movies}} Movies · {{totalMoviesAndShows(selectedPerson.data.combined_credits).producer.shows}} Shows</p> -->
                <!-- <p id="personBornDate" class="personText" v-if="selectedPerson.data.birthday">{{selectedPerson.data.birthday}}</p> -->
                <!-- <p id="personActedIn" class="personText" v-if="selectedPerson.data.combined_credits.cast.length != 0">Acted in {{totalMoviesAndShows(selectedPerson.data.combined_credits).actor.movies}} Movies</p> -->
                <!-- <p id="personActedIn" class="personText" v-if="selectedPerson.data.combined_credits.cast.length != 0">Acted in {{totalMoviesAndShows(selectedPerson.data.combined_credits).actor.shows}} Shows</p> -->
                <!-- <p id="personProduced" class="personText" v-if="selectedPerson.data.combined_credits.crew.length != 0">Produced {{totalMoviesAndShows(selectedPerson.data.combined_credits).producer.movies}} Movies</p> -->
                <!-- <p id="personProduced" class="personText" v-if="selectedPerson.data.combined_credits.crew.length != 0">Produced {{totalMoviesAndShows(selectedPerson.data.combined_credits).producer.shows}} Shows</p> -->

                <ul id="biographyList" v-if="selectedPerson.data.biography">
                    <li class="biographyLine" v-for="line in selectedPerson.data.biography.split('. ')" v-bind:key="line.nr">{{line}}</li>
                </ul>
            </div>

            <!-- section known from -->
            <div id="personSectionKnownFrom" class="personSection" v-if="selectedPerson.data">
                    <div v-if="selectedPerson.data.known_for_department != 'Directing'" class="KnownFrom" v-for="item in sortPersonFullHistory(selectedPerson.data.combined_credits.cast, 'cast').slice().sort((a, b) => {return b.vote_count - a.vote_count}).slice(0, 10)" v-bind:key="item.nr">
                        <img class="KnownFromImg" v-if="item.poster_path && item.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path" v-on:click="setUrl('movie', item.id)" />
                        <img class="KnownFromImg" v-else-if="item.poster_path && item.media_type == 'tv'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path"  v-on:click="setUrl('show', item.id)" />
                        <!-- <div id="KnownFromText">
                            <p id="KnownFromTitleMovie" v-if="item.title">{{item.title}} · ({{item.release_date.toString().substr(0, 4)}}) · {{item.vote_average}} ★</p>
                            <p id="KnownFromTitleShow" v-else-if="item.name">{{item.name}} · ({{item.first_air_date.toString().substr(0, 4)}}) · {{item.vote_average}} ★</p>
                            <p id="KnownFromSummary" v-if="item.overview">{{item.overview}}</p>
                        </div> -->
                    </div>

                    <div v-if="selectedPerson.data.known_for_department == 'Directing'" class="KnownFrom" v-for="item in sortPersonFullHistory(selectedPerson.data.combined_credits.crew, 'crew').slice().sort((a, b) => {return b.vote_count - a.vote_count}).slice(0, 10)" v-bind:key="item.nr">
                        <img class="KnownFromImg" v-if="item.poster_path && item.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path" v-on:click="setUrl('movie', item.id)" />
                        <img class="KnownFromImg" v-else-if="item.poster_path && item.media_type == 'tv'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path"  v-on:click="setUrl('show', item.id)" />
                        <!-- <div id="KnownFromText">
                            <p id="KnownFromTitleMovie" v-if="item.title">{{item.title}} · ({{item.release_date.toString().substr(0, 4)}}) · {{item.vote_average}} ★</p>
                            <p id="KnownFromTitleShow" v-else-if="item.name">{{item.name}} · ({{item.first_air_date.toString().substr(0, 4)}}) · {{item.vote_average}} ★</p>
                            <p id="KnownFromSummary" v-if="item.overview">{{item.overview}}</p>
                        </div> -->
                    </div>                
            </div>

            <!-- section latest work -->
            <div id="personSectionLatest" class="personSection">
                <div v-if="selectedPerson.data.known_for_department != 'Directing'" class="Latest" v-for="item in sortPersonLatest(selectedPerson.data.combined_credits.cast, 'cast')" v-bind:key="item.nr">
                    <img class="LatestImg" v-if="item.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path" v-on:click="setUrl('movie', item.id)" />
                    <img class="LatestImg" v-else-if="item.media_type == 'tv'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path"  v-on:click="setUrl('show', item.id)" />
                </div> 

                <div v-if="selectedPerson.data.known_for_department == 'Directing'" class="Latest" v-for="item in sortPersonLatest(selectedPerson.data.combined_credits.crew, 'crew')">
                    <img class="LatestImg" v-if="item.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path" v-on:click="setUrl('movie', item.id)" />
                    <img class="LatestImg" v-else-if="item.media_type == 'tv'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path"  v-on:click="setUrl('show', item.id)" />
                </div>
            </div>

            <!-- section top work -->
            <div id="personSectionTop" class="personSection">
                <div v-if="selectedPerson.data.known_for_department != 'Directing'" class="Top" v-for="credit in sortPersonTop(selectedPerson.data.combined_credits.cast, 'cast').sort((a, b) => {return b.vote_average - a.vote_average})" v-bind:key="credit.nr">
                    <img class="TopImg" v-if="credit.poster_path && credit.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + credit.poster_path" v-on:click="setUrl('movie', credit.id)" />
                    <img class="TopImg" v-else-if="credit.poster_path && credit.media_type == 'tv'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + credit.poster_path"  v-on:click="setUrl('show', credit.id)" />
                    <!-- <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.vote_count > '1000' && credit.vote_average != '0' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)">{{credit.title}}<img class="LatestImg" v-if="item.poster_path && item.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + item.poster_path" v-on:click="setUrl('movie', item.id)" /></div>
                    <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.vote_count > '1000' && credit.vote_average != '0' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)">{{credit.name}}<span class="opacityReducer"> · {{credit.release_date}} · Show</span></div> -->
                </div> 

                <div v-if="selectedPerson.data.known_for_department == 'Directing'" class="Top" v-for="credit in sortPersonTop(selectedPerson.data.combined_credits.crew, 'crew').sort((a, b) => {return b.vote_average - a.vote_average})">
                    <img class="TopImg" v-if="credit.poster_path && credit.media_type == 'movie'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + credit.poster_path" v-on:click="setUrl('movie', credit.id)" />
                    <img class="TopImg" v-else-if="credit.poster_path && credit.media_type == 'tv'" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + credit.poster_path"  v-on:click="setUrl('show', credit.id)" />
                    <!-- <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.vote_count > '1000' && credit.vote_average != '0' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)">{{credit.title}}<span class="opacityReducer">{{credit.release_date}} · Movie</span></div>
                    <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.vote_count > '1000' && credit.vote_average != '0' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)">{{credit.name}}<span class="opacityReducer">{{credit.release_date}} · Show</span></div> -->
                </div>
            </div>

            <!-- section unreleased work -->
            <div id="personSectionUnreleased" class="personSection">
                <div v-if="selectedPerson.data.known_for_department != 'Directing'">
                    <div v-for="credit in sortPersonUnreleased(selectedPerson.data.combined_credits.crew, 'cast')" v-bind:key="credit.nr">
                        <div class="personUnreleasedItem" v-if="credit.release_date == '0000' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)"><span class="opacityReducer">TBD · </span>{{credit.title}}<span class="opacityReducer"> · Movie</span></div>
                        <div class="personUnreleasedItem" v-if="credit.release_date == '0000' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)"><span class="opacityReducer">TBD · </span>{{credit.name}}<span class="opacityReducer"> · Show</span></div>
                    </div>
                </div>

                <div v-if="selectedPerson.data.known_for_department == 'Directing'">
                    <div v-for="credit in sortPersonUnreleased(selectedPerson.data.combined_credits.crew, 'crew')" v-bind:key="credit.nr">
                        <div class="personUnreleasedItem" v-if="credit.release_date == '0000' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)"><span class="opacityReducer">TBD · </span>{{credit.title}}<span class="opacityReducer"> · Movie</span></div>
                        <div class="personUnreleasedItem" v-if="credit.release_date == '0000' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)"><span class="opacityReducer">TBD · </span>{{credit.name}}<span class="opacityReducer"> · Show</span></div>
                    </div>
                </div>
            </div>

            <!-- section full history -->
            <div id="personSectionFullHistory" class="personSection">
                <div v-if="selectedPerson.data.known_for_department != 'Directing'">
                    <!-- without year categories -->
                    <!-- <div v-for="credit in sortPersonFullHistory(selectedPerson.data.combined_credits.cast)">
                        <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.title}} <span class="opacityReducer"> · Movie</span></div>
                        <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.name}} <span class="opacityReducer"> · Show</span></div>
                    </div> -->

                    <!-- with year categories -->
                    <div class="fullHistoryYearCategory" v-for="year in sortPersonFullHistoryYears(selectedPerson.data.combined_credits.cast)" v-bind:key="year">
                        <div class="fullHistoryYearCategoryText">{{year}}</div>

                        <div v-for="credit in sortPersonFullHistory(selectedPerson.data.combined_credits.cast)">
                            <div v-if="credit.release_date == year">
                                <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.title}} <span class="opacityReducer"> · Movie</span></div>
                                <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.name}} <span class="opacityReducer"> · Show</span></div>
                            </div>
                        </div>

                        <!-- might use later -->
                        <!-- <div style="display: flex; overflow-x: auto;" id="test">
                            <div v-for="credit in sortPersonFullHistory(selectedPerson.data.combined_credits.cast)">
                                <div v-if="credit.release_date == year">
                                    <img style="width: 62vw; margin: 0px 16px 0px 0px;" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + credit.poster_path" />
                                </div>
                            </div>
                        </div> -->
                    </div> 
                </div>
                
                <div id="" v-if="selectedPerson.data.known_for_department == 'Directing'">
                    <!-- without year categories -->
                    <!-- <div v-for="credit in sortPersonFullHistory(selectedPerson.data.combined_credits.crew, 'crew')">
                        <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.title}} <span class="opacityReducer"> · Movie</span></div>
                        <div class="personFullHistoryItem" v-if="credit.release_date != '0000' && credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.name}} <span class="opacityReducer"> · Show</span></div>
                    </div> -->

                    <!-- with year categories -->
                    <div class="fullHistoryYearCategory" v-for="year in sortPersonFullHistoryYears(selectedPerson.data.combined_credits.crew, 'crew')" v-bind:key="year">
                        <div class="fullHistoryYearCategoryText">{{year}}</div>
                    
                        <div v-for="credit in sortPersonFullHistory(selectedPerson.data.combined_credits.crew, 'crew')">
                            <div v-if="credit.release_date == year">
                                <div class="personFullHistoryItem" v-if="credit.media_type == 'movie'" v-on:click="setUrl('movie', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.title}} <span class="opacityReducer"> · Movie</span></div>
                                <div class="personFullHistoryItem" v-if="credit.media_type == 'tv'" v-on:click="setUrl('show', credit.id)"><span class="opacityReducer">{{credit.release_date}} · </span>{{credit.name}} <span class="opacityReducer"> · Show</span></div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import {ref, toRef, toRefs, reactive, computed, watch, watchEffect, onMounted, onBeforeMount, beforeUpdate, onUpdated, onBeforeUpdate} from 'vue'
import {useStore} from 'vuex'
import { useRouter, useRoute } from 'vue-router' //instead of this.$route
import router from '../router'

export default {
    setup() {
      //vuex
      const store = useStore() //same as this.$store
      const selectedPerson = computed(() => { return store.getters['selectedPerson']})
      const selectedPersonPoster = computed(() => { return store.getters['selectedPersonPoster']})

      //lifecycle hooks
      onMounted(() => {
        console.log("onMounted ComponentPerson")
      })

      onUpdated(() => {
        console.log("onUpdated ComponentPerson")
        
        //elements
        let componentOptionsBar = document.getElementById("componentOptionsBar")
        let modalReview = document.getElementById("modalReview")

        let personSectionLatest = document.getElementById("personSectionLatest")
        let personSectionKnownFrom = document.getElementById("personSectionKnownFrom")
        if(personSectionLatest) { personSectionLatest.scroll(0,0) }
        if(personSectionKnownFrom) { personSectionKnownFrom.scroll(0,0) }

        displayPersonSection('FullHistory')
        
        //display options bar
        componentOptionsBar.style.display = "block"

        //undisplay modal review
        modalReview.style.display = "none"
      })
        
        function sortPersonUnreleased(array, type)
        {
            for(let item in array)
            {
                let movieReleaseDate = array[item].release_date
                let showFirstAirDate = array[item].first_air_date

                if(movieReleaseDate) 
                { 
                    if(movieReleaseDate == "") { array[item].release_date = '0000'}
                    else {array[item].release_date = movieReleaseDate.substr(0, 4)}
                }
                else if(!movieReleaseDate)
                {
                    array[item].release_date = "0000"
                }
                if(showFirstAirDate) 
                { 
                    if(showFirstAirDate == "") { array[item].release_date = '0000'}
                    else {array[item].release_date = showFirstAirDate.substr(0, 4)}
                }
            }

            if(type == "crew") 
            { 
                array = [...new Map(array.map((m) => [m.id, m])).values()];

                //release date latest first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { return b.release_date - a.release_date}
                })

                return array
            }
            else 
            { 
                //release date latest first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { return b.release_date - a.release_date}
                })

                return array 
            }
        }
        
        function sortPersonFullHistory(array, type)
        {
            for(let item in array)
            {
                let movieReleaseDate = array[item].release_date
                let showFirstAirDate = array[item].first_air_date

                if(movieReleaseDate) 
                { 
                    if(movieReleaseDate == "") { array[item].release_date = '0000'}
                    else {array[item].release_date = movieReleaseDate.substr(0, 4)}
                }
                else if(!movieReleaseDate)
                {
                    array[item].release_date = "0000"
                }
                if(showFirstAirDate) 
                { 
                    if(showFirstAirDate == "") { array[item].release_date = '0000'}
                    else {array[item].release_date = showFirstAirDate.substr(0, 4)}
                }
            }

            if(type == "crew") 
            { 
                array = [...new Map(array.map((m) => [m.id, m])).values()];

                //release date latest first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { return b.release_date - a.release_date}
                })

                return array
            }
            else 
            { 
                //release date latest first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { return b.release_date - a.release_date}
                })

                return array 
            }
        }
        
        function sortPersonFullHistoryYears(array, type)
        {
            
            if(type == "crew") 
            { 
                let crewArray = []

                for(let item in array)
                {
                    let i = array[item]

                    if(i.release_date != null && i.release_date != "" && i.release_date != "0000") 
                    { 
                        crewArray.push(i.release_date.substr(0, 4)) 
                    }
                }

                crewArray = [...new Set(crewArray)]
                
                crewArray.sort((a, b) => { return b - a })

                return crewArray
            }
            else 
            { 
                let castArray = []

                for(let item in array)
                {
                    if(array[item].release_date != "0000") { castArray.push(array[item].release_date) }
                }

                castArray = [...new Set(castArray)]

                return castArray 
            }
        }
        
        function sortPersonLatest(array, type)
        {
            for(let item in array)
            {
                let movieReleaseDate = array[item].release_date
                let showFirstAirDate = array[item].first_air_date
                
                if(movieReleaseDate) 
                { 
                    if(movieReleaseDate == "") { array[item].release_date = '0000'}
                    else {array[item].release_date = movieReleaseDate.substr(0, 4)}
                }
                else if(!movieReleaseDate)
                {
                    array[item].release_date = "0000"
                }
                if(showFirstAirDate) 
                { 
                    if(showFirstAirDate == "") { array[item].release_date = '0000'}
                    else {array[item].release_date = showFirstAirDate.substr(0, 4)}
                }
            }

            //release date latest first
            // array.sort((a, b) => { if(a.release_date != null) { return b.release_date - a.release_date} })

            if(type == "crew") 
            { 
                array = [...new Map(array.map((m) => [m.id, m])).values()];

                //release date latest first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { return b.release_date - a.release_date}
                })

                return array.slice(0, 10)
            }
            else 
            { 
                //release date latest first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { return b.release_date - a.release_date}
                })

                return array.slice(0, 10) 
            }
        }
        
        function sortPersonTop(tempArray, type)
        {
            let array = []
    
            //filter out invalid items
            for (let item in tempArray)
            {
                if(tempArray[item].release_date != '' && tempArray[item].vote_count > '1000' && tempArray[item].vote_average != '0')
                {
                    array.push(tempArray[item])
                }
            }

            //check type
            if(type == "crew") 
            { 
                array = [...new Map(array.map((m) => [m.id, m])).values()];
                
                //set top first
                array.sort((a, b) => {
                    if(a.release_date != null)
                    { 
                        return b.vote_average - a.vote_average
                    }
                })

                return array.slice(0, 10)
            }
            else 
            { 
                //set top first
                array.sort((a, b) => { return b.vote_average - a.vote_average })

                return array.slice(0, 10) 
            }
        }
        
        function totalMoviesAndShows(array)
        {
            let arrayActor = array.cast
            let arrayProducer = array.crew
            let totalMoviesAsActor = 0
            let totalShowsAsActor = 0
            let totalMoviesAsProducer = 0
            let totalShowsAsProducer = 0

            for(let item in arrayActor)
            {
                if(arrayActor[item].media_type != null)
                {
                    if(arrayActor[item].media_type == "movie") {totalMoviesAsActor++}
                    else if(arrayActor[item].media_type == "tv") {totalShowsAsActor++}
                }
            }

            for(let item in arrayProducer)
            {
                if(arrayProducer[item].media_type != null)
                {
                    if(arrayProducer[item].media_type == "movie") {totalMoviesAsProducer++}
                    else if(arrayProducer[item].media_type == "tv") {totalShowsAsProducer++}
                }
            }

            return {actor: {movies: totalMoviesAsActor, shows: totalShowsAsActor}, producer: {movies: totalMoviesAsProducer, shows: totalShowsAsProducer}}
        }

        function setUrl(type, id)
        {
            //set url
            router.push({path: '/' + type + '/' + id})
        }

        function displayPersonSection(sectionName)
        {
            let personSectionUnreleased = document.getElementById("personSectionUnreleased")
            let personSectionLatest = document.getElementById("personSectionLatest")
            let personSectionBiography = document.getElementById("personSectionBiography")
            let personSectionKnownFrom = document.getElementById("personSectionKnownFrom")
            let personSectionFullHistory = document.getElementById("personSectionFullHistory")
            let personSectionTop = document.getElementById("personSectionTop")
            let personUnreleasedTitle = document.getElementById("personUnreleasedTitle")
            let personLatestTitle = document.getElementById("personLatestTitle")
            let personBiographyTitle = document.getElementById("personBiographyTitle")
            let personKnownFromTitle = document.getElementById("personKnownFromTitle")
            let personFullHistoryTitle = document.getElementById("personFullHistoryTitle")
            let personTopTitle = document.getElementById("personTopTitle")
            let selectedSection = document.getElementById("personSection" + sectionName)
            let selectedTitle = document.getElementById("person" + sectionName + "Title")

            if(personSectionUnreleased) { personSectionUnreleased.style.display = "none" }
            if(personSectionLatest) { personSectionLatest.style.display = "none" }
            if(personSectionBiography) { personSectionBiography.style.display = "none" }
            if(personSectionKnownFrom) { personSectionKnownFrom.style.display = "none" }
            if(personSectionFullHistory) { personSectionFullHistory.style.display = "none" }
            if(personSectionTop) { personSectionTop.style.display = "none" }

            if(personUnreleasedTitle) { personUnreleasedTitle.style.opacity = "0.3" }
            if(personLatestTitle) { personLatestTitle.style.opacity = "0.3" }
            if(personBiographyTitle) { personBiographyTitle.style.opacity = "0.3" }
            if(personKnownFromTitle) { personKnownFromTitle.style.opacity = "0.3" }
            if(personFullHistoryTitle) { personFullHistoryTitle.style.opacity = "0.3" }
            if(personTopTitle) { personTopTitle.style.opacity = "0.3" }
            if(selectedTitle) { selectedTitle.style.opacity = "1" }
            
            if(sectionName == "KnownFrom" || sectionName == "Latest" || sectionName == "Top") 
            { 
                if(selectedSection) { selectedSection.style.display = "flex"; }
                // selectedSection.style.width = "50%" 
                // selectedSection.style.paddingRight = "7px"
            }
            else { if(selectedSection) {selectedSection.style.display = "block"} }
        }

      return {
        //vuex
        selectedPerson,
        selectedPersonPoster,

        //functions
        totalMoviesAndShows,
        setUrl,
        displayPersonSection,
        sortPersonFullHistory,
        sortPersonLatest,
        sortPersonFullHistoryYears,
        sortPersonUnreleased,
        sortPersonTop
      }
    }

}
</script>

<style scoped>
  /*** scrollbar ***/
  #personInfo { scrollbar-width: none;}
  #personSectionKnownFrom::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; } /* width */
  #personSectionKnownFrom::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
  #personSectionKnownFrom::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
  #personSectionLatest::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; } /* width */
  #personSectionLatest::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
  #personSectionLatest::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
  #personSectionTop::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; } /* width */
  #personSectionTop::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
  #personSectionTop::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */

  .personFullHistoryItem { scrollbar-width: none;}
  .personUnreleasedItem { scrollbar-width: none;}
  .personLatestItem { scrollbar-width: none;}

  /*** elements  ***/

  /*** ids ***/
  #personInfo 
  { 
    position: relative; 
    height: auto; /* calc(68vh - 6px) */
    height: 54vh;
    width: 60%; 
    left: 0;
    margin: auto;
    padding: 0px;
    text-align: left;
    font-weight: bold; 
    /* z-index: 1;  */
    text-shadow: 2px 1px black; 
    overflow-y: auto; 
    color: white; 
    /* border-top: 1px solid white; */
  }
  #personName { margin-top: 0px; font-size: 27px; font-weight: bold; }
  #KnownFromText { width: 38vw; display: block; padding: 0px 10px 0px 16px; background-color: #ffffff0e; }
  #biographyList { margin: 0px 0px 0px -38px; list-style: none; }
  #KnownFromTitleMovie { margin-top: 12px; font-weight: bold;}
  #KnownFromTitleShow { margin-top: 12px; font-weight: bold;}
  #KnownFromSummary { max-height: 206px; margin-top: -10px; opacity: 0.8; overflow-y: auto; }
  #KnownFromText p { padding-right: 10px; flex-direction: row; }
  #personName, #personAge, #personBornDate, #personBornPlace, #personActedIn, #personProduced { text-align: center;}
  #personSectionInfoText { display: none; margin: 0px; padding: 20px 0px 0px 0px; font-weight: bold; border: 0px solid white; }
  #personUnreleasedTitle { text-align: left;}
  #personLatestTitle { text-align: left;}
  #personFullHistoryTitle { text-align: left;}
  #personBiographyTitle { opacity: 1; }
  #personKnownFromTitle { }
  #personNameTop { display: block; margin: 0px; font-weight: bold; color: white; text-align: center; }
  #personBornPlace { display: block; margin: 0px 0px 20px 0px; font-weight: bold; color: white; text-align: center; }
  #personSectionKnownFrom { display: none; flex-direction: row; margin: auto; padding: 0px 9px 0px 0px; overflow-x: auto;}
  #personSectionLatest { display: none; flex-direction: row; margin: auto; padding: 0px 9px 0px 0px; overflow-x: auto;}
  #personSectionTop { display: none; flex-direction: row; margin: auto; padding: 0px     9px 0px 0px; overflow-x: auto;}
  #personSectionUnreleased { display: none; margin: auto; padding: 0px 7px 0px 0px;}
  #personSectionBiography { display: none; margin: auto; padding: 0px 40px 0px 0px;}
  #personSectionFullHistory { display: none; margin: auto; padding: 0px 7px 0px 0px;}
  #personCategories 
  { 
    position: relative; 
    display: flex; 
    /* flex-direction: row;  */
    width: 60%; 
    margin: auto;
    margin-top: 16px; 
    margin-bottom: 16px;
    padding: 0px; 
    /* text-align: center;  */
    justify-content: center;    
    overflow-x: auto; 
    /* background-color: black; */
  }
  #personPoster { height: 30vh; width: 260px; margin: 0px; border-radius: 100%; }
  #componentPerson { width: 60vw; margin: auto; }

  /*** classes ***/
  .KnownFrom { display: flex; margin: auto; margin-bottom: 17px; margin-right: 20px; width: 80%; border: 1px solid rgb(48, 46, 46) }
  .Latest{ display: flex; margin: auto; margin-bottom: 17px; margin-right: 20px; width: 80%; border: 1px solid rgb(48, 46, 46) }
  .Top { display: flex; margin: auto; margin-bottom: 17px; margin-right: 20px; width: 80%; border: 1px solid rgb(48, 46, 46) }
  .LatestImg { margin: 0px; padding: 0px; height: 390px; width: 260px; user-select: none; }
  .KnownFromImg { margin: 0px; padding: 0px; height: 390px; width: 260px; user-select: none; }
  .TopImg { margin: 0px; padding: 0px; height: 390px; width: 260px; user-select: none; }
  .personText { margin: 0px; padding: 4px 0px 0px 0px;}
  .biographyLine { margin: 0px 0px 20px 0px; padding: 0px;}
  .personSection { position: relative; width: auto; margin: 0px; padding: 0px; border-top: 0px solid rgba(255, 255, 255, 0.3); }
  .personSectionTitle 
  { 
    display: inline-block; 
    margin: 0px; 
    padding: 0px; 
    text-align: left; 
    user-select: none; 
    font-size: 18px; 
    opacity: 0.3;
    white-space: nowrap;
    color: white;
    /* border: 1px solid white; */
    /* background-color: white; */
   }
  .personUnreleasedItem 
  {
    width: auto; 
    margin: auto; 
    padding: 0px 0px 10px 0px; 
    user-select: none; 
    text-align: left;
    white-space: nowrap;
    overflow-x: auto;
    border: 0px solid white; 
  }
  .personLatestItem 
  {
    width: auto; 
    margin: auto; 
    padding: 0px 0px 10px 0px; 
    user-select: none; 
    text-align: left;
    white-space: nowrap;
    overflow-x: auto;
    border: 0px solid white; 
  } 
  .personFullHistoryItem 
  { 
    margin: auto; 
    padding: 0px 0px 10px 0px; 
    user-select: none; 
    text-align: left;
    white-space: nowrap;
    overflow-x: auto;
    border: 0px solid white; 
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1)  */
  }
  /* .personUnreleasedItem:hover, .personLatestItem:hover, .personFullHistoryItem:hover { font-weight: bold;} */
  .personUnreleasedItem:active, .personLatestItem:active, .personFullHistoryItem:active { font-weight: bold;}
  .fullHistoryYearCategoryText { padding: 0px 0px 6px 0px; font-size: 24px; text-align: left; user-select: none;}
  .fullHistoryYearCategory { margin: 0px 0px 30px 0px; }
  .latestYearCategoryText { padding: 0px 0px 6px 10px; font-size: 24px; text-align: left; user-select: none;}
  .latestYearCategory { margin: 0px; }
  .opacityReducer { opacity: 0.4;}
  .dotChar { padding: 0px 10px 0px 10px; opacity: 0.4; color: white; }
      
    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        #personSectionKnownFrom::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; } /* width */
        #personSectionLatest::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; } /* width */
        #personSectionTop::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; } /* width */

        #componentPerson { position: absolute; height: calc(60vh - 40px); width: 100vw; left: 0vw; margin: auto; font-size: 13px; }
        #personInfo { height: auto; max-height: calc(60vh - 40px); width: 90vw; margin: auto; padding: 0px 0px 0px 3px; font-size: 18px;}
        #KnownFromText { display: none;}
        #personSectionInfoText { margin: auto;}
        #personNameTop { margin: 0px; padding: 0px; }
        #personCategories { display: flex; width: 60vw; padding-left: 74px; }
        #personPoster { height: 20vh; width: 149px; }
        #personSectionKnownFrom { display: none; flex-direction: row; height: 100%; width: 90vw; margin: auto; padding: 0px; overflow-x: auto;}
        #personSectionLatest {  display: none; flex-direction: row; height: 100%; width: 90vw; margin: auto; padding: 0px; overflow-x: auto;}
        #personSectionTop {  display: none; flex-direction: row; height: 100%; width: 90vw; margin: auto; padding: 0px; overflow-x: auto;}
        #personSectionBiography { width: 100%; margin: 0px; padding: 0px;}
        #personSectionUnreleased { width: 100%; }
        #personSectionFullHistory { width: 100%; }
        #biographyList { width: 90%; margin: 20px 0px 0px -20px; }

        .KnownFromImg { height: auto; width: 62vw; margin: auto; border: 1px solid #ffffff0e}
        .LatestImg { height: auto; height: 100%; width: 62vw; margin: auto; border: 1px solid #ffffff0e}
        .TopImg { height: auto; height: 100%; width: 62vw; margin: auto; border: 1px solid #ffffff0e}
        .personFullHistoryItem { padding: 0px 0px 10px 0px; white-space: nowrap; overflow-x: auto; font-size: 17px; text-align: left;}
        .personUnreleasedItem { white-space: nowrap; overflow-x: auto;}
        .personLatestItem { white-space: nowrap; overflow-x: auto;}
        .KnownFrom { height: 100%; margin: 0px 16px 0px 0px; padding: 0px;}
        .Latest { height: 100%; margin: 0px 16px 0px 0px; padding: 0px;}
        .Top { height: 100%; margin: 0px 16px 0px 0px; padding: 0px;}
        .personSection { width: 90vw; margin: auto; padding: 3px 0px 0px 0px;}
        .personText { white-space: nowrap; overflow-x: scroll;}
        .fullHistoryYearCategoryText {padding: 0px 0px 6px 0px;}
    }
</style>