<template>
  <div id="nav">
    <router-view />
  </div>
</template>

<script>
export default {
    setup() {
      return {

      }
    }

}
</script>

<style>
  /*** scrollbar  ***/
  html { scrollbar-width: none;}

  /*** elements ***/
  a { text-decoration: none; }
  body { margin: 0px 20vw 0px 20vw; padding: 0px; /* background-color: #41B883; */ background-color: black; }

  /*** ids ***/
  #app { margin: 0px; padding: 0px; font-family: Avenir, Helvetica, Arial, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-align: center; color: #2c3e50; }
  #nav { padding-bottom: 0px; padding-left: 0px; padding-right: 0px; padding-top: 0px; }
  /* #nav a { font-weight: bold; color: #2c3e50; } */
  /* #nav a.router-link-exact-active { color: #42b983;} */

  /*** classes ***/

  /*** scrollbar ***/
  ::-webkit-scrollbar { height: 0px; width: 0px; } /* width */ 
  ::-webkit-scrollbar-track { background-color: black; /* #41B883 */ } /* Track */
  ::-webkit-scrollbar-thumb { background-color: white; } /* Handle */

  /*** mobile ***/
  @media screen and (max-width: 1100px) {
    body {
      margin: 0px; padding: 0px;
    }
  }
</style>
