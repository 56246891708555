<template>
  <div id="componentLists">
      <!-- search list bar -->
      <div id="listsSearchList">
        <input id="listSearchListInput" maxlength="100"/>
        <div id="listSearchListSubmitButton" v-on:click="searchLists()">Search</div>
      </div>

      <!-- selected list -->
      <div id="listSelectedItem" v-if="getSelectedList">
        <p id="listViewTitle" v-if="getSelectedList.name">{{getSelectedList.name}}</p>
        
        <div id="listViewInfo">
            <!-- list items count -->
            <p class="listViewText listViewTypes"  v-if="getSelectedList.items"><span>{{getSelectedList.items.length}} items</span></p>
            <p v-else>no items</p>

            <!-- list categories -->
            <p class="listViewText listViewCategories" v-if="getSelectedList.categories">
                <!-- list has 1 category -->
                <span v-if="getSelectedList.categories.split(';')[0] && getSelectedList.categories.split(';')[0] != ' '">
                    #{{getSelectedList.categories.split(";")[0]}}
                </span>

                <!-- list has 2 categories -->
                <span v-if="getSelectedList.categories.split(';')[1] && getSelectedList.categories.split(';')[1] != ' '">
                    #{{getSelectedList.categories.split(";")[1]}}
                </span>
                
                <!-- list has 3 catgories -->
                <span v-if="getSelectedList.categories.split(';')[2] && getSelectedList.categories.split(';')[2] != ' '">
                    #{{getSelectedList.categories.split(";")[2]}}
                </span>
            </p>
            <p v-else>no categories</p>

            <!-- list description -->
            <p id="listViewDescription" class="listViewText" v-if="getSelectedList.description">{{getSelectedList.description}}</p>
            <p id="listViewDescription" class="listViewText" v-else="getSelectedList.description">no description</p>
          </div>

          <!-- selected list items -->
          <div id="listItems">
          <div class="listItem" v-for="item in getSelectedList.items.slice().sort((a, b) => {return a.order - b.order})" v-bind:key="item.nr">
              <!-- list img -->
              <div class="listItemImg" v-if="item.thumbnail" v-on:click="setUrl(item.type, item.id)">
                <img class="listItemThumbnail" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + item.thumbnail" />
              </div>
              
              <!-- list item order -->
              <p class="listItemOrder">{{item.order}}</p>
              
              <!-- <div v-if="item.type != 'person'"> -->
                <!-- <p class="listItemName">#{{item.order}} · {{item.name}} · {{item.release_date.substr(0, 4)}} · {{firstLetterToUpperCase(item.type)}}</p>
                <p class="listItemText">{{item.description}}</p> -->

              <!-- </div>     -->
              <!-- <div v-else-if="item.type == 'person'"> -->
                <!-- <p class="listItemName">#{{item.order}} · {{item.name}} · {{firstLetterToUpperCase(item.type)}}</p> -->
              <!-- </div>     -->
           </div>
          </div>

       </div>

      <!-- list menu -->
      <div id="listMenu">
        <!-- searched list results -->
        <div id="listsSearchResults">
            <div v-bind:id="'list-' + list.name.toLowerCase()" class="list" v-for="list in reactiveListsSearchResults.value" v-bind:key="list.nr" v-on:click="setUrl('list', list.id)">
                <!-- list img -->
                <div id="listBackgroundImg" v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + list.items.slice().sort((a, b) => b.order - a.order)[0].thumbnail + '); background-size: cover;'"></div>
                <!-- <div id="listBackgroundImg" v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + list.items[0].thumbnail + '); background-size: cover;'"></div> -->
                
                <!-- list info text -->
                <div id="listInfo">
                    <!-- list name -->
                    <div class="listName" v-if="list.name">{{list.name}}</div>
                    
                    <!-- list item count -->
                    <p class="listText listViewTypes"  v-if="list.items">
                        <span v-if="list.items">{{list.items.length}} items</span>
                    </p>

                    <!-- <p class="listText listCategories" v-if="list.categories"> -->
                        <!-- <span v-for="c in list.categories.toString().split(';')" v-bind:key="c.nr">{{c}} · </span> -->
                        <!-- <span v-if="list.categories">#{{list.categories.replaceAll(";", " #").slice(0, -1)}}</span> -->
                    <!-- </p> -->
                    
                    <!-- <p class="listText" v-if="list.description">{{list.description}}</p> -->
                </div>
            </div>
        </div>
      </div>

       <!-- select list catgory bar -->
      <!-- <div id="listsCategories"> -->
        <!-- create list button -->
        <!-- <div id="+" class="listCategory" v-if="getSessionUserData" v-on:click="displayModalLists()">+<span class="dot"> · </span></div> -->
        
        <!-- liked lists button -->
        <!-- <div id="list-category-liked" class="listCategory" v-if="getSessionUserData" v-on:click="specificListCategory('liked')">liked<span class="dot"> · </span></div> -->
        
        <!-- owned lists button -->
        <!-- <div id="list-category-owned" class="listCategory" v-if="getSessionUserData" v-on:click="specificListCategory('owned')">owned<span class="dot"> · </span></div> -->
        

        <!-- all lists button -->
        <!-- <div id="list-category-all" class="listCategory" v-if="getSessionPublicLists" v-on:click="specificListCategory('all')">All<span class="dot"> · </span></div> -->
        
        
        <!-- list categories -->
        <!-- <div v-bind:id="'list-category-' + c" class="listCategory" v-for="c in allListCategories().sort()" v-bind:key="c.nr" v-bind:value="c" v-on:click="specificListCategory(c)">
            <span>{{c.substr(0, 1).toUpperCase()}}{{c.substr(1)}}</span>
            <span class="dot"> · </span>
        </div> -->
      <!-- </div> -->

          <!-- modal select list category-->
        <div id="listsCategories">
            <div id="createNewList" v-on:click="displayModalLists()">+</div>
            <div id="selectedListTitle" v-on:click="displayModalListCategories()"></div>

            <div id="modalListCategories">
                <p id="listCategoriesTitle">List Categories</p>

                <!-- toplist categories -->
                <div id="listCategories">
                    <p class="listCategory" v-on:click="specificListCategory('all')">All</p>
                    <p class="listCategory" v-for="c in allListCategories().sort()" v-on:click="specificListCategory(c)">{{firstLetterToUpperCase(c)}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, reactive, computed, onUpdated, onMounted} from 'vue'
import {useStore} from 'vuex'
import router from '../router'
import { firstLetterToUpperCase } from '../assets/utility.js'

export default {
    setup()
    {
        //vuex
        const store = useStore() //same as this.$store
        const getSessionUserData = computed(() => { return store.getters['sessionUserData']})
        const getSessionPublicLists = computed(() => { return store.getters['sessionPublicLists']})
        const getSelectedList = computed(() => { return store.getters['selectedList']})

        //reactive
        var reactiveListsSearchResults = reactive({value: null})
        var reactiveSelectedList = reactive({value: null})
        // var reactiveSessionToken = reactive({value: null})
        // var reactiveSessionUsername = reactive({value: null})
        // var reactiveSessionLastLogin = reactive({value: null})
        // var reactiveSessionUserData = reactive({value: null})
        // var reactiveSessionAllUserLists = reactive({value: null})
        // var reactiveSelectedReview = reactive({value: null, type: null})

        //variables
        const BACKEND_API_URL = computed(() => { return store.getters['backendAPI']}).value
        
        //lifecycle hooks
        onMounted(() => {
            console.log("onMounted ComponentLists")

            //variables
            let lsPublicLists = localStorage.getItem('sessionPublicLists')
            
            //check public lists
            if(lsPublicLists == null || lsPublicLists.length == 0){ fetchPublicLists()}
            else if(lsPublicLists != null) { lsPublicLists = JSON.parse(lsPublicLists); store.dispatch('actionSetSessionPublicLists', lsPublicLists) }
            specificListCategory('all') 
        })

        onUpdated(() => {
            console.log("onUpdated ComponentLists")
        })

        function searchLists()
        {
            //elements
            let allLists = document.getElementsByClassName("list")
            let listSearchListInput = document.getElementById("listSearchListInput")
            let searchBarSubmitButton = document.getElementById("searchBarSubmitButton")
            let listCategoryAll = document.getElementById("list-category-all")
            let listSearchListSubmitButton = document.getElementById("listSearchListSubmitButton")
            
            //variables
            let searchHitCount = 0

            //reset elements
            for(let list in allLists)
            {
                let listId = allLists[list].id
                let listSearchString = listSearchListInput.value.toLowerCase()

                if(listId) 
                { 
                    if(!listId.includes(listSearchString)) 
                    {
                        allLists[list].style.display = "none"
                    }
                    else if(listId.includes(listSearchString)) 
                    {
                        allLists[list].style.display = "block"; 
                        searchHitCount++
                    }
                }
            }
            
            //update elements
            searchBarSubmitButton.value = "Search (" + searchHitCount + ")"
            listCategoryAll.innerText = "all (" + searchHitCount + ")"
        }

        function allListCategories()
        {
            //variables
            let listCategories = ["temp"]
            let lsPublicLists = localStorage.getItem("sessionPublicLists")
            let lsUserData = localStorage.getItem("sessionUserData")
            let ownedLists = null
            let publicLists = null
            let likedLists = []

            //set owned lists
            if(lsUserData)
            {
                lsUserData = JSON.parse(lsUserData)
                if(lsUserData.lists.length > 0) {ownedLists = lsUserData.lists }
            }

            //set liked lists
            if(lsUserData)
            {
                if(lsUserData.likes.length > 0)
                {
                    for(let item in lsUserData.likes)
                    {
                        if(lsUserData.likes[item].type == "list") { likedLists.push(lsUserData.likes[item])}
                    }
                }
            }

            //set public lists
            if(lsPublicLists)
            {
                publicLists = JSON.parse(lsPublicLists)
            }

            //filter public lists categories
            if(publicLists && publicLists.length > 0)
            {
                for(let list in publicLists)
                {
                    let categories = publicLists[list].categories
    
                    if(categories != null)
                    {
                        let categoriesSplit = publicLists[list].categories.split(";")
    
                        for(let category in categoriesSplit)
                        {
                            let currentCategory = categoriesSplit[category]
                            let categoryExists = listCategories.indexOf(currentCategory)
    
                            if(categoryExists == false || categoryExists == "-1")
                            {
                                if(currentCategory != "") { listCategories.push(currentCategory)}
                            }
                        }
                    }
                }
            }
            
            //filter owned lists categories
            if(ownedLists && ownedLists.length > 0)
            {
                for(let list in ownedLists)
                {
                    let categories = ownedLists[list].categories
    
                    if(categories != null)
                    {
                        let categoriesSplit = ownedLists[list].categories.split(";")
    
                        for(let category in categoriesSplit)
                        {
                            let currentCategory = categoriesSplit[category]
                            let categoryExists = listCategories.indexOf(currentCategory)
    
                            if(categoryExists == false || categoryExists == "-1")
                            {
                                if(currentCategory != "") { listCategories.push(currentCategory)}
                            }
                        }
                    }
                }
            }
            
            //filter liked lists categories
            if(likedLists && likedLists.length > 0)
            {
                for(let list in likedLists)
                {
                    let categories = likedLists[list].categories
    
                    if(categories != null)
                    {
                        let categoriesSplit = likedLists[list].categories.split(";")
    
                        for(let category in categoriesSplit)
                        {
                            let currentCategory = categoriesSplit[category]
                            let categoryExists = listCategories.indexOf(currentCategory)
    
                            if(categoryExists == false || categoryExists == "-1")
                            {
                                if(currentCategory != "") { listCategories.push(currentCategory)}
                            }
                        }
                    }
                }
            }

            //remove temp from list categories
            listCategories.splice(0, 1)
            
            //list categories to lower case
            for(let c in listCategories)
            {
                let categoryName = listCategories[c].toString().toLowerCase()
                listCategories[c] = categoryName
            }

            //remove category duplicates
            listCategories = [...new Set(listCategories)]

            //sort alphabetically
            listCategories.sort()

            //remove empty category
            listCategories.splice(0, 1)
            
            return listCategories
        }

        function specificListCategory(category)
        {
            //elements
            let listsSearchResults = document.getElementById("listsSearchResults")
            let selectListCategory = document.getElementById("list-category-" + category)
            let listCategory = document.getElementsByClassName("listCategory")
            let listSearchListSubmitButton = document.getElementById("listSearchListSubmitButton")
            let allLists = document.getElementsByClassName("list")
            let listSearchListInput = document.getElementById("listSearchListInput")
            let modalListCategories = document.getElementById("modalListCategories")
            let selectedListTitle = document.getElementById("selectedListTitle")
            if(category == "") { selectListCategory = document.getElementById("list-category-all")}
            
            //variables
            let specificCategoryLists = []
            let lsPublicLists = localStorage.getItem("sessionPublicLists")
            let lsUserData = localStorage.getItem("sessionUserData")
            let likedLists = []
            let publicLists = null
            let ownedLists = null
                
 

            //reset elements list categories
            // for(let c in listCategory)
            // {
            //     let listCategoryElement = document.getElementById(listCategory[c].id)

            //     if(listCategoryElement)
            //     {
            //         // listCategoryElement.style.backgroundColor = "white"
            //         // listCategoryElement.style.color = "black"
            //         // listCategoryElement.style.border = "1px solid white"
            //         listCategoryElement.style.opacity = "0.3"
            //         listCategoryElement.innerHTML = listCategoryElement.id.replace("list-category-", "").substring(0, 1).toUpperCase() + listCategoryElement.id.replace("list-category-", "").substring(1) + "<span class='dot' style='padding-left: 4px; padding-right: 6px;'> · </span>"
            //         listCategoryElement.style.fontWeight = "normal"
            //     }
            // }

            //update elements selected list category
            // selectListCategory.style.backgroundColor = "black"
            // selectListCategory.style.color = "white"
            // selectListCategory.style.border = "1px solid white"
            // selectListCategory.style.opacity = "1"
            listSearchListSubmitButton.innerText = "Search"
            listSearchListInput.value = ""
            
            //display selected list category
            for(let list in allLists)
            {
                if(allLists[list].id) { allLists[list].style.display = "block"}
            }

            //set public lists
            publicLists = JSON.parse(lsPublicLists)
            
            //set owned lists
            if(lsUserData)
            {
                lsUserData = JSON.parse(lsUserData)
                ownedLists = lsUserData.lists
            }

            //set liked lists
            if(lsUserData)
            {
                if(lsUserData.likes.length > 0)
                {
                    for(let item in lsUserData.likes)
                    {
                        if(lsUserData.likes[item].type == "list") { likedLists.push(lsUserData.likes[item])}
                    }
                }
            }
            
            //set selected category lists
            if(category == "all")
            {
                //add all public lists
                for(let list in publicLists)
                {
                    specificCategoryLists.push(publicLists[list])
                }

                //add all private owned lists
                if(ownedLists != null)
                {
                    for(let list in ownedLists)
                    {
                        let p = ownedLists[list].public

                        if(p == false)
                        {
                            specificCategoryLists.push(ownedLists[list])
                        }
                    }
                }

                //add all liked lists
                if(likedLists != null)
                {
                    for(let item in likedLists)
                    {
                        if(likedLists[item].type == "list"){ specificCategoryLists.push(likedLists[item])}
                    }
                }
            }
            else if(category == "liked" && ownedLists != null)
            {
                //add all liked lists
                for(let item in likedLists)
                {
                    if(likedLists[item].type == "list"){ specificCategoryLists.push(likedLists[item])}
                }
            }
            else
            {
                //add all public lists
                for(let list in publicLists)
                {
                    let categories = publicLists[list].categories

                    if(categories != null)
                    {
                        if(categories.includes(category) || categories.includes(category + ";") || categories.includes(";" + category))
                        {
                            specificCategoryLists.push(publicLists[list])
                        }
                    }
                }

                //add all private owned lists
                if(ownedLists != null)
                {
                    for(let list in ownedLists)
                    {
                        let categories = ownedLists[list].categories
                        let p = ownedLists[list].public

                        if(p == false && categories != null)
                        {
                            if(categories.includes(category + ";") || categories.includes(";" + category))
                            {
                                specificCategoryLists.push(ownedLists[list])
                            }
                        }
                    }
                }
                
                //add all liked lists
                if(likedLists.length != 0)
                {
                    for(let item in likedLists)
                    {
                        let temp = likedLists[item]
                        
                        if(temp && temp.type == "list" && temp.categories != null)
                        { 
                            if(temp.toString().includes(category) || temp.categories.toString().includes(category + ";") || temp.categories.toString().includes(";" + category))
                            {
                                specificCategoryLists.push(temp)
                            }
                        }
                    }
                }

            }

            //remove duplicates
            specificCategoryLists = [...new Map(specificCategoryLists.map((m) => [m.id, m])).values()];
            
            //set search results
            reactiveListsSearchResults.value = specificCategoryLists.slice().reverse()

            //update elements
            if(listsSearchResults) { listsSearchResults.style.display = "flex" }
            if(selectListCategory) { 
                selectListCategory.innerHTML = category.substr(0, 1).toUpperCase() + category.substr(1) + " (" + specificCategoryLists.length + ")" + "<span class='dot' style='padding-left: 4px; padding-right: 6px; opacity: 0.4;'> · </span>"
                selectListCategory.style.fontWeight = "bold"
            }
            if(category == "") { selectListCategory.innerText = "All (" + specificCategoryLists.length + ")" }

            modalListCategories.style.display = "none"
            selectedListTitle.innerText = firstLetterToUpperCase(category) + " (" + specificCategoryLists.length + ")"
        }
        
        function setUrl(type, id)
        {
            router.push({path: '/' + type + '/' + id})
        }

        async function fetchPublicLists()
        {
            let url = BACKEND_API_URL + "/public-lists"
    
            //fetch data
            await fetch(url, {method: 'get'})
            .then((response) => {
                    console.log("public lists fetched from API")
                    return response.json()
            })
            .then((data) => {
                // console.log(data)

                //set local storage
                localStorage.setItem('sessionPublicLists', JSON.stringify(data))

                //update vuex
                store.dispatch('actionSetSessionPublicLists', data)
            })
        }

        function displayModalLists()
        {
            console.log("display list modal")
            
            let sessionUserData = localStorage.getItem("sessionUserData")
            //check if logged in
            if(sessionUserData == null) { return }

            //elements
            let modalLists = document.getElementById("modalLists")
            let modalUnderlay = document.getElementById("modalUnderlay")
            let componentOptionsBar = document.getElementById("componentOptionsBar")
            let optionsBar = document.getElementById("optionsBar")
            
            //display underlay
            modalUnderlay.style.display = "block"
            componentOptionsBar.style.display = "block"
            optionsBar.style.display = "none"
                
            //reset modal lists
            // resetModalLists()

            if(modalLists.style.display == "none" || modalLists.style.display == "") { modalLists.style.display = "block" }
            else if(modalLists.style.display == "block") { modalLists.style.display = "none" }
        }

        function displayModalListCategories()
        {
            let modalListCategories = document.getElementById("modalListCategories")

            modalListCategories.style.display = "block"
        }

        return {
            //vuex
            getSessionUserData,
            getSessionPublicLists,
            getSelectedList,

            //variables
            reactiveSelectedList,
            reactiveListsSearchResults,
            
            //functions
            firstLetterToUpperCase,
            searchLists,
            allListCategories,
            specificListCategory,
            setUrl,
            displayModalLists,
            displayModalListCategories
        }
    }
}
</script>

<style scoped>
    /*** scrollbar  ***/
    #listViewTitle { scrollbar-width: none;}
    #listViewDescription { scrollbar-width: none;}
    #listItems { scrollbar-width: none;}
    #listItems::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; } /* width */
    #listItems::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
    #listItems::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
    #listsSearchResults::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; } /* width */
    #listsSearchResults::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
    #listsSearchResults::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
    #listsCategories::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; width: 10px; } /* width */
    #listsCategories::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
    #listsCategories::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
    #listsCategories { scrollbar-width: thin; }
    #listMenu { scrollbar-width: none;}
    .listItemName { scrollbar-width: none;}

    /*** elements ***/
    
    /*** ids ***/
    #componentLists { display: none; height: auto; width: 60vw; margin: 0px; overflow: auto; scrollbar-width: none;}
    #listImg { display: inline-block; width: 30%; padding: 28px 0px 26px 0px; border-right: 1px solid #ffffff26; background-color: black;}
    #listInfo 
    { 
        position: relative;
        display: inline-block; 
        height: auto;
        width: 240px;
        margin: -236px 0px 0px 0px;
        padding: 10px; 
        vertical-align: top; 
        text-align: left; 
        user-select: none;
        color: white;
    }
    #listItemInfo { display: inline-block; margin: 0px; padding: 10px 0px 0px 16px; vertical-align: top; text-align: left; overflow-x: auto; color: white; }
    #listSelectedItem { display: block; margin: 0px;}  
    #listViewTitle { margin: 0px 0px 11px 0px; padding: 0px; font-weight: bold; font-size: 31px; white-space: nowrap; overflow-x: auto; text-align: center; color: white;}
    #likeHeartCharacter { margin: 0px; padding: 0px; font-size: 49px; color: white;}
    #listOptions { margin: 0px 0px 8px 0px; padding: 0px; text-align: right;}
    #listViewDescription { white-space: nowrap; overflow-x: auto;}
    #listViewInfo { height: auto; width: 80%; margin: auto; margin-bottom: 70px; padding: 0px; opacity: 0.8; user-select: none; }
    #listMenu { display: block; max-height: 84vh; margin: 10px 0px 0px 0px; overflow-y: auto;}
    #listsSearchResults { display: none; overflow-x: auto; flex-direction: row; padding-bottom: 20px; }
    #listsSearchList { display: none; height: 100%; margin: 10px 0px 20px 0px; text-align: center;}
    #listsCategories { margin: 2px 0px 2px 0px; padding: 0px; overflow-x: auto; z-index: 2; }
    #listSearchListInput { margin: auto; padding: 7px; font-size: 19px; font-weight: bold; outline: none; color: white; border: 1px solid white; background-color: black;}
    #listSearchListSubmitButton { display: inline-block; margin: 0px 0px 0px -1px; padding: 8px 10px 8px 10px; vertical-align: top; font-size: 17px; font-weight: bold; user-select: none; color: black; border: 1px solid white; background-color: white;}
    #listItems { position: relative; display: flex; height: auto; width: auto; left: 0vw; margin: auto; margin-top: 30px; overflow-x: auto;}
    #listBackgroundImg 
    {  
        position: relative;
        display: block;
        width: 260px;
        height: 100%;
        background-size: cover;
        z-index: 0;
        opacity: 0.6;
        /* border-bottom: 1px solid rgb(48, 46, 46); */
    }
    #modalListCategories 
    { 
        display: block;
        position: fixed; 
        height: 100vh; 
        width: 100vw; 
        top: 0px;
        margin: auto; 
        margin-left: -20vw;
        padding: 2vh 0px 0px 0px; 
        z-index: 3;
        overflow-y: scroll;
        background-color: black; /* #181818 */ 
    }
    #listCategories
    {
        width: 20vw;
        margin: auto;
        margin-top: 20px;
        height: 86vh;
        overflow-y: scroll;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    #listCategoriesTitle
    {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        color: white;
    }
    #selectedListTitle 
    { 
        display: block; 
        margin: 19px 0px 0px 16px; 
        font-size: 18px; 
        font-weight: bold; 
        text-align: center;
        text-shadow: 2px 1px black;
        user-select: none;
        color: white; 
    }
    #createNewList 
    { 
        display: block; 
        margin: 0px; 
        font-size: 49px ; 
        font-weight: bold; 
        text-align: center;
        text-shadow: 2px 1px black;
        color: white; 
    }

    /*** classes ***/
    .list 
    { 
        display: flex; 
        height: 390px; 
        margin: 0px 30px 0px 0px; 
        padding: 0px; 
        flex-direction: column;
        /* opacity: 0.9;  */
        border: 2px solid white; 
        /* background-color: #ffffff26;  */
    }
    .listItem { display: block; margin: 0px 20px 0px 20px; padding: 0px; opacity: 0.9}
    .listText 
    { 
        max-height: 102px; 
        margin: 0px; 
        padding: 0px 0px 4px 0px; 
        text-align: center;
        font-weight: bold; 
        opacity: 0.7; 
        user-select: none; 
        white-space: nowrap;
        overflow-x: auto;
        text-shadow: 2px 1px black
    }
    .listItemText { max-height: 220px; margin: 0px; padding: 0px 10px 0px 0px; text-align: left; opacity: 0.9; overflow-y: auto;}
    .listImg { height: auto; width: auto; left: 0px; margin: 0px; padding: 0px;}
    .listName 
    { 
        width: 90%; 
        margin: auto; 
        padding: 0px; 
        overflow-x: auto; 
        word-break: keep-all; 
        font-weight: bold; 
        text-align: center; 
        user-select: none; 
        text-shadow: 2px 1px black; 
        font-size: 24px;
        white-space: nowrap;
        overflow-x: scroll;
    }
    .listItemName { width: 90%; margin: 0px; padding: 4px 0px 9px 0px; overflow-x: auto; word-break: keep-all; white-space: nowrap; font-weight: bold; text-align: left; overflow-x: auto;}
    .listCategories { margin: 0px; }
    .listViewCategories { }
    .listViewTypes { }
    .listViewType { margin: 0px; padding: 0px; }
    .listViewText {margin: 4px 0px 0px 0px; padding: 0px; text-align: center; font-weight: bold; opacity: 0.7; color: white; }
    .listItemThumbnail { height: auto; margin: 0px; width: 260px; border: 1px solid rgba(48, 46, 46, 0.4); }
    .listItemImg { display: inline-block; margin: 0px; padding: 0px; user-select: none; }
    .listItemOrder { opacity: 0.7; font-weight: bold; user-select: none; color: white;}
    .dot { color: white; padding: 0px 6px 0px 4px; opacity: 0.4; }
    .listCategory 
    { 
        width: auto; 
        margin: 0px; 
        padding: 16px 16px 16px 10px; 
        font-size: 18px; 
        user-select: none; 
        white-space: nowrap; 
        border-style: none; 
        text-align: left;
        font-weight: bold;
        text-shadow: 2px 1px black;
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        /* background-color: white; */
    }
    .listCategory:active { background-color: rgba(255, 255, 255, 0.1); }

    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        #listItems::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; } /* width */
        #listsSearchResults::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; } /* width */
        #listsCategories::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; width: 0px; } /* width */

        #componentLists { height: 93vh; width: auto; margin: auto;  }
        #listsSearchResults { display: flex; flex-direction: column; margin: 0px; padding: 0px; }
        #listViewTitle { margin: 0px; white-space: nowrap; overflow-x: auto; font-size: 27px; text-align: center;}
        #listViewDescription { overflow-x: auto; text-align: center;}
        #listItems { display: flex; height: auto; width: 90vw; margin: 0px 0px 0px 20px; overflow-x: auto;}
        #listItemInfo { display: none; width: auto;}    
        #listViewInfo { min-height: inherit; width: 83vw; margin: auto; margin: 0px 0px 24px 0px; margin-left: 7vw; padding: 0px;}
        #listsCategories { position: fixed; margin: 0px; padding: 0px; }
        #listBackgroundImg { position: initial; height: 20vh; width: auto; z-index: 0; }
        #listInfo { width: 100%; position: relative; margin-top: -13vh; padding: 0px; z-index: 1; background-color: transparent; }
        #listMenu { height: auto; max-height: none; margin: auto; overflow-y: auto; }
        #modalListCategories { margin: auto; margin-left: 0px; padding: 0px; }
        #listCategories { width: 90vw; }
        #selectedListTitle { position: fixed; bottom: 21px; left: calc(60px - 6px); font-size: 19px; }
        #createNewList { position: fixed; bottom: 0px; left: 18px; font-size: 60px; }


        .listItem { display: block; width: 100%; border: 0px solid white;}
        .listViewText { text-align: center;}
        .listItemText { max-height: 130px; max-width: 420px;}
        .listViewType { padding-right: 7px;}
        .listCategory { font-size: 19px;}
        .listItemThumbnail { height: auto; width: 70vw; border: 1px solid rgba(48, 46, 46, 0.4);}
        .listItemImg { width: auto; margin: auto; border: 0px solid white;}
        .listCategory { padding: 0px; }
        .list { height: 20vh; margin: 0px; border: 1px solid rgba(48, 46, 46, 0.4); }
        .listName { font-size: 22px; opacity: 0.9; }
        .listCategory 
        { 
            margin: 0px; 
            padding: 16px 16px 16px 10px; 
            text-align: left;
            font-weight: bold;
            font-size: 18px;
            text-shadow: 2px 1px black;
            user-select: none;
            color: white;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            /* background-color: white; */
        }
        .listCategory:active { background-color: rgba(255, 255, 255, 0.1); }
        /* .listViewTypes { margin: 10px 0px 0px 0px; } */
        /* .list { width: 86vw; margin: 0px 0px 20px 0px; border: 0px solid white;} */
        /* .listName { width: 90%; white-space: nowrap; } */
        /* .listText { width: 80%; overflow-x: auto;} */
    }
</style>