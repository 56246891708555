<template>
  <div id="componentFeatured">
      <!-- select category nav -->
      <div id="selectFeaturedCategory">
        <p id="selectTypeButtonMovies" class="selectTypeButton" v-on:click="displayCategory('movies')">Movies</p><span class="dot"> · </span>
        <p id="selectTypeButtonShows" class="selectTypeButton" v-on:click="displayCategory('shows')">Shows</p>
        <!-- <p id="selectTypeButtonPeople" class="selectTypeButton" v-on:click="displayCategory('people')">people</p> -->
      </div>
      
      <!-- featured movies -->
      <div id="featuredMovies" class="featured" v-if="getFeatured">
          <div class="featuredItem" v-for="item in getFeatured.slice().filter((item) => {return item.media_type == 'movie' && item.poster_path != null})" v-bind:key="item.nr" v-on:click="setUrl(item.media_type, item.id)">
            <img class="featuredImg" v-if="item.poster_path" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + item.poster_path">
          </div>
      </div>

      <!-- featured shows -->
      <div id="featuredShows" class="featured" v-if="getFeatured">
          <div class="featuredItem" v-for="item in getFeatured.slice().filter((item) => {return item.media_type == 'tv' && item.poster_path != null})" v-bind:key="item.nr" v-on:click="setUrl(item.media_type, item.id)">
            <img class="featuredImg" v-if="item.poster_path" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + item.poster_path">
          </div>
      </div>

      <!-- featured people -->
      <!-- <div id="featuredPeople" class="featured" v-if="getFeatured">
          <div class="featuredItem" v-for="item in getFeatured.slice().filter((item) => {return item.media_type == 'person' && item.profile_path != null})" v-bind:key="item.nr" v-on:click="setUrl(item.media_type, item.id)">
            <img class="featuredImg" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + item.profile_path">
          </div>
      </div> -->
  </div>
</template>

<script>
import {useStore} from 'vuex'
import {ref, reactive, computed, onUpdated, onMounted} from 'vue'
import router from '../router'

export default {
    setup() {
        //vuex
        const store = useStore() //same as this.$store
        const getFeatured = computed(() => { return store.getters['featured']})

        //lifecycle hooks
        onMounted(() => {
            console.log("onMounted ComponentFeatured")
        })
        
        onUpdated(() => {
            console.log("onUpdated ComponentFeatured")
        })

        //functions
        function setUrl(type, id)
        {
            //check type
            if(type == "movie") { }
            else if(type == "tv") { type = "show"}

            //set url
            router.push({path: '/' + type + '/' + id})
        }

        function displayCategory(name)
        {
            //elements
            let featuredMovies = document.getElementById("featuredMovies")
            let featuredShows = document.getElementById("featuredShows")
            // let featuredPeople = document.getElementById("featuredPeople")
            let selectTypeButtonMovies = document.getElementById("selectTypeButtonMovies")
            let selectTypeButtonShows = document.getElementById("selectTypeButtonShows")
            // let selectTypeButtonPeople = document.getElementById("selectTypeButtonPeople")

            //reset elements
            featuredMovies.style.display = "none"
            featuredShows.style.display = "none"
            // featuredPeople.style.display = "none"
            // selectTypeButtonMovies.style.fontWeight = "normal"
            // selectTypeButtonShows.style.fontWeight = "normal"
            // selectTypeButtonPeople.style.fontWeight = "normal"
            selectTypeButtonMovies.style.opacity = "0.4"
            selectTypeButtonShows.style.opacity = "0.4"
            // selectTypeButtonPeople.style.opacity = "0.4"

            //update elements
            if(name == "movies") 
            { 
                selectTypeButtonMovies.style.fontWeight = "bold"; 
                featuredMovies.style.display = "flex"
                selectTypeButtonMovies.style.opacity = "1"
            }
            else if(name == "shows") 
            { 
                selectTypeButtonShows.style.fontWeight = "bold"; 
                featuredShows.style.display = "flex"
                selectTypeButtonShows.style.opacity = "1"
            }
            // else if(name == "people") { selectTypeButtonPeople.style.fontWeight = "bold"; featuredPeople.style.display = "flex" }
        }
      
        return {
            //variables
            getFeatured,

            //functions
            setUrl,
            displayCategory
        }
    }
}
</script>

<style scoped>
    /*** scrollbar  ***/
    /* #featured { scrollbar-width: thin;} */
    ::-webkit-scrollbar { height: 10px; }
    ::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ }
    ::-webkit-scrollbar-thumb { background-color: rgba(255, 255, 255, 0.1); }

    /*** elements ***/

    /*** ids ***/
    #componentFeatured {display: none;}
    #featuredMovies { display: flex; }
    #featuredShows { display: none; }
    #featuredPeople { display: none; }
    #selectFeaturedCategory 
    { 
        display: block; 
        width: auto; 
        margin: 0px; 
        padding: 0px 0px 12px 0px; 
        text-align: left;
        font-size: 18px;
        user-select: none;
        text-shadow: 2px 1px black;
    }
    #selectTypeButtonMovies { font-weight: bold; opacity: 1; }
    #selectTypeButtonShows { font-weight: bold; opacity: 0.4; }

    /*** classes ***/
    .featured { display: flex; width: 60vw; flex-direction: row; overflow-x: auto;}
    .featuredImg { height: auto; width: 260px; user-select: none;}
    .featuredItem 
    {
        display: flex;
        height: auto;
        width: auto;
        margin: 4px 27px 21px 0px;
        padding: 0px;
        opacity: 0.9;
        color: white;
        border: 1px solid rgba(48, 46, 46, 0.4);
        /* background-color: #ffffff26; */
    }
    .featuredItem:active { opacity: 1;}
    .selectTypeButton { display: inline-flex; margin: 0px; padding: 0px; color: white; }
    .dot { color: white; padding: 0px 4px 0px 4px; opacity: 0.4; }
    .thumbnailPerson { height: 160px; width: 160px; opacity: 0.8; border-radius: 100%; border: 1px solid rgba(48, 46, 46, 0.4);}
        
    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        ::-webkit-scrollbar { /* margin-top: 10px; */ width: 0px; height: 0px; } /* width */

        #componentFeatured {height: auto; width: 100vw; margin: auto; overflow-y: auto;}
        #selectFeaturedCategory 
        { 
            position: fixed; 
            bottom: 12px; 
            width: 100%;
            margin: 0px; 
            padding: 8px 0px 10px 20px; 
            text-align: left;
            font-size: 19px;
            z-index: 1;
        }
        
        .featured::-webkit-scrollbar { /* margin-top: 10px; */ width: 0px; height: 0px; } /* width */
        .featured { width: 100vw; margin: 0px; align-items: center;}
        .featuredImg { margin: 0px; height: calc(93vh + 4px) /* calc(92vh - 6px) */; width: 100vw; border-right: 1px solid rgba(48, 46, 46, 0.4);}
        .featuredItem { margin: 0px; padding: 0px; border: 0px solid black;}
        .selectTypeButton { }
    }
</style>