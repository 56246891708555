<template>
  <div id="componentInstall">
    <div id="installPWA">
            <!-- debugging -->
            <!-- <div v-if="windowNavigator"> -->
                <!-- <span>User Agent:{{windowNavigator.userAgent}}</span><br /> -->
                <!-- <span>Vendor: {{windowNavigator.vendor}}</span><br /> -->
                <!-- <span>Platform: {{windowNavigator.platform}}</span><br /> -->
                <!-- <span>App: {{appTitle}}</span> -->
            <!-- </div> -->

            <!-- detect user device -->
            <div id="detectDevice">
              <span id="deviceDesktop">
                  <a class="deviceInfoText" href="/teruno_installer_windows.msi" v-if="windowNavigator.platform.toLowerCase().includes('win')">{{windowNavigator.platform}} · Desktop · Windows</a>
                  <a class="deviceInfoText" href="/teruno_installer_mac.dmg" v-else-if="windowNavigator.platform.toLowerCase().includes('apple')">{{windowNavigator.platform}} · Desktop · macOS</a>
                  <a class="deviceInfoText" href="/teruno_installer_mac.dmg" v-else-if="windowNavigator.platform.toLowerCase().includes('mac')">{{windowNavigator.platform}} · Desktop · macOS</a>
                  <a class="deviceInfoText" href="/teruno_installer_linux.deb" v-else-if="windowNavigator.platform.toLowerCase().includes('linux')">{{windowNavigator.platform}} · Desktop · Linux</a>
                  <span class="deviceInfoText" v-else>{{windowNavigator.platform}} · Desktop · Undetected</span>
              </span>

              <span id="deviceMobile">
                <a class="deviceInfoText" href="/teruno_installer_android.apk" v-if="windowNavigator.platform.toLowerCase().includes('android')">{{windowNavigator.platform}} · Mobile · Android</a>
                <a class="deviceInfoText" href="/teruno_installer_android.apk" v-else-if="windowNavigator.platform.toLowerCase().includes('linux')">{{windowNavigator.platform}} · Mobile · Android</a>
                <span class="deviceInfoText" v-else-if="windowNavigator.platform.toLowerCase().includes('apple')">{{windowNavigator.platform}} · Mobile · iOS</span>
                <span class="deviceInfoText" v-else-if="windowNavigator.platform.toLowerCase().includes('iphone')">{{windowNavigator.platform}} · Mobile · iOS</span>
                <span class="deviceInfoText" v-else-if="windowNavigator.platform.toLowerCase().includes('ipad')">{{windowNavigator.platform}} · Mobile · iOS</span>
                <span class="deviceInfoText" v-else>{{windowNavigator.platform}} · Mobile · Undetected</span>
              </span>
            </div>

            <!-- PWA install instructions -->
            <div id="browserInstallInstructions" class="browserInstallPWA">
              <!-- apple mobile devices -->
              <div id="appleDevices">
                  <div id="safariMobile" class="mobile">
                    <img class="browser-logo-img" src="/logos/safari_logo.png"/>
                    <b>Safari</b><br />
                    <span>Step 1: Wait 30 Seconds</span><br />
                    <span>Step 2: Press Menu Button</span><br />
                    <span>Step 3: Press Add to Home Screen </span><br />
                    <span>Step 4: Press Add</span><br />
                  </div>
              </div>

              <!-- undetected devices -->
              <div id="undetectedDevices">
                <p><b>Select Browser</b></p>

                <!-- install PWA on Safari -->
                <img class="browser-logo-img" src="/logos/safari_logo.png" v-on:click="displayInstallInstructions('safari')"/>
                <div id="safariInstallInstructions" class="installInstructions">
                    <b>Safari</b><br />
                    <div id="safariDesktop" class="desktop">
                      <span>Not supported</span>
                    </div>
                    <div id="safariMobile" class="mobile">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Share Button</span><br />
                      <span>Step 3: Press Add to Home Screen </span><br />
                      <span>Step 4: Press Add</span><br />
                    </div>
                </div>
  
                <!-- install PWA on Chrome -->
                <img class="browser-logo-img" src="/logos/chrome-logo.svg" v-on:click="displayInstallInstructions('chrome')"/>
                <div id="chromeInstallInstructions" class="installInstructions">
                    <b>Chrome</b><br />
                    <div id="chromeDesktop" class="desktop">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu</span><br />
                      <span>Step 3: Press Install {{appTitle}}...</span><br />
                      <span>Step 4: Press Install</span><br />
                    </div>
                    <div id="chromeMobile" class="mobile">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu</span><br />
                      <span>Step 3: Press Install app</span><br />
                      <span>Step 4: Press Install</span><br />
                    </div>
                </div>
  
                <!-- install PWA on Firefox -->
                <img class="browser-logo-img" src="/logos/firefox_logo.png" v-on:click="displayInstallInstructions('firefox')"/>
                <div id="firefoxInstallInstructions" class="installInstructions">
                    <b>Firefox</b><br />
                    <div id="firefoxDesktop" class="desktop">
                        <span>Not supported</span>
                    </div>
                    <div id="firefoxMobile" class="mobile">
                        <span>Step 1: Wait 30 Seconds</span><br />
                        <span>Step 2: Press Menu</span><br />
                        <span>Step 3: Press Install</span><br />
                        <span>Step 4: Press ADD</span><br />
                    </div>
                </div>
  
                <!-- install PWA on Edge -->
                <img class="browser-logo-img" src="/logos/edge_logo.png" v-on:click="displayInstallInstructions('edge')"/>
                <div id="edgeInstallInstructions" class="installInstructions">
                    <b>Edge</b><br />
                    <div id="edgeDesktop" class="desktop">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu Button</span><br />
                      <span>Step 3: Press Apps</span><br />
                      <span>Step 4: Press Install this site as an app</span><br />
                      <span>Step 5: Press Install</span><br />
                      <span>Step 6: Press Create Desktop shortcut</span><br />
                      <span>Step 7: Press Allow</span><br />
                    </div>
                    <div id="edgeMobile" class="mobile">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu Button</span><br />
                      <span>Step 3: Expand Menu by Swiping Up</span><br />
                      <span>Step 4: Press Add to phone</span><br />
                      <span>Step 5: Press Install</span><br />
                      <span>Step 6: Press ADD</span><br />
                    </div>
                </div>
  
                <!-- install PWA on Brave -->
                <img class="browser-logo-img" src="/logos/brave_logo.png" v-on:click="displayInstallInstructions('brave')"/>
                <div id="braveInstallInstructions" class="installInstructions">
                    <b>Brave</b><br />
                    <div id="braveDesktop" class="desktop">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu Button</span><br />
                      <span>Step 3: Press Install {{appTitle}}...</span><br />
                      <span>Step 4: Press Install</span><br />
                    </div>
                    <div id="braveMobile" class="mobile">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu Button</span><br />
                      <span>Step 3: Press Install app</span><br />
                      <span>Step 4: Press Install</span><br />
                      <span>Step 5: Press ADD</span><br />
                    </div>
                </div>
  
                <!-- install PWA on Vivaldi -->
                <img class="browser-logo-img" src="/logos/vivaldi_logo.png" v-on:click="displayInstallInstructions('vivaldi')"/>
                <div id="vivaldiInstallInstructions" class="installInstructions">
                    <b>Vivaldi</b><br />
                    <div id="vivaldiDesktop" class="desktop">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Right Click on Tab</span><br />
                      <span>Step 3: Press Install {{appTitle}}...</span><br />
                      <span>Step 4: Press Install</span><br />
                    </div>
                    <div id="vivaldiMobile" class="mobile">
                      <span>Step 1: Wait 30 Seconds</span><br />
                      <span>Step 2: Press Menu</span><br />
                      <span>Step 3: Press Install app</span><br />
                      <span>Step 4: Press Install</span><br />
                      <span>Step 5: Press ADD</span><br />
                    </div>
                </div>
              </div>
            </div>
        </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {ref, reactive, computed, onUpdated, onMounted} from 'vue'

export default {
    setup(){
        //variables
        var windowNavigator = window.navigator
        var appTitle = document.title
        // var deferredPrompt = null

        //automatic install pwa prompt
        // window.addEventListener('beforeinstallprompt', function (e) { 
        //     console.log("beforeinstallprompt event added")
        //     deferredPrompt = e; 
        //     showAddToHomeScreen(); 
        // }); 

        // function showAddToHomeScreen() 
        // { 
        //     console.log("showAddToHomeScreen")
        //     // var installApp = document.querySelector("installApp"); 
        //     var installApp = document.getElementById("installPWALogos"); 
            
        //     installApp.style.display = "block"; 
        //     installApp.addEventListener("click", addToHomeScreen); 
        // } 

        // function addToHomeScreen() { 
        //     console.log("addToHomeScreen")
        //     // var installApp = document.querySelector("installApp"); 
        //     // hide our user interface that shows our A2HS button 
        //     // installApp.style.display = 'none'; 

        //     // if(deferredPrompt.prompt() != null) 
        //     // {
        //         // Show the prompt 
        //         deferredPrompt.prompt()
                
        //         // Wait for the user to respond to the prompt 
        //         deferredPrompt.userChoice.then(
        //             function(choiceResult){ 
        //                 if (choiceResult.outcome === 'accepted') { console.log('User accepted install prompt'); } 
        //                 else { console.log('User dismissed install prompt'); }

        //                 deferredPrompt = null;
        //             }
        //         ); 
        //     // }
        //     // else if(deferredPrompt.prompt() == null) { console.log("app is already installed")}
        // }

        function displayInstallInstructions(browserName)
        {
            let browserInstallInstructions = document.getElementById(browserName + "InstallInstructions")

            if(browserInstallInstructions.style.display == "none" || browserInstallInstructions.style.display == "") 
            { 
                browserInstallInstructions.style.display = "block"
            }
            else if(browserInstallInstructions.style.display == "block") 
            { 
                browserInstallInstructions.style.display = "none"
            }
        }

        return {
            //variables
            windowNavigator,
            appTitle,

            //functions
            displayInstallInstructions
        }
    }
}
</script>

<style scoped>
/*** scrollbar ***/
#componentInstall::-webkit-scrollbar { height: 0px; width: 0px;}
#installPWA::-webkit-scrollbar { height: 0px; width: 0px;}

/*** elements ***/
/*** ids ***/
#componentInstall { display: none;}
#installPWA 
{ 
  position: fixed; 
  display: block; 
  height: 100vh; 
  width: 100vw;
  top: 0px;
  margin: 0px 0px 0px -20vw;
  padding: 0px;
  z-index: 10; 
  overflow-y: scroll; 
  text-align: left; 
  color: white;
  background-color: #101010;
}
#installProblems { display: none; position: fixed; width: 100%; bottom: 0px; margin: 0px; padding: 0px 0px 10px 0px; font-weight: bold; text-align: center; user-select: none;}
#installPWALogos { display: none; padding: 10px 0px 0px 0px; text-align: center;}
#browserInstallInstructions { display: block;}
#installLoadingText { position: absolute; width: 100%; top: 43vh; text-align: center; font-weight: bold;}
#deviceDesktop { display: block; text-align: center;}
#deviceMobile { display: none; text-align: center;}
#appleDevices { display: none; margin: 0px;}
#detectDevice { display: block; margin: 20px 0px 0px 0px;}
#undetectedDevices { display: none;}

/*** classes ***/
.logo { margin: 0px 0px 20px 0px;}  
.browserInstallPWA { margin: 0px 0px 20px 0px; text-align: center;}
.desktop { display: block;}
.mobile { display: none;}
.browserName { display: block; margin: 20px 0px 0px 0px; padding: 0px;}
.installInstructions { display: none;}
.browser-logo-img { display: block; height: 90px; margin: auto; margin-top: 20px; margin-bottom: 4px;}
.deviceInfoText { text-decoration: none; color: white; font-weight: bold;}

/*** mobile ***/
@media screen and (max-width: 1000px) {
  #installPWA { margin: 0px; }
  #installPWALogos { padding: 10px 0px 0px 0px; text-align: center;}
  #deviceDesktop { display: none;}
  #deviceMobile { display: block;}

  .desktop { display: none;}
  .mobile { display: block;}
  .browserInstallPWA { margin: 0px 0px 20px 0px;}
  .browserName { display: block; margin: 10px 0px 0px 0px; padding: 0px;}
}
</style>