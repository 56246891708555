<template>
  <div id="componentEpisodes"  v-if="selectedSeason" style="display: none;">
    <div id="episodesShowTitle" v-if="selectedSeason.showName">{{selectedSeason.showName}} · Season {{selectedSeason.season}}</div>
    <div v-if="selectedSeason.episodes" id="selectedSeasonEpisodes">
      <!-- <div v-on:click="fetchEpisodeDetails(selectedSeason.season, episode.episode_number)" v-for="episode in selectedSeason.episodes.slice().reverse()" v-bind:key="episode.episode_number" class="episode" v-bind:id="'season#' + selectedSeason.season + 'episode#' + episode.episode_number"> -->
      <!-- <b style="color: white;">Total Episodes {{selectedSeason.episodes.length}}</b><br /> -->
      <!-- <b style="color: white;">Start {{selectedSeason.episodes[0].air_date}}</b><br /> -->
      <!-- <b style="color: white;">End {{selectedSeason.episodes[selectedSeason.episodes.length - 1].air_date}}</b> -->
      <div v-bind:id="'season#' + selectedSeason.season + 'episode#' + episode.episode_number" class="episode" v-for="episode in selectedSeason.episodes.slice()" v-bind:key="episode.episode_number">
        <p v-if="episode" v-bind:id="'episode#' + episode.episode_number + 'Title'" class="episodeTitle" v-on:click="reviewEpisode(episode)">
          <span class="episodeNumber">#{{episode.episode_number}}</span>
          <span class="episodeName"> · {{episode.name}}</span>
          <span class="episodeDate">{{formatDate(episode.air_date)}}</span>
          </p>
        <div  v-bind:id="'season#' + selectedSeason.season + 'episode#' + episode.episode_number + 'Details'" class="episodeDetail">
        <!-- <img v-if="episode.still_path" v-bind:id="'season#' + selectedSeason.season + 'episode#' + episode.episode_number + 'Image'" class="episodeImage" v-bind:src="'https://www.themoviedb.org/t/p/w227_and_h127_bestv2' + episode.still_path">  -->
        <!-- <p v-if="episode.overview" v-bind:id="'season#' + selectedSeason.season + 'episode#' + episode.episode_number + 'Overview'" class="episodeOverview">{{ episode.overview }}</p> -->
        <!-- <p v-else v-bind:id="'season#' + selectedSeason.season + 'episode#' + episode.episode_number + 'Overview'" class="episodeOverview">Episode description not available</p> -->
        </div>
      </div>
    
    </div>

    <div id="underlayEpisodes"></div>
  </div>
</template>

<script>
import {ref, reactive, computed, onUpdated} from 'vue'
import {useStore} from 'vuex'

export default {
    setup() {
        //vuex
        const store = useStore() //same as this.$store
        const selectedSeason = computed(() => store.getters['selectedSeason'])
 
        //variables
        let selectedEpisode = null
        let localStorageData = []
        let sessionUserData = null

        //lifecycle hooks
        onUpdated(() => {
          console.log("episode list updated")          
          

          //elements
          let numberOfEpisodes = document.getElementsByClassName("episodeDetail").length
          let componentEpisodes = document.getElementById("componentEpisodes")
          let episodesShowTitle = document.getElementById("episodesShowTitle")
          // let componentEpisodes = document.getElementById("componentEpisodes")
        
          //variables
          let lsSessionUserData = localStorage.getItem("sessionUserData")

          //check if user logged in
          // if(lsSessionUserData == null)
          // {
          //     if(episodesShowTitle) {episodesShowTitle.style.top = "calc(38vh - 9px)" }
          //     if(componentEpisodes) {componentEpisodes.style.top = "calc(41vh - 4px)"; /* componentEpisodes.style.maxHeight = "calc(49vh + 42px)" */ }
          // }
          // else if(lsSessionUserData != null)
          // {
          //     if(episodesShowTitle) {episodesShowTitle.style.top = "42vh" }
          //     if(componentEpisodes) {componentEpisodes.style.top = "calc(46vh - 3px)"; /* componentEpisodes.style.maxHeight = "calc(49vh - 4px)" */ }
          // }

          displayEpisodeList()
          collapseAllEpisodes(numberOfEpisodes)

          //reset elements
          if(componentEpisodes && componentEpisodes.style.display == "none")
          {
            componentEpisodes.style.display = "block"
          }
        })

        // async function fetchEpisodeDetails(season, episode) 
        // {
        //     let episodeTitle = document.getElementById('season#' + season + 'episode#' + episode + 'Title')
        //     let episodeDetails = document.getElementById('season#' + season + 'episode#' + episode + 'Details')
        //     let episodeImage = document.getElementById('season#' + season + 'episode#' + episode + 'Image')
        //     let episodeOverview = document.getElementById('season#' + season + 'episode#' + episode + 'Overview')
            
        //     if(episodeDetails.style.display == "none")
        //     {
        //         episodeDetails.style.display = "block"
        //     }
        //     else if(episodeDetails.style.display == "block")
        //     {
        //         episodeDetails.style.display = "none"
        //     }
        // }

        function displayEpisodeList()
        {
          //elements
          let selectedSeasonEpisodes = document.getElementById("selectedSeasonEpisodes")
          let showInfo = document.getElementById("showInfo")

          //reset elements
          if(selectedSeasonEpisodes != null) { selectedSeasonEpisodes.style.display = "block" }
          if(showInfo) {showInfo.style.display = "none" }
        }

        function collapseAllEpisodes(numberOfEpisodes)
        {
          //reset elements
          for(var c = 0; c < numberOfEpisodes; c++)
          {
            var z = document.getElementsByClassName("episodeDetail")[c].id
            var e = document.getElementById(z)

            e.style.display = "none"
          }
        }

        function reviewEpisode(episode)
        {
          //check if logged in
          sessionUserData = localStorage.getItem("sessionUserData")
          if(sessionUserData == null) { return }
          
          //variables
          let data = JSON.stringify(episode)

          //elements
          let modalReview = document.getElementById("modalReview")
          let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
          let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")
          let reviewIncludeCommentCheckbox = document.getElementById("reviewIncludeCommentCheckbox")
          let reviewCommentTextarea = document.getElementById("reviewCommentTextarea")
          let reviewComment = document.getElementById("reviewComment")
          let modalUnderlay = document.getElementById("modalUnderlay")
          let sessionUserData = localStorage.getItem('sessionUserData')
          let optionsBar = document.getElementById("optionsBar")
          

          //set data
          data = JSON.parse(data)
            
          //set sessionUserData
          sessionUserData = JSON.parse(sessionUserData)

          //set vuex
          store.dispatch('actionSetSelectedEpisode', data)

          if(modalReview.style.display == "none" || modalReview.style.display == "")
          {
            //reset review modal
            modalReview.style.display = "block"
            reviewRatingSliderRange.value = 0
            reviewRatingSliderValueText.innerText = "0"
            reviewIncludeCommentCheckbox.checked = false
            reviewIncludeCommentCheckbox.value = "off"
            reviewCommentTextarea.value = ""
            reviewComment.style.display = "none"
            if(modalUnderlay) {modalUnderlay.style.display = "block" }
            optionsBar.style.display = "none"

            for(let r in sessionUserData.reviews)
            {
              if(sessionUserData.reviews[r].type == 'episode' && sessionUserData.reviews[r].id == data.id)
              {
                console.log("episode review exists")

                //update existing review rating and comment
                // sessionUserData.reviews[r].rating = newReview.rating
                // sessionUserData.reviews[r].comment = newReview.comment
                // episodeReviewExists = true
                // console.log(sessionUserData.reviews[r])
                // console.log(reviewRatingSliderValueText.innerText)
                // console.log(reviewRatingSliderValueText.innerText)
                    
                //set review rating
                reviewRatingSliderRange.value = parseReviewRating(sessionUserData.reviews[r].rating)
                reviewRatingSliderValueText.innerText = sessionUserData.reviews[r].rating

                //set review comment
                if(sessionUserData.reviews[r].comment != "") 
                {
                  reviewIncludeCommentCheckbox.checked = true 
                  reviewIncludeCommentCheckbox.value = "on"
                  reviewCommentTextarea.value = sessionUserData.reviews[r].comment
                  reviewComment.style.display = "block"
                }
              }
            }
          }
          else if(modalReview.style.display == "block")
          {
            //undisplay review modal
            modalReview.style.display = "none"

            //set vuex
            store.dispatch('actionSetSelectedEpisode', null)
          }
        }

        function parseReviewRating(rating)
        {
            if(rating == 10) {return 100}
            else if(rating != 10) {return parseInt(rating.replace(".", ""))}
        }

        function formatDate(date)
        {
            let year = null
            let month = null
            let day = null

            if(date == null || date == "") 
            { 
              date = "??-???-????"
            }
            else
            {
              year = date.substring(0, 4)
              month = date.substring(5, 7)
              day = date.substring(8, 10)
              date = day + "-" + month + "-" + year 
    
              if (date.includes("-01-")) { date = date.replace("-01-", "-Jan-") }
              else if (date.includes("-02-")) { date = date.replace("-02-", "-Feb-") }
              else if (date.includes("-03-")) { date = date.replace("-03-", "-Mar-") }
              else if (date.includes("-04-")) { date = date.replace("-04-", "-Apr-") }
              else if (date.includes("-05-")) { date = date.replace("-05-", "-May-") }
              else if (date.includes("-06-")) { date = date.replace("-06-", "-Jun-") }
              else if (date.includes("-07-")) { date = date.replace("-07-", "-Jul-") }
              else if (date.includes("-08-")) { date = date.replace("-08-", "-Aug-") }
              else if (date.includes("-09-")) { date = date.replace("-09-", "-Sep-") }
              else if (date.includes("-10-")) { date = date.replace("-10-", "-Oct-") }
              else if (date.includes("-11-")) { date = date.replace("-11-", "-Nov-") }
              else if (date.includes("-12-")) { date = date.replace("-12-", "-Dec-") }
            }

            return date
        }

        return {
            //variables
            selectedSeason,

            //functions
            // fetchEpisodeDetails,
            reviewEpisode,
            formatDate
        }
    }
}
</script>

<style scoped>
  /*** scrollbar  ***/
  #componentEpisodes { scrollbar-width: none;}
  .episode p { scrollbar-width: none;}

  /*** elements ***/
  ::-webkit-scrollbar { height: 0px; width: 0px; } /* width */

  /*** ids ***/
  #selectedSeasonEpisodes { margin: auto; margin-top: 0px; border-top: 0px solid black; user-select: none;}
  #componentEpisodes 
  { 
    display: none; 
    position: absolute; 
    max-height: calc(84vh + 4px); 
    width: 40vw; 
    top: 8vh; 
    left: 31vw; 
    margin: 0px; 
    padding: 0px; 
    overflow-y: auto; 
    z-index: 3;
  }
  #episodesShowTitle 
  { 
    display: block; 
    position: fixed; 
    width: 40vw; 
    top: 4vh; 
    margin: 0px; 
    padding: 0px 0px 0px 10px; 
    font-size: 27px; 
    font-weight: bold; 
    text-shadow: 2px 1px black; 
    white-space: nowrap; 
    overflow-x: auto; 
    user-select: none;  
    text-align: center; 
    color: white;
  }
  #underlayEpisodes { display: none; position: fixed; height: 100vh; width: 100vw; top: 0px; left: 0vw; z-index: -1;}
  /* #reviewEpisode { margin: 0px 0px 0px 0px; padding: 0px; font-size: 20px; } */

  /*** classes ***/
  .episode { text-align: left; /* background-color: #FED90F; */ /* background-color: black; */ }
  .episode p 
  { 
    margin: 0px; 
    padding: 11px 11px 11px 11px; 
    user-select: none; 
    text-shadow: 2px 1px black; 
    white-space: nowrap; 
    overflow-x: auto;
    font-size: 17px;
    color: white; 
    border-top: 1px solid rgba(255, 255, 255, 0.2); 
  }
  .episodeDetail { /* background-color: black; */ /* color: white; */ }
  .episodeImage { float: right; height: 125px; padding: 10px; }
  .episodeOverview { /* min-height: 125px; */ /* background-color: rgba(0, 0, 0, 0.1); */ }
  .episodeName { display: inline-block; width: 100%; margin-left: 4px;}
  .episodeDate { display: block; margin: 0px; opacity: 0.6; }
  .episodeNumber { text-shadow: 2px 1px black;}
  /* .episodeTitle { color: black; } */
  /* .episode p:hover, .episode p:active { background-color: white; color: #242424; } */
    
  /*** mobile ***/
  @media screen and (max-width: 1100px) {
    #componentEpisodes {max-height: inherit; max-height: 90vh; width: 88vw; top: calc(40vh + 10px); left: 0px; margin: -34vh 0px 0px 0px;}
    #episodesShowTitle { position: fixed; width: 90vw; top: inherit; margin: auto; margin-top: -39vh; padding: 0px 0px 0px 20px; text-align: left; white-space: nowrap; overflow-x: auto; z-index: 2; font-size: 22px;}
    #underlayEpisodes { display: none; position: fixed; height: 100%; width: 100%; top: 0px; z-index: -1; } /* background-color: rgba(0, 0, 0, 0.2); */
    #selectedSeasonEpisodes { height: 82vh; }

    .episodeTitle {white-space: nowrap; }
    .episode p { padding: 0px 11px 22px 20px; overflow-x: auto; font-size: 19px; opacity: 0.9; border-top: 0px solid white;}
    .episodeDate { margin: 0px; opacity: 0.4;}
    .episodeName { width: 90%;}
  }
</style>