<template>
  <div id="componentOptionsBar">
      <!-- sidebar -->
      <div id="optionsBar">
        <span id="Like" class="heartCharacter" v-on:click="like()">❥</span> <!-- class="heart"  -->
        <span id="Review" v-on:click="review(false)">★</span>
        <span id="List" v-on:click="displayModalLists()">+</span>
        <!-- &#x2764; -->
        <!-- <div class="heart"></div> -->
      </div>

      <!-- modal review -->
      <div id="modalReview">
          <!-- review data -->
          <input id="reviewData" type="hidden" v-if="reviewFromAccountPage" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'editFromAccountPage', data: reviewFromAccountPage})" />
          <input id="reviewData" type="hidden" v-else-if="selectedMovie" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'movie', data: selectedMovie})"  />
          <input id="reviewData" type="hidden" v-else-if="selectedShow && selectedEpisode == null" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'show', data: selectedShow})" />
          <input id="reviewData" type="hidden" v-else-if="selectedPerson" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'person', data: selectedPerson})" />
          <input id="reviewData" type="hidden" v-else-if="selectedEpisode" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'episode', data: selectedEpisode})" />
          <input id="reviewData" type="hidden" v-else-if="selectedList" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'list', data: selectedList})" />
          
          <!-- review title -->
          <div id="reviewType" class="reviewType" v-if="reviewFromAccountPage && reviewFromAccountPage.type == 'episode'">
            <div class="reviewTitle"><b>Review</b></div>
            <div id="reviewType" class="reviewType">Episode · {{reviewFromAccountPage.show}} S{{reviewFromAccountPage.season}}E{{reviewFromAccountPage.number}} · {{reviewFromAccountPage.name}}</div>
            <!-- Review · {{reviewFromAccountPage.show}} · S{{reviewFromAccountPage.season}}E{{reviewFromAccountPage.number}}: {{reviewFromAccountPage.name}} -->
          </div>

          <div id="reviewType" class="reviewType" v-else-if="reviewFromAccountPage && reviewFromAccountPage.type != 'episode'">
            <div class="reviewTitle"><b>Review</b></div>
            <div id="reviewType" class="reviewType">{{reviewFromAccountPage.name}} · {{firstLetterToUpperCase(reviewFromAccountPage.type)}}</div>
            <!-- Review · {{reviewFromAccountPage.name}} · {{firstLetterToUpperCase(reviewFromAccountPage.type)}} -->
          </div>

          <div id="reviewType" class="reviewType" v-else-if="selectedMovie">
            <div class="reviewTitle"><b>Review</b></div>
            <div id="reviewType" class="reviewType">Movie · {{selectedMovie.data.title}}</div>
            <!-- Review · Movie · {{selectedMovie.data.title}} -->
          </div>

          <div v-else-if="selectedShow && selectedEpisode == null">
            <div class="reviewTitle"><b>Review</b></div>
            <div id="reviewType" class="reviewType">Show · {{selectedShow.name}}</div>
          </div>

          <div id="reviewType" class="reviewType" v-else-if="selectedPerson">
            <div class="reviewTitle"><b>Review</b></div>
            <div id="reviewType" class="reviewType">Person · {{selectedPerson.name}}</div>
            <!-- Review · Person · {{selectedPerson.name}} -->
          </div>

          <div id="reviewType" class="reviewType" v-else-if="selectedEpisode">
            <div class="reviewTitle"><b>Review</b></div>
            <div id="reviewType" class="reviewType">Episode · {{selectedShow.name}} S{{selectedEpisode.season_number}}E{{selectedEpisode.episode_number}} · {{selectedEpisode.name}}</div>
            <!-- Review · {{selectedShow.name}} · S{{selectedEpisode.season_number}}E{{selectedEpisode.episode_number}} {{selectedEpisode.name}} -->
          </div>
            
          <!-- review rating -->
          <div id="reviewRatingSlider">
            <span id="reviewRatingArrowUp" v-on:click="reviewRatingArrowUp()"> ▲ </span>
            <span id="reviewRatingSliderValueText">0</span><span id="reviewRatingArrowDown" v-on:click="reviewRatingArrowDown()"> ▼ </span><br />
            <input id="reviewRatingSliderRange" type="range" min="0" max="100" step="1" value="0" v-on:input="reviewRatingSlider()" /><br />
          </div>

          <!-- review checkboxes -->
          <div id="reviewCommentCheckbox">
            <input id="reviewIncludeCommentCheckbox" type="checkbox" value="off" v-on:input="reviewCommentCheck()"><span>Comment</span>
            <!-- <span> · </span> -->
            <input id="reviewDeleteCheckbox" type="checkbox" value="off" v-on:input="reviewConfirmDelete()"><span id="reviewDeleteCheckboxText">Delete</span>
          </div>
          
          <!-- review comment -->
          <div id="reviewComment">  
            <textarea id="reviewCommentTextarea" maxlength="1000" v-on:input="reviewCommentTextCount()" /><br />
            <span id="reviewCommentTextCharactersCount">Characters: 0/1000</span>
            <!-- <span id="reviewCommentTextWordsCount">Words: 0</span> -->
          </div>
            
          <!-- review submit button -->
          <input id="reviewSubmitButton" type="submit" value="Confirm" v-on:click="review(true)"/>
      </div>

      <!-- modal create list -->
      <div id="modalLists">
          <div id="listSelectType" v-if="sessionUserDataX">
            <!-- new list -->
            <input id="listCheckboxNewList" class="listCheckbox" type="checkbox" v-on:click="list('selectType', 'newList')" />
            <span>New List</span>

            <!-- edit list 1 -->
            <span v-if="sessionUserDataX.lists.length == 0">
                <input disabled id="listCheckboxExistingList" class="listCheckbox" type="checkbox" v-on:click="list('selectType', 'existingList')" />
                <span>Edit List</span>
            </span>

            <!-- edit list 2 -->
            <span v-else-if="sessionUserDataX.lists.length > 0"> 
                <input id="listCheckboxExistingList" class="listCheckbox" type="checkbox" v-on:click="list('selectType', 'existingList')" />
                <span>Edit List</span>
            </span>
          </div>

          <!-- list title -->
          <p id="listTitle">Name</p>
          <input id="listNewListName" type="" placeholder="name (max 40 characters)" maxlength="40" /> <!-- v-on:keyup="list('newListName', null)" -->

          <!-- list description -->
          <div id="listDescription">
            <!-- <p id="listDescriptionTitle">Description</p> -->
            <input id="listDescriptionInput" placeholder="description (max 100 characters)" maxlength="100" v-on:keyup="list('description', null)" />
          </div>
    
            <!-- list privacy -->
            <div id="listSelectPrivacy">
                <select id="listPrivacySelect" name="">
                    <option value="private">private</option>
                    <option value="public">public</option>
                </select>
            </div>

          <!-- list categories -->
          <div id="listCategories">
            <select id="listCategory1" class="listCategoriesSelect" name=""><option v-for="item in listCategoriesValues()" v-bind:value="item">#{{item}}</option></select>
            <select id="listCategory2" class="listCategoriesSelect" name=""><option v-for="item in listCategoriesValues()" v-bind:value="item">#{{item}}</option></select>
            <select id="listCategory3" class="listCategoriesSelect" name=""><option v-for="item in listCategoriesValues()" v-bind:value="item">#{{item}}</option></select>
          </div>

            <!-- existing lists -->
            <div id="listExistingLists" v-if="sessionUserDataX">
                <div id="listExistingListsOwn" v-if="sessionUserDataX.lists.length > 0">
                    <div v-for="l in sessionUserDataX.lists.slice().reverse()" v-bind:id="'listExistingListName' + l.id" class="listExistingListsItem" v-bind:key="l.nr">
                        <!-- list img -->
                        <div class="listExistingListBackgroundImg" v-if="l.items" v-on:click="list('selectExistingList', l)" v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + l.items.slice().sort((a, b) => b.order - a.order)[0].thumbnail + '); background-size: cover;'"></div>
                        <!-- v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + l.items[0].thumbnail + '); background-size: cover;'" -->
                        
                        <!-- list info text -->
                        <div class="listExistingListInfoText" v-if="l.type == 'created'">
                            <!-- list name -->
                            <span class="listExistingListName">{{l.name}}</span>
                            
                            <!-- <span v-if="l.categories">
                                <span v-bind:id="'listExistingListCategories' + l.id" class="listExistingListCategories" v-for="c in l.categories.toString().split(';')" v-bind:key="c.nr"> {{c}} · </span>
                            </span><br /> -->
                            
                            <!-- list item count -->
                            <span class="listExistingListType">{{l.items.length}} items</span>
                        </div>
                        
                        <!-- hidden -->
                        <input v-bind:id="'listExistingListButton' + l.id" class="listExistingListsButton" type="hidden" value="Select" v-on:click="list('selectExistingList', l)"/>
                    </div>
                </div>
            </div>

          <!-- current item data -->
          <input id="listCurrentItemData" type="hidden" v-if="selectedMovie" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'movie', data: selectedMovie})"  />
          <input id="listCurrentItemData" type="hidden" v-else-if="selectedShow && selectedEpisode == null" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'show', data: selectedShow})" />
          <input id="listCurrentItemData" type="hidden" v-else-if="selectedPerson" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'person', data: selectedPerson})" />
          <input id="listCurrentItemData" type="hidden" v-else-if="selectedEpisode" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'episode', data: selectedEpisode})" />
          <input id="listCurrentItemData" type="hidden" v-else-if="selectedList" v-bind:value="JSON.stringify({user: userSessionToken(), type: 'list', data: selectedList})" />

          <!-- list search for more items -->
          <div id="listSearch">
              <!-- search bar -->
              <form id="listSearchBar" onsubmit="return false;">
                <input id="listSearchInput" type="" placeholder="search for item" />
                <input id="listSearchButton" type="submit" value="Search" v-on:click="list('search', null)" />
              </form>

              <!-- search type -->
              <span id="listSearchType">
                 <input id="listCheckboxSearchTypeShow" class="listCheckbox" type="checkbox" checked="true" v-on:click="list('selectSearchType', 'Shows')" /><span>Show</span>
                 <input id="listCheckboxSearchTypeMovie" class="listCheckbox" type="checkbox" v-on:click="list('selectSearchType', 'Movies')" /><span>Movie</span>
                 <input id="listCheckboxSearchTypePerson" class="listCheckbox" type="checkbox" v-on:click="list('selectSearchType', 'People')" /><span>Person</span>
              </span>
              
              <!-- search results -->
              <div id="listSearchResults" v-if="listSearchResults">
                <div v-for="item in listSearchResults.value" v-bind:key="item.nr">
                    <div id="listSearchItem" v-if="item.poster_path || item.profile_path">
                        <!-- <input class="listSearchResultsButton" type="button" value="+" v-on:click="list('addSearchHitItem', item)" /><br /> -->
                        <p class="listSearchResultsItem" v-if="item.known_for_department" v-on:click="list('addSearchHitItem', item)">{{item.name}}</p>
                        <p class="listSearchResultsItem" v-else-if="item.release_date" v-on:click="list('addSearchHitItem', item)">{{item.release_date.substr(0, 4)}} · {{item.title}}</p>
                        <p class="listSearchResultsItem" v-else-if="item.first_air_date" v-on:click="list('addSearchHitItem', item)">{{item.first_air_date.substr(0, 4)}} · {{item.name}}</p>
                    </div>
                </div>
              </div>
          </div>

          <!-- temp list items -->
          <p id="listTempTitle">Items · 0/100</p>
          <div id="listTemp">
             <div class="listItem" v-for="i in listObj.items.sort((a, b) =>  b.order - a.order)" v-bind:key="i.nr">
                <input v-bind:id="'listItemOrder#' + i.order" class="listItemOrder" v-bind:value="i.order" maxlength="3" v-on:keyup="list('changeItemOrder', i)" />
                 
                 <div class="listTempItemText">
                     <img style="width: 130px;" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + i.thumbnail" />
                 </div>
                    
                <input class="listTempButton" type="button" value="-" v-on:click="list('removeListItem', i)" />
             </div>
           </div>

          <!-- list checkboxes -->
          <span id="listComplete">
            <input id="listCheckboxComplete" type="checkbox" v-on:click="list('complete', null)" />
            <span>Complete </span>
          </span>

          <span id="listDelete">
            <input id="listCheckboxDelete" type="checkbox" v-on:click="list('delete', null)" />
            <span>Delete</span>
          </span>

          <input id="listConfirm" type="submit" value="Confirm" v-on:click="list('confirm', null)" />
 
          <span id="listCancel" v-on:click="undisplayModals()">Cancel</span>
      </div>

      <!-- click to close modal underlay -->
      <div id="modalUnderlay" v-on:click="undisplayModals()"></div>
  </div>
</template>

<script>
import {useStore} from 'vuex'
import {ref, toRef, toRefs, reactive, computed, watch, watchEffect, onMounted, onBeforeMount, beforeUpdate, onUpdated, onBeforeUpdate} from 'vue'
import { useRouter, useRoute } from 'vue-router' //instead of this.$route
import router from '../router'
import { v4 as uuidv4 } from 'uuid';

export default {
    setup() {
        //vuex
        const store = useStore() //same as this.$store
        const selectedMovie = computed(() => { return store.getters['selectedMovie']})
        const selectedShow = computed(() => { return store.getters['selectedShow']})
        const selectedPerson = computed(() => { return store.getters['selectedPerson']})
        const selectedEpisode = computed(() => { return store.getters['selectedEpisode']})
        const reviewFromAccountPage = computed(() => { return store.getters['reviewFromAccountPage']})
        const sessionUserDataX = computed(() => { return store.getters['sessionUserData']})
        const selectedList = computed(() => { return store.getters['selectedList']})
        // const selectedSeason = computed(() => { return store.getters['selectedSeason']})

        //variables
        let existingReview = null
        let sessionUserData = null
        let existingLike = null
        let listSearchResults = reactive({value: null})
        let listObj = reactive({userSessionToken: null, delete: false, added: null, type: 'created', id: null, name: null, description: null, categories: null, public: false, private: true, items: []})
        let sessionPublicLists = null
        const BACKEND_API_URL = computed(() => { return store.getters['backendAPI']}).value
        
        //lifecycle hooks
        onMounted(() => {
            console.log("onMounted ComponentOptionsBar")
            
            //get local storage
            sessionUserData = localStorage.getItem('sessionUserData')
            sessionUserData = JSON.parse(sessionUserData)

            //set vuex
            store.dispatch('actionSetSessionUserData', sessionUserData)
        })
        
        onUpdated(() => {
            console.log("onUpdated ComponentOptionsBar")

            //get local storage
            sessionUserData = localStorage.getItem('sessionUserData')
            sessionUserData = JSON.parse(sessionUserData)
            sessionPublicLists = localStorage.getItem('sessionPublicLists')
            sessionPublicLists = JSON.parse(sessionPublicLists)

            checkForExistingReview(sessionUserData)
            checkForExistingLike(sessionUserData)
        })
        
        //functions
        async function review(submit)
        { 
            console.log("review")
        
            //check if logged in
            if(sessionUserData == null) { return }

            //elements
            let modalReview = document.getElementById("modalReview")
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")
            let reviewData = document.getElementById("reviewData")
            let reviewCommentTextarea = document.getElementById("reviewCommentTextarea")
            let reviewComment = document.getElementById("reviewComment")
            let reviewIncludeCommentCheckbox = document.getElementById("reviewIncludeCommentCheckbox")
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")
            let modalUnderlay = document.getElementById("modalUnderlay")
            let bottomBar = document.getElementById("bottomBar")
            
            //display underlay
            modalUnderlay.style.display = "block"
            bottomBar.style.display = "none"
            
            //variables
            let review = JSON.parse(reviewData.value)
            let reviewObj = null
            
            if(review.type == "editFromAccountPage") 
            { 
                //set existing review
                existingReview = review.data

                //create editFromAccountPage reviewObj
                reviewObj = {
                    added: new Date(), 
                    userSessionToken: review.user, 
                    type: review.data.type, 
                    id: review.data.id, 
                    rating: reviewRatingSliderValueText.innerText, 
                    comment: reviewCommentTextarea.value, 
                    name: review.data.name,
                    show: review.data.show,
                    thumbnail: review.data.thumbnail,
                    number: review.data.number,
                    season: review.data.season,
                    release_date: review.data.release_date
                    // data: review.data.data
                }
            }
            else if(review.type == 'show') 
            { 
                //create show reviewObj
                reviewObj = {
                    added: new Date(), 
                    userSessionToken: review.user, 
                    type: review.type, 
                    id: review.data.id, 
                    rating: reviewRatingSliderValueText.innerText, 
                    comment: reviewCommentTextarea.value, 
                    name: review.data.name,
                    thumbnail: review.data.data.poster_path,
                    release_date: review.data.data.first_air_date
                    // data: review.data.data
                }
            }
            else if(review.type == 'movie') 
            { 
                //create movie reviewObj
                reviewObj = {
                    added: new Date(), 
                    userSessionToken: review.user, 
                    type: review.type, 
                    id: review.data.id, 
                    rating: reviewRatingSliderValueText.innerText, 
                    comment: reviewCommentTextarea.value, 
                    name: review.data.data.title,
                    thumbnail: review.data.data.poster_path,
                    release_date: review.data.data.release_date
                    // data: review.data.data
                }
            }
            else if(review.type == 'person') 
            {
                //remove combined credits data (takes up to much space)
                review.data.data.combined_credits = null; 

                //create person reviewObj
                reviewObj = {
                    added: new Date(), 
                    userSessionToken: review.user, 
                    type: review.type, 
                    id: review.data.id, 
                    rating: reviewRatingSliderValueText.innerText, 
                    comment: reviewCommentTextarea.value, 
                    name: review.data.data.name,
                    thumbnail: review.data.data.profile_path
                    // data: review.data.data
                }
            }
            else if(review.type == 'episode') 
            { 
                //create episode reviewObj
                reviewObj = {
                    added: new Date(), 
                    userSessionToken: review.user,
                    comment: reviewCommentTextarea.value, 
                    id: review.data.id, 
                    show: selectedShow._value.name, 
                    name: review.data.name, 
                    rating: reviewRatingSliderValueText.innerText, 
                    release_date: review.data.air_date, 
                    thumbnail: selectedShow._value.data.poster_path, 
                    type: review.type, 
                    number: review.data.episode_number,
                    season: review.data.season_number
                    // data: review.data
                }
            }

            if(submit == false)
            {
                if(modalReview.style.display == "none" || modalReview.style.display == "") { reviewReset(); modalReview.style.display = "block" }
                else if(modalReview.style.display == "block") { reviewReset(); modalReview.style.display = "none" }
                    
                if(existingReview != null)
                {
                    //set existing review variables
                    reviewRatingSliderValueText.innerText = existingReview.rating
                    reviewRatingSliderRange.value = parseInt(existingReview.rating.replace(".", ""))
                    if(existingReview.comment != "")
                    {
                        reviewIncludeCommentCheckbox.checked = true
                        reviewIncludeCommentCheckbox.value = "on"
                        reviewComment.style.display = "block"
                        reviewCommentTextarea.value = existingReview.comment
                    }
                }
    
            }
            else if(submit == true)
            {
                let url = BACKEND_API_URL + "/review"

                if(reviewSubmitButton.value == "Delete") {reviewObj.delete = true}
                
                //post data
                await fetch(url, {method: 'post', body: JSON.stringify(reviewObj)})
                .then((response) => { return response.json() })
                .then((data) => {
                  console.log(data)

                  if(data.status == "review-added-successfully") { reviewSuccessful(reviewObj, existingReview, "Review Successful!", false)}
                  else if(data.status == "review-edited-successfully") { reviewSuccessful(reviewObj, existingReview, "Review Edit Successful!", false)}
                  else if(data.status == "review-deleted-successfully") { reviewSuccessful(reviewObj, existingReview, "Review Deleted Successful!", true)}
                  else if(data.status == "review-failed") { reviewFailed() }
                })
            }
        }
            
        function previous()
        {
            console.log("previous")

            router.back()
        }

        function next()
        {
            console.log("next")
                        
            router.forward()
        }
        
        function reviewRatingSlider() 
        {
            //elements
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")
            let rating = reviewRatingSliderRange.value

            //update elements
            if(rating == 0) {reviewRatingSliderValueText.innerText = rating}
            else if(rating.length == 1) {reviewRatingSliderValueText.innerText = "0" + "." + rating}
            else if(rating.length == 2) {reviewRatingSliderValueText.innerText = rating.substr(0, 1) + "." + rating.substr(1, 1)}
            else if(rating.length == 3) {reviewRatingSliderValueText.innerText = rating.substr(0, 2)}
        }

        function reviewCommentTextCount()
        {
            //elements
            let reviewComment = document.getElementById("reviewCommentTextarea")
            let reviewCommentTextCharactersCount = document.getElementById("reviewCommentTextCharactersCount")
            // let reviewCommentTextWordsCount = document.getElementById("reviewCommentTextWordsCount")
            
            //variables
            let totalCharacters = reviewComment.value.length
            // let totalWords = reviewComment.value.split(" ").length
            
            //update elements
            reviewCommentTextCharactersCount.innerText = "Characters: " + totalCharacters + "/1000"
            // reviewCommentTextWordsCount.innerText = "Words: " + totalWords
        }

        function reviewCommentCheck()
        {
            //elements
            let reviewIncludeCommentCheckbox = document.getElementById("reviewIncludeCommentCheckbox")
            let reviewComment = document.getElementById("reviewComment")

            //update elements
            if(reviewIncludeCommentCheckbox.value == "off"){reviewIncludeCommentCheckbox.value = "on"; reviewComment.style.display = "block"}
            else if(reviewIncludeCommentCheckbox.value == "on"){reviewIncludeCommentCheckbox.value = "off"; reviewComment.style.display = "none"}
        }

        function reviewRatingArrowUp()
        {
            //elements
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")

            //update elements
            reviewRatingSliderRange.value++
            let rating = reviewRatingSliderRange.value
            if(rating.length == 1) {reviewRatingSliderValueText.innerText = "0" + "." + rating}
            else if(rating.length == 2) {reviewRatingSliderValueText.innerText = rating.substr(0, 1) + "." + rating.substr(1, 1)}
            else if(rating.length == 3) {reviewRatingSliderValueText.innerText = rating.substr(0, 2)}
        }

        function reviewRatingArrowDown()
        {
            //elements
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")

            //update elements
            reviewRatingSliderRange.value--
            let rating = reviewRatingSliderRange.value
            if(rating == 0) {reviewRatingSliderValueText.innerText = rating}
            else if(rating.length == 1) {reviewRatingSliderValueText.innerText = "0" + "." + rating}
            else if(rating.length == 2) {reviewRatingSliderValueText.innerText = rating.substr(0, 1) + "." + rating.substr(1, 1)}
            else if(rating.length == 3) {reviewRatingSliderValueText.innerText = rating.substr(0, 2)}
        }

        function userSessionToken()
        {
            //elements
            let sessionToken = localStorage.getItem('sessionToken')

            return sessionToken
        }

        function reviewReset()
        {
            //elements
            let modalReview = document.getElementById("modalReview")
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")
            let reviewCommentTextarea = document.getElementById("reviewCommentTextarea")
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")
            let reviewComment = document.getElementById("reviewComment")
            let reviewIncludeCommentCheckbox = document.getElementById("reviewIncludeCommentCheckbox")
            let reviewDeleteCheckbox = document.getElementById("reviewDeleteCheckbox")

            //set vuex
            store.dispatch('actionSetSelectedEpisode', null)

            //reset review modal
            modalReview.style.display = "none"
            modalReview.style.pointerEvents = "visible"

            //reset review rating
            reviewRatingSliderRange.value = 0
            reviewRatingSliderValueText.innerText = "0"

            //reset review comment
            reviewCommentTextarea.value = ""
            reviewIncludeCommentCheckbox.checked = false
            reviewIncludeCommentCheckbox.value = "off"
            reviewComment.style.display = "none";

            //reset review submit button
            reviewSubmitButton.style.backgroundColor = ""
            reviewSubmitButton.value = "Confirm"

            //reset review delete checkbox
            reviewDeleteCheckbox.checked = false
            reviewDeleteCheckbox.value = "off"
        }

        function reviewSuccessful(newReview, existingReview, message, deleteCheckbox)
        {
            //elements
            let modalReview = document.getElementById("modalReview")
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")
            let Review = document.getElementById("Review")
            let modalUnderlay = document.getElementById("modalUnderlay")
            
            //variables
            let reviewType = newReview.type
            let reviewId = newReview.id
            
            if(reviewType == 'show' || reviewType == 'movie' || reviewType == 'person') 
            {
                if(existingReview == null) //add new review
                { sessionUserData.reviews.push(newReview) }
                else if(existingReview != null) //find existing review
                {
                    for(let r in sessionUserData.reviews)
                    {
                        let review = sessionUserData.reviews[r]

                        if(review.type == existingReview.type && review.id == existingReview.id)
                        {
                            if(deleteCheckbox == true) //remove existing review 
                            {sessionUserData.reviews.splice(r, 1)}
                            else if(deleteCheckbox == false) //update existing review rating and comment
                            { sessionUserData.reviews[r].rating = newReview.rating; sessionUserData.reviews[r].comment = newReview.comment }
                        }
                    }
                }
            }
            else if(reviewType == 'episode') 
            {
                let episodeReviewExists = false
                
                //check for existing review
                for(let r in sessionUserData.reviews)
                {
                    if(sessionUserData.reviews[r].type == reviewType && sessionUserData.reviews[r].id == reviewId) //episode review exists
                    {
                        if(deleteCheckbox == true) //remove existing review 
                        { sessionUserData.reviews.splice(r, 1) }
                        else if(deleteCheckbox == false) //update existing review rating and comment
                        { sessionUserData.reviews[r].rating = newReview.rating; sessionUserData.reviews[r].comment = newReview.comment }

                        //set episode review exists
                        episodeReviewExists = true
                    }
                }

                //add new review
                if(episodeReviewExists == false) { sessionUserData.reviews.push(newReview) }
            }

            //update local storage
            localStorage.setItem('sessionUserData', JSON.stringify(sessionUserData))

            //update vuex
            store.dispatch('actionSetSessionUserData', sessionUserData)

            //update elements
            modalReview.style.pointerEvents = "none";
            reviewSubmitButton.value = message
            if(deleteCheckbox == true) //delete styling
            { reviewSubmitButton.style.backgroundColor = "red"; Review.style.color = "white" }
            else if(deleteCheckbox == false) //normal styling
            { reviewSubmitButton.style.backgroundColor = "#17ff17", Review.style.color = "yellow" }
            modalUnderlay.style.display = "none"

            //check for existing review
            checkForExistingReview(sessionUserData)

            //reset review
            setTimeout(() => { reviewReset() }, 1000)
        }

        function reviewFailed()
        {
            //elements
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")

            //update elements
            reviewSubmitButton.style.backgroundColor = "red"
            reviewSubmitButton.value = "Failed..."
                      
            //reset elements
            setTimeout(() => { reviewSubmitButton.style.backgroundColor = ""; reviewSubmitButton.value = "Confirm" }, 2000)
        }

        function checkForExistingReview(sessionUserData)
        {
            //elements
            let componentOptionsBar = document.getElementById("componentOptionsBar")
            let Review = document.getElementById("Review")
            
            //variables
            let type = null
            let id = null
            let reviewExists = false

            if(sessionUserData == null) {componentOptionsBar.style.display = "none" } // hide options bar
            else if(sessionUserData != null) //display options bar
            {
                componentOptionsBar.style.display = "block"

                if(selectedShow.value != null) //review = show
                { type = "show"; id = selectedShow.value.id;}
                else if(selectedMovie.value != null) //review = movie
                { type = "movie"; id = selectedMovie.value.id; }
                else if(selectedPerson.value != null) //review = person
                { type = "person"; id = selectedPerson.value.id; }
                else if(selectedEpisode.value != null) //review = episode
                { type = "episode"; id = selectedEpisode.value.id; }

                //reset review star color
                Review.style.color = "white"

                //check for existing review
                for(let r in sessionUserData.reviews)
                {
                    if(sessionUserData.reviews[r].type == type && sessionUserData.reviews[r].id == id)
                    {
                        //set review exists
                        reviewExists = true

                        //set existing review
                        existingReview = sessionUserData.reviews[r]

                        //update review star color
                        Review.style.color = "yellow"
                    }
                }

                if(reviewExists == true) {}
                else if(reviewExists == false) {existingReview = null}
            }
        }

        function reviewConfirmDelete()
        {
            //elements
            let reviewDeleteCheckbox = document.getElementById("reviewDeleteCheckbox")
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")

            //reset elements
            if(reviewDeleteCheckbox.value == "off") //check delete review
            { reviewDeleteCheckbox.checked = true; reviewSubmitButton.value = "Delete"; reviewSubmitButton.style.backgroundColor = "red"; reviewDeleteCheckbox.value = "on"}
            else if(reviewDeleteCheckbox.value == "on") //uncheck delete review
            { reviewSubmitButton.value = "Confirm"; reviewSubmitButton.style.backgroundColor = ""; reviewDeleteCheckbox.value = "off"}
        }

        function firstLetterToUpperCase(word)
        {
            return word[0].toUpperCase() + word.substr(1)
        }

        async function like()
        { 
            console.log("like")

            //check if logged in
            if(sessionUserData == null) { return }

            //elements
            let reviewData = document.getElementById("reviewData")
            
            //variables
            let like = JSON.parse(reviewData.value)
            let likeObj = null
            let url = BACKEND_API_URL + "/like"

            if(like.type == 'show') 
            { 
                //create show likeObj
                likeObj = {
                    added: new Date(), 
                    userSessionToken: like.user, 
                    type: like.type, 
                    id: like.data.id, 
                    name: like.data.name,
                    thumbnail: like.data.data.poster_path,
                    release_date: like.data.data.first_air_date
                    // data: like.data.data
                }
            }
            else if(like.type == 'movie') 
            { 
                //create movie likeObj
                likeObj = {
                    added: new Date(), 
                    userSessionToken: like.user, 
                    type: like.type, 
                    id: like.data.id, 
                    name: like.data.data.title,
                    thumbnail: like.data.data.poster_path,
                    release_date: like.data.data.release_date
                    // data: like.data.data
                }
            }
            else if(like.type == 'person') 
            {
                //create person likeObj
                likeObj = {
                    added: new Date(), 
                    userSessionToken: like.user, 
                    type: like.type, 
                    id: like.data.id, 
                    name: like.data.data.name,
                    thumbnail: like.data.data.profile_path
                    // data: like.data.data
                }
            }
            else if(like.type == 'list') 
            {
                //create person likeObj
                likeObj = {
                    added: new Date(), 
                    userSessionToken: like.user, 
                    type: like.type, 
                    id: like.data.id, 
                    name: like.data.name,
                    thumbnail: '/list.png',
                    description: like.data.description,
                    categories: like.data.categories,
                    items: like.data.items
                    // data: like.data.data
                }
            }
            
            //post data
            await fetch(url, {method: 'post', body: JSON.stringify(likeObj)})
            .then((response) => { return response.json() })
            .then((data) => {
                console.log(data)

                if(data.status == "like-added-successfully") { likeSuccessful(likeObj, existingLike, "Like Successful!", false)}
                else if(data.status == "like-deleted-successfully") { likeSuccessful(likeObj, existingLike, "Like Deleted Successful!", true)}
                else if(data.status == "like-failed") { console.log("Like failed...") }
            })
        }

        function likeSuccessful(newLike, existingLike, message, deleteCheckbox)
        {
            //elements
            let Like = document.getElementById("Like")
            
            //variables
            let likeType = newLike.type
            // let likeId = newLike.id
            
            if(likeType == 'show' || likeType == 'movie' || likeType == 'person' || likeType == 'list') 
            {
                if(existingLike == null) //add new like
                { sessionUserData.likes.push(newLike) }
                else if(existingLike != null) //find existing review
                {
                    deleteCheckbox = true

                    for(let l in sessionUserData.likes)
                    {
                        let like = sessionUserData.likes[l]

                        if(like.type == existingLike.type && like.id == existingLike.id)
                        {
                            if(deleteCheckbox == true) //remove existing like 
                            {sessionUserData.likes.splice(l, 1)}
                        }
                    }
                }
            }

            //update local storage
            localStorage.setItem('sessionUserData', JSON.stringify(sessionUserData))

            //update vuex
            store.dispatch('actionSetSessionUserData', sessionUserData)

            //update elements
            Like.style.pointerEvents = "none";
            setTimeout(() => {Like.style.pointerEvents = "visible";}, 3000)
            checkForExistingLike(sessionUserData)
        }

        function checkForExistingLike(sessionUserData)
        {
            //elements
            let componentOptionsBar = document.getElementById("componentOptionsBar")
            let Like = document.getElementById("Like")
            
            //variables
            let type = null
            let id = null
            let likeExists = false

            if(sessionUserData == null) {componentOptionsBar.style.display = "none" } // hide options bar
            else if(sessionUserData != null) //display options bar
            {
                componentOptionsBar.style.display = "block"

                if(selectedShow.value != null) //review = show
                { type = "show"; id = selectedShow.value.id;}
                else if(selectedMovie.value != null) //review = movie
                { type = "movie"; id = selectedMovie.value.id; }
                else if(selectedPerson.value != null) //review = person
                { type = "person"; id = selectedPerson.value.id; }
                else if(selectedList.value != null) //review = person
                { type = "list"; id = selectedList.value.id; }
                // else if(selectedEpisode.value != null) //review = episode
                // { type = "episode"; id = selectedEpisode.value.id; }

                //reset like heart color
                Like.style.color = "white"

                //check for existing like
                for(let l in sessionUserData.likes)
                {
                    if(sessionUserData.likes[l].type == type && sessionUserData.likes[l].id == id)
                    {
                        //set like exists
                        likeExists = true

                        //set existing like
                        existingLike = sessionUserData.likes[l]

                        //update like heart color
                        Like.style.color = "red"
                    }
                }

                if(likeExists == true) {}
                else if(likeExists == false) {existingLike = null}
            }
        }

        async function list(type, value)
        {
            console.log("list" + " " + type + " " + value)
            
            //check if logged in
            if(sessionUserData == null) { return }
            
            //elements
            let listNewListName = document.getElementById("listNewListName")
            let listSearchResults = document.getElementById("listSearchResults")
            let listCheckboxSearchTypeShow = document.getElementById("listCheckboxSearchTypeShow")
            let listCheckboxSearchTypeMovie = document.getElementById("listCheckboxSearchTypeMovie")
            let listCheckboxSearchTypePerson = document.getElementById("listCheckboxSearchTypePerson")
            let listSearchInput = document.getElementById("listSearchInput")
            let listCategoriesInput = document.getElementById("listCategoriesInput")
            let listDescriptionInput = document.getElementById("listDescriptionInput")
            let listTemp = document.getElementById("listTemp")
            let listCategory1 = document.getElementById("listCategory1")
            let listCategory2 = document.getElementById("listCategory2")
            let listCategory3 = document.getElementById("listCategory3")
            let listPrivacySelect = document.getElementById("listPrivacySelect")
            // let listTitle = document.getElementById("listTitle")
            // let listCurrentItemData = document.getElementById("listCurrentItemData")
                
            //variables
            let searchType = "Shows"
            let searchString = listSearchInput.value
            let searchYear = null

            if(type == "selectType")
            {
                //get local storage
                sessionUserData = localStorage.getItem('sessionUserData')
                sessionUserData = JSON.parse(sessionUserData)

                //set vuex
                store.dispatch('actionSetSessionUserData', sessionUserData)
                listObj.userSessionToken = userSessionToken()

                if(value == "newList") { listNewList(); listAddCurrentItem("new"); } 
                else if(value == "existingList") { listExistingList(); }
            }
            else if(type == "newListName")
            {
                if(listNewListName.value.length > 0) { } //listNameCheck()
                else if(listNewListName.value.length == 0) { listUndisplayInputs()}
                
                //set list name
                listObj.name = listNewListName.value
            }
            else if(type == "search")
            {
                //display search results
                listSearchResults.style.display = "block"
                
                if(listCheckboxSearchTypeShow.checked == true) { searchType = 'Shows'}
                else if(listCheckboxSearchTypeMovie.checked == true) { searchType = 'Movies'}
                else if(listCheckboxSearchTypePerson.checked == true) { searchType = 'People'}

                searchForMoreListItems(searchType, searchString, searchYear)
            }
            else if(type == 'selectExistingList')
            {
                 listExistingListSelected(value)
                 listAddCurrentItem("existing");
            }
            // else if(type == 'selectPrivacy')
            // {
            //     if(value == 'public') { listObj.public = true; listObj.private = false}
            //     else if(value == 'private') { listObj.public = false; listObj.private = true}
            // }
            else if(type == 'selectSearchType')
            {
                //reset checkboxes
                listCheckboxSearchTypeShow.checked = false; 
                listCheckboxSearchTypeMovie.checked = false; 
                listCheckboxSearchTypePerson.checked = false;

                if(value == 'Shows') { listCheckboxSearchTypeShow.checked = true;}
                else if(value == 'Movies') { listCheckboxSearchTypeMovie.checked = true;}
                else if(value == 'People') { listCheckboxSearchTypePerson.checked = true;}
            }
            else if(type == 'categories')
            {
                //filter 3 first categories
                let filteredCategories = listCategoriesInput.value.split(";").slice(0, 3)

                //remove whitespace from category names
                for(let category in filteredCategories)
                {
                    filteredCategories[category] = filteredCategories[category].trimStart().trimEnd()
                }
                
                //set list categories
                let lc = filteredCategories.toString().replaceAll(",", ";").toLowerCase()
                listObj.categories = lc
            }
            else if(type == 'description')
            {
                //set list description
                listObj.description = listDescriptionInput.value
            }
            else if(type == 'addSearchHitItem')
            {
                //check list items limit
                if(listObj.items.length == 100) { return }

                //variables
                let item = null
                let temp = listObj.items
                let itemName = value.name || value.title
                let itemThumbnail = value.poster_path || value.profile_path
                let itemId = value.id
                let itemType = null
                let itemReleaseDate = value.release_date || value.first_air_date
                let itemDescription = value.overview
                let itemRating = undefined
                let itemOrder = parseInt(listObj.items.length + 1)
                
                //check item rating
                if(value.vote_average) { itemRating = "avg " + value.vote_average + " / " + "count " + value.vote_count}

                //check item type
                if(value.title) { itemType = "movie"}
                else if(value.first_air_date) { itemType = "show"}
                else if(value.known_for_department) { itemType = "person"}

                //set item obj
                item = {order: itemOrder, type: itemType, id: itemId, name: itemName, release_date: itemReleaseDate, description: itemDescription, thumbnail: itemThumbnail, rating: itemRating}
                
                //add item to array
                temp.push(item)
                listObj.items = temp

                //remove duplicates
                listObj.items = [...new Map(listObj.items.map((m) => [m.id, m])).values()];

                //reset scrollbar
                listTemp.scroll(0, listTemp.scrollHeight)

                //set list items count
                listTempTitle.innerText = "Items" + " · " + listObj.items.length + "/100"
            }
            else if(type == 'confirm')
            {
                //variables
                let url = BACKEND_API_URL + "/list"
                let uuid = null
                let missingInfoName = false
                let missingInfoDescription = false
                let missingInfoCategories = false
                let listCategories = null
                
                if(listNewListName.value == "") { listNewListName.style.backgroundColor = "red"; missingInfoName = true }
                else { listNewListName.style.backgroundColor = "rgba(255, 255, 255, 0.2)"; missingInfoName = false }

                if(listDescriptionInput.value == "") { listDescriptionInput.style.backgroundColor = "red"; missingInfoDescription = true }
                else { listDescriptionInput.style.backgroundColor = "rgba(255, 255, 255, 0.2)"; missingInfoDescription = false }

                if(listObj.items.length == 0) { listTempTitle.style.color = "red"; missingInfoCategories = true }
                else { listTempTitle.style.color = "white"; missingInfoCategories = false }

                if(listCategory1.value == " " && listCategory2.value == " " && listCategory3.value == " ")
                {
                    listCategory1.style.backgroundColor = "red"
                    listCategory2.style.backgroundColor = "red"
                    listCategory3.style.backgroundColor = "red"
                }
                else 
                { 
                    listCategory1.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
                    listCategory2.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
                    listCategory3.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
                }

                
                if(listPrivacySelect.value == "private") { listObj.private = true; listObj.public = false}
                else if(listPrivacySelect.value == "public") { listObj.private = false; listObj.public = true}

                listCategories = listCategory1.value + ";" + listCategory2.value + ";" + listCategory3.value + ";"
                listObj.categories = listCategories
                listObj.name = listNewListName.value
                listObj.description = listDescriptionInput.value
                listObj.userSessionToken = userSessionToken()
                // listObj.added = new Date
                
                if(missingInfoName == true || missingInfoDescription == true || missingInfoCategories == true) 
                { 
                    console.log(listObj); return 
                }

                //check list id
                if(listObj.id == null) 
                { 
                    //set uuid
                    uuid = uuidv4()

                    //remove dash character from uuid
                    for(let c in uuid) { if(uuid[c] == "-") {uuid = uuid.replace("-", "")}}

                    //set list id
                    listObj.id = uuid
                }
                
                //post data
                await fetch(url, {method: 'post', body: JSON.stringify(listObj)})
                .then((response) => { return response.json() })
                .then((data) => {
                    console.log(data)

                    if(data.status == "list-added-successfully") { listSuccessful(listObj, false, "List Successful!", false)}
                    else if(data.status == "list-edited-successfully") { listSuccessful(listObj, true, "List Edited Successful!", false)}
                    else if(data.status == "list-deleted-successfully") { listSuccessful(listObj, true, "List Deleted Successful!", true)}
                    else if(data.status == "list-failed") { listFailed()}
                })
            }
            else if(type == 'removeListItem')
            {
                for(let i in listObj.items)
                {
                    if(listObj.items[i].id == value.id)
                    { 
                        //remove item from array
                        listObj.items.splice(i, 1)

                        //set list items count
                        listTempTitle.innerText = "Items · " + listObj.items.length + "/100"

                        return
                    }
                }
            }
            else if(type == 'changeItemOrder')
            {
                //variables
                let oldOrder = value.order
                let newOrder = null
                let item = value
                let listItemOrder = document.getElementById("listItemOrder#" + item.order)

                //set item order
                item.order = listItemOrder.value
                newOrder = item.order

                if(oldOrder == newOrder) { console.log("order not changed")}
                else if(oldOrder != newOrder) { console.log("oldOrder: " + oldOrder + " " + "newOrder: " + newOrder)}
            }
            else if(type == 'complete')
            {
                //elements
                let listCheckboxComplete = document.getElementById("listCheckboxComplete")
                let listConfirm = document.getElementById("listConfirm")
                let listCheckboxDelete = document.getElementById("listCheckboxDelete")

                if(listCheckboxComplete.checked == true) 
                { 
                    listConfirm.style.display = "block"; 
                    listConfirm.value = "Confirm";
                    listConfirm.style.backgroundColor = "";
                    listCheckboxDelete.checked = false
                    listObj.delete = false
                }
                else if(listCheckboxComplete.checked == false) { listConfirm.style.display = "none" }
            }
            else if(type == 'delete')
            {
                //elements
                let listCheckboxComplete = document.getElementById("listCheckboxComplete")
                let listConfirm = document.getElementById("listConfirm")
                let listCheckboxDelete = document.getElementById("listCheckboxDelete")

                if(listCheckboxDelete.checked == true) 
                { 
                    listConfirm.style.display = "block"; 
                    listConfirm.value = "Delete";  
                    listConfirm.style.backgroundColor = "red"; 
                    listCheckboxComplete.checked = false
                    listObj.delete = true
                }
                else if(listCheckboxDelete.checked == false) { listConfirm.style.display = "none" }
            }
        }

        async function searchForMoreListItems(queryType, queryString, specificYear)
        {
            //fetch data
            await fetch(BACKEND_API_URL + "/search?type=" + queryType + "&string=" + queryString + "&year=" + specificYear, {method: 'get'}).then((response) => { return response.json() })
            .then((data) => {
                console.log(data)

                //elements
                let listSearchResultsElement = document.getElementById("listSearchResults")

                //set search results
                for(let item in data.results)
                {
                    console.log(data.results[item].release_date)
                    if(data.results[item].release_date) { data.results[item].release_date = data.results[item].release_date.substr(0, 4) }
                    else if(data.results[item].first_air_date) { data.results[item].first_air_date = data.results[item].first_air_date.substr(0, 4) }
                }
                console.log("queryType: " + queryType)

                if(queryType == "Movies") { data.results.sort((a, b) => b.release_date - a.release_date) }
                else if(queryType == "Shows") { data.results.sort((a, b) => b.first_air_date - a.first_air_date) }
                else if(queryType == "People") { data.results.sort((a, b) => b.popularity - a.popularity) }
                
                for(let item in data.results)
                {
                    console.log(data.results[item].release_date)
                }

                listSearchResults.value = data.results
                console.log(listSearchResults)

                //reset scrollbar
                listSearchResultsElement.scroll(0, 0)
            })
        }

        function listNewList()
        {
            //elements
            let listCheckboxNewList = document.getElementById("listCheckboxNewList")
            let listCheckboxExistingList = document.getElementById("listCheckboxExistingList")
            let listNewListName = document.getElementById("listNewListName")
            let listExistingLists = document.getElementById("listExistingLists")
            let listTempTitle = document.getElementById("listTempTitle")
            let listDescription = document.getElementById("listDescription")
            let listCategories = document.getElementById("listCategories")
            let listSelectPrivacy = document.getElementById("listSelectPrivacy")
            let listComplete = document.getElementById("listComplete")
            let listDelete = document.getElementById("listDelete")
            let listSearchInput = document.getElementById("listSearchInput")
            let listDescriptionInput = document.getElementById("listDescriptionInput")
            let listCategoriesInput = document.getElementById("listCategoriesInput")
            let listSearch = document.getElementById("listSearch")
            let listTemp = document.getElementById("listTemp")
            let listTitle = document.getElementById("listTitle")
            let listConfirm = document.getElementById("listConfirm")
            let listSearchResults = document.getElementById("listSearchResults")
            // let listCurrentItem = document.getElementById("listCurrentItem")
            // let listSearchTitle = document.getElementById("listSearchTitle")

            //reset elements
            if(listCheckboxNewList) { listCheckboxNewList.checked = true }
            if(listCheckboxExistingList) { listCheckboxExistingList.checked = false }
            if(listNewListName) { listNewListName.value = "" }
            if(listSearchInput) { listSearchInput.value = "" }
            if(listDescriptionInput) { listDescriptionInput.value = "" }
            if(listCategoriesInput) { listCategoriesInput.value = "" }
            listExistingLists.style.display = "none"
            listNewListName.style.display = "block"
            listDescription.style.display = "block"
            listDescriptionInput.style.display = "block"
            listCategories.style.display = "block"
            listSelectPrivacy.style.display = "block"
            listSearch.style.display = "block"
            listComplete.style.display = "inline-block"
            listTemp.style.display = "flex"
            listTempTitle.style.display = "block"
            listDelete.style.display = "none"
            // if(listCheckboxNewList) { listCheckboxNewList.style.pointerEvents = "none"; }
            // if(listCheckboxExistingList) { listCheckboxExistingList.style.pointerEvents = "visible"; }
            // listTitle.style.display = "block"
            // listSearch.style.display = "none"
            // listConfirm.style.display = "none"
            // listTemp.style.display = "none"
            // listDescription.style.display = "none"
            // listCategories.style.display = "none"
            // listSelectPrivacy.style.display = "none"
            // listComplete.style.display = "none"
            // listSearchResults.style.display = "none"

            //reset list obj
            listObj.id = null
            listObj.name = null
            listObj.description = null
            listObj.categories = null
            listObj.public = false
            listObj.private = true
            listObj.added = null
            listCategory1.value = " "
            listCategory2.value = " "
            listCategory3.value = " "
            listTempTitle.innerText = "Items · 0/100"
            console.log(listObj)
            listObj.items = []
            // listObj.items = value.items
        }

        function listExistingList()
        {
            //elements
            let listCheckboxNewList = document.getElementById("listCheckboxNewList")
            let listCheckboxExistingList = document.getElementById("listCheckboxExistingList")
            let listNewListName = document.getElementById("listNewListName")
            let listExistingLists = document.getElementById("listExistingLists")
            let listTitle = document.getElementById("listTitle")
            let listSearch = document.getElementById("listSearch")
            let listConfirm = document.getElementById("listConfirm")
            let listTemp = document.getElementById("listTemp")
            let listTempTitle = document.getElementById("listTempTitle")
            let listDescription = document.getElementById("listDescription")
            let listCategories = document.getElementById("listCategories")
            let listSelectPrivacy = document.getElementById("listSelectPrivacy")
            let listComplete = document.getElementById("listComplete")
            let listDelete = document.getElementById("listDelete")
            let listSearchResults = document.getElementById("listSearchResults")
            let listSearchInput = document.getElementById("listSearchInput")
            let listCategory1 = document.getElementById("listCategory1")
            let listCategory2 = document.getElementById("listCategory2")
            let listCategory3 = document.getElementById("listCategory3")
                
            //reset elements
            listCheckboxNewList.checked = false
            listCheckboxExistingList.checked = true
            listExistingLists.style.display = "block"
            listNewListName.style.display = "none"
            listNewListName.value = ""
            listSearch.style.display = "none"
            listConfirm.style.display = "none"
            listTemp.style.display = "none"
            listTitle.style.display = "none"
            listDescription.style.display = "none"
            listCategories.style.display = "none"
            listSelectPrivacy.style.display = "none"
            listComplete.style.display = "none"
            listDelete.style.display = "none"
            listCheckboxComplete.checked = false
            listCheckboxDelete.checked = false
            listSearchResults.style.display = "none"
            listSearchInput.value = ""
            listTempTitle.style.display = "none"
            // listCheckboxNewList.style.pointerEvents = "visible"
            // listCheckboxExistingList.style.pointerEvents = "none";
            // listCurrentItem.style.display = "block"
        }

        function listNameCheck()
        {
            //elements
            let listTitle = document.getElementById("listTitle")
            let listSearch = document.getElementById("listSearch")
            let listTemp = document.getElementById("listTemp")
            let listTempTitle = document.getElementById("listTempTitle")
            let listDescription = document.getElementById("listDescription")
            let listCategories = document.getElementById("listCategories")
            let listSelectPrivacy = document.getElementById("listSelectPrivacy")
            let listComplete = document.getElementById("listComplete")
            let listDelete = document.getElementById("listDelete")
            // let listSearchTitle = document.getElementById("listSearchTitle")
            // let listCurrentItem = document.getElementById("listCurrentItem")

            //reset elements
            // listSearch.style.display = "block"
            // listTemp.style.display = "block"
            // listTempTitle.style.display = "block"
            // listSearchTitle.style.display = "inline-block"
            // listDescription.style.display = "block"
            // listCategories.style.display = "block"
            // listSelectPrivacy.style.display = "block"
            // listComplete.style.display = "inline-block"
            // listDelete.style.display = "none"
            // listCheckboxComplete.checked = false
            // listCheckboxDelete.checked = false
            // listTitle.style.display = "block"
            // listCurrentItem.style.display = "block"
        }

        function listUndisplayInputs()
        {
            //elements
            let listSearch = document.getElementById("listSearch")
            let listConfirm = document.getElementById("listConfirm")
            let listTemp = document.getElementById("listTemp")
            let listTempTitle = document.getElementById("listTempTitle")
            let listDescription = document.getElementById("listDescription")
            let listCategories = document.getElementById("listCategories")
            let listSelectPrivacy = document.getElementById("listSelectPrivacy")
            let listComplete = document.getElementById("listComplete")
            let listDelete = document.getElementById("listDelete")
            // let listSearchTitle = document.getElementById("listSearchTitle")
                
            //reset elements
            listSearch.style.display = "none"
            listConfirm.style.display = "none"
            listTemp.style.display = "none"
            listDescription.style.display = "none"
            listCategories.style.display = "none"
            listSelectPrivacy.style.display = "none"
            listComplete.style.display = "none"
            listDelete.style.display = "none"
            // listSearchTitle.style.display = "none"
            // listTempTitle.style.display = "none"
            // listCurrentItem.style.display = "block"
        }

        function listExistingListSelected(value)
        {
            //elements
            let listNewListName = document.getElementById("listNewListName")
            let listExistingLists = document.getElementById("listExistingLists")
            let listTitle = document.getElementById("listTitle")
            let listSearch = document.getElementById("listSearch")
            let listTemp = document.getElementById("listTemp")
            let listTempTitle = document.getElementById("listTempTitle")
            let listDescription = document.getElementById("listDescription")
            let listCategories = document.getElementById("listCategories")
            let listSelectPrivacy = document.getElementById("listSelectPrivacy")
            let listComplete = document.getElementById("listComplete")
            let listDelete = document.getElementById("listDelete")
            let listDescriptionInput = document.getElementById("listDescriptionInput")
            let listCategoriesInput = document.getElementById("listCategoriesInput")
            let listCheckboxComplete = document.getElementById("listCheckboxComplete")
            let listCheckboxDelete = document.getElementById("listCheckboxDelete")
            let listCategory1 = document.getElementById("listCategory1")
            let listCategory2 = document.getElementById("listCategory2")
            let listCategory3 = document.getElementById("listCategory3")
            // let listConfirm = document.getElementById("listConfirm")
            // let listCurrentItem = document.getElementById("listCurrentItem")

            //reset elements
            listNewListName.style.display = "block"
            listNewListName.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
            listExistingLists.style.display = "none"
            listSearch.style.display = "block"
            listTemp.style.display = "flex"
            listDescription.style.display = "block"
            listCategories.style.display = "block"
            listSelectPrivacy.style.display = "block"
            listComplete.style.display = "inline-block"
            listDelete.style.display = "inline-block"
            listCheckboxComplete.checked = false
            listCheckboxDelete.checked = false
            listNewListName.value = value.name
            listDescriptionInput.value = value.description
            listDescriptionInput.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
            listCategory1.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
            listCategory2.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
            listCategory3.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
            listTempTitle.style.display = "block"
            // listTitle.style.display = "block"
            // listCategoriesInput.value = value.categories
            // listSearchTitle.style.display = "inline-block"
            // listConfirm.style.display = "block"
            
            //sort categories
            let lc = value.categories.split(";")

            //remove empty category
            for(let c in lc)
            {
                if(lc[c] == '') { lc.splice(c, 1)}
            }

            if(lc.length == 1) //list has 1 category
            { 
                listCategory1.value = lc[0]
                listCategory2.value = " " 
                listCategory3.value = " " 
            }
            else if(lc.length == 2) //list has 2 categories
            { 
                listCategory1.value = lc[0] 
                listCategory2.value = lc[1] 
                listCategory3.value = " "
            }
            else if(lc.length == 3) //list has 3 categories
            { 
                listCategory1.value = lc[0] 
                listCategory2.value = lc[1] 
                listCategory3.value = lc[2] 
            }
            else if(lc.length == 4) //list has 4 categories 
            { 
                listCategory1.value = lc[0] 
                listCategory2.value = lc[1] 
                listCategory3.value = lc[2] 
            }
                      
            //set list obj values
            listObj.id = value.id
            listObj.name = value.name
            listObj.items = value.items
            listObj.description = value.description
            listObj.categories = value.categories
            listObj.public = value.public
            listObj.private = value.private
            listObj.added = value.added

            if(listObj.public == true) { listPrivacySelect.value = "public"}
            else if(listObj.private == true) { listPrivacySelect.value = "private" }
        }

        function listAddCurrentItem(listType)
        {
            //elements
            let listTempTitle = document.getElementById("listTempTitle")
            let listCurrentItemData = document.getElementById("listCurrentItemData")

            //check value
            let value = null
            if(listCurrentItemData) {value = JSON.parse(listCurrentItemData.value)}
            if(value == null) { return }

            //variables
            let currentItem = null
            let item = value.data.data || value.data
            let itemName = item.name || item.title || item.data.name
            let itemThumbnail = item.poster_path || item.profile_path
            let itemId = item.id
            let itemType = value.type
            let itemReleaseDate = item.release_date || item.first_air_date
            let itemDescription = item.overview
            let itemRating = undefined

            //check item rating
            if(item.vote_average) { itemRating = "avg " + item.vote_average + " / " + "count " + item.vote_count}

            //check item type
            if(itemType == "show" || itemType == "movie") { currentItem = {order: 1, type: itemType, id: itemId, name: itemName, release_date: itemReleaseDate, description: itemDescription, thumbnail: itemThumbnail, rating: itemRating} }
            else if(itemType == "person") { currentItem = {order: 1, type: itemType, id: itemId, name: itemName, thumbnail: itemThumbnail} }
            else if(itemType == "list") { currentItem = {order: 1, type: itemType, id: itemId, name: itemName, thumbnail: itemThumbnail} }

            //check list type
            if(listType == "new")
            {
                //reset list items
                listObj.items = []
                
                //check current item
                if(itemType != "list") {listObj.items.push(currentItem) }
                else if(itemType == "list") 
                { 
                    let items = value.data.items; 
                    
                    for(let i in items) { listObj.items.push(items[i]) } 
                }
                
                //set list items count
                listTempTitle.innerText = "Items · " + listObj.items.length + "/100"
            }
            else if(listType == "existing")
            {
                if(currentItem) { currentItem.order = listObj.items.length + 1; if(itemType != "list") { listObj.items.push(currentItem) } }
                else if(itemType == "list") 
                {
                    //variables
                    let items = value.data.items
                    let order = listObj.items.length

                    for(let i in items)
                    {
                        items[i].order = items[i].order + order
                        listObj.items.push(items[i]) 
                    }
                }
                    
                //set list items count
                listTempTitle.innerText = "Items · " + listObj.items.length + "/100"
            }
        }

        function displayModalLists()
        {
            //check if logged in
            if(sessionUserData == null) { return }

            //elements
            let modalLists = document.getElementById("modalLists")
            let modalUnderlay = document.getElementById("modalUnderlay")
            let bottomBar = document.getElementById("bottomBar")
            
            //display underlay
            modalUnderlay.style.display = "block"
                
            //reset modal lists
            // resetModalLists()

            if(modalLists.style.display == "none" || modalLists.style.display == "") 
            { 
                bottomBar.style.display = "none"
                modalLists.style.display = "block" 
            }
            else if(modalLists.style.display == "block") 
            { 
                bottomBar.style.display = "block"
            }
        }

        function resetModalLists()
        {
            console.log("resetModalLists")

            //elements
            let modalLists = document.getElementById("modalLists")
            let listCheckboxNewList = document.getElementById("listCheckboxNewList")
            let listCheckboxExistingList = document.getElementById("listCheckboxExistingList")
            let listSearch = document.getElementById("listSearch")
            let listConfirm = document.getElementById("listConfirm")
            let listTemp = document.getElementById("listTemp")
            let listTempTitle = document.getElementById("listTempTitle")
            let listDescription = document.getElementById("listDescription")
            let listCategories = document.getElementById("listCategories")
            let listSelectPrivacy = document.getElementById("listSelectPrivacy")
            let listComplete = document.getElementById("listComplete")
            let listDelete = document.getElementById("listDelete")
            let listNewListName = document.getElementById("listNewListName")
            let listTitle = document.getElementById("listTitle")
            let listExistingLists = document.getElementById("listExistingLists")
            let listSelectType = document.getElementById("listSelectType")
            // let listSearchTitle = document.getElementById("listSearchTitle")
            // let listSearchResults = document.getElementById("listSearchResults")

            //reset elements
            listSearch.style.display = "none"
            listConfirm.style.display = "none"
            listTemp.style.display = "none"
            listDescription.style.display = "none"
            listCategories.style.display = "none"
            listSelectPrivacy.style.display = "none"
            listComplete.style.display = "none"
            listDelete.style.display = "none"
            listNewListName.style.display = "none"
            listTitle.style.display = "none"
            listExistingLists.style.display = "none"
            listCheckboxNewList.checked = false
            listCheckboxExistingList.checked = false
            modalLists.style.pointerEvents = "visible";
            listTempTitle.style.display = "none"
            // listSearchTitle.style.display = "none"
            // listCheckboxNewList.style.pointerEvents = "visible"
            // listCheckboxExistingList.style.pointerEvents = "visible"
            // listSelectType.style.display = "block"
        }
        
        function listSuccessful(newList, existingList, message, deleteCheckbox)
        {
            //elements
            let modalLists = document.getElementById("modalLists")
            let listSubmitButton = document.getElementById("listConfirm")
            let modalUnderlay = document.getElementById("modalUnderlay")
            // let Review = document.getElementById("Review")
            
            //variables
            // let reviewType = newReview.type
            let listId = newList.id
            let el = null
            
           //check list type
            if(existingList == false)
            { 
                //add new list to array
                sessionUserData.lists.push(newList)

                //check list privacy
                if(newList.public == true) { sessionPublicLists.push(newList) }
                
            }
            else if(existingList == true)
            {
                //find existing list in user lists
                for(let l in sessionUserData.lists)
                {
                    //set existing list
                    el = sessionUserData.lists[l]

                    if(el.id == newList.id && deleteCheckbox == true)
                    {
                        //delete existing list 
                        sessionUserData.lists.splice(l, 1)
                    }
                    else if(el.id == newList.id && deleteCheckbox == false)
                    {
                        //edit existing list 
                        sessionUserData.lists[l] = newList
                    }
                }

                //find existing list in public lists
                for(let l in sessionPublicLists)
                {
                    //set existing list
                    el = sessionPublicLists[l]

                    if(el.id == newList.id && deleteCheckbox == true)
                    {
                        //delete existing list
                        sessionPublicLists.splice(l, 1)
                    }
                    else if(el.id == newList.id && deleteCheckbox == false)
                    {
                        //edit existing list
                        sessionPublicLists[l] = newList
                    }
                }
            }
       
            //update local storage
            localStorage.setItem('sessionUserData', JSON.stringify(sessionUserData))
            localStorage.setItem('sessionPublicLists', JSON.stringify(sessionPublicLists))

            //update vuex
            store.dispatch('actionSetSessionUserData', sessionUserData)
            store.dispatch('actionSetSessionPublicLists', sessionPublicLists)

            //update elements
            modalLists.style.pointerEvents = "none";
            listSubmitButton.value = message
            if(deleteCheckbox == true) { listSubmitButton.style.backgroundColor = "red"; } //delete styling
            else if(deleteCheckbox == false) { listSubmitButton.style.backgroundColor = "#17ff17"; } //normal styling
            modalUnderlay.style.display = "none"

            //reset modal lists
            setTimeout(() => { modalLists.style.display = "none"; resetModalLists() }, 1000)
        }

        
        function listFailed()
        {
            //elements
            let listSubmitButton = document.getElementById("listConfirm")

            //update elements
            listSubmitButton.style.backgroundColor = "red"
            listSubmitButton.value = "Failed..."
                      
            //reset elements
            setTimeout(() => { listSubmitButton.style.backgroundColor = ""; listSubmitButton.value = "Confirm" }, 2000)
        }

        function undisplayModals()
        {
            //elements
            let modalLists = document.getElementById("modalLists")
            let modalReview = document.getElementById("modalReview")
            let modalUnderlay = document.getElementById("modalUnderlay")
            let bottomBar = document.getElementById("bottomBar")
            // let searchBar = document.getElementById("searchBar")

            //reset elements
            modalUnderlay.style.display = "none"
            modalLists.style.display = "none"
            modalReview.style.display = "none"
            bottomBar.style.display = "block"
            // searchBar.style.display = "none"
        }

        function listCategoriesValues()
        {
            let listCategories = [" ",
                "fantasy", "triology", "action", "mafia", "assassin", "violence", "alien",
                "disney", "animated", "pirates", "space", "marvel", "heist", "mythology", 
                "racing", "satire", "zombies", "spy", "comedy", "jungle", "monsters", "standup",
                "superpowers", "director", "adventure", "custom"
            ]

            //sort categories alphabetically
            listCategories.sort()

            return listCategories
        }

        return {
            //functions
            review,
            reviewRatingSlider,
            reviewCommentTextCount,
            reviewCommentCheck,
            reviewRatingArrowUp,
            reviewRatingArrowDown,
            reviewConfirmDelete,
            like,
            previous,
            next,
            userSessionToken,
            firstLetterToUpperCase,
            list,
            displayModalLists,
            undisplayModals,
            listCategoriesValues,

            //vuex
            selectedMovie,
            selectedShow,
            selectedShow,
            selectedPerson,
            selectedEpisode,
            reviewFromAccountPage,
            sessionUserDataX,
            selectedList,
            
            //variables
            // urlPath,
            // path,
            listSearchResults,
            listObj,
            sessionUserData
        }
    }
}
</script>

<style scoped>
    /*** scrollbar ***/
    ::-webkit-scrollbar { /* margin-top: 10px; */ width: 10px;} /* width */
    ::-webkit-scrollbar-track { background-color: #1a1a1a; /* 41B883 */ } /* Track */
    ::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: white; } /* Handle */
    #modalLists { scrollbar-width: thin;}
    #modalLists::-webkit-scrollbar { /* margin-top: 1   0px; */ width: 0px;} /* width */
    #listSearchResults::-webkit-scrollbar { /* margin-top: 10px; */ width: 0px;} /* width */
    #listTemp::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; width: 10px;} /* width */
    #listExistingListsOwn::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; width: 10px;} /* width */

    /*** elements ***/
    input { background-color: transparent; color: white; border: 0px solid white; background-color: rgba(255, 255, 255, 0.2);}
    select { background-color: transparent; color: white; border: 0px solid white; background-color: rgba(255, 255, 255, 0.2);}
    option { color: black; }
    
    /*** ids ***/
    #componentOptionsBar
    {
        position: absolute;
        display: none;
        width: 100vw;
        top: calc(36% - 9px);
        left: 0vw;
        margin: auto;   
        padding: 0px;
        opacity: 1;
        z-index: 3;
        /* right: calc(47% + 11px); */
        /* text-align: right; */
    }
    /* #Review { display: block; width: auto; margin: 0px; padding: 0px; font-size: 49px; user-select: none; color: white;} */
    /* #Unlike { display: block; width: auto; margin: 0px; padding: 0px; font-size: 49px; user-select: none; color: white; text-shadow: 2px 1px black;} */
    /* #Previous { display: block; width: auto; margin: 0px; padding: 0px; font-size: 49px; user-select: none; color: white; text-shadow: 2px 1px black;} */
    /* #Next { display: block; width: auto; margin: 0px; padding: 0px; font-size: 49px; user-select: none; color: white; text-shadow: 2px 1px black;} */
    /* #List { display: block; width: auto; margin: 0px; padding: 0px; font-size: 49px; user-select: none; color: white; text-shadow: 2px 1px black;} */
    /* #Edit { color: yellow; margin: -8px 0px 0px 8px;} */
    /* #Like {color: white; margin: 0px 0px 0px -19px; text-shadow: 2px 1px black; } */
    /* #Unlike {color: red; margin: 0px;} */
    #Like { display: block; width: auto; margin: -2px 0px 0px 0px; padding: 0px; font-size: 47px; user-select: none; color: white; text-shadow: 2px 1px black;}
    #Review { color: white; margin: 9px -1px 0px 16px; font-size: 39px; font-weight: bold; text-shadow: 2px 1px black;}
    #Edit { display: block; width: auto; margin: 0px; padding: 0px; font-size: 49px; user-select: none; color: white; text-shadow: 2px 1px black;}
    #Next { color: white;  font-size: 40px; font-weight: bold; text-shadow: 2px 1px black;}
    #Previous { color: white;  font-size: 40px; font-weight: bold; text-shadow: 2px 1px black;}
    #List { color: white; margin: -3px 0px 0px 13px; font-size: calc(49px + 9px); font-weight: bold; text-shadow: 2px 1px black;}
    #Edit, #Unlike, #Previous, #Next { display: none; text-shadow: 2px 1px black;}
    #modalReview 
    { 
        position: fixed; 
        display: none; 
        width: 40vw;
        max-width: 499px; 
        top: 30%; 
        left: 50%; 
        margin: 0px; 
        padding: 20px;
        transform: translate(-50%); 
        color: white;
        text-align: left;
        z-index: 3;
        border: 2px solid white; 
        background-color: #1a1a1a;
    }
    #reviewRatingSlider { margin: 16px 0px 16px 0px; padding: 0px; user-select: none; text-align: center;}
    #reviewCommentTextarea { height: 20vh; width: 100%; font-weight: bold; font-size: 20px; font-family: Avenir, Helvetica, Arial, sans-serif;}
    #reviewComment { display: none; margin: 0px; padding: 0px;}
    #reviewHeader { margin: 0px; padding: 0px; text-align: center;}
    #reviewSubmitButton 
    { 
        width: 100%; 
        margin: 40px 0px 0px 0px; 
        padding: 13px; 
        font-weight: bold; 
        border-radius: 0%; 
        border-style: none;
        color: black;
        background-color: white;
    }
    #reviewSubmitButton:active { background-color: rgba(255, 255, 255, 0.9);}
    #reviewIncludeCommentCheckbox { margin: 0px 4px 0px 0px; padding: 0px;}
    #reviewRatingArrowUp:active, #reviewRatingArrowDown:active { color: gray;}
    #reviewRatingArrowUp, #reviewRatingArrowDown { font-size: 40px}
    #reviewRatingSliderValueText { margin: 0px; padding: 0px; font-size: 36px; font-weight: bold;}
    #reviewRatingSliderRange { width: 100%; }
    #reviewCommentTextCharactersCount, #reviewCommentTextWordsCount { opacity: 0.7;}
    #reviewCommentCheckbox { text-align: center; }
    #reviewDeleteCheckbox { margin: 0px 4px 0px 10px; }
    #modalLists 
    {        
        position: fixed; 
        display: none; 
        width: 30vw; 
        top: 30px; 
        left: 50%; 
        margin: 0px; 
        padding: 20px 20px 10px 20px;
        transform: translate(-50%); 
        overflow-y: scroll;
        color: white;
        text-align: center;
        z-index: 3;
        font-size: 18px;
        background-color: #1a1a1a;
        border: 2px solid white; 
        max-height: 89vh;
    }
    #listCancel { width: 100%; margin: 0px; padding: 10px;}
    #listTemp { display: flex; flex-direction: row; margin: 0px; overflow-y: auto; text-align: left; border: 0px solid gray;}
    #listTitle { display: none; margin: 0px; padding: 0px; font-weight: bold; text-align: left;}
    #listSelectType { display: block; margin: 0px 0px 13px 0px; user-select: none;}
    #listExistingLists { display: none; max-height: 400px; margin: 16px 0px 0px 0px; }
    #listNewListName 
    { 
        display: none; 
        width: 96%; 
        padding: 10px; 
        font-size: 16px; 
        outline: none; 
        border: 0px solid white; 
        /* margin: auto;  */
        /* font-weight: bold;  */
        /* color: white;  */
        /* background-color: transparent; */
    }
    #listCurrentItem { display: none; width: 100%; margin: 0px 0px 20px 0px; padding: 10px;}
    #listSearch { display: none; width: 100%; margin: auto; margin-top: 1px; text-align: left;}
    #listSearchResults { display: none; max-height: 200px; width: 100%; margin: 0px 0px 0px 0px; overflow-y: auto; }
    #listSearchInput { width: auto; padding: 10px; font-size: 16px; outline: none; background-color: rgba(255, 255, 255, 0.2); }
    #listSearchButton { display: none; width: auto; margin: 0px; padding: 11px; font-size: 16px; outline: none; border: 0px solid white; background-color: white;}
    #listSearchButton:active { font-weight: bold;}
    #listConfirm 
    { 
        display: none; 
        width: 100%; 
        margin: 20px 0px 20px 0px; 
        padding: 13px; 
        font-weight: bold; 
        border-style: none; 
        border-radius: 0%;
        color: black;
        background-color: white;
    }
    #listTempTitle 
    { 
        display: none; 
        margin: 0px 0px 10px 0px; 
        padding: 10px; 
        font-weight: normal; 
        text-align: center; 
        user-select: none;
        background-color: rgba(255, 255, 255, 0.2);
    }
    /* #listSearchTitle { display: none; margin: 20px 0px 0px 0px; padding: 0px; font-weight: bold; text-align: left;} */
    #listDescriptionTitle { display: none; margin: 20px 0px 0px 0px; padding: 0px; font-weight: bold; text-align: left; }
    #listCategoriesTitle { display: none; margin: 20px 0px 0px 0px; padding: 0px; font-weight: bold; text-align: left;}
    #listCategoriesInput { display: block; width: 100%; margin: auto; margin-top: 1px; padding: 10px; font-size: 16px; outline: none; border-top: 1px solid black; border: 0px solid white; }
    #listDescriptionInput 
    { 
        display: none; 
        width: 96%;
        margin-top: 1px; 
        padding: 10px; 
        font-size: 16px; 
        font-family: Arial, Helvetica, sans-serif;
        outline: none;
        border-top: 1px solid black;
        border: 0px solid white;
        /* margin: auto; */
    }
    #listSelectPrivacyTitle {display: none; margin: 20px 0px 0px 0px; padding: 0px; font-weight: bold; text-align: left;}
    #listDescription { display: none;}
    #listCategories { display: none; margin-left: -1px; text-align: left;}
    #listSelectPrivacy { display: none; text-align: left;}
    #listSearchType 
    { 
        display: block; 
        margin: 0px;
        margin-top: 1px;
        padding: 10px 0px 10px 0px; 
        text-align: center; 
        user-select: none; 
        background-color: rgba(255, 255, 255, 0.2); 
    }
    #listComplete { display: none; margin: 20px 0px 20px 0px; text-align: left; user-select: none; }
    #listDelete { display: none; margin: 10px 0px 8px 0px; text-align: left; user-select: none; }
    #listConfirm:active { opacity: 0.6;}
    #modalUnderlay 
    { 
        position: fixed;
        display: none;
        height: 100%;
        width: 100%;
        top: 0%;
        left: 50%;
        margin: 0px;
        transform: translate(-50%);
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.8);
        /* padding: 20px; */
        /* color: white; */
        /* text-align: left; */
        /* border: 1px solid white; */
    }
    #listExistingListsOwn {display: flex; overflow-x: auto;}
    #optionsBar { display: flex; width: auto; justify-content: center; user-select: none;}
    #listSearchItem { display: flex;}
    #listSearchBar { display: flex; flex-direction: column; }
    #listPrivacySelect 
    { 
        height: 40px; 
        width: calc(100% - 2px); 
        margin: 1px 0px 0px 0px;
        padding: 0px 0px 0px 10px;
        text-align: center; 
        font-size: 17px;
        border: 0px solid white;
        /* margin: auto;  */
    }
    #listCancel { display: none; }

    /*** classes ***/
    .reviewType{ margin: 0px; text-align: center; font-weight: normal; user-select: none;}
    .listTempItem
    { 
        display: inline-block; 
        width: calc(80% - 2px); 
        margin: 0px 0px 20px 0px; 
        padding: 10px 10px 6px 10px; 
        text-align: left;
        font-weight: normal;
        user-select: none; 
        color: black;
        border-bottom: 1px solid black; 
        /* background-color: gray; */
    }
    .listExistingListsItem
    { 
        display: inline-block; 
        width: calc(80% - 2px); 
        margin: 0px 0px 20px 0px; 
        padding: 10px 10px 6px 10px; 
        text-align: left;
        font-weight: normal;
        user-select: none; 
        color: black;
        border-bottom: 1px solid black; 
        /* background-color: gray; */
    }
    .listSearchResultsItem 
    { 
        display: inline-block; 
        width: calc(80% - 2px); 
        margin: 0px 0px 20px 0px; 
        padding: 10px 10px 6px 10px; 
        text-align: left;
        font-weight: normal;
        user-select: none; 
        color: black;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3); 
        /* background-color: gray; */
    }
    .listTempButton, .listExistingListsButton, .listSearchResultsButton { width: 16%; margin: 0px; padding: 4px; font-weight: bold; vertical-align: top; border-style: none;}
    .listTempButton:active, .listExistingListsButton:active, .listSearchResultsButton:active { opacity: 0.9;}
    .listTempButton {display: inline-block; width: auto; margin: 0px; padding: 4px; font-size: 26px; background-color: red;}
    .listTempButton:active { background-color: red;}
    .listSearchResultsButton {width: 80px; font-size: 20px; margin-left: 1px; border: 2px solid lightgreen; border-bottom: 1px solid black; background-color: lightgreen; }
    .listExistingListsButton { margin: 0px; padding: 10px; border: 0px solid white;}
    .listCheckbox { margin-left: 10px;}
    .listItemOrder 
    { 
        width: auto; 
        margin: 0px; 
        padding: 10px; 
        outline: none; 
        border-style: none; 
        text-align: center; 
        font-size: 16px;
        font-weight: bold;
        text-shadow: 2px 1px black;
        color: white; 
        background-color: transparent;    
    }
    .listItem { display: flex; flex-direction: column; width: 130px; margin: 0px 10px 10px 0px; padding: 0px; border-bottom: 0px solid #1a1a1a; }
    .listSearchResultsItem 
    { 
        max-height: 18px; 
        width: 100%; 
        margin: 0px; 
        padding: 10px; 
        white-space: nowrap; 
        overflow-x: auto; 
        overflow-y: hidden; 
        color: white; 
    }
    .listTempItemText { display: flex; width: auto; margin: auto; padding: 0px; white-space: nowrap; overflow-x: auto;}
    .listExistingListsCategory { margin: 20px 0px 0px 0px; padding: 0px; text-align: left; font-weight: bold;}
    .listExistingListsItem 
    { 
        height: 300px; 
        width: 190px; 
        margin: 0px 29px 20px 0px; 
        padding: 0px; 
        font-weight: bold; 
        text-shadow: 2px 1px black; 
        color: white;
        border: 2px solid white;
    }
    .listExistingListsItem:active { opacity: 1;}    
    .listExistingListCategories { display: inline-block; margin: 0px 0px 10px 0px; padding: 0px 4px 0px 0px; opacity: 0.7; font-weight: normal;}
    .listExistingListType { display: block; margin: 0px; padding: 0px; font-weight: bold; text-align: center; opacity: 0.7;}
    .listExistingListName { display: block; width: 190px; margin: 100px 0px 0px 0px; font-size: 20px; text-align: center;}
    .heartCharacter { display: inline-block; transform: rotate(90deg); font-size: 40px;}
    .listExistingListBackgroundImg { position: relative; height: 300px; width: 190px; z-index: 0; opacity: 0.6;}
    .listExistingListInfoText { position: relative; margin: auto; margin-top: -280px; pointer-events: none; white-space: break-spaces;}
    .listCategoriesSelect { width: calc(33% + 0.3px); margin: 1px 0px 0px 1px; padding: 9px; font-size: 17px;}
    .reviewTitle { display: block; margin: 0px 0px 16px 0px; padding: 0px; text-align: center;}
    /* .heart {
        display:inline-block;
        height: 30px;
        width: auto; 
        aspect-ratio: 1;
        border-image: radial-gradient(white 69%,#0000 70%) 84.5% fill/100%;
        clip-path: polygon(-41% 0,50% 91%, 141% 0);
    } */
    
    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        ::-webkit-scrollbar { height: 0px; width: 0px;}
        #listTemp::-webkit-scrollbar { height: 0px; width: 0px;}
        #listExistingListsOwn::-webkit-scrollbar { height: 0px; width: 0px;}

        #componentOptionsBar { margin: 0px; z-index: 10;}
        #optionsBar { position: fixed; max-height: 6vh; width: 100%; bottom: 26px; padding-left: 20px; justify-content: left; }
        #modalLists 
        { 
            max-height: initial;
            height: 100vh; 
            width: 100vw; 
            top: 0px; 
            margin: 0px; 
            padding: 0px 20px 20px 20px; 
            font-size: 18px; 
            overflow-y: auto; 
            overflow-x: hidden;
            border: 0px solid rgba(255, 255, 255, 0.8);
        }
        #listNewListName { width: 80%; margin: auto; margin-bottom: 1px; }
        #listDescriptionInput { width: 80%; margin: auto; margin-bottom: 1px; }
        #listPrivacySelect { width: 100%; margin: auto; padding: 0px; }
        #listCategoriesInput { width: 80%; margin: auto; }
        #listSelectPrivacy { width: calc(86% - 1px); margin: auto; }
        #listExistingLists { width: 80%; margin: auto; }
        #listTempTitle { width: calc(86% - 1px); margin: auto; margin-bottom: 10px; padding: 10px 0px 10px 0px; background-color: rgba(255, 255, 255, 0.2); }
        #listSearch { width: calc(86% - 1px); margin: auto; margin-top: 1px; }
        #listConfirm { width: 80%; margin: auto; margin-bottom: 30px; }
        #listTemp { width: calc(86% - 1px); margin: auto; }
        #listComplete { width: 80%; margin: auto; margin-top: 30px; margin-bottom: 30px; text-align: center; }
        #listSelectType { width: 80%; margin: auto; margin-top: 30px; margin-bottom: 30px; }
        #listSearchResults { width: 100%; max-height: 200px; }
        #listSearchType { padding: 10px; text-align: left;background-color: rgba(255, 255, 255, 0.2); }
        #modalReview { width: 88vw; top: 0px; bottom: auto; border: 0px solid rgba(255, 255, 255, 0.8)}
        #Like { position: fixed; margin: 0px; padding: 0px; font-size: 47px; bottom: 0px; left: 20px; }
        #Review { position: fixed; margin: 0px; padding: 0px; font-size: 43px; bottom: 7px; margin-left: 98px; }
        #List { position: fixed; margin: 0px; padding: 0px; font-size: 13vw; font-size: 64px; bottom: -3px; margin-left: 49px; }
        #Previous { display: block;}
        #listCategories { width: 94%; margin: auto; text-align: center; }
        #listCancel { display: block; width: 100%; margin: auto; padding: 20px 0px 20px 0px; font-size: 16px; font-weight: bold; }

        .listSearchResultsButton { width: 49px;}
        .listExistingListsItem { width: 400px; white-space: nowrap;}
        .reviewType {white-space: nowrap; overflow-x: auto;}
        .listCategoriesSelect { width: 30%;}
        /* .listTempItemText { padding: 0px 0px 0px 10px;} */
        /* .listExistingListName { display: block; width: 86%; margin: 0px 0px -20px 0px; padding: 0px; overflow-x: auto;} */
    }
</style>