<template>
    <div id="componentAccount">
        <!-- not signed in -->
        <div id="accountNotSignedIn">
            <!-- login -->
            <div id="login" v-if="!reactiveSessionToken.value">
                <form id="loginForm" @submit.prevent>
                    <input id="loginUsername" class="loginInput" placeholder="username" maxlength="10" v-on:keyup="loginValidation()"/><br />
                    <input id="loginPassword" class="loginInput" placeholder="password" type="password" maxlength="10" v-on:keyup="loginValidation()"/><br />
                    <input disabled id="loginSubmitButton" class="loginInput submitInput" type="submit" value="Login" v-on:click="login()"/>
                </form>

                <p id="accountWarningsIncorrectPassword" class="accountWarnings">Login Error: incorrect password</p>
                <p id="accountWarningsUserDoesNotExist" class="accountWarnings">Login Error: user does not exist</p>
                <p id="accountWarningsRegistrationSuccessful" class="accountWarnings">Registration Successful</p>
            </div>

            <!-- register -->
            <div id="register" v-if="!reactiveSessionToken.value">
                <div id="registerInfo">
                    <p id="registerInfoTitle">Register</p>
                    <span>username max characters: 10</span><br />
                    <span>password max characters: 10</span>
                </div>
            
                <form id="registerForm" @submit.prevent>
                    <input id="registerUsername" class="registerInput" placeholder="username" maxlength="10" v-on:keyup="confirmPasscode()"/><br />
                    <input id="registerPassword" class="registerInput" placeholder="password" type="password" maxlength="10" v-on:keyup="confirmPasscode()"/><br />
                    <input id="registerConfirmPassword" class="registerInput" placeholder="confirm password" type="password" maxlength="10" v-on:keyup="confirmPasscode()"/><br />
                    <input disabled id="registerSubmitButton" class="registerInput submitInput" type="submit" value="Register" v-on:click="register()" />
                </form>

                <p id="accountWarningsUsernameAlreadyExists" class="accountWarnings">Register Error: account already exists</p>
            </div>
        </div>

        <!-- signed in -->
        <div id="accountSignedIn" v-if="reactiveSessionToken.value">
            <!-- section likes -->
            <div id="accountSelectCategory">
                <div id="categoryTitleLikes" class="categoryTitle" v-on:click="accountDisplaySection('Likes')">Likes</div>
                <span class="dot"> · </span>
                <div id="categoryTitleReviews" class="categoryTitle" v-on:click="accountDisplaySection('Reviews')">Reviews</div>
                <span class="dot"> · </span>
                <div id="categoryTitleLists" class="categoryTitle" v-on:click="accountDisplaySection('Lists')">Lists</div>
            </div>

            <div id="sectionLikes" class="section">
                <!-- likes category recent -->
                <div id="likes-category-recent" class="likesCategory">
                    <div class="like" v-for="like in getSessionUserData.likes.slice().filter((like) => {return like.type != 'list'}).reverse()" v-bind:key="like.nr">
                        <!-- like obj type = show or movie -->
                        <div v-if="like.type == 'show' || like.type == 'movie'">
                            <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + like.thumbnail" v-on:click="accountSetUrlPath(like.type, like.id)" />
                            <div v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlike">
                                <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="likeHeartCharacter" v-on:click="unlikeToggle(like, 'recent')"><span class="heartCharacter">❥</span></span>
                                <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="unlikeConfirm" v-on:click="unlikeConfirm(like)">unlike</span>
                                <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="unlikeCancel" v-on:click="unlikeCancel(like)"></span>
                            </div>
                        </div>

                        <!-- like obj type = person -->
                        <div class="person" v-if="like.type == 'person'">
                            <img class="thumbnailPerson" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + like.thumbnail" v-on:click="accountSetUrlPath(like.type, like.id)" />
                            <div v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlikePerson">
                                <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="likeHeartCharacterPerson" v-on:click="unlikeToggle(like, 'recent')"><span class="heartCharacter">❥</span></span>
                                <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="unlikeConfirmPerson" v-on:click="unlikeConfirm(like)">unlike</span>
                                <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="unlikeCancelPerson" v-on:click="unlikeCancel(like)"></span>
                            </div>
                        </div>

                        <!-- like obj type = list -->
                        <!-- <div class="list" v-if="like.type == 'list'"></div> -->
                        <!-- <div class="list" v-if="like.type == 'list'" style="margin: 0px 0px 0px 0px;" v-on:click="accountSetUrlPath(like.type, like.id)" 
                        v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + like.items[0].thumbnail + '); background-size: cover;'">
                            <div id="listInfo">
                                <div class="listName" v-if="like.name">{{like.name}}</div>
                                <p class="listText listCategories" v-if="like.categories"><span v-for="c in like.categories.toString().split(';').slice(0, 3)" v-bind:key="c.nr">{{c}} · </span></p>
                                <p class="listText" v-if="like.items">
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'show'}).length > 1">{{like.items.slice().filter((review) => {return review.type == 'show'}).length}} shows<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'show'}).length == 1">{{like.items.slice().filter((review) => {return review.type == 'show'}).length}} show<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'movie'}).length > 1">{{like.items.slice().filter((review) => {return review.type == 'movie'}).length}} movies<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'movie'}).length == 1">{{like.items.slice().filter((review) => {return review.type == 'movie'}).length}} movie<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'person'}).length > 0">{{like.items.slice().filter((review) => {return review.type == 'person'}).length}} people<br /></span>
                                </p>
                                <p class="listText" v-if="like.description">{{like.description}}</p>
                            </div>
                        </div>
                        <div v-if="like.type == 'list'" v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlikeList">
                            <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id" class="likeHeartCharacterList" v-on:click="unlikeToggle(like, 'recent')"><span id="heartCharacter">❥</span></span>
                            <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'recent'" class="unlikeConfirmList" v-on:click="unlikeConfirm(like)">unlike</span>
                            <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id" class="unlikeCancelList" v-on:click="unlikeCancel(like)"></span>
                        </div>  -->
                    </div>
                </div>

                <!-- likes category shows -->
                <div id="likes-category-shows" class="likesCategory">
                    <div class="like" v-for="like in getSessionUserData.likes.slice().filter((like) => {return like.type == 'show'})" v-bind:key="like.nr">
                        <!-- like obj type = show -->
                        <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + like.thumbnail" v-on:click="accountSetUrlPath(like.type, like.id)" />
                        <div v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlike">
                            <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id + '#' + 'shows'" class="likeHeartCharacter" v-on:click="unlikeToggle(like, 'shows')"><span class="heartCharacter">❥</span></span>
                            <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'shows'" class="unlikeConfirm" v-on:click="unlikeConfirm(like)">unlike</span>
                            <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id + '#' + 'shows'" class="unlikeCancel" v-on:click="unlikeCancel(like)"></span>
                        </div>
                    </div>
                </div>

                <!-- likes category movies -->
                <div id="likes-category-movies" class="likesCategory">
                    <div class="like" v-for="like in getSessionUserData.likes.slice().filter((like) => {return like.type == 'movie'})" v-bind:key="like.nr">
                    <!-- like obj type = movie -->
                        <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + like.thumbnail" v-on:click="accountSetUrlPath(like.type, like.id)" />
                        <div v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlike">
                            <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id + '#' + 'movies'" class="likeHeartCharacter" v-on:click="unlikeToggle(like, 'movies')"><span class="heartCharacter">❥</span></span>
                            <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'movies'" class="unlikeConfirm" v-on:click="unlikeConfirm(like)">unlike</span>
                            <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id + '#' + 'movies'" class="unlikeCancel" v-on:click="unlikeCancel(like)"></span>
                        </div>
                    </div>
                </div>

                <!-- likes category people -->
                <div id="likes-category-people" class="likesCategory">
                    <div class="like" v-for="like in getSessionUserData.likes.slice().filter((like) => {return like.type == 'person'})" v-bind:key="like.nr">
                        <!-- like obj type = person -->
                        <img class="thumbnailPerson" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + like.thumbnail" v-on:click="accountSetUrlPath(like.type, like.id)" />
                        <div v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlikePerson">
                            <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id + '#' + 'people'" class="likeHeartCharacterPerson" v-on:click="unlikeToggle(like, 'people')"><span class="heartCharacter">❥</span></span>
                            <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'people'" class="unlikeConfirmPerson" v-on:click="unlikeConfirm(like)">unlike</span>
                            <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id + '#' + 'people'" class="unlikeCancelPerson" v-on:click="unlikeCancel(like)"></span>
                        </div>
                    </div>
                </div>

                <!-- likes category list -->
                <!-- <div id="likes-category-lists" class="likesCategory">
                    <div class="like" v-for="like in getSessionUserData.likes.slice().filter((like) => {return like.type == 'list'})" v-bind:key="like.nr">
                        <div class="list" style="margin: 0px 0px 0px 0px;" v-on:click="accountSetUrlPath(like.type, like.id)"
                        v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + like.items[0].thumbnail + '); background-size: cover;'">
                            <div id="listInfo">
                                <div class="listName" v-if="like.name">{{like.name}}</div>
                                <p class="listText" v-if="like.items">
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'show'}).length > 1">{{like.items.slice().filter((review) => {return review.type == 'show'}).length}} shows<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'show'}).length == 1">{{like.items.slice().filter((review) => {return review.type == 'show'}).length}} show<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'movie'}).length > 1">{{like.items.slice().filter((review) => {return review.type == 'movie'}).length}} movies<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'movie'}).length == 1">{{like.items.slice().filter((review) => {return review.type == 'movie'}).length}} movie<br /></span>
                                    <span v-if="like.items.slice().filter((review) => {return review.type == 'person'}).length > 0">{{like.items.slice().filter((review) => {return review.type == 'person'}).length}} people<br /></span>
                                </p>
                                <p class="listText" v-if="like.categories"><span v-for="c in like.categories.toString().split(';').slice(0, 3)" v-bind:key="c.nr">{{c}} · </span></p>
                                <p class="listText" v-if="like.description">{{like.description}}</p>
                            </div>
                        </div>
                        <div v-bind:id="'unlike#' + like.type + '#' + like.id" class="unlikeList">
                            <span v-bind:id="'likeHeartCharacter' + '#' + like.type + '#' + like.id" class="likeHeartCharacterList" v-on:click="unlikeToggle(like, 'lists')"><span class="heartCharacter">❥</span></span>
                            <span v-bind:id="'unlikeConfirm' + '#' + like.type + '#' + like.id + '#' + 'lists'" class="unlikeConfirmList" v-on:click="unlikeConfirm(like)">unlike</span>
                            <span v-bind:id="'unlikeCancel' + '#' + like.type + '#' + like.id" class="unlikeCancelList" v-on:click="unlikeCancel(like)"></span>
                        </div>  
                    </div>
                </div> -->

                <!-- select likes category -->
                <div id="likesSelectCategory">
                    <!-- select recent likes -->
                    <span id="likes-select-category-recent" class="likesSelectCategory" v-on:click="accountDisplayData('likes', 'recent')">
                        Recent <span id="likes-recent-count" class="likesCount"> ({{getSessionUserData.likes.length}})</span>
                    </span>
                    <span class="dotChar">∙</span>

                    <!-- select all likes -->
                    <!-- <span id="likes-select-category-all" class="likesSelectCategory" v-on:click="accountDisplayData('likes', 'all')">
                        all <span id="likes-all-count" class="likesCount"> ({{getSessionUserData.likes.length}})</span>
                    </span>
                    <span class="dotChar">∙</span> -->

                    <!-- select liked shows -->
                    <span id="likes-select-category-shows" class="likesSelectCategory" v-on:click="accountDisplayData('likes', 'shows')">
                        Shows <span id="likes-shows-count" class="likesCount"> ({{getSessionUserData.likes.slice().filter((like) => {return like.type == 'show'}).length}})</span>
                    </span>
                    <span class="dotChar">∙</span>

                    <!-- select liked movies -->
                    <span id="likes-select-category-movies" class="likesSelectCategory" v-on:click="accountDisplayData('likes', 'movies')">
                        Movies <span id="likes-movies-count" class="likesCount"> ({{getSessionUserData.likes.slice().filter((like) => {return like.type == 'movie'}).length}})</span>
                    </span>
                    <span class="dotChar">∙</span>

                    <!-- select liked people -->
                    <span id="likes-select-category-people" class="likesSelectCategory" v-on:click="accountDisplayData('likes', 'people')">
                        People <span id="likes-people-count" class="likesCount"> ({{getSessionUserData.likes.slice().filter((like) => {return like.type == 'person'}).length}})</span>
                    </span>

                    <!-- select liked lists -->
                    <!-- <span id="likes-select-category-lists" class="likesSelectCategory" v-on:click="accountDisplayData('likes', 'lists')">
                        lists <span id="likes-lists-count" class="likesCount"> ({{getSessionUserData.likes.slice().filter((like) => {return like.type == 'list'}).length}})</span>
                    </span> -->

                    <!-- select liked episodes -->
                    <!-- <span id="reviewCategoryEpisodes" class="reviewCategory" v-on:click="accountReviews('episodes')">episodes <span id="episodesReviewsTotalCount" class="reviewsCategoryTotalCount"> ({{getSessionUserData.reviews.slice().filter((review) => {return review.type == 'episode'}).length}})</span></span> -->
                </div>  
            </div>

            <!-- section reviews -->
            <div id="sectionReviews" class="section" v-if="getSessionUserData.reviews">
                <!-- reviews category recent -->
                <div id="reviews-category-recent" class="reviewsCategory">
                    <div class="review" v-for="r in getSessionUserData.reviews.slice().reverse()" v-bind:key="r.nr">
                        <!-- review obj type = show or movie -->
                        <div v-if="r.type == 'show' || r.type == 'movie'">
                            <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" v-on:click="accountSetUrlPath(r.type, r.id)" />
                            <p class="rating" v-if="r.type != 'episode'" v-on:click="accountDisplayModalReview(r)">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                        </div>

                        <!-- review obj type = episode -->
                        <div class="episode" v-if="r.type == 'episode'" v-on:click="accountDisplayModalReview(r)">
                            <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" />
                            <div id="episodeText"><span id="episodeNr">S{{r.season}}E{{r.number}}</span></div>
                            <p id="ratingEpisode">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                        </div>

                        <!-- review obj type = person -->
                        <div class="person" v-if="r.type == 'person'">
                            <img class="thumbnailPerson" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" v-on:click="accountSetUrlPath(r.type, r.id)" />
                            <p class="ratingPerson" v-on:click="accountDisplayModalReview(r)">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                            <!-- <p id="namePerson">{{r.name}}</p> -->
                        </div>
                    </div>
                </div>
                
                <!-- reviews category all -->
                
                <!-- reviews category shows  -->
                <div id="reviews-category-shows" class="reviewsCategory">
                    <div class="review" v-for="r in getSessionUserData.reviews.slice().filter((review) => {return review.type == 'show'}).sort((a, b) => {return b.rating - a.rating})" v-bind:key="r.nr">
                        <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" v-on:click="accountSetUrlPath(r.type, r.id)" />
                        <p class="rating" v-on:click="accountDisplayModalReview(r)">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                    </div>
                </div>
                
                <!-- reviews category movies -->
                <div id="reviews-category-movies" class="reviewsCategory">
                    <div class="review" v-for="r in getSessionUserData.reviews.slice().filter((review) => {return review.type == 'movie'}).sort((a, b) => {return b.rating - a.rating})" v-bind:key="r.nr">
                        <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" v-on:click="accountSetUrlPath(r.type, r.id)" />
                        <p class="rating" v-on:click="accountDisplayModalReview(r)">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                    </div>
                </div>
                
                <!-- reviews category people  -->
                <div id="reviews-category-people" class="reviewsCategory">
                    <div class="reviewPerson" v-for="r in getSessionUserData.reviews.slice().filter((review) => {return review.type == 'person'}).sort((a, b) => {return b.rating - a.rating})" v-bind:key="r.nr">
                        <img class="thumbnailPerson" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" v-on:click="accountSetUrlPath(r.type, r.id)" />
                        <p class="ratingPerson" v-on:click="accountDisplayModalReview(r)">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                    </div>
                </div>
                
                <!-- reviews category episodes  -->
                <div id="reviews-category-episodes" class="reviewsCategory">
                    <div class="review" v-for="r in getSessionUserData.reviews.slice().filter((review) => {return review.type == 'episode'}).sort((a, b) => {return b.rating - a.rating}).sort((a, b) => { if(a.show < b.show) {return -1} if(a.show > b.show) {return 1} return 0})" v-bind:key="r.nr" v-on:click="accountDisplayModalReview(r)">
                        <img class="thumbnail" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + r.thumbnail" />
                        <div id="episodeText"><span id="episodeNr">S{{r.season}}E{{r.number}}</span></div>
                        <p id="ratingEpisode">{{r.rating}}<span id="ratingStarCharacter">★</span></p>
                    </div>
                </div>

                <!-- select review category -->
                <div id="reviewsSelectCategory">    
                        <!-- select recent reviews -->
                        <span id="reviews-select-category-recent" class="reviewsSelectCategory" v-on:click="accountDisplayData('reviews', 'recent')">
                            Recent <span id="reviews-recent-count" class="reviewsCount"> ({{getSessionUserData.reviews.length}})</span>
                        </span>
                        <span class="dotChar">∙</span>

                        <!-- select all reviews -->
                        <!-- <span id="reviews-select-category-all" class="reviewsSelectCategory" v-on:click="accountDisplayData('reviews', 'all')">
                            all <span id="reviews-all-count" class="reviewsCount"> ({{getSessionUserData.reviews.length}})</span>
                        </span>
                        <span class="dotChar">∙</span> -->

                        <!-- select show reviews -->
                        <span id="reviews-select-category-shows" class="reviewsSelectCategory" v-on:click="accountDisplayData('reviews', 'shows')">
                            Shows <span id="reviews-shows-count" class="reviewsCount"> ({{getSessionUserData.reviews.slice().filter((review) => {return review.type == 'show'}).length}})</span>
                        </span>
                        <span class="dotChar">∙</span>

                        <!-- select movie reviews -->
                        <span id="reviews-select-category-movies" class="reviewsSelectCategory" v-on:click="accountDisplayData('reviews', 'movies')">
                           Movies <span id="reviews-movies-count" class="reviewsCount"> ({{getSessionUserData.reviews.slice().filter((review) => {return review.type == 'movie'}).length}})</span>
                        </span>
                        <span class="dotChar">∙</span>

                        <!-- select people reviews -->
                        <span id="reviews-select-category-people" class="reviewsSelectCategory" v-on:click="accountDisplayData('reviews', 'people')">
                            People <span id="reviews-people-count" class="reviewsCount"> ({{getSessionUserData.reviews.slice().filter((review) => {return review.type == 'person'}).length}})</span>
                        </span>
                        <span class="dotChar">∙</span>
                                
                        <!-- select episode reviews -->                    
                        <span id="reviews-select-category-episodes" class="reviewsSelectCategory" v-on:click="accountDisplayData('reviews', 'episodes')">
                            Episodes <span id="reviews-episodes-count" class="reviewsCount"> ({{getSessionUserData.reviews.slice().filter((review) => {return review.type == 'episode'}).length}})</span>
                        </span>
                </div>
            </div>

            <!-- section lists -->
            <div id="sectionLists" class="section">
                <!-- latest list -->
                <div id="lists-category-latest" class="listsCategory">
                    <div class="list" v-for="list in getSessionUserData.lists.slice().reverse()" v-bind:key="list.nr" v-on:click="accountSetUrlPath('list', list.id)">
                        <div id="listBackgroundImg" v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + list.items.slice().sort((a, b) => b.order - a.order)[0].thumbnail + '); background-size: cover;'"></div>
                        <!-- list info text -->
                        <div id="listInfo">
                            <!-- list name -->
                            <div class="listCreatedName" v-if="list.name">{{list.name}}</div>
                            
                            <!-- list item count -->
                            <p class="listCreatedText"  v-if="list.items"><span v-if="list.items">{{list.items.length}} items</span></p>
                        </div>
                    </div>
                </div>

                <!-- oldest list -->
                <div id="lists-category-oldest" class="listsCategory">
                    <div class="list" v-for="list in getSessionUserData.lists.slice()" v-bind:key="list.nr" v-on:click="accountSetUrlPath('list', list.id)">
                        <div id="listBackgroundImg" v-bind:style="'background-image: url(' + 'https://image.tmdb.org/t/p/w600_and_h900_bestv2//' + list.items.slice().sort((a, b) => b.order - a.order)[0].thumbnail + '); background-size: cover;'"></div>
                        <!-- list info text -->
                        <div id="listInfo">
                            <!-- list name -->
                            <div class="listCreatedName" v-if="list.name">{{list.name}}</div>
                            
                            <!-- list item count -->
                            <p class="listCreatedText"  v-if="list.items"><span v-if="list.items">{{list.items.length}} items</span></p>
                        </div>
                    </div>
                </div>

            </div>

            <!-- select list category -->
            <div id="listsSelectCategory">
                <span id="lists-select-category-latest" class="listsSelectCategory" v-on:click="accountDisplayData('lists', 'latest')">
                    Latest<span id="lists-latest-count" class="listsCount"></span>
                </span>
                <span class="dotChar">∙</span>
                <span id="lists-select-category-oldest" class="listsSelectCategory" v-on:click="accountDisplayData('lists', 'oldest')">
                    Oldest <span id="lists-oldest-count" class="listsCount"></span>
                </span>
            </div>

            <!-- section info -->
            <h3 class="categoryTitle"><span v-on:click="accountDisplaySection('Info')"></span></h3> <!-- i Info -->
            <div id="sectionInfo" class="section">
                <!-- <p id="loggedInTime">logged in as <b>{{reactiveSessionUsername.value}}</b> since {{reactiveSessionLastLogin.value.substr(11, 8)}} / {{reactiveSessionLastLogin.value.substr(0, 10)}}</p> -->
                <!-- <p>Login Status: Logged In</p> -->
                <!-- <p>Login Username: {{reactiveSessionUsername.value}}</p> -->
                <!-- <p>Login Date: {{reactiveSessionLastLogin.value.substr(11, 8)}} / {{reactiveSessionLastLogin.value.substr(0, 10)}}</p> -->
                <!-- <p>logged in as <b>{{reactiveSessionUsername.value}}</b> since {{reactiveSessionLastLogin.value.substr(11, 8)}} / {{reactiveSessionLastLogin.value.substr(0, 10)}}</p> -->
                <!-- <p>logged in as <b>{{reactiveSessionUsername.value}}</b></p> -->
                <!-- <p>logged in at {{reactiveSessionLastLogin.value.substr(11, 8)}} / {{reactiveSessionLastLogin.value.substr(0, 10)}}</p> -->
            </div>
            
            <!-- logout button -->
            <div id="logout" v-if="reactiveSessionToken.value">
                <form @submit.prevent>
                    <input class="logoutInput" type="submit" value="Logout" v-on:click="logout()"/>
                </form>
                <p id="accountWarningsLogoutFailed" class="accountWarnings">Logout Error: logout failed</p>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, reactive, computed, onUpdated, onMounted} from 'vue'
import {useStore} from 'vuex'
import router from '../router'

export default {
    setup() {
        //vuex
        const store = useStore() //same as this.$store
        const getSessionUserData = computed(() => { return store.getters['sessionUserData']})

        //reactive
        var reactiveSessionToken = reactive({value: null})
        var reactiveSessionUsername = reactive({value: null})
        var reactiveSessionLastLogin = reactive({value: null})
        var reactiveSessionUserData = reactive({value: null})
        var reactiveSessionPublicLists = reactive({value: null})
        var reactiveSelectedReview = reactive({value: null, type: null})
        var reactiveRecentlySearched = reactive({value: null})

        //variables
        const BACKEND_API_URL = computed(() => { return store.getters['backendAPI']}).value
        
        //lifecycle hooks
        onMounted(() => {
            console.log("onMounted ComponentAccount")

            //set reactive session
            reactiveSessionToken.value = localStorage.getItem('sessionToken')
            reactiveSessionUsername.value = localStorage.getItem('sessionUsername')
            reactiveSessionLastLogin.value = localStorage.getItem('sessionLastLogin')
            reactiveSessionUserData.value = JSON.parse(localStorage.getItem('sessionUserData'))
        })

        onUpdated(() => {
            console.log("onUpdated ComponentAccount")
        })

        //functions
        function confirmPasscode()
        {
            //elements
            let registerPassword = document.getElementById("registerPassword")
            let registerConfirmPassword = document.getElementById("registerConfirmPassword")
            let registerSubmitButton = document.getElementById("registerSubmitButton")
            let registerUsername = document.getElementById("registerUsername")

            if(registerUsername.value != "")
            {
                registerPassword.style.opacity = 1
                registerConfirmPassword.style.opacity = 1
                registerSubmitButton.style.opacity = 0
            }
            else if(registerUsername.value == "")
            {
                registerPassword.style.opacity = 0
                registerConfirmPassword.style.opacity = 0
                registerSubmitButton.style.opacity = 0
            }

            if(registerPassword.value != "" && registerConfirmPassword.value != "" && 
            registerPassword.value == registerConfirmPassword.value)
            {
                registerPassword.style.color = "#17ff17"
                registerConfirmPassword.style.color = "#17ff17"
                registerSubmitButton.style.opacity = 1
                registerSubmitButton.disabled = false

            }
            else if(registerPassword.value != "" &&  registerConfirmPassword.value != "" && 
            registerPassword.value != registerConfirmPassword.value)
            {
                registerPassword.style.color = "red"
                registerConfirmPassword.style.color = "red"
                registerSubmitButton.style.opacity = 0
                registerSubmitButton.disabled = true
                
            }
        }

        async function login()
        {
            console.log("login")

            //elements
            let accountWarningsIncorrectPassword = document.getElementById("accountWarningsIncorrectPassword")
            let accountWarningsUserDoesNotExist = document.getElementById("accountWarningsUserDoesNotExist")
            let loginUsername = document.getElementById("loginUsername")
            let loginPassword = document.getElementById("loginPassword")
            let loginSubmitButton = document.getElementById("loginSubmitButton")

            //variables
            let username = loginUsername.value
            let passcode = loginPassword.value
            let url = BACKEND_API_URL + "/login"

            await fetch(url, {method: 'post', body: JSON.stringify({username: username, passcode: passcode})})
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                // console.log(data)

                if(data.status == "correct-password")
                {
                    //undisplay elements
                    accountWarningsIncorrectPassword.style.display = "none"
                    accountWarningsUserDoesNotExist.style.display = "none"
                    setTimeout(() => {
                        let componentOptionsBar = document.getElementById("componentOptionsBar") 
                        componentOptionsBar.style.display = "none"}
                    , 0)

                    //set local storage
                    localStorage.setItem("sessionToken", data.token)
                    localStorage.setItem("sessionUsername", data.username)
                    localStorage.setItem("sessionLastLogin", data.lastLogin)
                    localStorage.setItem("sessionUserData", JSON.stringify(data.userData[0]))
                    localStorage.setItem("sessionPublicLists", JSON.stringify(data.publicLists))

                    //set reactive
                    reactiveSessionToken.value = data.token
                    reactiveSessionUsername.value = data.username
                    reactiveSessionLastLogin.value = data.lastLogin
                    reactiveSessionUserData.value = data.userData[0]

                    //set vuex
                    store.dispatch('actionSetSessionUserData', data.userData[0])
                    store.dispatch('actionSetSessionPublicLists', data.publicLists)
                }
                else if(data.status == "incorrect-password")
                {
                    //display elements
                    accountWarningsIncorrectPassword.style.display = "block"
                    accountWarningsUserDoesNotExist.style.display = "none"
                }
                else if(data.status == "user-does-not-exist")
                {
                    //display elements
                    accountWarningsUserDoesNotExist.style.display = "block"
                    accountWarningsIncorrectPassword.style.display = "none"
                }
            })

            loginSubmitButton.disabled = true
        }

        async function logout()
        {
            console.log("logout")

            //elements
            let accountWarningsLogoutFailed = document.getElementById("accountWarningsLogoutFailed")

            //variables
            let username = reactiveSessionUsername.value
            let token = reactiveSessionToken.value
            let url = BACKEND_API_URL + "/logout"

            await fetch(url, {method: 'post', body: JSON.stringify({username: username, sessionToken: token})})
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                // console.log(data)

                if(data.status == "logout-failed")
                {
                    accountWarningsLogoutFailed.style.display = "block"
                }
                else if(data.status == "logout-successful")
                {
                    //set local storage
                    localStorage.removeItem("sessionToken")
                    localStorage.removeItem("sessionUsername")
                    localStorage.removeItem("sessionLastLogin")
                    localStorage.removeItem("sessionUserData")
                    localStorage.removeItem("sessionPublicLists")
                    localStorage.removeItem("sessionRecentlySearched")
                    
                    //set variables
                    reactiveSessionToken.value = null
                    reactiveSessionUsername.value = null
                    reactiveSessionLastLogin.value = null
                    reactiveSessionUserData.value = null
                    reactiveSessionPublicLists.value = null
                    reactiveRecentlySearched.value = null

                    //reset elements
                    accountWarningsLogoutFailed.style.display = "none"

                    //set vuex
                    store.dispatch('actionSetSessionUserData', null)
                    store.dispatch('actionSetSessionPublicLists', null)
                    store.dispatch('actionSetReviewFromAccountPage', null)
                    store.dispatch('actionSetTopListType', null)
                    store.dispatch('actionSetTopListResult', null)
                    store.dispatch('actionSetRecentlySearched', null)
                    store.dispatch('actionSetSelectedList', null)
                    store.dispatch('actionSetSelectedEpisode', null)
                    store.dispatch('actionSetSelectedPerson', null)
                    store.dispatch('actionSetSelectedMovie', null)
                    store.dispatch('actionSetSelectedShow', null)
                    store.dispatch('actionSetSelectedSeason', null)
                }
            })
        }

        async function register()
        {
            console.log("register")

            //elements
            let registerPassword = document.getElementById("registerPassword")
            let registerConfirmPassword = document.getElementById("registerConfirmPassword")
            let registerSubmitButton = document.getElementById("registerSubmitButton")
            let registerUsername = document.getElementById("registerUsername")
            let accountWarningsUsernameAlreadyExists = document.getElementById("accountWarningsUsernameAlreadyExists")
            let loginUsername = document.getElementById("loginUsername")
            let loginPasscode = document.getElementById("loginPassword")
            let register = document.getElementById("register")
            let loginSubmitButton = document.getElementById("loginSubmitButton")
            // console.log("username: " + registerUsername.value + " / " + "password: " + registerPassword.value)

            //variables
            let username = registerUsername.value
            let passcode = registerPassword.value
            let url = BACKEND_API_URL + "/register"

            await fetch(url, {method: 'post', body: JSON.stringify({username: username, passcode: passcode})})
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                // console.log(data)

                if(data.status == "registration-user-exists")
                {
                    accountWarningsUsernameAlreadyExists.style.display = "block"
                    accountWarningsRegistrationSuccessful.style.display = "none"
                    registerPassword.value = ""
                    registerConfirmPassword.value = ""
                    // registerUsername.value = ""
                }
                else if(data.status == "registration-successful")
                {
                    accountWarningsRegistrationSuccessful.style.display = "block"
                    accountWarningsUsernameAlreadyExists.style.display = "none"
                    register.style.display = "none"
                    loginUsername.value = username
                    // loginPasscode.value = passcode
                    loginSubmitButton.disabled = false
                }
            })

            registerSubmitButton.disabled = true
        }

        function loginValidation()
        {
            let loginUsername = document.getElementById("loginUsername")
            let loginPassword = document.getElementById("loginPassword")
            let loginSubmitButton = document.getElementById("loginSubmitButton")

            if(loginUsername.value != "" && loginPassword.value != "")
            {
                loginSubmitButton.disabled = false
            }
            else if(loginUsername.value == "" || loginPassword.value == "")
            {
                loginSubmitButton.disabled = true
            }
        }

        function accountDisplayData(section, category)
        {
            //debugging
            // console.log("section: " + section)
            // console.log("category: " + category)

            //elements
            let sectionCategories = document.getElementsByClassName(section + "Category")
            let sectionSelectCategories = document.getElementsByClassName(section + "SelectCategory")
            let sectionCounts = document.getElementsByClassName(section + "Count")
            let sectionLists = document.getElementById("sectionLists")

            //update elements
            for(let c = 0; c < sectionSelectCategories.length; c++)
            {
                //split id string
                let sectionSplit = sectionSelectCategories[c].id.split("-")

                //set section category
                let sectionCategory = sectionSplit[3]
                
                if(category == sectionCategory)
                {
                    // sectionSelectCategories[c].style.color = "white"
                    // sectionSelectCategories[c].style.border = "1px solid white"
                    // sectionSelectCategories[c].style.backgroundColor = "#ffffff0e"
                    sectionSelectCategories[c].style.opacity = "1"
                    sectionCounts[c].style.display = "inline-block"
                    if(sectionCategories[c]) 
                    { 
                        sectionCategories[c].style.display = "flex"; 
                        sectionCategories[c].scroll(0, 0)
                    }
                }
                else
                {
                    // sectionSelectCategories[c].style.color = "black"
                    // sectionSelectCategories[c].style.border = "0px solid white"
                    // sectionSelectCategories[c].style.backgroundColor = "white"
                    sectionSelectCategories[c].style.opacity = "0.2"
                    sectionCounts[c].style.display = "none"
                    if(sectionCategories[c]) { sectionCategories[c].style.display = "none" }
                }
            }

            sectionLists.scroll(0, 0)
        }

        function accountDisplayModalReview(review)   
        {
            //elements
            let modalReview = document.getElementById("modalReview")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewIncludeCommentCheckbox = document.getElementById("reviewIncludeCommentCheckbox")
            let reviewComment = document.getElementById("reviewComment")
            let reviewCommentTextarea = document.getElementById("reviewCommentTextarea")
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")
            let optionsBar = document.getElementById("optionsBar")
            let modalUnderlay = document.getElementById("modalUnderlay")
            // let reviewDeleteCheckbox = document.getElementById("reviewDeleteCheckbox")

            //display modal underlay
            modalUnderlay.style.display = "block"

            //set review rating            
            reviewRatingSliderValueText.innerText = review.rating
            reviewRatingSliderRange.value = parseReviewRating(review.rating)

            //set review comment
            if(review.comment != "")
            {
                reviewIncludeCommentCheckbox.checked = true
                reviewIncludeCommentCheckbox.value = "on"
                reviewComment.style.display = "block"
                reviewCommentTextarea.value = review.comment
            }
            else if(review.comment == "")
            {
                reviewCommentTextarea.value = ""
                reviewSubmitButton.style.backgroundColor = ""
                reviewIncludeCommentCheckbox.checked = false
                reviewIncludeCommentCheckbox.value = "off"
                reviewComment.style.display = "none";
            }

            //reset modal
            if(modalReview.style.display == "none" || modalReview.style.display == "") 
            { 
                modalReview.style.display = "block"
                optionsBar.style.display = "none"

                //set vuex
                store.dispatch('actionSetReviewFromAccountPage', review)
            }
            else if(modalReview.style.display == "block") 
            { 
                resetAccountReview()
            }
        }

        function parseReviewRating(rating)
        {
            if(rating == 10) {return 100}
            else if(rating != 10) {return parseInt(rating.replace(".", ""))}
        }

        function resetAccountReview()
        {
            //elements
            let modalReview = document.getElementById("modalReview")
            let reviewRatingSliderValueText = document.getElementById("reviewRatingSliderValueText")
            let reviewRatingSliderRange = document.getElementById("reviewRatingSliderRange")
            let reviewIncludeCommentCheckbox = document.getElementById("reviewIncludeCommentCheckbox")
            let reviewComment = document.getElementById("reviewComment")
            let reviewCommentTextarea = document.getElementById("reviewCommentTextarea")
            let reviewSubmitButton = document.getElementById("reviewSubmitButton")
            let reviewDeleteCheckbox = document.getElementById("reviewDeleteCheckbox")

            //undisplay review modal
            modalReview.style.display = "none"
            modalReview.style.pointerEvents = "visible"

            //reset review comment
            reviewCommentTextarea.value = ""
            reviewIncludeCommentCheckbox.checked = false
            reviewIncludeCommentCheckbox.value = "off"
            reviewComment.style.display = "none";
                 
            //reset review button
            reviewSubmitButton.style.backgroundColor = ""
            reviewSubmitButton.value = "Confirm"

            //reset review rating 
            reviewRatingSliderRange.value = 0
            reviewRatingSliderValueText.innerText = "0"

            //reset review delete
            reviewDeleteCheckbox.checked = false
            reviewDeleteCheckbox.value = "off"

            //set vuex
            store.dispatch('actionSetReviewFromAccountPage', null)
        }
        
        function unlikeToggle(unlikeObj, category)
        {
            //debugging
            console.log("unlikeToggle")
            // console.log(unlikeObj)
            // console.log(category)
            
            //elements
            let unlikeConfirm = document.getElementById("unlikeConfirm#" + unlikeObj.type + "#" + unlikeObj.id + "#" + category)
            let unlikeCancel = document.getElementById("unlikeCancel#" + unlikeObj.type + "#" + unlikeObj.id + "#" + category)
            
            //reset elements
            if(unlikeConfirm.style.display == "none" || unlikeConfirm.style.display == "")
            {
              unlikeConfirm.style.display = "block"
              unlikeCancel.style.display = "block"

            //   if(unlikeObj.type != 'list'){ unlikeConfirm.style.width = "calc(160px - 1px)"}
            //   else if(unlikeObj.type == 'list'){ unlikeConfirm.style.width = "auto"}
              
            }
            else if(unlikeConfirm.style.display == "block")
            {
              unlikeConfirm.style.display = "none"
              unlikeCancel.style.display = "none"
            //   unlikeConfirm.style.width = "0px"
            }
        }

        async function unlikeConfirm(obj)
        {
            // console.log("unlikeConfirm")
            
            //variables
            let unlike = JSON.stringify(obj)
            let unlikeObj = null

            //local storage
            let sessionToken = localStorage.getItem("sessionToken")

            //set like
            unlike = JSON.parse(unlike)
            
            if(unlike.type == 'show') 
            { 
                //create show unlike obj
                unlikeObj = {
                    added: unlike.added, 
                    userSessionToken: sessionToken, 
                    type: unlike.type, 
                    id: unlike.id, 
                    name: unlike.name,
                    thumbnail: unlike.thumbnail,
                    release_date: unlike.release_date
                }
            }
            else if(unlike.type == 'movie') 
            { 
                //create movie unlike obj
                unlikeObj = {
                    added: unlike.added, 
                    userSessionToken: sessionToken, 
                    type: unlike.type, 
                    id: unlike.id, 
                    name: unlike.name,
                    thumbnail: unlike.thumbnail,
                    release_date: unlike.release_date
                }
            }
            else if(unlike.type == 'person') 
            { 
                //create movie unlike obj
                unlikeObj = {
                    added: unlike.added, 
                    userSessionToken: sessionToken, 
                    type: unlike.type, 
                    id: unlike.id, 
                    name: unlike.name,
                    thumbnail: unlike.thumbnail,
                    // release_date: unlike.release_date
                }
            }
            else if(unlike.type == 'list') 
            { 
                //create movie unlike obj
                unlikeObj = {
                    added: unlike.added, 
                    userSessionToken: sessionToken, 
                    type: unlike.type, 
                    id: unlike.id, 
                    name: unlike.name,
                    thumbnail: unlike.thumbnail,
                    // release_date: unlike.release_date
                }
            }

            let url = BACKEND_API_URL + "/unlike"

            //post data
            await fetch(url, {method: 'post', body: JSON.stringify(unlikeObj)})
            .then((response) => { return response.json() })
            .then((data) => {
                console.log(data)

                if(data.status == "unlike-successful") { unlikeSuccessful(unlikeObj)}
                else if(data.status == "unlike-failed") { console.log("unlike-failed") 
                }
            })
        }

        function unlikeCancel(unlikeObj)
        {
            console.log("unlikeCancel")

            //elements
            let unlikeConfirms = document.getElementsByClassName("unlikeConfirm")
            let unlikeConfirmPerson = document.getElementsByClassName("unlikeConfirmPerson")
            let unlikeCancels = document.getElementsByClassName("unlikeCancel")
            let unlikeCancelPerson = document.getElementsByClassName("unlikeCancelPerson")

            //reset elements
            for(let uc in unlikeConfirms)
            {
                if(unlikeConfirms[uc].style) { unlikeConfirms[uc].style.display = "none"}
            }

            for(let uc in unlikeConfirmPerson)
            {
                if(unlikeConfirmPerson[uc].style) { unlikeConfirmPerson[uc].style.display = "none"}
            }

            for(let uc in unlikeCancels)
            {
                if(unlikeCancels[uc].style) { unlikeCancels[uc].style.display = "none"}
            }

            for(let uc in unlikeCancelPerson)
            {
                if(unlikeCancelPerson[uc].style) { unlikeCancelPerson[uc].style.display = "none"}
            }
        }

        function unlikeSuccessful(unlikeObj)
        {
            //elements
            let unlikeConfirms = document.getElementsByClassName("unlikeConfirm")
            let unlikeCancels = document.getElementsByClassName("unlikeCancel")

            //variables
            let sessionUserData = JSON.parse(localStorage.getItem("sessionUserData"))
            
            if(unlikeObj.type == 'show' || unlikeObj.type == 'movie' || unlikeObj.type == 'person' || unlikeObj.type == "list") 
            {
                for(let l in sessionUserData.likes)
                {
                    let like = sessionUserData.likes[l]

                    if(like.type == unlikeObj.type && like.id == unlikeObj.id)
                    {
                        //remove existing like 
                        sessionUserData.likes.splice(l, 1)
                    }
                }
                
            }

            //update local storage
            localStorage.setItem('sessionUserData', JSON.stringify(sessionUserData))

            //update vuex
            store.dispatch('actionSetSessionUserData', sessionUserData)

            //update elements
            for(let uc in unlikeConfirms)
            {
                if(unlikeConfirms[uc].style) { unlikeConfirms[uc].style.display = "none"}
            }

            for(let uc in unlikeCancels)
            {
                if(unlikeCancels[uc].style) { unlikeCancels[uc].style.display = "none"}
            }
            
            setTimeout(() => {
                let componentOptionsBar = document.getElementById("componentOptionsBar") 
                componentOptionsBar.style.display = "none"}
            , 0)
        }

        function accountDisplaySection(section)
        {
            let sectionLikes = document.getElementById("sectionLikes")
            let sectionReviews = document.getElementById("sectionReviews")
            let sectionLists = document.getElementById("sectionLists")
            let categoryTitleLikes = document.getElementById("categoryTitleLikes")
            let categoryTitleReviews = document.getElementById("categoryTitleReviews")
            let categoryTitleLists = document.getElementById("categoryTitleLists")
            let listsSelectCategory = document.getElementById("listsSelectCategory")
            
            listsSelectCategory.style.display = "none"
            sectionLikes.style.display = "none"
            sectionReviews.style.display = "none"
            sectionLists.style.display = "none"
            categoryTitleLikes.style.opacity = "0.2"
            categoryTitleReviews.style.opacity = "0.2"
            categoryTitleLists.style.opacity = "0.2"

            if(section == "Likes")
            {
                sectionLikes.style.display = "block"
                categoryTitleLikes.style.fontWeight = "bold"
                categoryTitleLikes.style.opacity = "1"
            }
            else if(section == "Reviews")
            {              
                sectionReviews.style.display = "block"
                categoryTitleReviews.style.fontWeight = "bold"
                categoryTitleReviews.style.opacity = "1"
            }
            else if(section == "Lists")
            {
                sectionLists.style.display = "block"
                categoryTitleLists.style.fontWeight = "bold"
                categoryTitleLists.style.opacity = "1"
                listsSelectCategory.style.display = "flex"
            }
        }

        function accountSetUrlPath(type, id)
        {
            //debugging
            console.log("accountSetUrlPath")
            console.log("type: " + type + " / " + "id: " + id)
            
            //set url path
            if(type == 'movie') { router.push({path: '/movie/' + id})}
            else if(type == 'show') { router.push({path: '/show/' + id})}
            else if(type == 'person') { router.push({path: '/person/' + id})}
            else if(type == 'list' || type == 'created') { router.push({path: '/list/' + id})}
        }

        function listCategories(list)
        {
            // console.log(list)

            //variables
            let filteredCategories = []

            for(let l in list)
            {
                let listCategories = list[l].categories.split(";")
                
                for(let c in listCategories)
                {
                    // console.log(listCategories[c])
                    filteredCategories.push(listCategories[c])
                }
            }

            //filter out duplicates
            filteredCategories = [...new Set(filteredCategories)]

            return filteredCategories
        }

        function accountDisplayModalLists(value)
        {
            console.log("accountDisplayModalLists")

            //elements
            let modalLists = document.getElementById("modalLists")
            let componentOptionsBar = document.getElementById("componentOptionsBar")
            let optionsBar = document.getElementById("optionsBar")
            let listCheckboxExistingList = document.getElementById("listCheckboxExistingList")
            let listSelectType = document.getElementById("listSelectType")
            let existingLists = document.getElementsByClassName("listExistingListsItem")
            let modalUnderlay = document.getElementById("modalUnderlay")
            let listTempTitle = document.getElementById("listTempTitle")

            //reset elements
            componentOptionsBar.style.display = "block"
            modalLists.style.display = "block"
            optionsBar.style.display = "none"
            listSelectType.style.display = "none"
            modalUnderlay.style.display = "block"

            //click checkbox existing list
            listCheckboxExistingList.click()
            
            //find list item
            for(let c = 0; c < existingLists.length; c++)
            {
                //elements
                let listId = existingLists[c].id.replace("listExistingListName", "")
                let existingSelectButton = null

                if(value.id == listId)
                {
                    //set existing list item button
                    existingSelectButton = document.getElementById("listExistingListButton" + listId)

                    //click list item
                    existingSelectButton.click()
                }
            }

            //set list items count
            listTempTitle.innerText = "Items (" + value.items.length + "/100)"
        }

        return {
            //variables
            reactiveSessionToken,
            reactiveSessionUsername,
            reactiveSessionLastLogin,
            reactiveSessionUserData,
            getSessionUserData,
            reactiveSelectedReview,

            //functions
            confirmPasscode,
            login,
            logout,
            register,
            loginValidation,
            accountDisplayModalReview,
            accountDisplayData,
            accountDisplaySection,
            accountSetUrlPath,
            unlikeCancel,
            unlikeConfirm,
            unlikeToggle,
            listCategories,
            accountDisplayModalLists
        }
    }
}
</script>

<style scoped>
    /*** scrollbar  ***/
    #componentAccount { scrollbar-width: none;}
    .listsCategory { scrollbar-width: thin;}
    .likesCategory { scrollbar-width: thin;}
    .reviewsCategory { scrollbar-width: thin;}
    #listTemp { scrollbar-width: thin;}
    #reviewSelectCategory { scrollbar-width: thin;}
    #likesSelectCategory { scrollbar-width: thin;}
    #listsSelectCategory { scrollbar-width: thin;}

    /*** elements ***/
    ::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; width: 10px; } /* width */
    ::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
    ::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
    #componentAccount::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; width: 0px; } /* width */

    /*** ids ***/
    #componentAccount
    {
        display: none;
        position: absolute;
        height: 96vh;
        width: 60vw;
        top: 4vh;
        margin: 0px;
        padding: 0px;
        opacity: 1;
        color: white;
        overflow-y: auto;
        border: 0px solid white;
        background-color: black;
    }
    #register { margin: 40px 30px 30px 30px; /* background-color: blue; */}
    #login { margin: 30px;; /* background-color: blue; */}
    #logout { position: absolute; width: 100%; bottom: 24px; margin: 0px; padding: 0px; }
    #registerInfo { margin: 10px; color: red; }
    #accountWarningsIncorrectPassword { display: none; margin: 0px; margin-top: 27px; padding: 0px;} 
    #accountWarningsUsernameAlreadyExists { display: none; margin: 0px; margin-top: 27px; padding: 0px;} 
    #accountWarningsUserDoesNotExist { display: none; margin: 0px; margin-top: 27px; padding: 0px;} 
    #accountWarningsLogoutFailed { display: none; margin: 0px; margin-top: 27px; padding: 0px;}
    #accountWarningsRegistrationSuccessful { display: none; color: #17ff17; }
    #registerSubmitButton { opacity: 0; }
    #register { border-top: 0px solid white; }
    #registerPassword { opacity: 0; }
    #registerConfirmPassword { opacity: 0; }
    #registerInfoTitle { margin: 10px 0px 3px 0px; /* margin-bottom: 13px; */ font-weight: bold; color: white; }
    #reviewsSelectCategory 
    { 
        display: flex; 
        position: relative; 
        height: auto; 
        width: 60vw; 
        margin: auto; 
        margin-top: 0px; 
        padding: 0px; 
        font-size: 18px; 
        text-align: center; 
        opacity: 1; 
        overflow-x: auto; 
    } 
    #likesSelectCategory 
    { 
        display: flex; 
        position: relative; 
        height: auto; 
        width: 60vw; 
        margin: auto; 
        margin-top: 0px; 
        padding: 0px; 
        font-size: 18px; 
        text-align: center; 
        opacity: 1; 
        overflow-x: auto; 
    } 
    #listsSelectCategory 
    { 
        display: none; 
        position: relative; 
        height: auto; 
        width: 60vw; 
        margin: auto; 
        margin-top: 0px; 
        padding: 0px; 
        font-size: 18px; 
        text-align: center; 
        opacity: 1; 
        overflow-x: auto; 
    }
    /* #reviewsSelectCategoryParagraph, #likesSelectCategoryParagraph, #listsSelectCategoryParagraph { } */
    #reviews-category-recent { display: flex;} 
    #likes-category-recent { display: flex;} 
    #lists-category-latest { display: flex;}
    #reviews-recent-count { display: inline-block;} 
    #likes-recent-count { display: inline-block;} 
    #lists-recent-count { display: inline-block;}
    #reviews-select-category-recent { opacity: 1; } 
    #likes-select-category-recent { opacity: 1; } 
    #lists-select-category-latest { opacity: 1; }
    #episodeText { margin: 0px; padding: 0px; font-weight: bold; text-shadow: 2px 1px black;}
    #episodeShow { display: block; margin: 10px; padding: 0px; font-weight: bold; text-align: center;} 
    #episodeNr { display: block; margin: 10px; padding: 0px; font-weight: bold; text-align: center;} 
    #episodeName { display: block; margin: 10px; padding: 0px; font-weight: bold; text-align: center;}
    #episodeNr { margin: -160px 0px 0px 0px; font-size: 26px; opacity: 1 }
    #ratingStarCharacter { padding: 0px 0px 0px 3px; font-size: 30px; font-weight: bold; color: yellow;}
    #ratingEpisode { width: 160px; margin: 87px 0px 0px -40px; padding: 0px; font-size: 26px; text-align: center; font-weight: bold; opacity: 1; text-shadow: 2px 1px black;}
    #accountReviews { display: flex; width: 60vw; margin: auto; overflow-x: auto;} 
    #accountLikes { display: flex; width: 60vw; margin: auto; overflow-x: auto;} 
    #accountLists { display: flex; width: 60vw; margin: auto; overflow-x: auto;}
    #accountReviewsRecent { display: flex;} 
    #accountLikesRecent { display: flex;} 
    #accountListsRecent { display: flex;}
    #namePerson { margin: -20px 0px 0px 0px; padding: 0px; font-size: 21px; font-weight: bold; text-shadow: 2px 1px black; opacity: 0.8}
    #loggedInTime { margin: 0px; padding: 0px;}
    #listsAll {display: none; flex-direction: row; width: 60vw; margin: auto; overflow: auto;} 
    #listsPrivate {display: none; flex-direction: row; width: 60vw; margin: auto; overflow: auto;} 
    #listsPublic {display: none; flex-direction: row; width: 60vw; margin: auto; overflow: auto;}
    #listInfo { display: inline-block; width: 449px; margin: 0px; text-align: center;}
    #listImg { display: inline-block; width: 30%; padding: 30px 20px 30px 20px; border-right: 0px solid #ffffff26; background-color: black;}
    #lists-category-all { display: flex;}
    #lists-select-category-all { color: white; border: 1px solid white; background-color: #ffffff0e;}
    #listEditCharacter { display: inline-block; margin: 176px 0px 0px 0px; padding: 0px; font-size: 90px; font-weight: normal; text-align: left; text-shadow: 2px 1px black; user-select: none;}
    #lists-all-count { display: inline-block;}
    #sectionInfo { margin: 30px 0px 0px 0px;}
    #sectionLikes { display: block; }
    #sectionReviews { display: none; }
    #sectionLists { display: none; }
    #accountSelectCategory { display: block; width: 60vw; margin: auto; margin-top: 6px; padding: 0px 0px 16px 0px; text-align: left; }
    #categoryTitleReviews { opacity: 0.2; }
    #categoryTitleLists { opacity: 0.2; }
    #listBackgroundImg 
    {  
        position: relative;
        display: block;
        width: 260px;
        height: 100%;
        background-size: cover;
        z-index: 0;
        opacity: 0.7;
        /* border-bottom: 1px solid rgb(48, 46, 46); */
    }
    #listInfo 
    { 
        position: relative;
        display: inline-block; 
        height: auto;
        width: 240px;
        margin: -236px 0px 0px 0px;
        padding: 10px; 
        vertical-align: top; 
        text-align: left; 
        user-select: none;
        color: white;
    }

    /*** classes ***/
    .registerInput { width: 260px; padding: 4px; font-size: 17px; text-align: center; color: white; border: 1px solid white; background-color: black; }
    .loginInput { width: 260px; padding: 4px; font-size: 17px; text-align: center; color: white; border: 1px solid white; background-color: black; } 
    .loginInput::placeholder { color: rgba(211, 211, 211, 0.4); } 
    .registerInput::placeholder { color: rgba(211, 211, 211, 0.4); }
    .submitInput { height: 40px; width: 270px; font-size: 14px; font-weight: bold; color: black; border: 2px solid white; background-color: white; }
    .submitInput:active { border: 1px solid gray; background-color: gray; }
    .accountWarnings { font-style: italic; color: red; }
    .thumbnail { height: auto; width: 260px; opacity: 1; border: 1px solid rgba(48, 46, 46, 0.4); }
    .thumbnailPerson { height: auto; width: 260px; opacity: 1; border: 1px solid rgba(48, 46, 46, 0.4); }
    .thumbnailList { height: auto; width: 260px; opacity: 1; border: 1px solid rgba(48, 46, 46, 0.4); opacity: 0.6; }
    .review { display: inline-block; height: auto; margin: 0px 30px 0px 0px; padding: 0px; user-select: none; overflow-wrap: break-word; opacity: 0.9; border: 0px solid white;} 
    .like { display: inline-block; height: auto; margin: 0px 30px 0px 0px; padding: 0px; user-select: none; overflow-wrap: break-word; opacity: 0.9; border: 0px solid white;}
    .list { display: inline-block; height: auto; margin: 0px 30px 0px 0px; padding: 0px; user-select: none; overflow-wrap: break-word; opacity: 0.9; border: 2px solid white;}
    .reviewPerson { display: inline-block; height: 190px; min-width: 160px; margin: 4px 10px 10px 0px; padding: 0px; user-select: none; overflow-wrap: break-word; border: 0px solid white; background-color: transparent;}
    .reviewsSelectCategory 
    { 
        margin: 0px 3px 6px 0px; 
        padding: 10px 13px 8px 0px; 
        font-weight: bold; 
        user-select: none; 
        white-space: nowrap; 
        opacity: 0.2; 
        color: white; 
    } 
    .likesSelectCategory 
    { 
        margin: 0px 3px 6px 0px; 
        padding: 10px 13px 8px 0px; 
        font-weight: bold; 
        user-select: none; 
        white-space: nowrap; 
        opacity: 0.2; 
        color: white; 
    } 
    .listsSelectCategory 
    { 
        margin: 0px 3px 6px 0px; 
        padding: 10px 13px 8px 0px; 
        font-weight: bold; 
        user-select: none; 
        white-space: nowrap; 
        opacity: 0.2; 
        color: white; 
    }
    .reviewsCount { display: none;} 
    .likesCount { display: none;} 
    .listsCount { display: none;}
    .reviewsCategory { display: none; width: 60vw; margin: auto; overflow-x: scroll; overflow-y: hidden; min-height: 420px;} 
    .likesCategory { display: none; width: 60vw; margin: auto; overflow-x: scroll; overflow-y: hidden; min-height: 420px;} 
    .listsCategory { display: none; width: 60vw; margin: auto; padding-bottom: 20px; overflow-x: auto; overflow-y: hidden; min-height: 400px;}
    .categoryTitle 
    { 
        display: inline; 
        width: auto; 
        margin: 0px;
        padding: 0px; 
        font-weight: bold;
        font-size: 18px; 
        user-select: none; 
        -webkit-user-select: none; 
        color: white; 
    }
    .rating { margin: -44px 0px 0px 0px; padding: 0px 0px 6px 9px; font-size: 31px; text-align: left; font-weight: bold; text-shadow: 2px 1px black; opacity: 1; }
    .ratingPerson { margin: -44px 0px 0px 0px; padding: 0px 0px 6px 9px; font-size: 31px; text-align: left; font-weight: bold; text-shadow: 2px 1px black; opacity: 1; }
    .episode { margin: 0px; padding: 0px; opacity: 1; border: 0px solid white; }
    .person { margin: 0px;}
    .section { margin: 0px; }
    /* .section { margin: 0px; opacity: 0.2; } */
    /* .section:hover { opacity: 1; } */
    .likeHeartCharacter { display: block; width: 30px; margin: 190px 0px 0px 16px; padding: 0px; font-size: 49px; text-align: left; font-weight: bold; text-shadow: 2px 1px black; color: red;}
    .likeHeartCharacterPerson { display: block; width: 30px; margin: 0px 0px 0px 11px; padding: 0px; font-size: 49px; text-align: left; font-weight: bold; text-shadow: 2px 1px black; color: red; }
    /* .likeHeartCharacterList { display: block; width: 30px; margin: 4px 0px 0px 6px; padding: 0px; font-size: 49px; text-align: left; font-weight: bold; text-shadow: 2px 1px black; color: red;} */
    .unlike { display: block; position: relative; width: 0px; margin-top: -244px; opacity: 1;}
    .unlikeCancel { display: none; height: 390px; width: 260px; margin: -390px 0px 0px 0px; padding: 0px; background-color: transparent; }
    /* .unlikeCancelList { display: none; height: 160px; margin: -240px 0px 0px 0px; padding: 12px 10px 12px 10px; background-color: transparent;} */
    .unlikeConfirm { display: none; width: 260px; margin: 0px; margin: calc(-60px + 9px) 0px 0px 0px; padding: 12px 0px 12px 0px; font-weight: bold; text-shadow: 2px 1px black; color: white; }
    /* .unlikeConfirmList { display: none; width: 112%; margin: 0px; margin: calc(-62px + 7px) 0px 0px 1px; padding: 20px 1px 17px 0px; font-weight: bold; color: white; background-color: black;} */
    .unlikeCancelPerson { display: none; height: 390px; width: 260px; margin: -386px 0px 0px 0px; padding: 0px; background-color: transparent; }
    .unlikeConfirmPerson {  display: none; width: 260px; margin: 0px; margin: calc(-60px + 9px) 0px 0px 0px; padding: 12px 0px 12px 0px; font-weight: bold; text-shadow: 2px 1px black; color: white; }
    .unlikePerson { display: block; width: 0px; margin: calc(-63px - -7px) 0px 0px 0px; opacity: 0.8; text-shadow: 2px 1px black;}
    .unlikeList {  display: block; width: 421px; margin:calc(-60px + 3px) 0px 0px 0px; opacity: 0.8;}
    .listText { max-height: 102px; margin: 0px; padding: 0px 12px 10px 12px; text-align: left; opacity: 0.9; user-select: none; text-shadow: 2px 1px black;}
    .listImg { height: auto; width: auto; left: 0px; margin: 0px; padding: 0px;}
    .listName 
    { 
        height: auto;
        width: auto; 
        margin: 0px; 
        padding: 10px 0px 0px 12px; 
        overflow-x: auto; 
        word-break: keep-all; 
        font-weight: bold;  
        text-align: left; 
        user-select: none;
        font-size: 19px;
        text-shadow: 2px 1px black;
    }
    .listCategories { margin: -4px 0px 0px 0px;}
    .logoutInput 
    { 
        height: auto; 
        font-size: 18px; 
        font-weight: bold; 
        opacity: 0.9; 
        color: white; 
        border: 0px solid white; 
        background-color: transparent; 
    }
    .heartCharacter { display: inline-block; transform: rotate(90deg); vertical-align: top; font-size: 40px; text-shadow: 2px 1px black; color: red; }
    .select-category-title { margin: 0px; padding: 10px 13px 8px 0px; font-size: 20px; font-weight: bold; user-select: none; }
    .dotChar { display: block; padding: 11px 10px 0px 0px; user-select: none; opacity: 0.2; font-weight: bold; }
    .dot { padding: 8px 10px 0px 10px; opacity: 0.2; user-select: none; font-weight: bold; color: white; }
    .listCreatedText 
    { 
        max-height: 102px; 
        margin: 0px; 
        padding: 0px 0px 4px 0px; 
        text-align: center;
        font-weight: bold; 
        opacity: 0.7; 
        user-select: none; 
        white-space: nowrap;
        overflow-x: auto;
        text-shadow: 2px 1px black
    }
    .listCreatedName 
    { 
        width: 100%; 
        margin: 0px; 
        padding: 0px; 
        overflow-x: auto; 
        word-break: keep-all; 
        font-weight: bold; 
        text-align: center; 
        user-select: none; 
        text-shadow: 2px 1px black; 
        font-size: 24px;
    }

    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        ::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; width: 0px; } /* width */

        #componentAccount { width: 100vw; margin: 0px; padding: 0px;}
        #listsSelectCategory { width: 80vw; margin: auto; margin-left: 22px; margin-top: 12px; overflow-x: auto; }
        #likesSelectCategory { width: 80vw; margin: auto; margin-left: 22px; margin-top: 0px; overflow-x: auto;}
        #reviewsSelectCategory { width: 80vw; margin: auto; margin-left: 22px; margin-top: 0px; overflow-x: auto;}
        #accountSignedIn { margin: 10px 0px 10px 0px; }
        #accountNotSignedIn { margin: 30px 0px 0px 0px;}
        #login { margin: 0px;}
        #logout { position: fixed; bottom: 24px; left: 10px; width: 100%; margin: 40px 0px 0px 0px; z-index: 3;}
        #episodeNr { margin: -240px 0px 0px 0px; font-size: 40px;}
        #ratingEpisode { width: auto; margin: 130px 0px 0px -130px; font-size: 40px;}
        #ratingStarCharacter {display: inline-block; font-size: 40px;}
        #loggedInTime { max-width: 320px; margin: auto; overflow-x: auto; white-space: nowrap;}
        #accountSelectCategory 
        { 
            display: block; 
            width: 90vw; 
            margin: auto; 
            margin-top: -10px; 
            padding: 0px 0px 20px 0px; 
            text-align: center; 
        }
        #listBackgroundImg { width: 100%; }
        #sectionLists { height: calc(67vh + 4px); overflow-y: auto; }
        #lists-category-latest { display: flex; flex-direction: column; }
        #lists-category-oldest { display: flex; flex-direction: column; }
        #listInfo { width: 90%; margin: 0px; margin-top: -12vh; }

        .listsCategory { width: 90vw; min-height: 69vh; }
        .likesCategory { width: 90vw; min-height: 69vh; }
        .reviewsCategory { width: 90vw; min-height: 69vh; }
        .likesSelectCategory { white-space: nowrap; }
        .categoryTitle { width: 90vw; text-align: center;}
        .listCategories { margin: 0px;}
        /* .likeHeartCharacter { font-size: 29px;} */
        .review { min-height: calc(60vh - 2vh); height: auto;}
        .like { height: auto; }
        .episode { height: auto; width: auto;}
        .list { height: 16vh; width: auto; margin: 0px; border: 1px solid rgba(48, 46, 46, 0.4); }
        .thumbnail { width: 80vw; }
        .thumbnailPerson { width: 80vw; }
        .thumbnailList { width: 80vw; }
        .rating { margin: -64px 0px 0px 4px; font-size: 40px;}
        .ratingPerson { margin: -64px 0px 0px 4px; font-size: 40px;}    
        .reviewsSelectCategory { font-size: 18px;} 
        .likesSelectCategory { font-size: 18px;} 
        .unlikeCancel { height: 390px; width: 80vw; margin: -382px 0px 0px 0px;}
        .unlike { height: 40px; margin: -249px 0px 20px 0px;}
        .unlikeConfirm { width: 80vw; padding: 12px 0px 12px 0px; font-size: 18px;}
        .section { opacity: 1; }
        .select-category-title { padding-top: 12px; }
        .dotChar { padding-top: 10px; }
        .listCreatedName { white-space: nowrap; overflow-x: scroll; opacity: 0.9; font-size: 21px; }
        .logoutInput { font-size: 18px; }
    }
</style>