<template>
  <div id="componentMovie">
      <div v-if="selectedMovie">
        <div id="moviePosterContainer" v-if="selectedMoviePoster">
          <img id="moviePoster" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + selectedMoviePoster" />
        </div>

        <div id="movieInfo" v-if="selectedMovie">
            <!-- movie title -->
            <p id="movieNameText" v-if="selectedMovie.data.title"><b>{{selectedMovie.data.title}}</b>
              <div id="movieGeneralInfo">
                <!-- movie release date -->
                <span v-if="selectedMovie.data.release_date">{{selectedMovie.data.release_date.substr(0, 4)}} · </span>

                <!-- movie language -->
                <span v-if="selectedMovie.data.spoken_languages[0]">{{selectedMovie.data.original_language.toUpperCase()}} · </span>

                <!-- movie rating -->
                <span class ="movieInfoText movieRatingStarCharacter" v-if="selectedMovie.data.vote_average" v-on:click="displayMovieRating()">★</span>
                <span id="movieRating" class ="movieInfoText" v-if="selectedMovie.data.vote_average">{{selectedMovie.data.vote_average.toFixed(1)}} tmbd</span>
              </div>
            </p>

          <!-- movie summary -->
          <div id="movieSummary">
              <b id="movieSummaryTitle">Summary</b>
              <br />
              <p id="movieDescription"  class="movieInfoText" v-if="selectedMovie.data.overview.length != 0">{{selectedMovie.data.overview}}</p>
          </div>

          <!-- movie slogan -->
          <div id="movieSlogan">
            <b id="movieSloganTitle">Slogan</b><br />
            <p id="movieTagline" class="movieInfoText" v-if="selectedMovie.data.tagline">{{selectedMovie.data.tagline}}</p>
          </div>

          <!-- might use later -->
          <!-- <p>{{selectedMovie.data}}</p> -->
          <!-- <img v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + selectedMovie.data.poster_path" id="moviePoster" /> -->
          <!-- <p v-if="selectedMovie.data.status" id="movieStatus">Status: <b>{{selectedMovie.data.status}}</b></p> -->
          <!-- <p v-if="selectedMovie.data.release_date" id="movieReleaseDate">Release Date: <b>{{selectedMovie.data.release_date}}</b></p> -->
          <!-- <p v-if="selectedMovie.data.release_date" id="movieReleaseDate"><b>{{selectedMovie.data.release_date}}</b></p> -->
          <!-- <p v-if="selectedMovie.data.runtime" id="movieDuration">Duration: {{selectedMovie.data.runtime}} mins</p> -->
          <!-- <p v-if="selectedMovie.data.original_language" id="movieReleaseDate">Language: {{selectedMovie.data.original_language.toUpperCase()}}</p> -->
          <!-- <p v-if="selectedMovie.data.homepage" id="movieReleaseDate">homepage: {{selectedMovie.data.homepage}}</p> -->
          <!-- <p v-if="selectedMovie.data.production_companies.length != 0" id="movieReleaseDate">· Produced By: <span v-for="company in selectedMovie.data.production_companies" v-bind:key="company.nr"> · {{company.name}}</span></p> • -->
          <!-- <p v-if="selectedMovie.data.tagline" id="movieTagline"><b>Tagline: {{selectedMovie.data.tagline}}</b></p> -->
        </div>
          
        <!-- movie details -->
        <p id="movieDetailsTitle" v-on:click="displayMovieDetails()">Details</p>
        <div id="movieDetails">
          <!-- movie title -->
          <p id="" v-if="selectedMovie.data.title" class="movieInfoText movieDetailCategory">
            <b>Title</b>
            <span>{{selectedMovie.data.title}}</span>
          </p>
          
          <!-- movie status -->
          <p id="" v-if="selectedMovie.data.status" class="movieInfoText movieDetailCategory">
            <b>Status</b>
            <span v-if="selectedMovie.data.status">{{selectedMovie.data.status}}</span>
          </p>

          <!-- movie release date -->
          <p id="" v-if="selectedMovie.data.release_date" class="movieInfoText movieDetailCategory">
            <b>Release Date</b>
            <span>{{formatDate(selectedMovie.data.release_date)}}</span>
          </p>
          
          <!-- movie main language -->
          <p id="" v-if="selectedMovie.data.original_language" class="movieInfoText movieDetailCategory">
            <b>Main Language</b>
            <span>{{selectedMovie.data.original_language.toUpperCase()}}</span>
          </p>
          
          <!-- movie rating -->
          <p id="" v-if="selectedMovie.data.vote_average" class="movieInfoText movieDetailCategory">
            <b>Rating</b>
            <span class="movieRatingStarCharacter" v-on:click="displayMovieRating()">★<span id="movieRatingDetails">{{selectedMovie.data.vote_average.toFixed(1)}} tmbd</span></span>
          </p>
          
          <!-- movie summary -->
          <p id="" v-if="selectedMovie.data.overview" class="movieInfoText movieDetailCategory">
            <b>Summary</b>
            <span>{{selectedMovie.data.overview}}</span>
          </p>

          <!-- movie slogan -->
          <p id="" v-if="selectedMovie.data.tagline" class="movieInfoText movieDetailCategory">
            <b>Slogan</b>
            <span>{{selectedMovie.data.tagline}}</span>
          </p>

          <!-- movie original title -->
          <p id="movieOriginalNameText" class="movieInfoText movieDetailCategory" v-if="selectedMovie.data.original_title != selectedMovie.data.title"><b>Original Title </b>{{selectedMovie.data.original_title}}</p>
          
          <!-- movie status -->
          <!-- <p id="movieStatus" class ="movieInfoText" v-if="selectedMovie.data.status">
            {{selectedMovie.data.status}}: 
          </p> -->
          
          <!-- movie runtime -->
          <p id="movieDuration" class ="movieInfoText movieDetailCategory" v-if="selectedMovie.data.runtime"><b>Runtime </b>{{selectedMovie.data.runtime}} mins</p>
          
          <!-- movie spoken languages -->
          <p id="movieLanguages" class ="movieInfoText movieDetailCategory" v-if="selectedMovie.data.spoken_languages">
            <b>Spoken Language(s) </b>
            <span v-for="language in selectedMovie.data.spoken_languages" v-bind:key="language.nr"> · {{language.english_name}}</span>
          </p>

          <!-- movie ratings count -->
          <!-- <p id="movieVoteCount" class ="movieInfoText" v-if="selectedMovie.data.vote_count">TMDB Total Rates: {{selectedMovie.data.vote_count.toLocaleString('en-US', {maximumFractionDigits: 0}).replaceAll(",", ".")}}</p> -->
          
          <!-- movie money details -->
          <div id="movieMoneyInfo" class ="movieInfoText movieDetailCategory" v-if="selectedMovie.data.budget || selectedMovie.data.revenue">
            <b>Money</b>
            <p id="movieBudget" class ="movieInfoText" v-if="selectedMovie.data.budget"> · Budget: {{selectedMovie.data.budget.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).replaceAll(",", ".")}}</p>
            <p id="movieRevenue" class ="movieInfoText" v-if="selectedMovie.data.revenue"> · Revenue: {{selectedMovie.data.revenue.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).replaceAll(",", ".")}}</p>
          </div>

          <!-- movie genres -->
          <p id="movieGenres" class ="movieInfoText movieDetailCategory" v-if="selectedMovie.data.genres.length != 0"><b>Genre(s) </b><span v-for="genre in selectedMovie.data.genres" v-bind:key="genre.nr"> · {{genre.name}}</span></p>
          
          <!-- movie production countries -->
          <p id="movieProductionCountries" class ="movieInfoText movieDetailCategory" v-if="selectedMovie.data.production_countries.length != 0">
            <b>Produced In </b>
            <span v-for="country in selectedMovie.data.production_countries" v-bind:key="country.nr"> · {{country.name}}</span>
          </p> <!-- country.iso_3166_1 -->
          
          <!-- movie production companies -->
          <p id="movieProductionCompanies" class ="movieInfoText movieDetailCategory" v-if="selectedMovie.data.production_companies.length != 0">
            <b>Produced By </b>
            <span v-for="company in selectedMovie.data.production_companies" v-bind:key="company.nr"> · {{company.name}}</span>
          </p> <!-- country.iso_3166_1 -->
        </div>
      </div>

      <!-- if movie missing poster -->
      <!-- <div v-if="selectedMovie && path == selectedMovie.id && selectedMovie.data.poster_path == null" id="moviePosterMissing">
        <div v-if="selectedMovie && selectedMovie.data.status_code != 34" id="movieInfo">
          <p v-if="selectedMovie.data.title" id="movieNameText"><b>{{selectedMovie.data.title}}</b></p>
          <p v-if="selectedMovie.data.original_title != selectedMovie.data.title" id="movieOriginalNameText">original title: {{selectedMovie.data.original_title}}</p>
          <p v-if="selectedMovie.data.status" id="movieStatus">status: {{selectedMovie.data.status}}</p>
          <p v-if="selectedMovie.data.release_date" id="movieReleaseDate">release date: {{selectedMovie.data.release_date}}</p>
          <p v-if="selectedMovie.data.runtime" id="movieDuration">duration: {{selectedMovie.data.runtime}} mins</p>
          <p v-if="selectedMovie.data.overview" id="movieDescription">{{selectedMovie.data.overview}}</p>
        </div>
      </div> -->

      <!-- if movie does not exist -->
      <!-- <div v-if="selectedMovie != null && selectedMovie.data.status_code == 34"><p id="errorMessage"><b>No movie with that id exist</b></p></div> -->

      <!-- if correct data is not loaded -->
      <!-- <div v-else-if="selectedMovie && path != selectedMovie.id"><h3> Loading... </h3></div> -->
      
      <!-- {{urlPath}}
      <br />
      <div v-if="selectedMovie">{{selectedMovie.data.original_title}}</div> -->

      <div id="underlayMovies"></div>
  </div>
</template>

<script>
import {ref, toRef, toRefs, reactive, computed, watch, watchEffect, onMounted, onBeforeMount, beforeUpdate, onUpdated, onBeforeUpdate} from 'vue'
import {useStore} from 'vuex'
import { useRouter, useRoute } from 'vue-router' //instead of this.$route
import router from '../router'

export default {
    setup() {
      //router
      const path = computed(() => { return useRouter().currentRoute.value.params.movieId})
      const urlPath = computed(() => { return router.currentRoute._value.path })
      // console.log("ComponentMovie path: " + path.value)
      // console.log("ComponentMovie computed: " + urlPath)
      // let path = useRouter().currentRoute.value.params.movieId
      // const path = computed(() => { return useRouter().currentRoute})
      // console.log(path)
      // console.log(router.currentRoute._value.params.movieId)
      // const urlPath = computed(() => { return router.currentRoute._value.path})
      // var urlPath = reactive({data: null})
      // urlPath.data = router.currentRoute._value.path
      // console.log("reactive: " + urlPath.data)
      // console.log(urlPath.value)

      //vuex
      const store = useStore() //same as this.$store
      const selectedMovie = computed(() => { return store.getters['selectedMovie']})
      const selectedMoviePoster = computed(() => { return store.getters['selectedMoviePoster']})
      // console.log(selectedMovie)
      
      //variables
      // var movieId = useRouter().currentRoute.value.params.movieId
      // var movieData = null
      // var localStorageData = []
      // var diff = null

      //lifecycle hooks
      onMounted(() => {
        console.log("onMounted ComponentMovie")
        // if(screen.width < 1024)
        // {
        //   document.getElementById("header").style.display = "block" //display header
        // }
        // else
        // {
        //   document.getElementById("header").style.display = "none" //display header
        // }
        
        window.scrollTo(0,0); //reset scroll to top

      })

      onUpdated(() => {
        console.log("onUpdated ComponentMovie")
      
        //variables
        // let urlMovieId = useRouter().currentRoute.value.params.movieId
        let backgroundImageUrl = null

        //debugging
        // console.log("urlMovieId: " + urlMovieId)
        // console.log("movieId: " + movieId)
        // console.log("path: " + path.value)
        // console.log(selectedMovie._value.data.backdrop_path)

        //elements
        let underlayMovies = document.getElementById("underlayMovies")
        let componentOptionsBar = document.getElementById("componentOptionsBar")
        let modalReview = document.getElementById("modalReview")
        
        //set background image url
        if(selectedMovie.value) { if(selectedMovie.value.data.backdrop_path != null) {backgroundImageUrl = "https://www.themoviedb.org/t/p/original/" + selectedMovie._value.data.backdrop_path}}
        else { backgroundImageUrl = ""}

        //set background image
        underlayMovies.style.backgroundImage = "url('" + backgroundImageUrl + "')"
        underlayMovies.style.backgroundSize = "cover"

        //display options bar
        componentOptionsBar.style.display = "block"

        //undisplay modal review
        modalReview.style.display = "none"
      })

      function displayMovieDetails()
      {
        //elements
        let movieDetails = document.getElementById("movieDetails")
        let componentTopBar = document.getElementById("componentTopBar")
        let componentOptionsBar = document.getElementById("componentOptionsBar")
        let movieDetailsTitle = document.getElementById("movieDetailsTitle")
        let underlayMovies = document.getElementById("underlayMovies")
        let movieInfo = document.getElementById("movieInfo")
        let moviePoster = document.getElementById("moviePoster")
        let bottomBar = document.getElementById("bottomBar")
        // let scrollBarSearch = document.getElementById("scrollBarSearch")

        //variables
        let mq = window.matchMedia("(max-width: 1100px)")
        let lsSessionUserData = localStorage.getItem("sessionUserData")

        //reset movie details
        if(movieDetails.style.display == "" || movieDetails.style.display == "none")
        {
          movieDetails.style.display = "block"
          movieInfo.style.display = "none"
          componentTopBar.style.display = "none"
          componentOptionsBar.style.display = "none"
          if(moviePoster) {moviePoster.style.display = "none"}
          bottomBar.style.display = "none"
          movieDetailsTitle.innerText = "Exit Details"

          if(mq.matches == true) //mobile
          { 
            // underlayMovies.style.display = "block"
            movieDetailsTitle.style.bottom = "0px"
            movieDetailsTitle.style.padding = "3vh 0px 3vh 0px"
            // movieDetailsTitle.style.backgroundColor = "black"
            underlayMovies.style.opacity = "0.6"
            // movieDetailsTitle.style.borderTop = "1px solid white"
            // if(scrollBarSearch)scrollBarSearch.style.display = "none"
          }
          else if(mq.matches == false) //desktop
          { 
            // scrollBarSearch.style.display = "none"
          }
        }
        else if(movieDetails.style.display == "block")
        {
          movieDetails.style.display = "none"
          movieInfo.style.display = "block"
          componentTopBar.style.display = "block"
          if(moviePoster) { moviePoster.style.display = "inline-block" }
          bottomBar.style.display = "block"
          movieDetailsTitle.innerText = "Details"

          //check if logged in
          if(lsSessionUserData != null) {componentOptionsBar.style.display = "block" }
          else if(lsSessionUserData == null) { componentOptionsBar.style.display = "none"}
          
          // scrollBarSearch.style.display = "block"

          if(mq.matches == true) 
          { 
            movieDetailsTitle.style.bottom = "14vh"
            movieDetailsTitle.style.padding = "0px"
            movieDetailsTitle.style.borderTop = "0px solid white"
            // movieDetailsTitle.style.backgroundColor = "transparent"
            underlayMovies.style.opacity = "0"
            // underlayMovies.style.display = "none"
          }
          else if(mq.matches == false) 
          { 
            //nothing
          }
        }
      }

      function displayMovieRating()
      {
        //elements
        let movieRating = document.getElementById("movieRating")
        let movieRatingDetails = document.getElementById("movieRatingDetails")

        //display movie rating
        if(movieRating.style.display == "" || movieRating.style.display == "none"){ movieRating.style.display = "inline-block"}
        else if(movieRating.style.display == "inline-block") { movieRating.style.display = "none"}

        //display movie rating details
        if(movieRatingDetails.style.display == "" || movieRatingDetails.style.display == "none"){ movieRatingDetails.style.display = "inline-block"}
        else if(movieRatingDetails.style.display == "inline-block") { movieRatingDetails.style.display = "none"}
        
      }

      function formatDate(date)
      {
          let year = null
          let month = null
          let day = null

          if(date == null || date == "") 
          { 
            date = "??-???-????"
          }
          else
          {
            year = date.substring(0, 4)
            month = date.substring(5, 7)
            day = date.substring(8, 10)
            date = day + "-" + month + "-" + year 
  
            if (date.includes("-01-")) { date = date.replace("-01-", "-Jan-") }
            else if (date.includes("-02-")) { date = date.replace("-02-", "-Feb-") }
            else if (date.includes("-03-")) { date = date.replace("-03-", "-Mar-") }
            else if (date.includes("-04-")) { date = date.replace("-04-", "-Apr-") }
            else if (date.includes("-05-")) { date = date.replace("-05-", "-May-") }
            else if (date.includes("-06-")) { date = date.replace("-06-", "-Jun-") }
            else if (date.includes("-07-")) { date = date.replace("-07-", "-Jul-") }
            else if (date.includes("-08-")) { date = date.replace("-08-", "-Aug-") }
            else if (date.includes("-09-")) { date = date.replace("-09-", "-Sep-") }
            else if (date.includes("-10-")) { date = date.replace("-10-", "-Oct-") }
            else if (date.includes("-11-")) { date = date.replace("-11-", "-Nov-") }
            else if (date.includes("-12-")) { date = date.replace("-12-", "-Dec-") }
          }

          return date
      }

      return {
        //variables
        path,
        urlPath,

        //vuex
        selectedMovie,
        selectedMoviePoster,

        //functions
        displayMovieDetails,
        displayMovieRating,
        formatDate
      }
    }

}
</script>

<style scoped>
  /*** scrollbar  ***/
  #movieInfo { scrollbar-width: none;}
  #movieDetails { scrollbar-width: none;}
  #movieDescription { scrollbar-width: none;}
  #movieNameText { scrollbar-width: none;}

  /*** elements  ***/

  /*** ids ***/
  #componentMovie { user-select: none; }
  #movieInfo 
  { 
    display: block; 
    position: absolute; 
    max-height: 49vh; 
    width: 39%; 
    /* top: 38vh;  */
    top: 49vh;
    left: 34vw; 
    margin: 0px; 
    text-align: left; 
    z-index: 1; 
    opacity: 1; 
    text-shadow: 2px 1px black; 
    overflow-y: auto; 
    overflow-x: hidden; 
    color: white; 
  }
  /* #movieNameText { position: fixed; }  */
  #movieOriginalNameText { } 
  #movieStatus { } 
  #movieReleaseDate { } 
  #movieDuration { } 
  #movieTagline { width: 80%; }
  #movieNameText { position: fixed; width: 100%; margin: -7vh 0px 4px 0px; text-align: left; white-space: nowrap; overflow-x: auto; font-size: 27px;}
  #movieStatus { }
  #movieDescription 
  { 
    display: block; 
    max-height: 110px; 
    width: 80%; 
    margin: 0px 0px 20px 0px;
    overflow-y: auto;
  }
  #underlayMovies { display: block; position: absolute; height: 100vh; width: 100vw; bottom: 0px; left: 0px; z-index: 0; opacity: 0.4; background-color: black; }
  #movieDetails 
  { 
    display: none; 
    position: fixed; 
    height: 89vh; 
    width: 40vw; 
    top: 4vh; 
    left: 30vw;
    margin: 0px; 
    padding: 0px;
    z-index: 1; 
    overflow-y: auto; 
    text-align: center; 
    opacity: 1; 
    color: white;
    border-top: 0px solid rgba(255, 255, 255, 0.2); 
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  }
  #movieSlogan { margin: 0px 0px 20px 0px;}
  #movieProductionCountries { display: flex; flex-direction: column;}
  #movieProductionCompanies { display: flex; flex-direction: column;}
  #movieLanguages { display: flex; flex-direction: column;}
  #movieGenres { display: flex; flex-direction: column;}
  #movieGeneralInfo 
  { 
    margin: -4px 0px 0px 0px; 
    padding: 0px; 
    font-size: 16px; 
    text-shadow: 2px 1px solid black; 
    overflow-y: hidden; 
    overflow-x: auto; 
    user-select: none;
    opacity: 0.9; 
  }
  #movieDetailsTitle 
  { 
    position: fixed; 
    width: 100%; 
    bottom: 3vh; 
    left: 0vw; 
    margin: 0px; 
    padding: 0px; 
    text-align: center; 
    font-weight: bold;
    font-size: 18px;
    z-index: 1; 
    user-select: none; 
    text-shadow: 2px 1px black;
    color: white;
  }
  #movieRating { display: none;  width: auto; margin: 0px 0px 0px 5px; }
  #movieRatingDetails { display: none;  width: auto; font-size: 19px; margin: 0px 0px 0px 4px;}
  #moviePoster 
  { 
    position: fixed; 
    display: inline-block;
    bottom: calc(64vh + 10px); 
    width: 20vh; /* 190px */
    margin: 0px; 
    z-index: 2; 
    border: 1px solid rgba(48, 46, 46, 0.4); /* #00000066 */
  }
  #moviePosterContainer { display: flex; justify-content: center; }
  #movieSummaryTitle { font-size: 18px; }
  #movieSloganTitle { font-size: 18px; }
  #movieSummary { margin: 12px 0px 0px 0px; }
  #movieSlogan { margin: 26px 0px 0px 0px; }
  /* #movieInfo p { margin: 0px; padding-bottom: 5px; padding-left: 155px; } */
  /* #movieInfoPosterMissing
 {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 15px;
    padding: 10px;
    min-height: 235px;
    width: calc(1000px - 20px);
    text-align: left;
    color: white;
    background-color: black;
  } */
  /* #movieInfoPosterMissing p
  {
    margin: 0px;
    padding-bottom: 4px;
    padding-left: 4px; */
    /* padding-left: 155px; */
  /* } */
  /* #moviePoster 
  {
    position: absolute;
    height: 320px;
    width: 230px;
    margin: 0px;
    margin-top: -8px; */
    /* margin-top: -47px; */
    /* margin-left: 30px;
    padding: 0px;
    float: left;
    z-index: 1;
    border: 2px solid white;
  } */
  /* #errorMessage
  {
    color: black;
  } */
  /* #moviePosterMissing p
  {
    margin: 0px;
    padding-bottom: 4px;
    padding-left: 4px;
  } */
  /* #componentMovie
  { */
    /* padding-top: 4vh; */
    /* padding-bottom: 33vh; */
    /* opacity: 0.9; */
  /* } */
  
  /*** classes ***/
  .movieInfoText { margin: 0px; padding: 0px; padding-bottom: 2px; text-shadow: 2px 1px black; opacity: 0.9; font-size: 17px; }
  .movieDetailCategory { display: flex; flex-direction: column; margin: 0px 0px 20px 0px;}
  .movieRatingStarCharacter { display: inline; width: auto; font-size: 19px; user-select: none;}
    
  /*** mobile ***/
  @media screen and (max-width: 1100px) {
    ::-webkit-scrollbar { width: 0px; height: 0px; } /* width */
    
    #movieInfo { max-height: 67vh; width: 90vw; left: 4vw; top: 8vh; overflow-y: auto; z-index: 2; font-size: 18px;}
    #scrollBarSeasons { width: 100vw; left: 0px; bottom: 10vh; margin: 0px; padding: 10 0px 1px 0px; border-top: 0px solid white;}
    #movieDetails 
    { 
      position: fixed; 
      height: 66vh;
      width: 100vw; 
      top: 0vh; 
      left: 0vh; 
      margin: 0px; 
      padding: 33px 0px 20vh 0px; 
      opacity: 1; 
      font-size: 18px;
      border-top: 0px solid white; 
      border-bottom: 0px solid rgba(255, 255, 255, 0.4); 
      /* background-color: rgba(0, 0, 0, 0.3); */
    }
    #movieNameText { position: relative; width: 90vw; margin: 0px; text-align: left; font-size: 24px; white-space: nowrap; overflow-x: auto; }
    #movieDescription { max-height: 49vh; width: 100%; margin-top: -2px; opacity: 0.9; white-space: nowrap; }
    #movieSlogan { opacity: 0.9; }
    #movieDates { opacity: 0.9;}
    #underlayMovies { display: block; opacity: 0;}
    #movieGeneralInfo { margin: -4px 0px 20px 0px; padding: 0px; font-size: 18px; }
    #movieDetailsTitle { bottom: 14vh; text-shadow: 2px 1px black; z-index: 2;}
    #moviePoster 
    { 
      position: relative; 
      height: calc(93vh + 4px); 
      width: 100vw; 
      bottom: initial; 
      margin: 0px; 
      opacity: 0.8;
      border: 0px;
    }
    #movieTagline { width: 100%; margin-top: -2px; white-space: nowrap; overflow-x: auto; }
    #movieRating { position: absolute; margin-top: 2px; }
    #movieSummary { margin: 6px 0px 0px 0px; }
    #movieSlogan { margin: 26px 0px 0px 0px; }

    /* .season {margin-right: 10px; margin-left: 10px; font-size: 21px;} */
    /* .movieInfoText { display: flex; flex-direction: column;} */
    .movieInfoText { width: 90%; font-size: 18px; }
    .movieDetailCategory { margin: auto; margin-bottom: 30px; }
  }
</style>