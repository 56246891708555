<template>
  <div id="componentStart">
    <!-- current route -->
    <b style="display: none;">{{currentRoute.value.path}}</b>

    <!-- search bar -->
    <div id="searchBar">
        <!-- search types -->
        <div id="searchTypeBar">
            <span id="searchTypeMovies" class="searchType" v-on:click="searchBarChangeType('movies')">movie</span><span class="smallDotCharacter">·</span>
            <span id="searchTypeShows" class="searchType" v-on:click="searchBarChangeType('shows')">show</span><span class="smallDotCharacter">·</span>
            <span id="searchTypePeople" class="searchType" v-on:click="searchBarChangeType('people')">person</span>
            <!-- <span id="searchTypeLists" class="searchType" v-on:click="searchBarChangeType('lists')">list</span> -->
            <!-- <span id="searchTypeCloseWindow" class="searchType" v-on:click="searchBarCloseWindow()">x</span> -->
        </div>

        <!-- search input form -->
        <form id="searchBarForm" @submit.prevent v-on:keyup.enter="setUrl('search', searchType.value.toLowerCase() + '/' + searchString.value)">
            <input id="searchBarInput" type="text" v-on:keyup="setSearchString()" placeholder="select search type" maxlength="100"><br />
            <input id="searchBarSubmitButton" type="submit" value="search" />
        </form>
    </div>

    <!-- search results -->
    <div id="searchResultsBox">
        <div v-if="getRecentlySearched != null && getRecentlySearched.results.length != 0" id="scrollBarSearch">
            <div v-bind:id="'hit#' + hit.id" class="hit" v-for="hit in getRecentlySearched.results.filter(item => item.poster_path != null || item.profile_path != null)" v-bind:key="hit.id">
                <!-- search hit type = movie -->
                <div v-if="hit.first_air_date == null && hit.gender == null" v-on:click="setUrl('movie', hit.id)">
                    <img v-bind:id="'hitImg#' + hit.id" class="hitImg" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + hit.poster_path" />
                </div>

                <!-- search hit type = show -->
                <div v-else-if="hit.first_air_date != null && hit.gender == null" v-on:click="setUrl('show', hit.id)">
                    <img v-bind:id="'hitImg#' + hit.id" class="hitImg" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + hit.poster_path" />
                </div>

                <!-- search hit type = people -->
                <div v-else-if="hit.gender != null">
                    <div v-on:click="setUrl('person', hit.id)">
                        <img v-bind:id="'hitImg#' + hit.id" class="hitImg hitImgPerson" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/' + hit.profile_path" />
                    </div>
                    <div class="personName" v-if="hit.name.length < 20">{{hit.name}}</div>
                    <div class="personName" v-if="hit.name.length > 20">{{hit.name.substr(0, 17)}}...</div>
                </div>
            </div>
        </div>
    </div>

    <!-- toplists -->
    <div id="toplists">
        <div id="modalToplistCategories"> <!-- toplist categories -->
            <p id="toplistCategoriesTitle">RankingsX:</p>
            <span class="dot"></span>
            <p id="toplistCategoriesTitleMovies" v-on:click="displayToplistCategory('movies')">Movies</p>
            <span class="dot"> · </span>
            <p id="toplistCategoriesTitleShows" v-on:click="displayToplistCategory('shows')">Shows</p>

            <div id="toplistCategoriesMovies">
                <div id="toplistCategoryAll">
                    <div class="toplistCategory">
                        <span v-on:click="selectToplistCategory('movies', 'allTime', null, 'All Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', null, 'This Year Movies')">{{new Date().getFullYear()}}</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'previousYear', null, 'Previous Year Movies')">{{new Date().getFullYear() - 1}}</span>
                    </div>
                </div>
                <!-- <p class="toplistCategory">Specific Year</p> -->
                <!-- <p class="toplistCategory">Specific Country</p> -->
                <!-- <p class="toplistCategory">Specific Decade</p> -->
                <div id="toplistCategoryOriginals">
                    <div class="toplistCategory">
                        <span>Netflix Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'allTime', 'netflix', 'All Netflix Original Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'netflix', 'This Year Netflix Original Movies')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>Disney Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'allTime', 'disney', 'All Disney Original Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'disney', 'This Year Disney Original Movies')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>Amazon Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'allTime', 'amazon', 'All Amazon Original Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'amazon', 'This Year Amazon Original Movies')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>Apple Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'allTime', 'apple', 'All Apple Original Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'apple', 'This Year Apple Original Movies')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>HBO Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'allTime', 'hbo', 'All HBO Original Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'hbo', 'This Year HBO Original Movies')">{{new Date().getFullYear()}}</span>
                    </div>
                </div>
                
                <div id="toplistCategoryGenres">
                    <div class="toplistCategory">
                        <span>Animated:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'genre', 'animated', 'All Animated Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'animated', 'This Year Animated Movies')">{{new Date().getFullYear()}}</span>
                    </div>

                    <div class="toplistCategory">
                        <span>Anime:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'genre', 'anime', 'All Anime Movies')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'anime', 'This Year Anime Movies')">{{new Date().getFullYear()}}</span>
                    </div>

                    <div class="toplistCategory">
                        <span>Documentary:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'genre', 'documentary', 'All Documentary Movies')">All</span>
                        <!-- <span> · </span> -->
                        <!-- <span v-on:click="selectToplistCategory('movies', 'currentYear', 'documentary', 'This Year Documentary Movies')">{{new Date().getFullYear()}}</span> -->
                    </div>                    
                </div>
                
                <div id="toplistCategoryCountries">
                    <div class="toplistCategory">
                        <span>Korean:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'allTime', 'korean', 'Korean Movies All')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('movies', 'currentYear', 'korean', 'Korean Movies This Year')">{{new Date().getFullYear()}}</span>
                    </div>
                </div>
                
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'genre', 'horror', 'Horror Movies All')">Horror Movies All</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'genre', 'crime', 'Crime Movies All')">Crime Movies All</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'genre', 'scifi', 'Sci-Fi Movies All')">Sci-Fi Movies All</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'genre', 'biography', 'Biography Movies All')">Biography Movies All</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'genre', 'war', 'War Movies All')">War Movies All</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'genre', 'thriller', 'Thriller Movies All')">Thriller Movies All</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'currentYear', 'horror', 'Horror Movies This Year')">Horror Movies {{new Date().getFullYear()}}</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'currentYear', 'crime', 'Crime Movies This Year')">Crime Movies {{new Date().getFullYear()}}</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'currentYear', 'scifi', 'Sci-Fi Movies This Year')">Sci-Fi Movies {{new Date().getFullYear()}}</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'currentYear', 'biography', 'Biography Movies This Year')">Biography Movies {{new Date().getFullYear()}}</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'currentYear', 'war', 'War Movies This Year')">War Movies {{new Date().getFullYear()}}</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('movies', 'currentYear', 'thriller', 'Thriller Movies This Year')">Thriller Movies {{new Date().getFullYear()}}</p> -->
            </div>

            <div id="toplistCategoriesShows">
                <!-- <p class="toplistCategory">Specific Year</p> -->
                <!-- <p class="toplistCategory">Specific Country</p> -->
                <!-- <p class="toplistCategory">Specific Decade</p> -->
                <div id="toplistCategoryAll">
                    <div class="toplistCategory">
                        <span v-on:click="selectToplistCategory('shows', 'allTime', null, 'All Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span> 
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', null, 'This Year Shows')">{{new Date().getFullYear()}}</span>
                        <span class="dotCharacterRankings"> · </span> 
                        <span v-on:click="selectToplistCategory('shows', 'previousYear', null, 'Previous Year Shows')">{{new Date().getFullYear() - 1}}</span>
                    </div>
                </div>

                <div id="toplistCategoryOriginals">
                    <div class="toplistCategory">
                        <span>Netflix Originals:</span>
                        <span class="dotCharacterRankings"> · </span> 
                        <span v-on:click="selectToplistCategory('shows', 'allTime', 'netflix', 'All Netflix Original Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'netflix', 'This Year Netflix Original Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>Disney Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'allTime', 'disney', 'All Disney Original Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'disney', 'This Year Disney Original Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>Amazon Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'allTime', 'amazon', 'All Amazon Original Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'amazon', 'This Year Amazon Original Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>Apple Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'allTime', 'apple', 'All Apple Original Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'apple', 'This Year Apple Original Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                    
                    <div class="toplistCategory">
                        <span>HBO Originals:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'allTime', 'hbo', 'All HBO Original Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'hbo', 'This Year HBO Original Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                </div>

                <div id="toplistCategoryGenres">
                    <div class="toplistCategory">
                        <span>Animated:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'genre', 'animated', 'All Animated Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'animated', 'This Year Animated Shows')">{{new Date().getFullYear()}}</span>
                    </div>
    
                    <div class="toplistCategory">
                        <span>Anime:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'genre', 'anime', 'All Anime Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'anime', 'This Year Anime Shows')">{{new Date().getFullYear()}}</span>
                    </div>
    
                    <div class="toplistCategory">
                        <span>Documentary:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'genre', 'documentary', 'All Documentary Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'documentary', 'This Year Documentary Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                </div>

                <div id="toplistCategoryCountries">
                    <div class="toplistCategory">
                        <span>Korean:</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'allTime', 'korean', 'All Korean Shows')">All</span>
                        <span class="dotCharacterRankings"> · </span>
                        <span v-on:click="selectToplistCategory('shows', 'currentYear', 'korean', ' This Year Korean Shows')">{{new Date().getFullYear()}}</span>
                    </div>
                </div>

                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'genre', 'horror', 'All Horror Shows')">All Horror Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'genre', 'crime', 'All Crime Shows')">All Crime Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'genre', 'scifi', 'All Sci-Fi Shows')">All Sci-Fi Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'genre', 'biography', 'All Biography Shows')">All Biography Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'genre', 'war', 'All War Shows')">All War Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'genre', 'thriller', 'All Thriller Shows')">All Thriller Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'currentYear', 'horror', 'This Year Horror Shows')">{{new Date().getFullYear()}} Horror Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'currentYear', 'crime', 'This Year Crime Shows')">{{new Date().getFullYear()}} Crime Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'currentYear', 'scifi', 'This Year Sci-Fi Shows')">{{new Date().getFullYear()}} Sci-Fi Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'currentYear', 'biography', 'This Year Biography Shows')">{{new Date().getFullYear()}} Biography Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'currentYear', 'war', 'This Year War Shows')">{{new Date().getFullYear()}} War Shows</p> -->
                <!-- <p class="toplistCategory" v-on:click="selectToplistCategory('shows', 'currentYear', 'thriller', 'This Year Thriller Shows')">{{new Date().getFullYear()}} Thriller Shows</p> -->
            </div>
        </div>
                
        
        <!-- toplist items -->
        <div id="listView">
            <div v-bind:id="'listItem#' + (index + 1)" v-bind:class="'listItem'" v-for="(obj, index) in getTopListResult" v-bind:key="obj.nr">
                <!-- list item show -->
                <div id="listItemContainer">
                    <span id="listItemRank">#{{index + 1}}</span>

                    <span v-if="obj.type == 'shows'" v-on:click="search(obj.title, 'Shows', 'toplist', obj.date, obj, index)">
                        <span class="dot">·</span>
                        <span v-bind:id="'listItemTitle#' + (index + 1)" v-on:click="search(obj.title, 'Shows', 'toplist', obj.date, obj, index, null)"><b>{{obj.title}}</b></span>
                        <span class="dot">·</span>
                    </span>

                    <span v-else-if="obj.type == 'movies'" v-on:click="search(obj.title, 'Movies', 'toplist', obj.date, obj, index)">
                        <span class="dot">·</span>
                        <span v-bind:id="'listItemTitle#' + (index + 1)" v-on:click="search(obj.title, 'Movies', 'toplist', obj.date, obj, index, null)"><b>{{obj.title}}</b></span>
                        <span class="dot">·</span>
                    </span>
                
                    <span v-bind:id="'listItemRating' + (index + 1)" v-if="obj.rating.split('(')[0].length == 1" v-on:click="displayItemRating(index + 1)">
                        <span class="itemRatingStar">★</span>
                        <span v-bind:id="'ratingText' + (index + 1)" class="ratingText">{{obj.rating.split("(")[0].split("/")[0]}}.0 imdb</span>
                    </span>
                    <span v-bind:id="'listItemRating' + (index + 1)" v-else-if="obj.rating.length < 7" v-on:click="displayItemRating(index + 1)">
                        <span class="itemRatingStar">★</span>
                        <span v-bind:id="'ratingText' + (index + 1)" class="ratingText">{{obj.rating}} imdb</span>
                    </span>
                    <span v-bind:id="'listItemRating' + (index + 1)" v-else-if="obj.rating.split('(')[0].length == 7" v-on:click="displayItemRating(index + 1)">
                        <span class="itemRatingStar">★</span>
                        <span v-bind:id="'ratingText' + (index + 1)" class="ratingText">{{obj.rating.split("(")[0].substr(0, 3)}} imdb</span>
                    </span>
                    <span v-bind:id="'listItemRating' + (index + 1)" v-else-if="obj.rating.split('(')[0].length == 5" v-on:click="displayItemRating(index + 1)">
                        <span class="itemRatingStar">★</span>
                        <span v-bind:id="'ratingText' + (index + 1)" class="ratingText">{{obj.rating.split("(")[0].split("/")[0]}}.0 imdb</span>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div id="scrollBarTopList">
        <!-- <div v-bind:id="'scrollBarTopList#' + item.list + '#' + item.type" class="scrollBarTopList" v-for="item in getTopListType" v-bind:key="item.nr" v-on:click="displayModalToplistCategories()"> v-on:click="fetchSpecificTopList(item.list, item.type)" -->
            <!-- categories: movies, shows -->
            <!-- subcategories movies, shows: all-time, specific-year, country, genre, originals -->
            <!-- genres: anime, cartoon, horror, crime, scifi, biography, war, thriller -->

            <!-- <span>{{item.name}}</span> -->
            <!-- <span v-if="item.name == 'movies'" class="dot">·</span> -->

            <!-- <span v-if="item.list == 'company' || item.list == 'country'">{{firstLetterToUpperCase(item.type)}}</span>
            <span v-else-if="item.list == 'top100'">
                <span v-if="item.type == 'netflix' || item.type == 'disney' || item.type == 'amazon'">{{firstLetterToUpperCase(item.type)}} Originals</span>
                <span v-else-if="item.type == 'korean'">{{firstLetterToUpperCase(item.type)}} Movies</span>
                <span v-else>{{firstLetterToUpperCase(item.type)}}</span>
            </span>
            <span v-else>
                <span v-if="item.type == 'netflix' || item.type == 'disney' || item.type == 'amazon'">{{firstLetterToUpperCase(item.list)}} {{firstLetterToUpperCase(item.type)}} Originals</span>
                <span v-else-if="item.type == 'korean'">2022 {{firstLetterToUpperCase(item.type)}} Movies</span>
                <span v-else>{{firstLetterToUpperCase(item.list)}} {{firstLetterToUpperCase(item.type)}}</span>
            </span> -->
        <!-- </div> -->
        <span id="selectedToplistCategory" v-on:click="displayModalToplistCategories()">Select Category</span>
    </div>

    <!-- warnings -->
    <p v-if="getRecentlySearched != null && getRecentlySearched.results.length == 0" id="searchNoResultsFound">No Results Found <!-- ({{searchString.value}}) --></p>
    <!-- <p id="searchLimitReached">You reached your search limit</p> -->
  </div>
</template>

<script>
import {ref, reactive, computed, onUpdated, onMounted} from 'vue'
import {useStore} from 'vuex'
import router from '../router'
import { useRouter, useRoute } from 'vue-router' //instead of this.$route

export default {
    setup() {
        //reactive
        var searchResultItems = reactive({data: null})
        var searchString = reactive({value: null})
        var searchType = reactive({value: "Shows"})
        var searchPreviousSearchType = reactive({value: null})
        var searchPreviousSearchString = reactive({value: null})
        
        //local storage
        var localStorageRecentlySearched = localStorage.getItem('sessionRecentlySearched')
        localStorageRecentlySearched = JSON.parse(localStorageRecentlySearched)

        //computed
        const urlPath = computed(() => { return router.currentRoute._value.path })
        const currentRoute = computed(() => { return router.currentRoute })

        //vuex
        const store = useStore() //same as this.$store
        if(localStorageRecentlySearched != null) { store.dispatch('actionSetRecentlySearched', localStorageRecentlySearched) }
        var getRecentlySearched = computed(() => { return store.getters['recentlySearched']})
        const getTopListResult = computed(() => { return store.getters['topListResult']}) 
        const getTopListType = computed(() => { return store.getters['topListType']})

        //variables
        const BACKEND_API_URL = computed(() => { return store.getters['backendAPI']}).value

        //lifecycle hooks
        onMounted(() => {
            console.log("onMounted ComponentStart")

            //variables
            let fullPath = router.currentRoute._value.fullPath
            let currentPath = router.currentRoute._value.fullPath
            let previousPath = router
            // let lsPublicLists = localStorage.getItem('sessionPublicLists')

            //reset session data
            resetSessionData()

            selectToplistCategory('movies', 'allTime', null, 'Movies All Time')

            //check public lists
            // if(lsPublicLists == null || lsPublicLists.length == 0){ fetchPublicLists()}
            // else if(lsPublicLists != null) { lsPublicLists = JSON.parse(lsPublicLists); store.dispatch('actionSetSessionPublicLists', lsPublicLists) }

            //check routing
            if(currentPath != previousPath) { routing(fullPath) }
        })

        onUpdated(() => {
            console.log("onUpdated ComponentStart")

            //variables
            let fullPath = router.currentRoute._value.fullPath
            let previousPath = router
            let currentPath = router.currentRoute._value.fullPath

            
            // let Review = document.getElementById("Review")
            
            //variables
            // let type = null
            // let id = null
            // let reviewExists = false


            
            //check routing
            if(currentPath != previousPath) { routing(fullPath) }
        })

        //functions
        async function search(queryString, queryType, type, specificYear, obj, rankNumber, specificLanguage)
        {
            //debugging
            // console.log("queryType: " + queryType)
            // console.log("queryString: " + queryString)
            // console.log("type: " + type)
            
            //replace white space character
            queryString = queryString.replaceAll("%20", " ")

            //search validation previous search
            if(queryType == searchPreviousSearchType.value && queryString == searchPreviousSearchString.value) 
            { 
                //search input is identical to previous search
                console.log("error: search string is identical to previous search"); 
                
                searchBarCloseWindow()
                searchBarScrollToStart()
                
                return 
            }
            else if(searchQueryNullCheck(queryString) == false) 
            { 
                //search input is null
                console.log("error: search string is empty or null"); 
                
                searchBarCloseWindow()
                searchBarScrollToStart()
                
                return 
            }
            
            //search validation forbidden characters
            queryString = replaceForbiddenCharacters(queryString)
            
            //set query string to lower case
            queryString = queryString.toLowerCase()

            //check if toplist item
            // if(type == "toplist") { fetchSpecificSearchData(queryString, queryType, type, specificYear) }
            // else if(type != "toplist") { fetchSpecificSearchData(queryString, queryType, null, null) }
            
            //set format specificYear
            if(specificYear)
            {
                specificYear = specificYear.replace("(I)", "")
                specificYear = specificYear.replace("(II)", "")
                specificYear = specificYear.replace("(III)", "")
                specificYear = specificYear.replace("(IV)", "")
                specificYear = specificYear.replace("(V)", "")
                specificYear = specificYear.replace("(VI)", "")
                specificYear = specificYear.replace("TV Special", "")
                specificYear = specificYear.replace("Video", "")
                specificYear = specificYear.replaceAll(" ", "")
                specificYear = specificYear.trim()
            }

            //fetch specific search data
            fetchSpecificSearchData(queryString, queryType, type, specificYear, obj, rankNumber, specificLanguage)
        }

        async function fetchSpecificSearchData(queryString, queryType, type, specificYear, obj, rankNumber, specificLanguage)
        {            
            //elements
            let listSearchListInput = document.getElementById("listSearchListInput")
            let listSearchListSubmitButton = document.getElementById("listSearchListSubmitButton")
            let listCategoryAll = document.getElementById("list-category-all")

            //variables
            let recentlySearched = localStorage.getItem('sessionRecentlySearched')
            let lsSearches = localStorage.getItem('sessionSearches')
            let searchDataSaved = false
            let searchData = null

            //set recently searched
            if(recentlySearched) { recentlySearched = JSON.parse(recentlySearched)}

            //check if identical previous search
            if(recentlySearched != null && recentlySearched.queryString == queryString && recentlySearched.queryType == queryType) 
            {
                console.log("search identical to previous search"); return 
            }
            
            //check if search lists
            if(queryType == "Lists" && queryString != null)
            {
                //search lists
                if(listCategoryAll)
                {
                    setTimeout(() => { listSearchListInput.value = queryString}, 200)
                    setTimeout(() => { listSearchListSubmitButton.click()}, 300)
                }

                return
            }

            //check if search exists in local storage
            if(lsSearches == "null" || lsSearches == null) { lsSearches = [] }
            else if(lsSearches.length > 0)
            {
                lsSearches = JSON.parse(lsSearches)

                for(let item in lsSearches)
                {
                    if(lsSearches[item].queryString == queryString && lsSearches[item].queryType == queryType)
                    {
                        console.log("search#" + queryType + "/" + queryString + " data saved in local storage")

                        //set search data saved
                        searchDataSaved = true

                        //set search data
                        searchData = lsSearches[item]
                    }
                }
            }
            
            if(searchDataSaved == true) 
            { 
                if(type != "toplist")
                {
                    //set vuex
                    store.dispatch('actionSetRecentlySearched', searchData);
                    
                    //save to local storage
                    localStorage.setItem('sessionRecentlySearched', JSON.stringify(searchData))
                }
                else if(type == "toplist")
                {
                    //debugging
                    // console.log("type: toplist")
                    // console.log("queryType: " + queryType)
                    // console.log("queryString: " + queryString)

                    //variables
                    let show = false
                    let movie = false
                    let id = null

                    //determine if movie or show
                    if(queryType == "Movies") { movie = true }
                    else if(queryType == "Shows") { show = true }

                    //set specific year
                    specificYear = specificYear.substr(1, 4)
    
                    //find search item
                    for(let item in searchData.results)
                    {
                        let itemDate = null
                        let itemName = null
                        
                        if(movie == true) 
                        { 
                            if(searchData.results[item].release_date) 
                            {
                                itemDate = searchData.results[item].release_date.toString().substr(0, 4) 
                                itemName = searchData.results[item].title.toLowerCase()
                            }
                        }
                        else if(show == true) 
                        { 
                            if(searchData.results[item].first_air_date) 
                            { 
                                itemDate = searchData.results[item].first_air_date.toString().substr(0, 4) 
                                itemName = searchData.results[item].name.toLowerCase()
                            }
                        }

                        //set id
                        if(specificYear == itemDate && queryString.includes(itemName)) 
                        { 
                            //debugging
                            if(queryType == "Movies")
                            {
                                console.log(itemDate + " / " + specificYear + ": " + searchData.results[item].title + " - " + queryType)
                            }
                            else if(queryType == "Shows")
                            {
                                console.log(itemDate + " / " + specificYear + ": " + searchData.results[item].name + " - " + queryType)
                            }

                            //set id
                            id = searchData.results[item].id

                            break
                        }
                        else if(queryString.includes(itemName))
                        {
                            id = searchData.results[0].id
                        }
                        else if(specificYear == itemDate)
                        {
                            id = searchData.results[0].id
                        }

                        //error check
                        if(id == null) 
                        { 
                            // console.log(searchData.results)
                            // id = searchData.results[0].id

                            // console.log("cannot find toplist item " + obj.rank + " searchData")

                            if(searchData.results.length > 0 && searchData.results[0].id != null) 
                            { 
                                id = searchData.results[0].id
                            }
                            else 
                            {
                                rankNumber = rankNumber + 1
                                rankNumber = rankNumber.toString()
                                let listItem = document.getElementById("listItemTitle#" + rankNumber)
                                listItem.style.color = "red"
                                
                                return
                            }
                        }
                    }
    
                    //check if movie or show
                    if(show == true) { router.push({path: '/show/' + id}) }
                    else if(movie == true) { router.push({path: '/movie/' + id}) }
                }
            }
            else if(searchDataSaved == false)
            {
                console.log("specific year:" + specificYear)

                //fetch search data
                await fetch(BACKEND_API_URL + "/search?type=" + queryType + "&string=" + queryString + "&year=" + specificYear, {method: 'get'})
                .then((response) => { 
                    console.log("search#" + queryType + "/" + queryString + " data fetched from API")
                    return response.json() 
                })
                .then((data) => {
                    console.log(data)

                    //variables
                    let recentlySearched = []
                    
                    //set search results
                    searchResultItems.data = data
                    searchBarSubmitButton.value = "search (" + data.results.length + ")"
    
                    //set query data
                    data.queryString = queryString
                    data.queryType = queryType
        
                    //set recently searched
                    recentlySearched.push(JSON.stringify(data))
    
                    //save to local storage
                    localStorage.setItem('sessionRecentlySearched', recentlySearched)
    
                    //check if toplist item
                    if(type != "toplist")
                    {
                        //save to vuex
                        store.dispatch('actionSetRecentlySearched', data)

                    }
                    else if(type == "toplist")
                    {
                        //debugging
                        console.log("type: toplist")
                        console.log("queryType: " + queryType)
                        console.log("queryString: " + queryString)

                        //variables
                        let show = false
                        let movie = false
                        let id = null

                        //determine if movie or show
                        if(queryType == "Movies") { movie = true }
                        else if(queryType == "Shows") { show = true }

                        //set specific year
                        specificYear = specificYear.substr(1, 4)
    
                        //variables
                        for(let item in data.results)
                        {
                            let itemDate = null
                            let itemName = null
                            
                            if(movie == true) 
                            { 
                                if(data.results[item].release_date) 
                                {
                                    itemDate = data.results[item].release_date.toString().substr(0, 4) 
                                    itemName = data.results[item].title.toLowerCase()
                                }
                            }
                            else if(show == true) 
                            { 
                                if(data.results[item].first_air_date) 
                                { 
                                    itemDate = data.results[item].first_air_date.toString().substr(0, 4) 
                                    itemName = data.results[item].name.toLowerCase()
                                }
                            }
                            
                            //set id
                            if(specificYear == itemDate && queryString.includes(itemName)) 
                            { 
                                //debugging
                                if(queryType == "Movies")
                                {
                                    console.log(itemDate + " / " + specificYear + ": " + data.results[item].title + " - " + queryType)
                                }
                                else if(queryType == "Shows")
                                {
                                    console.log(itemDate + " / " + specificYear + ": " + data.results[item].name + " - " + queryType)
                                }

                                id = data.results[item].id

                                break
                            }
                            else if(queryString.includes(itemName))
                            {
                                id = data.results[0].id
                            }
                            else if(specificYear == itemDate)
                            {
                                id = data.results[0].id
                            }
                        }
    
                        //error check
                        if(id == null) 
                        { 
                            // console.log(data.results)
                            // id = data.results[0].id

                            // console.log("cannot find toplist item " + obj.rank + " data")

                            if(data.results.length > 0 && data.results[0].id != null) 
                            { 
                                id = data.results[0].id
                            }
                            else 
                            {
                                rankNumber = rankNumber + 1
                                rankNumber = rankNumber.toString()
                                let listItem = document.getElementById("listItemTitle#" + rankNumber)
                                listItem.style.color = "red"
                                
                                return
                            }
                        }

                        //check if movie or show
                        if(show == true) { router.push({path: '/show/' + id}) }
                        else if(movie == true) { router.push({path: '/movie/' + id}) }
                    }

                    //save search data to local storage
                    searchData = JSON.parse(recentlySearched)
                    lsSearches.push(searchData)
                    localStorage.setItem('sessionSearches', JSON.stringify(lsSearches))
                })
            }

            searchBarCloseWindow()
            searchBarScrollToStart()
        }

        function setSearchString()
        {
            //elements
            let searchStringFromUser = document.getElementById("searchBarInput").value

            //set searchString
            searchString.value = searchStringFromUser
        }

        function searchBarChangeType(type)
        {
            //elements
            let searchBarInput = document.getElementById("searchBarInput")
            let searchTypeMovies = document.getElementById("searchTypeMovies")
            let searchTypeShows = document.getElementById("searchTypeShows")
            let searchTypePeople = document.getElementById("searchTypePeople")
            // let searchTypeLists = document.getElementById("searchTypeLists")
            let searchBarSubmitButton = document.getElementById("searchBarSubmitButton")

            //reset elements
            searchTypeMovies.style.fontWeight = "normal"
            searchTypeShows.style.fontWeight = "normal"
            searchTypePeople.style.fontWeight = "normal"
            // searchTypeLists.style.fontWeight = "normal"
            searchTypeMovies.style.color = "white"
            searchTypeShows.style.color = "white"
            searchTypePeople.style.color = "white"
            // searchTypeLists.style.color = "white"
            searchBarInput.value = ""
            // searchTypeMovies.style.backgroundColor = "black"
            // searchTypeShows.style.backgroundColor = "black"
            // searchTypePeople.style.backgroundColor = "black"
            searchTypeMovies.style.opacity = "0.6"
            searchTypeShows.style.opacity = "0.6"
            searchTypePeople.style.opacity = "0.6"
            // searchTypeLists.style.opacity = "0.6"

            if(type == "movies")
            {
                //update search type
                searchTypeMovies.style.fontWeight = "bold"
                searchType.value = "Movies"

                //update search button
                searchBarSubmitButton.innerText = "Search Movies"

                //update search input
                searchBarInput.placeholder = "..."
                // searchBarInput.disabled = false

                //update opacity
                searchTypeMovies.style.opacity = "1"

            }
            else if(type == "shows")
            {
                //update search type
                searchTypeShows.style.fontWeight = "bold"
                searchType.value = "Shows"
                
                //update search button
                searchBarSubmitButton.innerText = "Search Shows"

                //update search input
                searchBarInput.placeholder = "..."
                // searchBarInput.disabled = false
                
                //update opacity
                searchTypeShows.style.opacity = "1"
            }
            else if(type == "people")
            {
                //update search type
                searchTypePeople.style.fontWeight = "bold"
                searchType.value = "People"
                
                //update search button
                searchBarSubmitButton.innerText = "Search People"

                //update search input
                searchBarInput.placeholder = "..."
                // searchBarInput.disabled = false
                                
                //update opacity
                searchTypePeople.style.opacity = "1"
            }
            // else if(type == "lists")
            // {
            //     //update search type
            //     searchTypeLists.style.fontWeight = "bold"
            //     searchType.value = "Lists"
                
            //     //update search button
            //     searchBarSubmitButton.innerText = "Search Lists"

            //     //update search input
            //     searchBarInput.placeholder = "..."
            //     // searchBarInput.disabled = false
                                
            //     //update opacity
            //     searchTypeLists.style.opacity = "1"
            // }

            //foucs search bar
            searchBarInput.focus()
        }

        async function fetchSpecificMovieData(id)
        {                
            //variables
            let movieData = null
            let url = BACKEND_API_URL + "/movie?id=" + id
            let recentlySearchedData = {page: 1, type: "movie", results: null}
            let recentlySearched = []
            let lsRecentlySearched = localStorage.getItem('sessionRecentlySearched')
            let previousSearchId = null
            let lsMovies = localStorage.getItem("sessionMovies")
            let movieDataSaved = false
            let error = false

            //check if identical previous search
            lsRecentlySearched = JSON.parse(lsRecentlySearched) //set recently searched
            if(lsRecentlySearched != null && lsRecentlySearched.results.length > 0){previousSearchId = lsRecentlySearched.results[0].id} //set previous search id
            if(previousSearchId == id && lsRecentlySearched.type == 'movie') { return } //check id and type

            //check if movie exists in local storage
            if(lsMovies == "null" || lsMovies == null) { lsMovies = []}
            else if(lsMovies.length > 0)
            {
                lsMovies = JSON.parse(lsMovies)
                
                for(let item in lsMovies)
                {
                    let movie = lsMovies[item]
                    
                    if(movie.id == id)
                    {
                        console.log("movie#" + id + " data saved in local storage")

                        //set movie data saved
                        movieDataSaved = true

                        //set movie data obj
                        movieData = {id: movie.data.id, name: movie.data.name, data: movie.data}
                        
                        //set movie profile pic data
                        recentlySearchedData.results = [movie.data]
                    }
                }
            }
            
            //fetch movie data
            if(movieDataSaved == false)
            {
                await fetch(url, {method: 'get'})
                .then((response) => {
                        console.log("movie#" + id + " data fetched from API")
                        return response.json()
                })
                .then((data) => {
                    console.log(data)
                    
                    //check error
                    if(data.status_code == "34") { error = true; return }
                    
                    //set movie data
                    movieData = {id: data.id, name: data.name, data: data}
                    
                    //set movie profile pic data
                    recentlySearchedData.results = [data]
                    
                    //save movie data to local storage
                    lsMovies.push(movieData)
                    localStorage.setItem("sessionMovies", JSON.stringify(lsMovies))
                })
            }

            if(error != true)
            {
                //set search results box items
                searchResultItems.data = recentlySearchedData //set search result items
                recentlySearched.push(recentlySearchedData) //set recently searched
                localStorage.setItem('sessionRecentlySearched', JSON.stringify(recentlySearchedData)) //save movie profile pic data to local storage
    
                //set vuex
                // store.dispatch('actionSetRecentlySearched', recentlySearchedData)
                store.dispatch('actionSetSelectedMovie', movieData)
                store.dispatch('actionSetSelectedMoviePoster', movieData.data.poster_path)
                store.dispatch('actionSetSelectedShow', null)
                store.dispatch('actionSetSelectedPerson', null)
                store.dispatch('actionSetSelectedEpisode', null)
                store.dispatch('actionSetSelectedList', null)
                store.dispatch('actionSetReviewFromAccountPage', null)
            }
        }

        async function fetchSpecificShowData(id)
        {
            //elements
            let underlayShows = document.getElementById("underlayShows")

            //variables
            let showData = null
            let url = BACKEND_API_URL + "/show?id=" + id
            let recentlySearchedData = {page: 1, type: "show", results: null}
            let lsRecentlySearched = localStorage.getItem('sessionRecentlySearched')
            let previousSearchId = null
            let lsShows = localStorage.getItem("sessionShows")
            let showDataSaved = false
            let error = false
            // let recentlySearched = []

            //check if identical previous search
            lsRecentlySearched = JSON.parse(lsRecentlySearched) //set recently searched
            if(lsRecentlySearched != null && lsRecentlySearched.results.length > 0){previousSearchId = lsRecentlySearched.results[0].id} //set previous search id
            if(previousSearchId == id && lsRecentlySearched.type == 'show') { return } //check id and type

            //check if show exists in local storage
            if(lsShows == "null" || lsShows == null) { lsShows = []}
            else if(lsShows.length > 0)
            {
                //set ls shows
                lsShows = JSON.parse(lsShows)
                
                for(let item in lsShows)
                {
                    let show = lsShows[item]
                    
                    if(show.id == id)
                    {
                        console.log("show#" + id + " data saved in local storage")

                        //set show data saved
                        showDataSaved = true

                        //set show data obj
                        showData = {id: show.data.id, name: show.data.name, data: show.data}
                        
                        //set show profile pic data
                        recentlySearchedData.results = [show.data]
                    }
                }
            }
            
            //fetch show data
            if(showDataSaved == false)
            {
                //fetch data
                await fetch(url, {method: 'get'})
                .then((response) => {
                    console.log("show#" + id + " data fetched from API")
                    return response.json()
                })
                .then((data) => {
                    console.log(data)
                    
                    //check error
                    if(data.status_code == "34") { error = true; return }

                    //set show data obj
                    showData = {id: data.id, name: data.name, data: data}
                    
                    //set show profile pic data
                    recentlySearchedData.results = [data]

                    //save movie data to local storage
                    lsShows.push(showData)
                    localStorage.setItem("sessionShows", JSON.stringify(lsShows))
                })
            }
                
            if(error != true)
            {
                //set search results box items
                localStorage.setItem('sessionRecentlySearched', JSON.stringify(recentlySearchedData)) //save show profile pic data to local storage
                // searchResultItems.data = recentlySearchedData //set search result items
                // recentlySearched.push(recentlySearchedData) //set recently searched
    
                //set show background image
                if(showData.data.backdrop_path != null)
                {
                    underlayShows.style.backgroundImage = "url('" + "https://www.themoviedb.org/t/p/original" + showData.data.backdrop_path + "')"
                    underlayShows.style.backgroundSize = "cover" 
                }
                else
                {
                    underlayShows.style.backgroundImage = ""
                }
    
                //set vuex
                // store.dispatch('actionSetRecentlySearched', recentlySearchedData)
                store.dispatch('actionSetSelectedShow', showData)
                store.dispatch('actionSetSelectedShowPoster', showData.data.poster_path)
                store.dispatch('actionSetSelectedMovie', null)
                store.dispatch('actionSetSelectedPerson', null)
                store.dispatch('actionSetSelectedEpisode', null)
                store.dispatch('actionSetSelectedList', null)
                store.dispatch('actionSetReviewFromAccountPage', null)
            }
        }

        async function fetchSpecificPersonData(id)
        {
            //variables
            let personData = null
            let url = BACKEND_API_URL + "/person?id=" + id + "&append_to_response=combined_credits"
            let recentlySearchedData = {page: 1, type: "person", results: null}
            let lsRecentlySearched = localStorage.getItem('sessionRecentlySearched')
            let previousSearchId = null
            let lsPeople = localStorage.getItem("sessionPeople")
            let personDataSaved = false
            let error = false
            // let recentlySearched = null
            
            //check if identical previous search
            lsRecentlySearched = JSON.parse(lsRecentlySearched) //set recently searched
            if(lsRecentlySearched != null && lsRecentlySearched.results.length > 0){previousSearchId = lsRecentlySearched.results[0].id} //set previous search id
            if(previousSearchId == id && lsRecentlySearched.type == 'person') { return } //check id and type

            //check if person exists in local storage
            if(lsPeople == "null" || lsPeople == null) { lsPeople = []}
            else if(lsPeople.length > 0)
            {
                lsPeople = JSON.parse(lsPeople)
                
                for(let item in lsPeople)
                {
                    let person = lsPeople[item]
                    
                    if(person.id == id)
                    {
                        console.log("person#" + id + " data saved in local storage")

                        //set person data saved
                        personDataSaved = true

                        //set person data obj
                        personData = {id: person.data.id, name: person.data.name, data: person.data}
                        
                        //set person profile pic data
                        recentlySearchedData.results = [person.data]
                    }
                }
            }

            //set ls recently searched
            // lsRecentlySearched = JSON.parse(lsRecentlySearched)

            //set previous search id
            // if(lsRecentlySearched){previousSearchId = lsRecentlySearched.results[0].id}

            //check if identical previous search
            // if(previousSearchId == id && lsRecentlySearched.type == 'person') { return }

            //fetch person data
            if(personDataSaved == false)
            {
                //fetch data
                await fetch(url, {method: 'get'})
                .then((response) => {
                    console.log("person#" + id + " data fetched from API")
                    return response.json()
                })
                .then((data) => {
                    console.log(data)
                    
                    //check error
                    if(data.status_code == "34") { error = true; return }

                    //reset recently searched
                    // recentlySearched = []
    
                    //set variables
                    personData = {id: data.id, name: data.name, data: data}
                    
                    //set show profile pic data
                    recentlySearchedData.results = [data]
                    
                    //save movie data to local storage
                    lsPeople.push(personData)
                    localStorage.setItem("sessionPeople", JSON.stringify(lsPeople))
                })
            }

            if(error != true)
            {
                //set search results box items
                localStorage.setItem('sessionRecentlySearched', JSON.stringify(recentlySearchedData)) //save person profile pic data to local storage
                // searchResultItems.data = recentlySearchedData //set search results
                // recentlySearched.push(recentlySearchedData) //set recently searched
    
                //set vuex
                // store.dispatch('actionSetRecentlySearched', recentlySearchedData)
                store.dispatch('actionSetSelectedPerson', personData)
                store.dispatch('actionSetSelectedPersonPoster', personData.data.profile_path)
                store.dispatch('actionSetSelectedMovie', null)
                store.dispatch('actionSetSelectedShow', null)
                store.dispatch('actionSetSelectedEpisode', null)
                store.dispatch('actionSetSelectedList', null)
                store.dispatch('actionSetReviewFromAccountPage', null)
            }
        }

        async function fetchSpecificTopList(list, type)
        {
            //debugging
            // console.log("topList")
            // console.log("list: " + list + " " + type)
            // console.log("type: " + type)

            let topListItems = document.getElementsByClassName("listItem")

            if(topListItems)
            {
                for(let item in topListItems)
                {
                    if(topListItems[item].style) { topListItems[item].style.color = "white" }
                }
            }

            //elements
            let listView = document.getElementById("listView")
            let topListCategories = document.getElementsByClassName("scrollBarTopList")

            //variables
            let lsToplists = localStorage.getItem("sessionToplists")
            let toplistDataSaved = false

            //reset elements
            listView.style.display = "block"
            listView.scroll(0, 0)
            for(let c = 0; c < topListCategories.length; c++)
            {
                topListCategories[c].style.opacity = "0.3"
                topListCategories[c].style.pointerEvents = "visible"
                
                if(type != null)
                {
                    if(topListCategories[c].id.split("#")[2] == type && topListCategories[c].id.split("#")[1] == list)
                    {
                        topListCategories[c].style.opacity = "1"
                        topListCategories[c].style.pointerEvents = "none"
                    }
                }

            }

            //check if toplist exists in local storage
            if(lsToplists == "null" || lsToplists == null) { lsToplists = []}
            else if(lsToplists.length > 0)
            {
                lsToplists = JSON.parse(lsToplists)

                for(let item in lsToplists)
                {
                    if(lsToplists[item].name == list + type)
                    {
                        console.log("toplist#" + list + type + " saved in local storage")

                        //set toplist data saved
                        toplistDataSaved = true
                        
                        //set vuex
                        store.dispatch('actionSetTopListResult', lsToplists[item].data)
                    }
                }
            }
            
            //fetch toplist data
            if(toplistDataSaved == false && type != null)
            {
                let url = BACKEND_API_URL + "/" + list + "?id=" + type

                await fetch(url, {method: 'get'})
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    console.log("toplist#" + list + type + " fetched from API")
                    
                    //set vuex
                    store.dispatch('actionSetTopListResult', data)

                    lsToplists.push({name: list + type, data: data})
                    localStorage.setItem("sessionToplists", JSON.stringify(lsToplists))
                })
            }
        }

        function firstLetterToUpperCase(word)
        {
            return word[0].toUpperCase() + word.substr(1)
        }
        
        function currentYear()
        {
            return new Date().getFullYear()
        }
        
        function searchQueryNullCheck(queryString)
        {
            if (queryString == null) { return false }
            else if (queryString == undefined) { return false }
            else if (queryString == "") { return false }
            else if (queryString == " ") { return false }
            else if (queryString.includes("  ")) { return false }
            else { return true }
        }

        function replaceForbiddenCharacters(queryString)
        {
            //variables
            let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            let specialCharactersArray = ["[", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "\\", "-", "=", "\[", "\]", "{", "}", ";", "'", "\\", "|", ",", ".", "<", ">", "\/", "?", "]", "+", ";", "]"]
            //  ":",
            //replace special characters
            if(queryString.match(specialCharacters))
            {
                console.log("search string contains special characters")
                
                for(let c in specialCharactersArray) { queryString = queryString.replace(specialCharactersArray[c], "") }
            }

            return queryString
        }
        
        function searchBarCloseWindow()
        {
            //elements
            let searchBar = document.getElementById("searchBar")
            
            //reset elements
            searchBar.style.display = "none"
        }

        // function displaySearchBar()
        // {
            //     //elements
        //     let searchBar = document.getElementById("searchBar")
        //     let searchBarInput = document.getElementById("searchBarInput")

        //     //update elements
        //     if(searchBar.style.display == "none" || searchBar.style.display == "") { searchBar.style.display = "block"; searchBarInput.focus() }
        //     else if(searchBar.style.display == "block") { searchBar.style.display = "none" }
        // }

        function searchBarScrollToStart()
        {
            let scrollBarSearch = document.getElementById("scrollBarSearch")

            if(scrollBarSearch) {scrollBarSearch.scroll(0,0)}
        }
        
        async function topListCategory(list, type, name)
        {
            //debugging
            // console.log("topList")
            // console.log("list: " + list + " " + type)
            // console.log("type: " + type)

            //lists
            let listAll = [
                {name: "movies", type: "category", list: null},
                {name: "shows", type: "category", list: null},
                // {name: "originals", type: "category", list: null},
                // {name: "anime", type: "category", list: null},
                // {name: "cartoon", type: "category", list: null},
                // {list: "top100", type: "movies"},
                // {list: "top100", type: "shows"},
                // {list: "top100", type: "korean"},
                // {list: "top100", type: "netflix"},
                // {list: "top100", type: "disney"},
                // {list: "2022", type: "movies"},
                // {list: "2022", type: "shows"},
                // {list: "2022", type: "korean"},
                // {list: "2022", type: "netflix"},
                // {list: "2022", type: "disney"}
                // {list: "top100", type: "amazon"},
                // {list: "2022", type: "amazon"},
                ]
                
            // let listTop100 = [{list: "top100", type: "movies"},{list: "top100", type: "shows"},{list: "top100", type: "korean"},{list: "top100", type: "netflix"},{list: "top100", type: "amazon"},{list: "top100", type: "disney"}]
            // let list2022 = [{list: "2022", type: "movies"},{list: "2022", type: "shows"},{list: "2022", type: "korean"},{list: "2022", type: "netflix"},{list: "2022", type: "amazon"},{list: "2022", type: "disney"}]
            // let listCompanies = [{list: "company", type: "netflix"},{list: "company", type: "amazon"},{list: "company", type: "disney"}]
            // let listCountries = [{list: "country", type: "korean"}]
            
            //set vuex
            if(getTopListType.value == null)
            {
                store.dispatch('actionSetTopListType', listAll)
            }
            // if(getTopListType.value != null)
            // {
            //     //variables
            //     let listType = test[0].list
                
            //     //set list ype
            //     listType = firstLetterToUpperCase(listType)
                
            //     //debugging
            //     console.log("listType: " + listType)
            //     console.log("name: " + name)
                
            //     if(listType != name) { if(list == "top100"){ store.dispatch('actionSetTopListType', listTop100) }
            //     else if(list == "2022"){ store.dispatch('actionSetTopListType', list2022) }
            //     // else if(list == "companies"){ store.dispatch('actionSetTopListType', listCompanies) }
            //     // else if(list == "countries"){ store.dispatch('actionSetTopListType', listCountries) }
            // }
            // else if(getTopListType.value == null)
            // {
            //     if(list == "top100"){ store.dispatch('actionSetTopListType', listTop100) }
            //     else if(list == "2022"){ store.dispatch('actionSetTopListType', list2022) }
            //     // else if(list == "companies"){ store.dispatch('actionSetTopListType', listCompanies) }
            //     // else if(list == "countries"){ store.dispatch('actionSetTopListType', listCountries) }
            // }
        }

        function displayComponent(name)
        {
            console.log("displayComponent " + name)
            
            //variables
            let lsSessionUserData = localStorage.getItem("sessionUserData")
            let mq = window.matchMedia("(max-width: 1100px)")

            //set name
            name = firstLetterToUpperCase(name)

            //elements components
            let selectedComponent = document.getElementById("component" + name)
            let componentAbout = document.getElementById("componentAbout")
            let componentAccount = document.getElementById("componentAccount")
            let componentEpisodes = document.getElementById("componentEpisodes")
            let componentLists = document.getElementById("componentLists")
            let componentMovie = document.getElementById("componentMovie")
            let componentOptionsBar = document.getElementById("componentOptionsBar")
            let componentPerson = document.getElementById("componentPerson")
            let componentShow = document.getElementById("componentShow")
            let componentStart = document.getElementById("componentStart")
            let componentTopBar = document.getElementById("componentTopBar")
            let componentFeatured = document.getElementById("componentFeatured")
            let componentInstall = document.getElementById("componentInstall")

            //elements searches
            let scrollBarTopList = document.getElementById("scrollBarTopList")
            let scrollBarSearch = document.getElementById("scrollBarSearch")
            let searchResultsBox = document.getElementById("searchResultsBox")
            // let listsSearchList = document.getElementById("listsSearchList")
            
            //elements modals
            let modalLists = document.getElementById("modalLists")
            let modalReview = document.getElementById("modalReview")

            //elements topbar
            let topBarSlideshow = document.getElementById("topBarSlideshow")
            let topBarFeatured = document.getElementById("topBarFeatured")
            let topBarTop100 = document.getElementById("topBarTop100")
            let topBarLists = document.getElementById("topBarLists")
            let topBarAbout = document.getElementById("topBarAbout")
            let topBarAccount = document.getElementById("topBarAccount")
            let topBarSearch = document.getElementById("topBarSearch")
            // let topBarRandom = document.getElementById("topBarRandom")
            
            //elements others
            let Review = document.getElementById("Review")
            let List = document.getElementById("List")
            let listView = document.getElementById("listView")
            let toplists = document.getElementById("toplists")
            let listSelectedItem = document.getElementById("listSelectedItem")
            let listMenu = document.getElementById("listMenu")
            let listsCategories = document.getElementById("listsCategories")
            let personInfo = document.getElementById("personInfo")
            let showInfo = document.getElementById("showInfo")
            let movieInfo = document.getElementById("movieInfo")
            let movieDetails = document.getElementById("movieDetails")
            let movieDetailsTitle = document.getElementById("movieDetailsTitle")
            let scrollBarSeasons = document.getElementById("scrollBarSeasons")
            let featured = document.getElementById("featured")
            let listsCategoryAll = document.getElementById("list-category-all")
            let topListCategories = document.getElementsByClassName("scrollBarTopList")
            let optionsBar = document.getElementById("optionsBar")
            let episodesShowTitle = document.getElementById("episodesShowTitle")
            let showDetails = document.getElementById("showDetails")
            let showDetailsTitle = document.getElementById("showDetailsTitle")
            let movieRating = document.getElementById("movieRating")
            let movieRatingDetails = document.getElementById("movieRatingDetails")
            let showRating = document.getElementById("showRating")
            let showRatingDetails = document.getElementById("showRatingDetails")
            let moviePoster = document.getElementById("moviePoster")
            let showPoster = document.getElementById("showPoster")
            let personPoster = document.getElementById("personPoster")
            let listItems = document.getElementById("listItems")
            let modalToplistCategories = document.getElementById("modalToplistCategories")
            let modalListCategories = document.getElementById("modalListCategories")
            let selectedListTitle = document.getElementById("selectedListTitle")
            
            //reset component elements
            if(componentAccount) { componentAccount.style.display = "none" }
            if(componentAbout) { componentAbout.style.display = "none" }
            if(componentShow) { componentShow.style.display = "none" }
            if(componentEpisodes) {componentEpisodes.style.display = "none" }
            if(componentMovie) { componentMovie.style.display = "none" }
            if(componentPerson) { componentPerson.style.display = "none" }
            if(componentLists) { componentLists.style.display = "none" }
            if(componentStart) { componentStart.style.display = "none" }
            if(componentFeatured) { componentFeatured.style.display = "none" }
            if(componentTopBar) { componentTopBar.style.display = "block" }
            if(componentOptionsBar) 
            { 
                componentOptionsBar.style.display = "none"
                componentOptionsBar.style.margin = "0px"
                componentOptionsBar.style.position = "absolute"
                componentOptionsBar.style.marginTop = "0px"
                componentOptionsBar.style.width = "100vw" 
            }
            // if(componentOptionsBar) { componentOptionsBar.style.right = "21vw" }

            //reset top bar elements
            if(topBarLists) { topBarLists.style.opacity = "0.2" }
            if(topBarFeatured) { topBarFeatured.style.opacity = "0.2" }
            if(topBarTop100) { topBarTop100.style.opacity = "0.2" }
            if(topBarAbout) { topBarAbout.style.opacity = "0.2" }
            if(topBarAccount) { topBarAccount.style.opacity = "0.2" }
            if(topBarRandom) { topBarRandom.style.opacity = "0.2" }
            if(topBarSearch) { topBarSearch.style.opacity = "0.2" }
            if(topBarSlideshow) { topBarSlideshow.style.opacity = "0.2" }

            //reset elements others
            if(scrollBarSearch) 
            { 
                scrollBarSearch.style.display = "none"
                scrollBarSearch.style.minHeight = "24vh" 
            }
            if(scrollBarTopList) { scrollBarTopList.style.display = "none" }
            if(listView) { listView.style.display = "none" }
            if(Review) { Review.style.display = "block" }
            if(List) { List.style.display = "block" }
            if(modalLists) { modalLists.style.display = "none" }
            if(modalReview) { modalReview.style.display = "none" }
            if(componentOptionsBar) 
            { 
                componentOptionsBar.style.position = "absolute"
                componentOptionsBar.style.marginTop = "0px"
                componentOptionsBar.style.width = "100vw" 
            }
            if(searchResultsBox) { searchResultsBox.style.opacity = "1" }
            if(showDetails) { showDetails.style.display = "none" }
            if(movieRating) { movieRating.style.display = "none"}
            if(movieRatingDetails) { movieRatingDetails.style.display = "none"}
            if(showRating) { showRating.style.display = "none"}
            if(showRatingDetails) { showRatingDetails.style.display = "none"}
            if(modalToplistCategories) { modalToplistCategories.style.display = "none"}
            if(modalListCategories) { modalListCategories.style.display = "none"}

            //check if logged in
            if(lsSessionUserData == null) 
            { 
                setTimeout(() => {let componentOptionsBar = document.getElementById("componentOptionsBar"); componentOptionsBar.style.display = "none" }, 0) // hide options bar
                // if(movieInfo) {movieInfo.style.top = "33vh" } 
                // if(showInfo) {showInfo.style.top = "calc(36vh + 10px)" }
                // if(scrollBarSeasons) {scrollBarSeasons.style.bottom = "0px" }
            }
            else if(lsSessionUserData != null) 
            { 
                // if(movieInfo) {movieInfo.style.top = "38vh" }
                // if(showInfo) {showInfo.style.top = "49vh" }
                // if(scrollBarSeasons) {scrollBarSeasons.style.bottom = "0px" } 
            }

            //handle selected component elements
            if(name == "Account")
            {
                setTimeout(() => { if(componentOptionsBar) { componentOptionsBar.style.display = "none"} }, 0)
                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(topBarAccount) 
                { 
                    topBarAccount.style.fontWeight = "bold" 
                    topBarAccount.style.opacity = "1"
                    topBarAccount.scrollIntoView()
                }
            }
            else if(name == "Top100")
            {
                selectedComponent = document.getElementById("componentStart")
                topListCategory("top100", null, name)

                if(componentStart) { componentStart.style.display = "block" }
                if(toplists) { toplists.style.display = "block" }
                if(scrollBarTopList) { scrollBarTopList.style.display = "inline-flex" }
                if(listView) { listView.style.display = "block" }
                if(selectedComponent) { selectedComponent.style.display = "block" }
                setTimeout(() => { if(componentOptionsBar) { componentOptionsBar.style.display = "none" } }, 0)
                if(topBarTop100) 
                { 
                    topBarTop100.style.fontWeight = "bold" 
                    topBarTop100.style.opacity = "1"
                    topBarTop100.scrollIntoView()
                }
            }
            else if(name == "Lists")
            {
                selectedComponent = document.getElementById("componentLists")

                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(listSelectedItem) { listSelectedItem.style.display = "none" }
                if(listMenu) { listMenu.style.display = "block" }
                if(listsCategories) { listsCategories.style.display = "flex" }
                setTimeout(() => { if(componentOptionsBar) { componentOptionsBar.style.display = "none" } }, 0)
                if(topBarLists) 
                { 
                    topBarLists.style.fontWeight = "bold" 
                    topBarLists.style.opacity = "1"
                    topBarLists.scrollIntoView() 
                }
                // setTimeout(() => { let listsCategoryAll = document.getElementById("list-category-all"); listsCategoryAll.click()}, 100)
            }
            else if(name == "Search")
            {
                selectedComponent = document.getElementById("componentStart")

                if(searchResultsBox) { searchResultsBox.style.display = "block" }
                if(scrollBarSearch) {scrollBarSearch.style.display = "block" }
                if(scrollBarSearch) 
                { 
                    topBarSearch.style.fontWeight = "bold"
                    topBarSearch.style.opacity = "1" 
                }
                if(selectedComponent) { selectedComponent.style.display = "block" }
                // searchBar.style.display = "block"
            }
            else if(name == "List")
            {
                selectedComponent = document.getElementById("componentLists")

                if(listSelectedItem) {listSelectedItem.style.display = "block" }
                if(listMenu) { listMenu.style.display = "none" }
                if(listsCategories) { listsCategories.style.display = "none" }
                if(Review) { Review.style.display = "none" }
                if(componentOptionsBar) 
                { 
                    componentOptionsBar.style.position = "absolute"
                    componentOptionsBar.style.width = "100vw"
                    componentOptionsBar.style.top = "149px" 
                }
                if(optionsBar) { optionsBar.style.display = "flex" }
                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(lsSessionUserData != null) { setTimeout(() => { if(componentOptionsBar) { componentOptionsBar.style.display = "block" } }, 0)}
                if(listItems) { listItems.scroll(0,0) }
                if(List) { List.style.display = "none" }
                if(selectedListTitle) { selectedListTitle.style.display = "block" }
            }
            else if(name == "Movie")
            {   
                //variables
                selectedComponent = document.getElementById("componentMovie")

                //reset elements
                if(componentStart) { componentStart.style.display = "block" }
                if(componentOptionsBar) 
                { 
                    componentOptionsBar.style.display = "block"
                    componentOptionsBar.style.top = "calc(36% - 9px)" 
                }
                if(optionsBar) { optionsBar.style.display = "flex" }
                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(scrollBarSearch) {scrollBarSearch.style.display = "none" }
                if(movieInfo) { movieInfo.style.display = "block" }
                if(movieDetails) { movieDetails.style.display = "none" }
                if(moviePoster) { moviePoster.style.display = "inline-block" }
                if(movieDetailsTitle) { movieDetailsTitle.style.display = "block" }

                //reset movie poster position
                if(lsSessionUserData == null) 
                { 
                    if(mq.matches == false) { componentMovie.style.marginTop = "42px"} //desktop
                    if(mq.matches == true) { } //mobile
                    
                }
                else if(lsSessionUserData != null) 
                { 
                    if(mq.matches == false) { componentMovie.style.marginTop = "0px"} //desktop
                    if(mq.matches == true) { } //mobile
                }

                // if(scrollBarSearch) {scrollBarSearch.style.display = "block" }
                // if(searchResultsBox) { searchResultsBox.style.display = "block" }

                // if(mq.matches == true) { searchResultsBox.style.opacity = "0.6" }
                // else if(mq.matches == false) { searchResultsBox.style.opacity = "1" }  
            }
            else if(name == "Show")
            {
                //variables
                selectedComponent = document.getElementById("componentShow")

                //reset elements
                if(componentStart) { componentStart.style.display = "block" }
                if(componentOptionsBar) 
                { 
                    componentOptionsBar.style.display = "block"
                    componentOptionsBar.style.top = "calc(36% - 9px)" 
                }
                if(optionsBar) { optionsBar.style.display = "flex" }
                if(showInfo) {showInfo.style.display = "block" }
                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(scrollBarSearch) {scrollBarSearch.style.display = "none" }
                if(showInfo) { showInfo.style.display = "block" }
                if(showDetails) { showDetails.style.display = "none" }
                if(showPoster) { showPoster.style.display = "inline-block" }
                if(showDetailsTitle) { showDetailsTitle.style.display = "block" }
                if(showDetails) {showDetails.style.display = "none" }
                if(scrollBarSeasons) { scrollBarSeasons.style.display = "none" }
                // if(scrollBarSeasons) { scrollBarSeasons.style.display = "block" }
                // if(scrollBarSearch) {scrollBarSearch.style.display = "block" }
                // if(searchResultsBox) { searchResultsBox.style.display = "block" }

                //reset show poster position
                if(lsSessionUserData == null) 
                { 
                    if(mq.matches == false) { componentShow.style.marginTop = "42px"} //desktop
                    if(mq.matches == true) { } //mobile
                    
                }
                else if(lsSessionUserData != null) 
                { 
                    if(mq.matches == false) { componentShow.style.marginTop = "0px"} //desktop
                    if(mq.matches == true) { } //mobile
                }

                // if(lsSessionUserData == null)
                // {
                //     // if(mq.matches == true) 
                //     // {
                //     //     if(showInfo) {showInfo.style.top = "46vh" }
                //     //     if(scrollBarSeasons) {scrollBarSeasons.style.display = "flex"; scrollBarSeasons.style.bottom = "0px" }
                //     // }
                //     // else if(mq.matches == false) 
                //     // {
                //     //     if(showInfo) {showInfo.style.top = "44vh" }
                //     //     if(scrollBarSeasons) {scrollBarSeasons.style.display = "block"; scrollBarSeasons.style.bottom = "0px" }
                //     // }
                // }
                // else if(lsSessionUserData != null)
                // {
                //     // if(mq.matches == true) 
                //     // {
                //     //     if(showInfo) {showInfo.style.top = "46vh" }
                //     // }
                //     // else if(mq.matches == false) 
                //     // {
                //     //     if(showInfo) {showInfo.style.top = "49vh" }
                //     // }
                // }

                // if(mq.matches == true) { searchResultsBox.style.opacity = "0.6"}
                // else if(mq.matches == false) 
                // { 
                //     searchResultsBox.style.opacity = "1"
                //     if(showDetailsTitle) {showDetailsTitle.style.bottom = "7vh" }
                // }
            }
            else if(name == "Person")
            {
                //variables
                selectedComponent = document.getElementById("componentStart")

                //reset elements
                if(componentPerson) { componentPerson.style.display = "block" }
                if(componentOptionsBar) { componentOptionsBar.style.display = "block" }
                // if(searchResultsBox) { searchResultsBox.style.display = "block" }
                if(personInfo) {personInfo.scroll(0, 0) }
                if(scrollBarSearch) {scrollBarSearch.style.minHeight = "28vh" }
                if(componentOptionsBar) 
                { 
                    componentOptionsBar.style.position = "fixed"
                    componentOptionsBar.style.marginTop = "-78px"
                    componentOptionsBar.style.marginLeft = "-6px"
                    componentOptionsBar.style.width = "100vw" 
                }
                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(scrollBarSearch) {scrollBarSearch.style.display = "none" }
                // if(scrollBarSearch) {scrollBarSearch.style.display = "block" }
            }
            else if(name == "Featured")
            {
                //variables
                selectedComponent = document.getElementById("componentFeatured")

                //reset elements
                if(selectedComponent) { selectedComponent.style.display = "block" }
                setTimeout(() => { if(componentOptionsBar) { componentOptionsBar.style.display = "none" } }, 0)
                if(topBarFeatured) 
                { 
                    topBarFeatured.style.fontWeight = "bold"
                    topBarFeatured.style.opacity = "1"
                    topBarFeatured.scrollIntoView() 
                }
                // if(featured) {featured.scroll(0, 0) }
            }
            else if(name == "About")
            {
                //reset elements
                setTimeout(() => { if(componentOptionsBar) { componentOptionsBar.style.display = "none" } }, 0)
                if(selectedComponent) { selectedComponent.style.display = "block" }
                if(topBarAbout) { 
                    topBarAbout.style.fontWeight = "bold"
                    topBarAbout.style.opacity = "1"
                    topBarAbout.scrollIntoView() 
                }
            }
            else if(name == "Install")
            {
                //reset elements
                if(selectedComponent) { selectedComponent.style.display = "block" }
            }
        }

        function setUrl(type, id)
        {
            // console.log("setUrl type: " + type)
            // console.log("setUrl id: " + id)

            if(type == null) { return }
            router.push({path: '/' + type + '/' + id })
        }

        // async function fetchPublicLists()
        // {
        //     let url = "http://localhost:8080/public-lists"
    
        //     //fetch data
        //     await fetch(url, {method: 'get'})
        //     .then((response) => {
        //             console.log("public lists fetched from API")
        //             return response.json()
        //     })
        //     .then((data) => {
        //         console.log(data)

        //         //set local storage
        //         localStorage.setItem('sessionPublicLists', JSON.stringify(data))

        //         //update vuex
        //         store.dispatch('actionSetSessionPublicLists', data)
        //     })
        // }

        function fetchSpecificListData(listId)
        {
            //variables
            let lsPublicLists = localStorage.getItem("sessionPublicLists")
            let lsUserData = localStorage.getItem("sessionUserData")
            let listExists = false
            let likedLists = []
            let ownedLists = null
            let listData = null
            let publicLists = null

            //set public lists
            if(lsPublicLists)
            {
                publicLists = JSON.parse(lsPublicLists)
            }
            
            //set owned lists
            if(lsUserData)
            {
                lsUserData = JSON.parse(lsUserData)
                ownedLists = lsUserData.lists
            }

            //set liked lists
            if(lsUserData)
            {
                if(lsUserData.likes.lists > 0)
                {
                    for(let item in lsUserData.likes)
                    {
                        if(lsUserData.likes[item].type == "list"){ likedLists.push(lsUserData.likes[item])}
                    }
                }
            }

            //check for list in public lists
            for(let list in publicLists)
            {
                if(publicLists[list].id == listId)
                {
                    //set list data
                    listData = publicLists[list]
                    
                    //set list exists
                    listExists = true
                }
            }

            //check for list in own lists
            if(listExists == false)
            {
                for(let list in ownedLists)
                {
                    if(ownedLists[list].id == listId)
                    {
                        //set list data
                        listData = ownedLists[list]

                        //set list exists
                        listExists = true
                    }
                }
            }

            //check for list in liked lists
            if(listExists == false)
            {
                for(let list in likedLists)
                {
                    if(likedLists[list].id == listId)
                    {
                        //set list data
                        listData = likedLists[list]

                        //set list exists
                        listExists = true
                    }
                }
            }

            if(listData == null)
            {
                listData = {name: "sorry list does not exists"}
            }

            //set vuex
            store.dispatch('actionSetSelectedList', listData)
            store.dispatch('actionSetSelectedEpisode', null)
            store.dispatch('actionSetReviewFromAccountPage', null)
            // store.dispatch('actionSetRecentlySearched', null)
            // store.dispatch('actionSetSelectedPerson', null)
            // store.dispatch('actionSetSelectedMovie', null)
            // store.dispatch('actionSetSelectedShow', null)
        }

        function routing(fullPath)
        {                
            //variables
            let last = fullPath.split("/").length - 1
            let secondLast = fullPath.split("/").length - 2
            let searchString = fullPath.split("/")[last]
            let searchType = fullPath.split("/")[secondLast]

            //set search type
            if(searchType) {searchType = firstLetterToUpperCase(searchType) }

            //check for route
            if(fullPath.includes('/search/movies/'))
            {
                search(searchString, searchType, null, null)

                //update elements
                displayComponent('search')
                searchBarChangeType('movies')
                componentStart.style.display = "block"
                // searchBar.style.display = "block"
                // searchBarInput.value = searchString
                // searchBarInput.focus()
            }
            else if(fullPath.includes('/search/shows/'))
            {
                search(searchString, searchType, null, null)

                //update elements
                displayComponent('search')
                searchBarChangeType('shows')
                componentStart.style.display = "block"
                // searchBar.style.display = "block"
                // searchBarInput.value = searchString
                // searchBarInput.focus()
            }
            else if(fullPath.includes('/search/people/'))
            {
                search(searchString, searchType, null, null)

                //update elements
                displayComponent('search')
                searchBarChangeType('people')
                componentStart.style.display = "block"
                // searchBar.style.display = "block"
                // searchBarInput.value = searchString
                // searchBarInput.focus()
            }
            else if(fullPath.includes('/search/lists/'))
            {
                displayComponent("lists")
                setTimeout(() => { 
                    //elements
                    let listsCategoryAll = document.getElementById("list-category-all");

                    //select all lists
                    if(listsCategoryAll) { listsCategoryAll.click(); }
                    else { return }

                    //search
                    search(searchString, searchType, null, null)

                    //update elements
                    searchBarChangeType('lists')
                    componentStart.style.display = "block"
                    searchResultsBox.style.display = "none"
                    // searchBar.style.display = "block"
                    // searchBarInput.value = searchString
                    // searchBarInput.focus()
                    // componentLists.style.display = "block"
                    // componentOptionsBar.style.display = "none"
                }, 100)
                
            }
            else if(fullPath.includes('/movie/'))
            {
                let movieId = fullPath.replace("/movie/", "")
                
                fetchSpecificMovieData(movieId)
                
                //update elements
                displayComponent('movie')
            }
            else if(fullPath.includes('/show/'))
            {
                let showId = fullPath.replace("/show/", "")
                
                fetchSpecificShowData(showId)

                //update elements
                displayComponent('show')
            }
            else if(fullPath.includes('/person/'))
            {
                let personId = fullPath.replace("/person/", "")
                
                fetchSpecificPersonData(personId)

                //update elements
                displayComponent('person')
            }
            else if(fullPath.includes('/list/'))
            {
                //variables
                let listId = fullPath.replace("/list/", "")
                
                fetchSpecificListData(listId)
                
                //update elements
                displayComponent('list')
            }
            else if(fullPath.includes('/featured')) 
            { 
                //variables
                let lsFeatured = localStorage.getItem("sessionFeatured")
                
                //check ls featured
                if(lsFeatured == "null" || lsFeatured == null) { fetchFeatured()}
                
                //update elements
                displayComponent('featured') 
            }
            else if(fullPath.includes('/install')) 
            { 
                displayComponent('install') 
                // if(currentRoute == "install") 
                // { 
                //elements
                // let componentInstall = document.getElementById("componentInstall")
                let appleDevices = document.getElementById("appleDevices")
                let undetectedDevices = document.getElementById("undetectedDevices")
                
                //display install page
                // componentInstall.style.display = "block"
                if(screen.width < 1000) //mobile
                { 
                    //elements
                    let deviceMobile = document.getElementById("deviceMobile")
                    //variables
                    let deviceType = deviceMobile.innerText.toLowerCase()
                    //detect device
                    if(deviceType.includes("android")) { window.location = "/teruno_installer_android.apk"}
                    else if(deviceType.includes("linux")) { window.location = "/teruno_installer_android.apk"}
                    else if(deviceType.includes("apple")) { appleDevices.style.display = "block"}
                    else if(deviceType.includes("iphone")) { appleDevices.style.display = "block"}
                    else if(deviceType.includes("ipad")) { appleDevices.style.display = "block"}
                    else { undetectedDevices.style.display = "block"}
                }
                else if(screen.width > 1000) //desktop
                { 
                    //elements
                    let deviceDesktop = document.getElementById("deviceDesktop")
                    //variables
                    let deviceType = deviceDesktop.innerText.toLowerCase()
                    //detect device
                    if(deviceType.includes("win")){ window.location = "/teruno_installer_windows.msi"} 
                    else if(deviceType.includes("apple")){ window.location = "/teruno_installer_mac.dmg"}
                    else if(deviceType.includes("mac")){ window.location = "/teruno_installer_mac.dmg"}
                    else if(deviceType.includes("linux")){ window.location = "/teruno_installer_linux.deb"}
                    else { undetectedDevices.style.display = "block"}
                }
                
                return
                // }
            }
            else if(fullPath.includes('/lists')) { displayComponent('lists') }
            else if(fullPath.includes('/account')) { displayComponent('account') }
            else if(fullPath.includes('/credits')) { displayComponent('credits') }
            else if(fullPath.includes('/privacy')) { displayComponent('about') }
            else if(fullPath.includes('/about')) { displayComponent('about') }
            else if(fullPath.includes('/top100')) { displayComponent('top100') }
            else if(fullPath.includes('/info')) { displayComponent('info') }
            else 
            {                
                //variables
                let lsFeatured = localStorage.getItem("sessionFeatured")
                
                //check ls featured
                if(lsFeatured == "null" || lsFeatured == null) { fetchFeatured()}
                
                //update elements
                displayComponent('featured')
            }
        }

        function resetSessionData()
        {
            //reset vuex
            store.dispatch('actionSetSelectedMovie', null)
            store.dispatch('actionSetSelectedShow', null)
            store.dispatch('actionSetSelectedPerson', null)
            store.dispatch('actionSetSelectedEpisode', null)
            store.dispatch('actionSetSelectedList', null)
            store.dispatch('actionSetReviewFromAccountPage', null)
            store.dispatch('actionSetRecentlySearched', null)
            store.dispatch('actionSetFeatured', null)

            //reset local storage
            localStorage.setItem("sessionRecentlySearched", null)
            localStorage.setItem("sessionMovies", null)
            localStorage.setItem("sessionShows", null)
            localStorage.setItem("sessionPeople", null)
            localStorage.setItem("sessionToplists", null)
            localStorage.setItem("sessionSearches", null)
            localStorage.setItem("sessionFeatured", null)
            localStorage.setItem("sessionSeasons", null)
            // localStorage.setItem("sessionPublicLists", null)
        }

        async function fetchFeatured()
        {
            //variables
            let url = BACKEND_API_URL + "/featured"
            
            //fetch featured data
            await fetch(url, {method: 'get'})
            .then((response) => {
                console.log("featured movies and shows data fetched from API")
                return response.json()
            })
            .then((data) => {
                // console.log(data)

                //set local storage
                localStorage.setItem("sessionFeatured", JSON.stringify(data))

                //set vuex
                store.dispatch('actionSetFeatured', data)
            })
        }

        async function selectToplistCategory(type, category, id, name)
        {
            //elements
            let modalToplistCategories = document.getElementById("modalToplistCategories")
            let selectedToplistCategory = document.getElementById("selectedToplistCategory")
            let listView = document.getElementById("listView")

            //variables
            let thisYear = new Date().getFullYear()
            let previousYear = thisYear - 1

            //update elements
            modalToplistCategories.style.display = "none"
            listView.scroll(0, 0)
            
            //set name
            if(name != null)
            {
                name = name.replace("This Year", thisYear)
                name = name.replace("Previous Year", previousYear)
                selectedToplistCategory.innerText =  name
            }
            
            //set url
            let url = null
            if(id == null) { url = BACKEND_API_URL + "/toplist?type=" + type + "&category=" + category }
            else { url = BACKEND_API_URL + "/toplist?type=" + type + "&category=" + category + "&id=" + id }
            
            
            //fetch featured data
            await fetch(url, {method: 'get'})
            .then((response) => {
                console.log("toplist data fetched from API")
                return response.json()
            })
            .then((data) => {
                console.log(data)

                //set local storage
                localStorage.setItem("sessionToplist", JSON.stringify(data))

                //set vuex
                store.dispatch('actionSetTopListResult', data)
            })
        }

        function displayModalToplistCategories()
        {
            let modalToplistCategories = document.getElementById("modalToplistCategories")

            modalToplistCategories.style.display = "block"
        }

        function displayItemRating(id)
        {
            console.log("displayItemRating")
            console.log(id)
            
            let itemRating = document.getElementById("ratingText" + id)

            if(itemRating.style.display == "none" || itemRating.style.display == "")
            {
                itemRating.style.display = "inline-block"
            }
            else
            {
                itemRating.style.display = "none"
            }
        }

        function displayToplistCategory(type)
        {
            let toplistCategoriesMovies = document.getElementById("toplistCategoriesMovies")
            let toplistCategoriesShows = document.getElementById("toplistCategoriesShows")
            let toplistCategoriesTitleMovies = document.getElementById("toplistCategoriesTitleMovies")
            let toplistCategoriesTitleShows = document.getElementById("toplistCategoriesTitleShows")

            toplistCategoriesMovies.style.display = "none"
            toplistCategoriesShows.style.display = "none"
            toplistCategoriesTitleMovies.style.opacity = "0.4"
            toplistCategoriesTitleShows.style.opacity = "0.4"

            if(type == "movies")
            {
                toplistCategoriesMovies.style.display = "block"
                toplistCategoriesTitleMovies.style.opacity = "1"
            }
            else if(type == "shows")
            {
                toplistCategoriesShows.style.display = "block"
                toplistCategoriesTitleShows.style.opacity = "1"
            }
        }

        return {
            //variables
            searchResultItems,
            searchString,
            searchType,

            //vuex
            getRecentlySearched,
            getTopListResult,
            getTopListType,

            //functions
            search,
            setSearchString,
            searchBarChangeType,
            fetchSpecificMovieData,
            fetchSpecificShowData,
            fetchSpecificTopList,
            firstLetterToUpperCase,
            currentYear,
            fetchSpecificPersonData,
            searchBarCloseWindow,
            setUrl,
            fetchSpecificSearchData,
            resetSessionData,
            selectToplistCategory,
            displayModalToplistCategories,
            displayItemRating,
            displayToplistCategory,

            //router
            currentRoute
        }
    }
}
</script>

<style scoped>
    /*** scrollbar ***/
    ::-webkit-scrollbar { /* margin-top: 10px; */ height: 10px; } /* width */
    ::-webkit-scrollbar-track { background-color: transparent; /* 41B883 */ } /* Track */
    ::-webkit-scrollbar-thumb { /* margin-top: 10px; */ background-color: rgba(255, 255, 255, 0.1); } /* Handle */
    #scrollBarTopList { scrollbar-width: thin;}
    #listView { scrollbar-width: thin;}
    #scrollBarSearch { scrollbar-width: thin;}
    .listItem {scrollbar-width: none;}
    .listItem::-webkit-scrollbar {height: 0px;}

    /*** elements ***/
    img { height: 100%; width: 100%; }

    /*** ids ***/
    /* #componentStart { } */
    #scrollBarShows, #scrollBarMovies { margin: auto; padding-bottom: 16px; overflow-y: hidden; overflow-x: auto; white-space: nowrap; }
    #scrollBarSearch { min-height: 24vh; width: auto; margin: 0px; margin: auto; padding: 0px; justify-content: center; overflow-y: hidden; overflow-x: auto; white-space: nowrap; }
    #searchResultsBox { width: 100%; position: relative; margin: 0px; padding: 0px; z-index: 2;}
    #searchBar 
    { 
        display: none; 
        position: fixed; 
        margin: 0px; 
        padding: 0px; 
        bottom: 50%; 
        right: 50%; 
        transform: translate(50%, 50%); 
        opacity: 1; 
        font-size: 18px;
        z-index: 10; 
        border: 1px solid white; 
        background-color: black; 
    }
    #searchBarInput { margin: 0px; padding: 10px 8px 10px 8px; width: 90%; font-size: 20px; color: white; border-radius: 0; border: 0px solid white; background-color: black; }
    #searchBarInput:focus { outline: none; }
    #searchBarSelectBox { display: inline-block; margin: 0px; padding: 10px; text-align: center; user-select: none; font-weight: normal; color: white; }
    #searchBarSubmitButton { display: none; margin: 0px; padding: 10px; text-align: center; user-select: none; font-weight: bold; opacity: 1; color: white; background-color: white;}
    #searchNoResultsFound { margin: 0px; margin: auto; padding: 0px; padding-top: 20px; padding-bottom: 20px; font-family: Arial, Helvetica, sans-serif; font-size: 20px; font-weight: bold; border: 0px solid white; color: white; background-color: black; }
    #searchBarSelectBox { border-right: 0px solid black; }
    #searchBarSubmitButton { padding: 13px; width: 100%; font-weight: normal; font-size: 16px; color: black; border: 0px solid black; }
    #searchBarSubmitButton:active { font-weight: bold; }
    #scrollBarTopList 
    { 
        display: none; 
        position: relative; 
        height: auto; 
        width: auto;
        margin: 13px 0px 10px 0px;
        padding: 0px;
        z-index: 1;
        overflow-y: hidden;
        overflow-x: auto;
        text-shadow: 2px 1px black;
        color: white; 
    }
    #listView 
    { 
        display: none; 
        position: relative; 
        bottom: 0px; 
        max-height: 90vh; 
        width: 49vw; 
        margin: auto; 
        z-index: 1; 
        text-align: 
        left; 
        overflow-y: auto; 
    }
    #listItemRank { opacity: 0.6; font-size: 20px; }
    #listItemRating { font-size: 20px; opacity: 0.6; }
    #listItemDate { display: none; opacity: 0.6; }
    #searchTypeBar { height: 100%; padding: 11px 0px 10px 1px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);}
    #searchTypeCloseWindow { background-color: rgba(255, 0, 0, 0.9);}
    #listItemDate { padding: 0px 10px 0px 0px;}
    #listItemContainer { padding: 20px 0px 20px 10px; }
    #modalToplistCategories 
    { 
        display: none;
        position: fixed; 
        height: 100vh; 
        width: 100vw; 
        top: 0px;
        margin: auto; 
        margin-left: -20vw;
        padding: 0px; 
        z-index: 3;
        overflow-y: scroll;
        background-color: black; /* #181818 */ 
    }
    #toplistCategoriesMovies
    {
        display: block;
        width: 20vw;
        margin: auto;
        margin-top: 10px;
        height: 86vh;
        overflow-y: scroll;
        /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
    }
    #toplistCategoriesShows
    {
        display: none;
        width: 20vw;
        margin: auto;
        margin-top: 10px;
        height: 86vh;
        overflow-y: scroll;
        /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
    }
    #toplistCategoriesTitleMovies
    {
        display: inline-block;
        margin: 7px 0px 0px 0px;
        padding: 0px;
        font-size: 20px;
        font-weight: bold;
        opacity: 1;
        user-select: none;
        color: white;
    }
    #toplistCategoriesTitleShows
    {
        display: inline-block;
        margin: 7px 0px 0px 0px;
        padding: 0px;
        font-size: 20px;
        font-weight: bold;
        opacity: 0.4;
        user-select: none;
        color: white;
    }
    #toplistCategoriesTitle
    {
        display: inline-block;
        margin: 0px;
        padding: 24px 0px 12px 0px;
        font-size: 20px;
        font-weight: bold;
        color: white;
    }
    #selectedToplistCategory { font-size: 18px; font-weight: bold; user-select: none; }
    #toplistCategoryOriginals { padding: 12px 0px 12px 0px; border-top: 1px solid rgba(255, 255, 255, 0.1); }
    #toplistCategoryGenres { padding: 12px 0px 12px 0px; border-top: 1px solid rgba(255, 255, 255, 0.1);  }
    #toplistCategoryCountries { padding: 12px 0px 12px 0px; border-top: 1px solid rgba(255, 255, 255, 0.1);  }
    #toplistCategoryAll { padding: 12px 0px 12px 0px; border-top: 1px solid rgba(255, 255, 255, 0.1);  }

    /** classes ***/
    .show, .movie { display: inline-block; margin-top: 6px; margin-right: 15px; }
    .hit { display: inline-block; margin: 0px 30px 0px 0px; padding: 0px; width: 260px; user-select: none; }
    .hit:hover { opacity: 1; }
    .sliderCategory { margin: 0px; margin: auto; margin-top: 30px; padding: 0px; color: black; }
    .hitImg {margin: 4px 0px 17px 0px; border: 1px solid rgba(48, 46, 46, 0.4);}
    .scrollBarTopList 
    { 
        display: inline-block; 
        margin: 0px; 
        padding: 0px; 
        /* min-width: 180px;  */
        user-select: none; 
        /* opacity: 0.3;  */
        font-weight: normal;
        font-size: 18px; 
        white-space: nowrap;
        color: white; 
        /* background-color: white;  */
    }
    .listItem 
    { 
        width: auto; 
        margin: auto; 
        padding: 0px; 
        user-select: none; 
        white-space: nowrap; 
        overflow-x: auto;
        font-size: 18px;
        color: white; 
        border-top: 1px solid rgba(255, 255, 255, 0.2); 
        /* background-color: rgba(0, 0, 0, 0.7);  */
    }
    .listItemThumbnail { width: 49px; }
    /* .listItem:active { color: black; background-color: white; } */
    .searchType { margin: 0px; padding: 0px 17px 0px 17px; font-weight: normal; color: white; text-align: left; user-select: none; background-color: black; }
    .personName { position: relative; margin: -44px 0px 24px 0px; padding: 0px; text-shadow: 2px 1px black; color: white; font-weight: bold;}
    .hitImgPerson {height: 30vh; border-radius: 100%; }
    .smallDotCharacter { color: white; opacity: 0.4; user-select: none;}
    .dot { padding: 0px 6px 0px 6px; opacity: 0.4; font-size: 20px; color: white; }
    .toplistCategory 
    { 
        margin: 16px; 
        padding: 0px; 
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        text-shadow: 2px 1px black;
        user-select: none;
        color: white;
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
        /* background-color: white; */
    }
    .ratingText { display: none; margin: 0px 20px 0px -10px; }
    .itemRatingStar { margin: 0px 20px 0px 0px; }
    .dotCharacterRankings { padding: 0px 4px 0px 4px; opacity: 0.4; }
    /* .listItem:hover { } */
    
    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        ::-webkit-scrollbar { /* margin-top: 10px; */ width: 0px; height: 0px; } /* width */
        #scrollBarTopList::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; width: 0px;} /* width */
        .listItem::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; width: 0px;} /* width */
        #listItemTitle::-webkit-scrollbar { /* margin-top: 10px; */ height: 0px; width: 0px;} /* width */
        
        #toplists { width: 100vw; margin: auto;}
        #scrollBarTopList { position: relative; z-index: 2; }
        #listView { max-height: 93vh; width: 100vw; padding-bottom: 40px; overflow-x: hidden; }
        #scrollBarSearch { }
        #searchBar { max-width: 90vw; top: 27vh; bottom: inherit; font-size: 18px; border: 2px solid white;}
        #searchResultsBox { width: 100vw; margin: auto; padding: 0px; z-index: 0;}
        #searchTypeBar { text-align: left; overflow-x: auto;}
        #listItemContainer { padding: 0px; }
        #modalToplistCategories { margin-left: 0px; }
        #toplistCategoriesMovies { width: 90vw; }
        #toplistCategoriesShows { width: 90vw; }
        #toplistCategoriesTitleMovies { margin-top: 7px; }
        #toplistCategoriesTitleShows { margin-top: 7px; }
        #scrollBarTopList { position: fixed; width: 100%; left: 20px; bottom: 22px; margin: 0px; padding: 0px; }
        #selectedToplistCategory { font-size: 19px; }
        #listItemTitle { margin: 0px 10px 0px 0px; }
        /* #listItemDate { display: block; opacity: 0.7;} */
        #listItemRank { font-weight: bold; font-family: Arial, Helvetica, sans-serif; opacity: 0.4; }
        /* #listItemRating { display: inline-block; opacity: 1;} */

        .listItem 
        { 
            width: auto; 
            margin: 0px; 
            padding: 20px; 
            white-space: nowrap; 
            overflow-x: auto; 
            font-size: 18px; 
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
        .scrollBarTopList { margin: 0px; padding: 0px; font-size: 20px; white-space: nowrap;}
        .hitImg 
        { 
            position: relative; 
            height: calc(92vh - 6px); 
            width: 100vw; 
            top: 0px; 
            margin: 0px; 
            opacity: 1; 
            overflow-y: hidden; 
            overflow-x: hidden;
            border: 0px solid rgb(48, 46, 46);
            border-right: 1px solid rgb(48, 46, 46);
        }
        .hit { width: auto; margin: 0px; padding: 0px; border-right: 0px solid black;}
        .personName { max-width: 100px; margin-top: -27px; font-size: 19px; overflow-x: auto;}
        /* .listItem:active { color: black; background-color: white; } */
        .hitImgPerson {height: 17vh; width: 30vw; top: 6px; margin: 4px 10px 10px 10px;}
        .searchType { padding: 0px 16px 0px 16px;}
        .itemRatingStar { margin: 0px 20px 0px 0px; }
    }
</style>