<template>
    <div id="componentAbout">
        <!-- about -->
        <div id="aboutSectionAbout" class="section">
            <h3 id="titleAbout" class="sectionTitle">Why</h3>
            <p class="infoText">This is a student project made to learn about Vue, Node and REST APIs.</p>
        </div>

        <!-- credits -->
        <div id="aboutSectionCredits" class="section">
        <h3 id="title" class="sectionTitle">Credits</h3>
            <p id="text" class="infoText">This product uses the TMDB API but is not endorsed or certified by TMDB.</p>
            <a href="https://www.themoviedb.org">
                <img id="tmdbLogo" v-bind:src="'https://www.themoviedb.org/assets/2/v4/logos/v2/blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg'" />
            </a>
        </div>

        <!-- privacy -->
        <div id="aboutSectionPrivacy" class="section">
        <h3 id="title" class="sectionTitle">Privacy Policy</h3>
            <ul id="privacyPolicyList">
                <li>Collects Account Data: <span class="privacyYes">Yes</span></li>
                <li>Collects Personal Data: <span class="privacyNo">No</span></li>
                <li>Collects Device Data: <span class="privacyNo">No</span></li>
                <li>Collects Metrics Data: <span class="privacyNo">No</span></li>
                <li>Collects Diagnostics Data: <span class="privacyNo">No</span></li>
                <li>Collects Location Data: <span class="privacyNo">No</span></li>
                <li>Collects Financial Data: <span class="privacyNo">No</span></li>
                <li>Collects Messages Data: <span class="privacyNo">No</span></li>
                <li>Collects Media Data: <span class="privacyNo">No</span></li>
                <li>Uses Cookies: <span class="privacyNo">No</span></li>
                <li>Uses Local Storage: <span class="privacyYes">Yes</span></li>
                <li>Links to Other Websites: <span class="privacyYes">Yes</span></li>
                <li>Policy May Change in the Future: <span class="privacyYes">Yes</span></li>
            </ul>
        </div>

        <!-- keybinds -->
        <div id="aboutSectionKeybinds" class="section">
            <h3 id="titleKeybinds" class="sectionTitle">Keybinds</h3>
            <div id="keybindExplanations">
                <table>
                    <tr><th>§</th><td><span class="equalsCharacter">=</span> Search</td></tr>
                    <tr><th>&lt;</th><td><span class="equalsCharacter">=</span> Back</td></tr>
                    <tr><th>></th><td><span class="equalsCharacter">=</span> Forward</td></tr>
                    <tr><th>Esc</th><td><span class="equalsCharacter">=</span> Close</td></tr>
                </table>
            </div>
        </div>

        <!-- buttons -->
        <div id="aboutSectionButton" class="section">
            <h3 id="titleLikes" class="sectionTitle"><b>Buttons</b></h3>
            <table>
                <tr><th class="buttonCharacter heartCharacter">❥</th><td><span class="equalsCharacter">=</span> Like</td></tr> <!-- ♥ -->
                <tr><th class="buttonCharacter reviewCharacter">★</th><td><span class="equalsCharacter">=</span> Review</td></tr>
                <tr><th class="buttonCharacter">+</th><td><span class="equalsCharacter">=</span> List</td></tr>
            </table>
        </div>

        <!-- contact -->
        <div id="aboutSectionContact" class="section">
            <h3 id="title" class="sectionTitle">Contact</h3>
            <p id="text" class="infoText"><a class="links" href="mailto:contact@teruno.app">contact@teruno.app</a></p>
        </div>

        <!-- version -->
        <div id="aboutSectionVersion" class="section">
            <h3 id="title" class="sectionTitle">Version</h3>
            <p id="text" class="infoText"></p>
        </div>

        <!-- install -->
        <!-- <div id="aboutSectionInstall" class="section">
            <h3 id="titleLikes" class="sectionTitle"><b>Apps</b></h3>
            <p id="text" class="infoText"><a class="links" href="/install">Mobile</a></p>
            <p id="text" class="infoText"><a class="links" href="/install">Desktop</a></p>
        </div> -->

        <!-- slideshow -->
        <!-- <div id="aboutSectionSlideShow">
            <h3 id="titleSlideshow" class="sectionTitle">Slideshow</h3>
            <p class="infoText">Random TV-Show or Movie every 30s</p>
        </div> -->

        <!-- IMDB Rankings -->
        <!-- <div id="aboutSectionImdbRankings">
            <h3 id="titleImdbRankings" class="sectionTitle">RankingsX</h3>
            <p class="infoText">Top 100 Lists from IMDB data</p>
        </div> -->

        <!-- IMDB vs TMDB -->
        <!-- <div id="aboutSectionImdbVsTmdb"> -->
            <!-- <h3 id="titleImdbVsTmdb" class="sectionTitle">IMDB</h3> -->
            <!-- <p class="infoText">imdb.com = data source for Rankings</p> -->
            <!-- <p class="infoText">(data source for Rankings)</p><br /> -->
            <!-- <p class="infoText">TMDB = themoviedb.org</p> -->
            <!-- <p class="infoText">(data source for everything else)</p> -->
        <!-- </div> -->

        <!-- <div id="aboutSectionImdbVsTmdb"> -->
            <!-- <h3 id="titleImdbVsTmdb" class="sectionTitle">TMDB</h3> -->
            <!-- <p class="infoText">IMDB = imdb.com</p> -->
            <!-- <p class="infoText">(data source for Rankings)</p><br /> -->
            <!-- <p class="infoText">TMDB = themoviedb.org</p> -->
            <!-- <p class="infoText">themoviedb.org = data source for everything else</p> -->
        <!-- </div> -->
            
        <!-- help -->
        <!-- <div id="aboutSectionHelp"> -->
            <!-- <h3 id="titleHelp" class="sectionTitle">Help</h3> -->
            <!-- <p class="infoText">Help #1</p> -->
            <!-- <p class="infoText">Help #2</p> -->
            <!-- <p class="infoText">Help #3</p> -->
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    setup() {
        
        return {
            
        }
    }
}
</script>

<style scoped>
  /*** scrollbar  ***/
  #componentAbout { scrollbar-width: none;}

    /*** elements ***/
    th { text-align: right; white-space: nowrap; overflow-x: auto;}
    td { text-align: left; white-space: nowrap; overflow-x: auto;}
    table { margin: auto; }

    /*** ids ***/
    #componentAbout 
    { 
        display: none;     
        position: absolute;
        height: auto;
        top: calc(4vh + 8px);
        left: 30vw;
        height: 94vh;
        width: 40vw;
        padding: 0px;
        opacity: 1;
        overflow-y: auto;
        color: white;
        border: 0px solid white;
        background-color: black;
    }
    #keybindExplanations { width: 100%; margin: auto; opacity: 0.9;}
    #tmdbLogo { margin: 0px; margin-top: 10px; padding: 0px; width: 200px; }
    #titleAbout { margin: 0px; padding: 0px; }
    #privacyPolicyList { margin: 0px; padding: 0px; text-align: center; opacity: 0.9; list-style: none; user-select: none; }
    #aboutSectionKeybinds { user-select: none; }
    #aboutSectionButton { user-select: none; }
    #aboutSectionContact { user-select: none; }
    #aboutSectionVersion { user-select: none; }
    #aboutSectionCredits { user-select: none; }
    #aboutSectionAbout { user-select: none; }

    /*** classes ***/
    .infoText { width: 49%; margin: auto; padding: 0px; text-align: center; opacity: 0.9; color: white;}
    .keybindShift { }
    .sectionTitle { margin: auto; padding: 0px; font-weight: bold; text-align: center; color: white;}
    .buttonCharacter { margin: 0px; padding: 0px; font-size: 30px;}
    .reviewCharacter { margin: 0px; padding: 0px; font-size: 20px;}
    .heartCharacter { display: inline-block; margin: 0px 2px 0px 0px; padding: 0px; transform: rotate(90deg); font-size: 20px;}
    .links { text-decoration: none; color: white; }
    .privacyPolicyCategory { margin: 16px 0px 1px 0px; padding: 0px; font-weight: bold; }
    .privacyNo { font-weight: bold; color: rgba(255, 0, 0, 0.8); }
    .privacyYes { font-weight: bold; color: rgba(0, 255, 0, 0.8); }
    .equalsCharacter { margin: 0px 4px 0px 4px; opacity: 0.6; }
    .section { margin: 0px 0px 40px 0px; }
    .sectionTitle { user-select: none; }

    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        #componentAbout 
        { 
            display: block; 
            position: relative; 
            height: 90vh; 
            width: 82vw; 
            top: 2px; 
            left: 0vw; 
            margin: auto; 
            padding: 0px;
            overflow-y: auto;
        }
        #aboutSectionKeybinds { display: none; }
        /* #tmdbLogo { width: 200px; margin: 20px 0px 0px;} */
        #titleKeybinds { display: none;}
        #aboutSectionSlideShow { display: none;}
        #tmdbLogo { width: 60%; }

        .infoText { width: 100%; margin: auto; text-align: center; }
        .sectionTitle { text-align: center; }
    }
</style>