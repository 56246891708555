export function firstLetterToUpperCase(word)
{
    return word[0].toUpperCase() + word.substr(1)
}

export function currentYear()
{
    return new Date().getFullYear()
}

export function searchQueryNullCheck(queryString)
{
    if (queryString == null) { return false }
    else if (queryString == undefined) { return false }
    else if (queryString == "") { return false }
    else if (queryString == " ") { return false }
    else if (queryString.includes("  ")) { return false }
    else { return true }
}

export function replaceForbiddenCharacters(queryString)
{
    //variables
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let specialCharactersArray = ["[", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "\\", "-", "=", "\[", "\]", "{", "}", ";", "'", ":", "\\", "|", ",", ".", "<", ">", "\/", "?", "]", "+", ";", "]"]

    //replace special characters
    if(queryString.match(specialCharacters))
    {
        console.log("search string special characters")
        
        for(let c in specialCharactersArray) { queryString = queryString.replace(specialCharactersArray[c], "") }

        return queryString
    }
}

export function displayMovieDetails()
{
  //null check
  let movieDetails = document.getElementById("movieDetails")
  if(movieDetails == null || movieDetails == "null") { return }

  //elements
  let componentTopBar = document.getElementById("componentTopBar")
  let componentOptionsBar = document.getElementById("componentOptionsBar")
  let movieDetailsTitle = document.getElementById("movieDetailsTitle")
  let underlayMovies = document.getElementById("underlayMovies")
  let movieInfo = document.getElementById("movieInfo")
  let moviePoster = document.getElementById("moviePoster")
  // let scrollBarSearch = document.getElementById("scrollBarSearch")

  //variables
  let mq = window.matchMedia("(max-width: 1100px)")
  let lsSessionUserData = localStorage.getItem("sessionUserData")

  //reset movie details
  if(movieDetails.style.display == "" || movieDetails.style.display == "none")
  {
      if(movieDetails) { movieDetails.style.display = "block" }
      if(movieInfo) { movieInfo.style.display = "none" }
      if(componentTopBar) { componentTopBar.style.display = "none" }
      if(componentOptionsBar) { componentOptionsBar.style.display = "none" }

      if(mq.matches == true) //mobile
      { 
        if(underlayMovies) { underlayMovies.style.display = "block" }
        if(movieDetailsTitle) 
        { 
          movieDetailsTitle.style.bottom = "0px" 
          movieDetailsTitle.style.padding = "3vh 0px 3vh 0px"
          movieDetailsTitle.style.borderTop = "1px solid white"
          movieDetailsTitle.style.backgroundColor = "black"
        }
        // if(scrollBarSearch) { scrollBarSearch.style.display = "none" }
      }
      else if(mq.matches == false) //desktop
      { 
        // if(scrollBarSearch) { scrollBarSearch.style.display = "none" }
      }
  }
  else if(movieDetails.style.display == "block")
  {
      if(movieDetails) { movieDetails.style.display = "none" }
      if(movieInfo) { movieInfo.style.display = "block" }
      if(componentTopBar) { componentTopBar.style.display = "block" }
      if(moviePoster) { moviePoster.style.display = "inline-block" }
      // if(scrollBarSearch) { scrollBarSearch.style.display = "block" }

      //check if logged in
      if(lsSessionUserData != null) {componentOptionsBar.style.display = "block" }
      else if(lsSessionUserData == null) { componentOptionsBar.style.display = "none"}
      
      if(mq.matches == true) //mobile
      { 
        if(movieDetailsTitle) 
        { 
          movieDetailsTitle.style.bottom = "11vh" 
          movieDetailsTitle.style.padding = "0px"
          movieDetailsTitle.style.borderTop = "0px solid white"
          movieDetailsTitle.style.backgroundColor = "transparent"
          movieDetailsTitle.innerText = "Details"
        }
        if(underlayMovies) { underlayMovies.style.display = "none" }
      }
      else if(mq.matches == false) //desktop
      {
        if(movieDetailsTitle) { movieDetailsTitle.innerText = "Details" }
      }
  }
}

export function displayShowDetails()
{
  //null check
  let showDetails = document.getElementById("showDetails")
  if(showDetails == null || showDetails == "null") { return }

  //elements
  let componentTopBar = document.getElementById("componentTopBar")
  let componentOptionsBar = document.getElementById("componentOptionsBar")
  let showDetailsTitle = document.getElementById("showDetailsTitle")
  let scrollBarSeasons = document.getElementById("scrollBarSeasons")
  let underlayShows = document.getElementById("underlayShows")
  let showInfo = document.getElementById("showInfo")
  let showPoster = document.getElementById("showPoster")
  // let scrollBarSearch = document.getElementById("scrollBarSearch")

  //variables
  let mq = window.matchMedia("(max-width: 1100px)")
  let lsSessionUserData = localStorage.getItem("sessionUserData")

  //reset show details
  if(showDetails.style.display == "" || showDetails.style.display == "none")
  {
    if(showDetails) { showDetails.style.display = "block" }
    if(componentTopBar) { componentTopBar.style.display = "none" }
    if(componentOptionsBar) { componentOptionsBar.style.display = "none" }
    
    if(mq.matches == true) //mobile
    {
      if(showDetailsTitle) 
      { 
        showDetailsTitle.style.bottom = "0px" 
        showDetailsTitle.style.padding = "3vh 0px 3vh 0px"
        showDetailsTitle.style.borderTop = "1px solid white"
        showDetailsTitle.style.backgroundColor = "black"
      }
    }
    else if(mq.matches == false) //desktop
    {
      showDetailsTitle.style.bottom = "3vh"
    }

    if(scrollBarSeasons) { scrollBarSeasons.style.display = "none" }
    if(underlayShows) { underlayShows.style.display = "block" }
    if(showInfo) { showInfo.style.display = "none" }
    // if(scrollBarSearch) { scrollBarSearch.style.display = "none" }
  }
  else if(showDetails.style.display == "block")
  {
    if(showDetails) { showDetails.style.display = "none" }
    if(componentTopBar) { componentTopBar.style.display = "block" }
    // if(scrollBarSearch) { scrollBarSearch.style.display = "block" }

    //check if logged in
    if(lsSessionUserData != null) {componentOptionsBar.style.display = "block" }
    else if(lsSessionUserData == null) { componentOptionsBar.style.display = "none"}
    
    if(mq.matches == true) //mobile
    { 
      if(underlayShows) { underlayShows.style.display = "none" }
      if(showDetailsTitle) 
      { 
        showDetailsTitle.style.bottom = "12vh" 
        showDetailsTitle.style.padding = "0px" 
        showDetailsTitle.style.borderTop = "0px solid white"
        showDetailsTitle.style.backgroundColor = "transparent"
        showDetailsTitle.innerText = "Details"
      }
      // if(scrollBarSeasons) { scrollBarSeasons.style.display = "flex" }
    }
    else if(mq.matches == false) //desktop
    { 
      // if(scrollBarSeasons) { scrollBarSeasons.style.display = "block" }
      if(underlayShows) { underlayShows.style.display = "block" }
      if(showDetailsTitle) { showDetailsTitle.innerText = "Details"; showDetailsTitle.style.bottom = "3vh" }
    }
    
    if(showInfo) { showInfo.style.display = "block" }
    if(showPoster) { showPoster.style.display = "inline-block" }
  }
}

export function undisplayEpisodes()
{
  //elements
  let componentTopBar = document.getElementById("componentTopBar")
  let componentOptionsBar = document.getElementById("componentOptionsBar")
  let showDetailsTitle = document.getElementById("showDetailsTitle")
  let underlayShows = document.getElementById("underlayShows")
  let showInfo = document.getElementById("showInfo")
  let componentEpisodes = document.getElementById("componentEpisodes")
  let showPoster = document.getElementById("showPoster")
  // let scrollBarSearch = document.getElementById("scrollBarSearch")
  
  //variables
  let mq = window.matchMedia("(max-width: 1100px)")
  let lsSessionUserData = localStorage.getItem("sessionUserData")

  //null check
  if(componentEpisodes == null) { return }
  if(componentEpisodes.style.display == "none") { return }
  
  //reset elements
  if(componentEpisodes) {componentEpisodes.style.display = "none" }
  if(showInfo) {showInfo.style.display = "block" }
  if(selectedSeasonOpacityStyling) {selectedSeasonOpacityStyling(null, null) }
  if(underlayShows) {underlayShows.style.display = "block" }
  if(showPoster) {showPoster.style.display = "inline-block"}
  if(scrollBarSeasons) { scrollBarSeasons.style.display = "none" }

  //check if desktop or mobile
  if(mq.matches == true) { underlayShows.style.display = "none"; /* underlayShows.style.opacity = "1" */} //mobile
  else if(mq.matches == false) { underlayShows.style.display = "block"; underlayShows.style.opacity = "0.4" } //desktop

  //reset options bar
  if(optionsBar) {optionsBar.style.display = "flex" }

  //check if logged in
  if(lsSessionUserData != null) {componentOptionsBar.style.display = "block" }
  else if(lsSessionUserData == null) { componentOptionsBar.style.display = "none"}

  if(componentTopBar) {componentTopBar.style.display = "block" }
  if(showDetailsTitle) {showDetailsTitle.style.display = "block" }
  // scrollBarSearch.style.display = "block"

  return
}

function selectedSeasonOpacityStyling(season, numberOfEpisodes)
{
  //elements
  let selectedDiv = document.getElementById("season#" + season)
  let seasons = document.getElementsByClassName("season")
  let seasonSpecials = document.getElementById("season#" + [0])

  //reset elements
  if(seasonSpecials != null && seasons != null)
  {
      for(let c = 0; c < seasons.length; c++)
      {
          if(document.getElementById("season#" + [c]))
          {
            document.getElementById("season#" + [c]).style.opacity = ".50"
            document.getElementById("season#" + [c]).style.backgroundColor = ""
          }
      }
  }
  else
  {
      for(let c = 1; c <= seasons.length; c++)
      {
          if(document.getElementById("season#" + [c]))
          {
            document.getElementById("season#" + [c]).style.opacity = ".50"
            document.getElementById("season#" + [c]).style.backgroundColor = ""
          }
      }
  }

  if(selectedDiv != null)
  {
    selectedDiv.style.opacity = "1"
  } 
}