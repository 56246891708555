import { createStore } from 'vuex'

export default createStore({
  state: 
  {
    //selected
    selectedShow: null, 
    selectedSeason: null, 
    selectedMovie: null, 
    selectedPerson: null, 
    selectedEpisode: null,
    selectedList: null,
    selectedMoviePoster: null,
    selectedShowPoster: null,
    selectedPersonPoster: null,
    
    //search
    recentlySearched: null,
    
    //toplist
    topListResult: null, 
    topListType: null,
    
    //session
    sessionUserData: null,
    sessionPublicLists: null,
    
    //review
    reviewFromAccountPage: null,

    //featured
    featured: null,

    //api
    backendAPI: "https://node-943209423-api.herokuapp.com"
    // backendAPI: "http://localhost:3000"
  },
  
  getters: 
  {
    //selected
    selectedSeason(state) { return state.selectedSeason },
    selectedShow(state) { return state.selectedShow},
    selectedMovie(state) { return state.selectedMovie },
    selectedPerson(state) { return state.selectedPerson },
    selectedEpisode(state) { return state.selectedEpisode },
    selectedList(state) { return state.selectedList },
    selectedMoviePoster(state) { return state.selectedMoviePoster },
    selectedShowPoster(state) { return state.selectedShowPoster },
    selectedPersonPoster(state) { return state.selectedPersonPoster },

    //search
    recentlySearched(state) { return state.recentlySearched },

    //toplist
    topListResult(state) { return state.topListResult },
    topListType(state) { return state.topListType },
    
    //session
    sessionUserData(state) { return state.sessionUserData },
    sessionPublicLists(state) { return state.sessionPublicLists },

    //review
    reviewFromAccountPage(state) { return state.reviewFromAccountPage },

    //featured
    featured(state) { return state.featured },

    //api
    backendAPI(state) { return state.backendAPI },
  },

  mutations: 
  {      
    //selected
    mutationSetSelectedSeason (state, value) { state.selectedSeason = value },
    mutationSetSelectedShow (state, value) { state.selectedShow = value },
    mutationSetSelectedMovie (state, value) { state.selectedMovie = value },
    mutationSetSelectedPerson (state, value) { state.selectedPerson = value },
    mutationSetSelectedEpisode (state, value) { state.selectedEpisode = value },
    mutationSetSelectedList (state, value) { state.selectedList = value },
    mutationSetSelectedMoviePoster (state, value) { state.selectedMoviePoster = value },
    mutationSetSelectedShowPoster (state, value) { state.selectedShowPoster = value },
    mutationSetSelectedPersonPoster (state, value) { state.selectedPersonPoster = value },

    //search
    mutationSetRecentlySearched (state, value) { state.recentlySearched = value },

    //toplist
    mutationSetTopListResult (state, value) { state.topListResult = value },
    mutationSetTopListType (state, value) { state.topListType = value },

    //session
    mutationSetSessionUserData (state, value) { state.sessionUserData = value },
    mutationSetSessionPublicLists (state, value) { state.sessionPublicLists = value },

    //review
    mutationSetReviewFromAccountPage(state, value) { state.reviewFromAccountPage = value },

    //featured
    mutationSetFeatured(state, value) { state.featured = value },
  },

  actions: 
  {
    //selected
    actionSetSelectedSeason ({commit}, value) { commit('mutationSetSelectedSeason', value) },
    actionSetSelectedShow ({commit}, value) { commit('mutationSetSelectedShow', value) },
    actionSetSelectedMovie ({commit}, value) { commit('mutationSetSelectedMovie', value) },
    actionSetSelectedPerson ({commit}, value) { commit('mutationSetSelectedPerson', value) },
    actionSetSelectedEpisode ({commit}, value) { commit('mutationSetSelectedEpisode', value) },
    actionSetSelectedList ({commit}, value) { commit('mutationSetSelectedList', value) },
    actionSetSelectedMoviePoster ({commit}, value) { commit('mutationSetSelectedMoviePoster', value) },
    actionSetSelectedShowPoster ({commit}, value) { commit('mutationSetSelectedShowPoster', value) },
    actionSetSelectedPersonPoster ({commit}, value) { commit('mutationSetSelectedPersonPoster', value) },

    //search
    actionSetRecentlySearched ({commit}, value) { commit('mutationSetRecentlySearched', value) },

    //toplist
    actionSetTopListResult ({commit}, value) { commit('mutationSetTopListResult', value) },
    actionSetTopListType ({commit}, value) { commit('mutationSetTopListType', value) },

    //session
    actionSetSessionUserData ({commit}, value) { commit('mutationSetSessionUserData', value) },
    actionSetSessionPublicLists ({commit}, value) { commit('mutationSetSessionPublicLists', value) },

    //review
    actionSetReviewFromAccountPage ({commit}, value) { commit('mutationSetReviewFromAccountPage', value) },

    //featured
    actionSetFeatured ({commit}, value) { commit('mutationSetFeatured', value) },
  }
})
