<template>
    <div id="componentTopBar">
        <!-- categories -->
        <p id="topBarCenterParagraph">
            <span id="topBarFeatured" class="topBarCategory" v-on:click="setUrl('featured')">Featured</span>
            <span class="topBarDot">·</span>
            <span id="topBarLists" class="topBarCategory" v-on:click="setUrl('lists')">Lists</span>
            <span class="topBarDot">·</span>
            <span id="topBarTop100" class="topBarCategory" v-on:click="setUrl('top100')">RankingsX</span>
            <span class="topBarDot"></span>
            <span id="topBarRandom" class="topBarCategory" v-on:click="setUrl('random')">Random</span>
            <!-- <span class="topBarDot">·</span> -->
            <span id="topBarAccount" class="topBarCategory" v-on:click="setUrl('account')">Account</span>
            <span class="topBarDot">·</span>
            <span id="topBarAbout" class="topBarCategory" v-on:click="setUrl('about')">About</span>
            <span class="topBarDot">·</span>
            <span id="topBarSearch" class="topBarCategory desktopOnly" v-on:click="displaySearchBar()">§</span>
            <span class="topBarDot desktopOnly">·</span>
            <span id="topBarPrevious" class="desktopOnly" v-on:click="previous()">➤</span> <!-- ❮ ❯ ➤ ▲ ᐸ -->
            <span id="topBarNext" class="desktopOnly" v-on:click="next()">➤</span> <!-- ❮ ❯ ➤ ▲ ᐸ -->
            <span id="topBarFullscreen" class="topBarCategory mobileOnly" v-on:click="fullscreen()">[ ]</span>
            <!-- <span id="topBarSlideshow" class="topBarCategory" v-on:click="slideshow()">Slideshow</span><span class="topBarDot">·</span> -->
            <!-- <span id="topBarCredits" class="topBarCategory" v-on:click="setUrl('credits')">Credits</span><span class="topBarDot">·</span> -->
            <!-- <span id="topBarAbout" class="topBarCategory" v-on:click="setUrl('about')">About</span><span class="topBarDot">·</span> -->
            <!-- <span id="topBarHelp" class="topBarCategory" v-on:click="setUrl('help')">Help</span> -->
            <!-- <span id="topBarPrevious" class="topBarCategory" v-on:click="">⇦</span> -->
            <!-- <span id="topBarNext" class="topBarCategory" v-on:click="">⇨</span> -->
            <!-- <span id="topBar2022" class="topBarCategory" v-on:click="setUrl('2022', null)">2022</span> -->
            <!-- <span class="topBarCategory" v-on:click="topListCategory('companies', null)">Companies</span><span>·</span>  -->
            <!-- <span class="topBarCategory" v-on:click="topL  istCategory('countries', null)">Countries</span><span>·</span> -->
            <!-- <span class="topBarCategory" v-on:click="topListCategory('countries', null)">Featured</span><span>·</span> -->
        </p>
    </div>
</template>

<script>
import {useStore} from 'vuex'
import {ref, reactive, computed, onUpdated, onMounted} from 'vue'
import router from '../router'
import { firstLetterToUpperCase, displayMovieDetails, displayShowDetails, undisplayEpisodes } from '../assets/utility'

export default {
    setup() {
        //vuex
        const store = useStore() //same as this.$store
        const getTopListType = computed(() => { return store.getters['topListType']})
        
        //variables
        var slideShowInterval = null
        var slideShowList = null
        const BACKEND_API_URL =  computed(() => { return store.getters['backendAPI']}).value
        var isFullscreen = false

        //event listeners
        document.addEventListener('wheel', function(e) {
            let featuredMovies = document.getElementById("featuredMovies")
            let featuredShows = document.getElementById("featuredShows")
            let listsSearchResults = document.getElementById("listsSearchResults")
            let likesCategoryRecent = document.getElementById("likes-category-recent")
            let likesCategoryShows = document.getElementById("likes-category-shows")
            let likesCategoryMovies = document.getElementById("likes-category-movies")
            let likesCategoryPeople = document.getElementById("likes-category-people")
            let reviewsCategoryRecent = document.getElementById("reviews-category-recent")
            let reviewsCategoryShows = document.getElementById("reviews-category-shows")
            let reviewsCategoryMovies = document.getElementById("reviews-category-movies")
            let reviewsCategoryPeople = document.getElementById("reviews-category-people")
            let reviewsCategoryEpisodes = document.getElementById("reviews-category-episodes")
            let listsCategoryOldest = document.getElementById("lists-category-oldest")
            let listsCategoryLatest = document.getElementById("lists-category-latest")
            let scrollBarSearch = document.getElementById("scrollBarSearch")
            
            if (e.deltaY > 0) 
            { 
                if(featuredMovies != null) { featuredMovies.scrollLeft += 60; }
                if(featuredShows != null) { featuredShows.scrollLeft += 60; }   
                if(listsSearchResults != null) { listsSearchResults.scrollLeft += 60; }   
                if(likesCategoryRecent != null) { likesCategoryRecent.scrollLeft += 60; }   
                if(likesCategoryShows != null) { likesCategoryShows.scrollLeft += 60; }   
                if(likesCategoryMovies != null) { likesCategoryMovies.scrollLeft += 60; }   
                if(likesCategoryPeople != null) { likesCategoryPeople.scrollLeft += 60; }   
                if(reviewsCategoryRecent != null) { reviewsCategoryRecent.scrollLeft += 60; }   
                if(reviewsCategoryShows != null) { reviewsCategoryShows.scrollLeft += 60; }   
                if(reviewsCategoryMovies != null) { reviewsCategoryMovies.scrollLeft += 60; }   
                if(reviewsCategoryPeople != null) { reviewsCategoryPeople.scrollLeft += 60; }   
                if(reviewsCategoryEpisodes != null) { reviewsCategoryEpisodes.scrollLeft += 60; }   
                if(listsCategoryOldest != null) { listsCategoryOldest.scrollLeft += 60; }   
                if(listsCategoryLatest != null) { listsCategoryLatest.scrollLeft += 60; }   
                if(scrollBarSearch != null) { scrollBarSearch.scrollLeft += 60; }   
            }
            else 
            { 
                if(featuredMovies != null) { featuredMovies.scrollLeft -= 60; }
                if(featuredShows != null) { featuredShows.scrollLeft -= 60; }
                if(listsSearchResults != null) { listsSearchResults.scrollLeft -= 60; }
                if(likesCategoryRecent != null) { likesCategoryRecent.scrollLeft -= 60; }
                if(likesCategoryShows != null) { likesCategoryShows.scrollLeft -= 60; }
                if(likesCategoryMovies != null) { likesCategoryMovies.scrollLeft -= 60; }
                if(likesCategoryPeople != null) { likesCategoryPeople.scrollLeft -= 60; }
                if(reviewsCategoryRecent != null) { reviewsCategoryRecent.scrollLeft -= 60; }   
                if(reviewsCategoryShows != null) { reviewsCategoryShows.scrollLeft -= 60; }   
                if(reviewsCategoryMovies != null) { reviewsCategoryMovies.scrollLeft -= 60; }   
                if(reviewsCategoryPeople != null) { reviewsCategoryPeople.scrollLeft -= 60; }   
                if(reviewsCategoryEpisodes != null) { reviewsCategoryEpisodes.scrollLeft -= 60; }   
                if(listsCategoryOldest != null) { listsCategoryOldest.scrollLeft -= 60; }   
                if(listsCategoryLatest != null) { listsCategoryLatest.scrollLeft -= 60; } 
                if(scrollBarSearch != null) { scrollBarSearch.scrollLeft -= 60; } 
            }
        })

        // document.addEventListener("fullscreenchange", function(event) {
        //     if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement)
        //     {
        //         console.log("exit fullscreen")

        //         //remove interval
        //         clearInterval(slideShowInterval)

        //         //elements
        //         let componentTopBar = document.getElementById("componentTopBar")
        //         let optionsBar = document.getElementById("optionsBar")
        //         let scrollBarSearch = document.getElementById("scrollBarSearch")

        //         //set elements
        //         if(componentTopBar) { componentTopBar.style.display = "flex" }
        //         if(optionsBar) { optionsBar.style.display = "flex" }
        //         if(scrollBarSearch) 
        //         { 
        //             scrollBarSearch.style.position = "relative"
        //             scrollBarSearch.style.top = ""
        //             scrollBarSearch.style.left = "" 
        //             scrollBarSearch.style.width = "auto"
        //         }
        //     }
        // });

        //keybinds
        document.addEventListener("keydown", function(event) {
            if(event.code == "Escape")
            {
                //elements
                let searchBar = document.getElementById("searchBar")
                let modalReview = document.getElementById("modalReview")
                let modalLists = document.getElementById("modalLists")
                let movieDetails = document.getElementById("movieDetails")
                let showDetails = document.getElementById("showDetails")
                let modalUnderlay = document.getElementById("modalUnderlay")

                //undisplay elements
                searchBar.style.display = "none"
                modalLists.style.display = "none"
                modalUnderlay.style.display = "none"

                if(movieDetails && movieDetails.style && movieDetails.style.display == "block")
                {
                    displayMovieDetails()
                }   
                else if(showDetails && showDetails.style && showDetails.style.display == "block")
                {
                    displayShowDetails()
                }
                else if(modalReview.style.display == "block")
                {
                    modalReview.style.display = "none"
                }
                else if(componentEpisodes.style.display == "block" && modalReview.style.display == "none")
                {
                    undisplayEpisodes()
                }

            }
            if(event.shiftKey == true && event.code == "IntlBackslash") { next() }
            else if(event.code == "IntlBackslash") { previous() }
            else if(document.activeElement.id != "searchBarInput") //check if search bar is active
            {
                //elements
                let topBarAccount = document.getElementById("topBarAccount")
                let topBarTop100 = document.getElementById("topBarTop100")
                let topBarFeatured = document.getElementById("topBarFeatured")

                //check key type
                // if(event.shiftKey == true && event.code == "KeyX") { topBarAccount.click() }
                // else if(event.shiftKey == true && event.code == "KeyR") { topBarTop100.click() }
                // else if(event.shiftKey == true && event.code == "KeyF") { topBarFeatured.click() }
                // else if(event.shiftKey == true && event.code == "KeyA") { previous() }
                // else if(event.shiftKey == true && event.code == "ArrowLeft") { previous() }
                // else if(event.shiftKey == true && event.code == "KeyD") { next() }
                // else if(event.shiftKey == true && event.code == "ArrowRight") { next() }
            }
            if(event.code == "Backquote")
            {
                displaySearchBar()
            }
        })

        //mousebinds
        document.addEventListener("mousedown", function(event) {
            if(event.button == "1") { displaySearchBar()}
            // else if(event.button == "4") { next() }
            // else if(event.button == "3") { previous() }
        })

        //lifecycle hooks
        onMounted(() => {
            console.log("onMounted ComponentTopBar")
        })

        onUpdated(() => {
            console.log("onUpdated ComponentTopBar")

        })
      
        //functions
        async function setUrl(type)
        {
            //variables
            let topBarCategory = document.getElementsByClassName("topBarCategory")
            let currentPath = router.currentRoute._value.fullPath

            // for(let c in topBarCategory)
            // {
            //     if(topBarCategory[c].style) 
            //     { 
            //         topBarCategory[c].style.color = "white"
            //         topBarCategory[c].style.fontWeight = "normal"
            //     }
            // }
            // for(let c in topBarCategory)
            // {
            //     if(topBarCategory[c].id != undefined)
            //     {
            //         if(topBarCategory[c].id.includes(firstLetterToUpperCase(type)))
            //         {
            //             let selectedCategory = document.getElementById(topBarCategory[c].id)
            //             selectedCategory.style.fontWeight = "bold"
            //         }

            //     }
            // }

            //check url
            if(type == "random") { await fetchRandomNumber(); topBarRandom.style.fontWeight = "bold"; topBarRandom.style.opacity = "1"}
            else if(currentPath != "/" + type) { router.push({path: '/' + type}); topBarRandom.style.fontWeight = "normal" } //check if identical previous path
        }

        function displaySearchBar()
        {
            //elements
            let searchBar = document.getElementById("searchBar")
            let componentStart = document.getElementById("componentStart")
            let searchBarInput = document.getElementById("searchBarInput")
            let topBarSearch = document.getElementById("topBarSearch")
            
            //display elements
            if(componentStart == null){ return }
            if(componentStart.style.display == "" || componentStart.style.display == "none" || 
            searchBar.style.display == "" || searchBar.style.display == "none")
            {
                componentStart.style.display = "block"
                searchBar.style.display = "block"
                topBarSearch.style.fontWeight = "bold"
                topBarSearch.style.opacity = "1"

                //focus search bar
                setTimeout(() => {searchBarInput.focus(); searchBarInput.value = ""}, 0)
            }
            else if(searchBar.style.display == "block") 
            { 
                searchBar.style.display = "none" 
                topBarSearch.style.fontWeight = "normal"
                topBarSearch.style.opacity = "0.3"

                //clear search input
                // searchBarInput.value = ""
            }
        }

        async function fetchRandomNumber()
        {
            //variables
            let randomNumber = Math.floor(Math.random() * 200)
            let type = null
            let url = null

            //random type
            if(randomNumber > 100) { type = "Movies" }
            else if(randomNumber < 100) { type = "Shows" }

            //set url
            url = BACKEND_API_URL + "/random"
            
            //fetch random movie or show data
            await fetch(url, {method: 'get'})
            .then((response) => {
                    console.log("random number fetched from API")
                    return response.json()
            })
            .then((data) => {
                console.log(data)
                
                //check type
                if(type == "Movies") { router.push({path: '/movie' + '/' + data.nr})}
                else if(type == "Shows") {router.push({path: '/show' + '/' + data.nr}) }
            })
        }

        function previous()
        {
            console.log("previous")
            
            router.back()
        }

        function next()
        {
            console.log("next")
                        
            router.forward()
        }

        // function slideshow()
        // {
        //     console.log("start slideshow mode")

        //     //variables
        //     let currentWindow = document.documentElement;
        //     let lsSessionUserData = localStorage.getItem("sessionUserData")

        //     //elements
        //     let componentTopBar = document.getElementById("componentTopBar")
        //     let optionsBar = document.getElementById("optionsBar")
        //     let topBarRandom = document.getElementById("topBarRandom")
        //     //set elements
        //     componentTopBar.style.display = "none"
        //     optionsBar.style.display = "none"

        //     //check if fullscreen
        //     if (currentWindow.requestFullscreen) { currentWindow.requestFullscreen();} 
        //     else if (currentWindow.webkitRequestFullscreen) { currentWindow.webkitRequestFullscreen();} 
        //     else if (currentWindow.msRequestFullscreen) {currentWindow.msRequestFullscreen();}

        //     //random move or show
        //     topBarRandom.click()
        //     //set elements
        //     // setTimeout(() => {
        //     // componentTopBar.style.display = "none"
        //     // optionsBar.style.display = "none"}, 1000)
        //     //set elements
        //     // setTimeout(() => {
        //     //     // let scrollBarSearch = document.getElementById("scrollBarSearch")
        //     //     // scrollBarSearch.style.position = "absolute"
        //     //     // scrollBarSearch.style.top = "4vh"
        //     //     if(lsSessionUserData != null)
        //     //     {
        //     //         scrollBarSearch.style.top = "10vh"

        //     //     }
        //     //     scrollBarSearch.style.left = "-20vw"
        //     //     scrollBarSearch.style.width = "100vw"
        //     // }, 1000)

        //     //set random movie or show interval
        //     slideShowInterval = setInterval(() => {
        //         console.log("slideshow next")

        //         //random movie or show
        //         topBarRandom.click()
        //     // //set elements
        //     // setTimeout(() => {
        //     // componentTopBar.style.display = "none"
        //     // optionsBar.style.display = "none"}, 1000)

        //         //set elements
        //         // setTimeout(() => {
        //         //     let scrollBarSearch = document.getElementById("scrollBarSearch")
        //         //     scrollBarSearch.style.position = "absolute"
        //         //     scrollBarSearch.style.top = "12vh"
        //         //     scrollBarSearch.style.left = "-20vw"
        //         //     scrollBarSearch.style.width = "100vw"}, 0)
        //     }, 10000)
        // }

        function fullscreen()
        {
                //variables
            let currentWindow = document.documentElement;

            //check if fullscreen
            if(isFullscreen == false)
            {
                console.log("start fullscreen mode")
                if (currentWindow.requestFullscreen) { currentWindow.requestFullscreen();} 
                else if (currentWindow.webkitRequestFullscreen) { currentWindow.webkitRequestFullscreen();} 
                else if (currentWindow.msRequestFullscreen) {currentWindow.msRequestFullscreen();}
                isFullscreen = true
            }
            else if(isFullscreen == true)
            {
                console.log("exit fullscreen mode")
                if(document.exitFullscreen) {document.exitFullscreen() }
                else if (docment.webkitExitFullscreen) {document.webkitExitFullscreen() }
                else if(document.msRequestExitFullscreen) {document.msRequestExitFullscreen() }
                isFullscreen = false
            }
        
        }

        return {
            //vuex
            getTopListType,

            //functions
            setUrl,
            displaySearchBar,
            previous,
            next,
            // slideshow,
            fullscreen
        }
    }
}
</script>

<style scoped>
    /*** scrollbar  ***/
    #topBarCenterParagraph { scrollbar-width: none;}

    /*** elements ***/
    /* #topBarCenterParagraph::-webkit-scrollbar { height: 10px; width: 10px; } */
    
    /*** ids ***/
    #componentTopBar 
    { 
        display: flex; 
        position: relative;
        height: 40px;
        width: 100vw;
        left: -20vw;
        margin: auto; 
        padding: 0px; 
        text-align: center; 
        user-select: none; 
        overflow-y: hidden;
        overflow-x: auto; 
        z-index: 1; 
        color: white;
        /* border-bottom: 1px solid white; */
        /* background-color: black; */
    }
    #topBarCenterParagraph { display: flex; max-width: 90vw; margin: auto; justify-content: center; overflow-y: hidden; overflow-x: auto; white-space: nowrap;}
    #topBarPrevious { display: inline-block; position: relative; margin: 0px 4px 0px 4px; margin-bottom: 6px; transform: rotate(180deg); font-size: 24px; opacity: 0.3;}
    #topBarPrevious:active { opacity: 1;}
    #topBarNext 
    { 
        display: inline-block; 
        position: relative; 
        margin: 6px 10px 0px 0px; 
        transform: rotate(0deg); 
        font-size: 24px; 
        opacity: 0.3; 
        z-index: 3;
    }
    #topBarNext:active { opacity: 1;}
    #topBarFullscreen { display: none; font-weight: bold; opacity: 0.3; }
    #topBarSearch { font-size: 20px; padding: 9px 9px 0px 6px;}
    #topBarRandom { display: none; }
    /* #topBarArrows { display: block;} */
    
    /*** classes ***/
    .topBarCategory { padding: 10px; font-weight: bold; font-size: 18px;}
    .topBarArrow { padding: 0px 0px 0px 0px; opacity: 1;}
    .topBarArrow:active { color: lightgray;}
    .topBarDot { display: block; padding: 12px 0px 0px 0px; opacity: 0.2; font-weight: bold; }
    .desktopOnly { display: block; }
    .mobileOnly { display: none; }

    /*** mobile ***/
    @media screen and (max-width: 1100px) {
        #topBarCenterParagraph::-webkit-scrollbar { height: 0px; width: 0px;}
        #componentTopBar {height: 40px; left: 0px; z-index: 2; border-bottom: 0px solid rgba(255, 255, 255, 0.9); background-color: black;}
        #topBarCenterParagraph { position: absolute; max-width: none; width: 100vw; left: 1vw; margin: 0px; overflow-x: auto; justify-content: start;}
        #topBarPrevious {display: none;}
        #topBarNext { display: none;}
        #topBarSlideshow { display: none;}
        #topBarFullscreen { display: block; margin-right: 12px; }
        #topBarSearch { display: none;}
        /* #topBarArrows { display: block; position: fixed; height: 60px; width: 100vw; bottom: 0px; padding: 0px 0px 0px 10px; font-size: 38px;} */
        
        /* .topBarDot { display: block;} */
        .topBarCategory { padding: 10px; font-size: 19px; }
        .desktopOnly { display: none; }
        .mobileOnly { display: block; }
        .topBarCategory { padding: 9px 10px 10px 10px; }
    }
</style>