<template>
  <div id="componentShow">
    <div v-if="selectedShow">
      <div id="showPosterContainer" v-if="selectedShowPoster">
        <img id="showPoster" v-bind:src="'https://image.tmdb.org/t/p/w600_and_h900_bestv2/' + selectedShowPoster" />
      </div>

      <div id="showInfo" v-if="showInfo.data">
        <!-- show title -->
        <p id="showNameText" v-if="showInfo.data.data"><b>{{showInfo.data.data.name}}</b>        
          <div id="showGeneralInfo">
            <!-- show status -->
            <!-- <p id="showStatus" class="showInfoText" v-if="showInfo.data.data.status"><b>Status: </b>{{showInfo.data.data.status}}</p> -->

            <!-- show release date -->
            <span v-if="showInfo.data.data.status == 'Ended' && showInfo.data.data.last_air_date != null && showInfo.data.data.first_air_date != null">
              {{showInfo.data.data.first_air_date.substr(0, 4)}}-{{showInfo.data.data.last_episode_to_air.air_date.substr(0, 4)}} · 
            </span>
            <span v-else id="showFirstAirDate" class="showInfoText" v-if="showInfo.data.data.first_air_date">{{showInfo.data.data.first_air_date.substr(0, 4)}} · </span>

            <!-- show language -->
            <span id="showSpokenLanguage" class="showInfoText" v-if="showInfo.data.data.spoken_languages[0]">{{showInfo.data.data.original_language.toUpperCase()}} · </span>
            <span id="showOriginalLanguage" class="showInfoText" v-else>{{showInfo.data.data.original_language.toUpperCase()}} · </span>

            <!-- show seasons -->
            <span id="showNumberOfSeasons" class="showInfoText" v-if="showInfo.data.data.number_of_seasons == null || showInfo.data.data.number_of_seasons == 0">0 Seasons · </span>
            <span id="showNumberOfSeasons" class="showInfoText" v-else-if="showInfo.data.data.number_of_seasons == 1" v-on:click="fetchShowSeason(showInfo.data.id, 1, showInfo.data.data)">1 Season · </span>
            <span id="showNumberOfSeasons" class="showInfoText" v-else-if="showInfo.data.data.number_of_seasons > 1" v-on:click="fetchShowSeason(showInfo.data.id, showInfo.data.data.number_of_seasons, showInfo.data.data)">{{showInfo.data.data.number_of_seasons}} Seasons · </span>
            
            <!-- show rating -->
            <span class="showInfoText showRatingStarCharacter" v-if="showInfo.data.data.vote_average" v-on:click="displayShowRating()">★</span>
            <span id="showRating" class="showInfoText" v-if="showInfo.data.data.vote_average">{{showInfo.data.data.vote_average.toFixed(1)}} tmbd</span>


            <!-- show episodes -->
            <!-- <span id="showNumberOfEpisodes" class="showInfoText" v-if="showInfo.data.data.number_of_episodes != 0">{{showInfo.data.data.number_of_episodes}} Episodes</span> -->
          </div>
        </p>

        <!-- show summary -->
        <div id="showSummary">
          <b id="showSummaryTitle">Summary</b>
          <br />
          <p id="showDescription" class="showInfoText" v-if="showInfo.data.data.overview != ''">{{showInfo.data.data.overview}}</p>
        </div>

        <!-- show slogan -->
        <div id="showSlogan" v-if="showInfo.data.data">
          <b id="showSloganTitle">Slogan</b><br />
          <p id="showTagline" class="showInfoText" v-if="showInfo.data.data.tagline">{{showInfo.data.data.tagline}}</p>
        </div>

        <!-- show dates -->
        <div id="showDates" v-if="showInfo.data.data">
          <b id="showDatesTitle">Dates</b><br />
          <p id="showNextEpisode" class="showInfoText" v-if="showInfo.data.data.next_episode_to_air">Next: {{formatDate(showInfo.data.data.next_episode_to_air.air_date)}}</p>
          <p id="showNextEpisode" class="showInfoText" v-else>Next: Unannounced</p>
          <p id="showLatestEpisode" class="showInfoText" v-if="showInfo.data.data.last_air_date">Latest: {{formatDate(showInfo.data.data.last_episode_to_air.air_date)}}</p>
          <p id="showPremieredEpisode" class="showInfoText" v-if="showInfo.data.data.first_air_date">Premiered: {{formatDate(showInfo.data.data.first_air_date)}}</p>
        </div>
      </div>

      <!-- show details -->
      <p id="showDetailsTitle" v-on:click="displayShowDetails()">Details</p>
      <div id="showDetails" class="showInfoText" v-if="showInfo.data.data">
        <!-- show title -->
        <p id="" v-if="showInfo.data.data.name" class="showInfoText showDetailCategory">
          <b>Title</b>
          <span>{{showInfo.data.data.name}}</span>
        </p>

        <!-- show status -->
        <p id="" v-if="showInfo.data.data" class="showInfoText showDetailCategory">
          <b>Status</b>
          <span v-if="showInfo.data.data.status">{{showInfo.data.data.status}}</span>
        </p>

        <!-- show dates -->
        <p id="" v-if="showInfo.data.data" class="showInfoText showDetailCategory">
          <b>Dates</b>
          <!-- <span v-if="showInfo.data.data.first_air_date">Release Date: {{showInfo.data.data.first_air_date}}</span> -->
          <span v-if="showInfo.data.data.next_episode_to_air">Next: {{formatDate(showInfo.data.data.next_episode_to_air.air_date)}}</span>
          <span v-else>Next: Unannounced</span>
          <span v-if="showInfo.data.data.last_episode_to_air">Latest: {{formatDate(showInfo.data.data.last_episode_to_air.air_date)}}</span>
          <span v-if="showInfo.data.data.first_air_date">Premiered: {{formatDate(showInfo.data.data.first_air_date)}}</span>
        </p>

        <!-- show season -->
        <p id="" v-if="showInfo.data.data.number_of_seasons" class="showInfoText showDetailCategory">
          <b>Total Seasons</b>
          <span>{{showInfo.data.data.number_of_seasons}}</span>
        </p>

        <!-- show episodes -->
        <p id="" v-if="showInfo.data.data.number_of_episodes" class="showInfoText showDetailCategory">
          <b>Total Episodes</b>
          <span>{{showInfo.data.data.number_of_episodes}}</span>
        </p>

        <!-- show main language -->
        <p id="" v-if="showInfo.data.data.original_language" class="showInfoText showDetailCategory">
          <b>Main Language</b>
          <span>{{showInfo.data.data.original_language.toUpperCase()}}</span>
        </p>

        <!-- show rating -->
        <p id="" v-if="showInfo.data.data.vote_average" class="showInfoText showDetailCategory">
          <b>Rating</b>
          <span class="showRatingStarCharacter" v-on:click="displayShowRating()">★<span id="showRatingDetails">{{showInfo.data.data.vote_average.toFixed(1)}} tmbd</span></span>
        </p>

        <!-- show summary -->
        <p id="" v-if="showInfo.data.data.overview" class="showInfoText showDetailCategory">
          <b>Summary</b>
          <span>{{showInfo.data.data.overview}}</span>
        </p>

        <!-- show slogan -->
        <p id="" v-if="showInfo.data.data.tagline" class="showInfoText showDetailCategory">
          <b>Slogan</b>
          <span>{{showInfo.data.data.tagline}}</span>
        </p>

        <!-- show runtimes -->
        <p id="showRuntimes" class="showInfoText showDetailCategory" v-if="showInfo.data.data.episode_run_time">
          <b>Runtime(s) </b>
          <span v-for="runtime in showInfo.data.data.episode_run_time" v-bind:key="runtime.nr"> · {{runtime}} mins</span>
        </p>

        <!-- show original title -->
        <p id="showOriginalTitle" class="showInfoText showDetailCategory" v-if="showInfo.data.data.original_name && showInfo.data.name != showInfo.data.data.original_name"><b>Original Title</b> {{showInfo.data.data.original_name}}</p>

        <!-- show creators -->
        <p id="showCreatedBy" class="showInfoText showDetailCategory" v-if="showInfo.data.data.created_by.length != 0"><b>Created By </b><span v-for="name in showInfo.data.data.created_by" v-bind:key="name.nr"> · {{name.name}}</span></p>

        <!-- show spoken languages -->
        <p id="showLanguages" class="showInfoText showDetailCategory" v-if="showInfo.data.data.spoken_languages"><b>Spoken Language(s) </b><span v-for="language in showInfo.data.data.spoken_languages" v-bind:key="language.nr"> · {{language.english_name}}</span></p>

        <!-- show genres -->
        <p id="showGenres" class="showInfoText showDetailCategory" v-if="showInfo.data.data.genres.length != 0"><b>Genre(s) </b><span v-for="genre in showInfo.data.data.genres" v-bind:key="genre.nr"> · {{genre.name}}</span></p>
        
        <!-- show production countries -->
        <p id="showProductionCountries" class="showInfoText showDetailCategory" v-if="showInfo.data.data.production_countries.length != 0">
          <b>Produced In </b><span v-for="country in showInfo.data.data.production_countries" v-bind:key="country.nr"> · {{country.name}}</span>
        </p>

        <!-- show production companies -->
        <p id="showProductionCompanies" class="showInfoText showDetailCategory" v-if="showInfo.data.data.production_companies.length != 0">
          <b>Produced By </b><span v-for="company in showInfo.data.data.production_companies" v-bind:key="company.nr"> · {{company.name}}</span>
        </p>

        <!-- show networks -->
        <p id="showNetworks" class="showInfoText showDetailCategory" v-if="showInfo.data.data.networks.length != 0">
          <b>Network(s) </b>
          <span v-for="network in showInfo.data.data.networks" v-bind:key="network.nr"> · {{network.name}}</span>
        </p>
        
        <!-- might use later -->
        <!-- <p v-if="showInfo.data.data.vote_count" id="showLastEpisode">TMDB Total Rates: {{showInfo.data.data.vote_count}}</p> -->
        <!-- <img v-if="showInfo.data && showInfo.data.data.poster_path" id="showPoster" v-bind:src="'https://www.themoviedb.org/t/p/w600_and_h900_bestv2' + showInfo.data.data.poster_path"/> -->
        <!-- <a id="showNameLink"><p id="showNameText">{{showInfo.data.data.name}}</p></a> -->
        <!-- <a v-if="showInfo.data" id="showNameLink" v-bind:href="showInfo.data.data.homepage"></a> -->
        <!-- <p v-if="showInfo.data.data.homepage" id="showHomePage">Homepage: {{showInfo.data.data.homepage}}</p> -->
      </div>
      
      <!-- if show has seasons -->
      <div v-if="showInfo.data && showInfo.data.data.number_of_seasons != 0" id="scrollBarSeasons">
        <div v-on:click="fetchShowSeason(showInfo.data.id, season.season_number, showInfo.data.data)" v-for="season in showInfo.data.data.seasons.slice()" v-bind:key="season.id" class="season" v-bind:id="'season#' + season.season_number">
          <p>{{season.season_number}}</p>
        </div>
      </div>
    </div>

    <div id="underlayShows"></div>
  </div>
</template>

<script>
import {ref, toRef, toRefs, reactive, computed, watch, watchEffect, onMounted, onBeforeMount, beforeUpdate, onUpdated, onBeforeUpdate} from 'vue'
import {useStore} from 'vuex'
import { useRouter, useRoute } from 'vue-router' //instead of this.$route

export default {
    setup() {
      //router
      let path = useRouter().currentRoute.value.params.showId

      //vuex
      const store = useStore() //same as this.$store
      const selectedShow = computed(() => { return store.getters['selectedShow']})
      const selectedSeason = computed(() => { return store.getters['selectedSeason']})
      const selectedShowPoster = computed(() => { return store.getters['selectedShowPoster']})

      //variables
      const BACKEND_API_URL =  computed(() => { return store.getters['backendAPI']}).value

      //reactive
      let showInfo = reactive({
        data: selectedShow,
        name: null,
        description: null,
        status: null,
        poster: null,
        homePage: null,
        numberOfSeasons: null,
        seasons: null,
        numberOfEpisodes: null,
        episodes: null
      })
      let seasonInfo = reactive({ data: selectedSeason })

      //variables
      var seasonData = null
      var seasonData = null
      var seasonNumber = null
      var showId = null
      var numberOfEpisodes = null
      var airDate = null
      var url = null
      var localStorageData = []
      // const seasonInfoRefs = toRefs(seasonInfo)

      //lifecycle hooks
      // watch(seasonInfoRefs.data, (newValue, oldValue) => { selectedSeasonOpacityStyling(seasonInfoRefs.data.value.season) })
            
      onMounted(() => {
        console.log("onMounted ComponentShow")
      })

      onUpdated(() => { 
        console.log("onUpdated ComponentShow")


        //elements
        let componentOptionsBar = document.getElementById("componentOptionsBar")
        let modalReview = document.getElementById("modalReview")
        let seasons = document.getElementsByClassName("season")
        let seasonSpecials = document.getElementById("season#" + [0])

        //update elements
        componentOptionsBar.style.display = "block"
        modalReview.style.display = "none"
        if(seasonSpecials != null)
        {
            for(let c = 0; c < seasons.length; c++)
            {
                if(document.getElementById("season#" + [c]))
                {
                  document.getElementById("season#" + [c]).style.opacity = "1"
                  document.getElementById("season#" + [c]).style.backgroundColor = ""
                }
            }
        }
        else
        {
            for(let c = 1; c <= seasons.length; c++)
            {
                if(document.getElementById("season#" + [c]))
                {
                  document.getElementById("season#" + [c]).style.opacity = "1"
                  document.getElementById("season#" + [c]).style.backgroundColor = ""
                }
            }
        }
      })

      //functions
      async function fetchShowSeason(show, season, showData)
      {
          //debugging
          console.log("show: " + show)
          console.log("season: " + season)
          console.log("seasonNumber: " + seasonNumber)
          
          //elements
          let componentEpisodes = document.getElementById("componentEpisodes")
          let showInfo = document.getElementById("showInfo")
          let underlayShows = document.getElementById("underlayShows")
          let componentOptionsBar = document.getElementById("componentOptionsBar")
          let componentTopBar = document.getElementById("componentTopBar")
          let showDetailsTitle = document.getElementById("showDetailsTitle")
          let showPoster = document.getElementById("showPoster")
          let bottomBar = document.getElementById("bottomBar")
          let scrollBarSeasons = document.getElementById("scrollBarSeasons")
          let seasonNr = document.getElementById("season#" + season)
          // let scrollBarSearch = document.getElementById("scrollBarSearch")

          //variables
          let seasonExists = false
          let mq = window.matchMedia("(max-width: 1100px)")
          let ls = localStorage.getItem("sessionSeasons")

          //reset elements
          // if(underlayShows) { underlayShows.style.display = "block" }
          if(componentOptionsBar) { componentOptionsBar.style.display = "none" }
          if(componentTopBar) { componentTopBar.style.display = "none" }
          if(showDetailsTitle) { showDetailsTitle.style.display = "none" }
          if(showPoster) { showPoster.style.display = "none" }
          if(bottomBar) { bottomBar.style.display = "none" }
          if(scrollBarSeasons) { scrollBarSeasons.style.display = "block"; }
          if(seasonNr) { seasonNr.scrollIntoView() } 
          // if(scrollBarSearch) { scrollBarSearch.style.display = "none" }
          // if(bottomBar) { bottomBar.style.visibility = "hidden" }

          if(mq.matches == true) {scrollBarSeasons.style.bottom = "2vh"; underlayShows.style.opacity = "0.6" } // mobile  
          else if(mq.matches == false) { } // desktop
            
          //local storage
          if(ls == "null" || ls == null) { ls = []}
          else if(ls) { localStorageData = JSON.parse(ls) }

          //update elements
          if(season == seasonNumber)
          {            
            componentEpisodes.style.display = "none"
            showInfo.style.display = "block"
            selectedSeasonOpacityStyling(null, null)
            underlayShows.style.display = "block"
            bottomBar.style.display = "block"
            scrollBarSeasons.style.display = "none";
            // bottomBar.style.visibility = "visible"

            if(mq.matches == true) // mobile 
            { 
              // underlayShows.style.display = "none"; 
              underlayShows.style.opacity = "0"
              scrollBarSeasons.style.bottom = "17vh"
            }
            else if(mq.matches == false) // desktop 
            { 
              // underlayShows.style.display = "block"; 
              underlayShows.style.opacity = "0.4" 
              scrollBarSeasons.style.bottom = "0px"
            }
          
            seasonNumber = null
            optionsBar.style.display = "flex"

            //check if logged in
            let lsSessionUserData = localStorage.getItem("sessionUserData")
            if(lsSessionUserData != null) {componentOptionsBar.style.display = "block" }
            else if(lsSessionUserData == null) { componentOptionsBar.style.display = "none"}

            componentTopBar.style.display = "block"
            showDetailsTitle.style.display = "block"
            if(showPoster) { showPoster.style.display = "inline-block" }
            // bottomBar.style.display = "block"
            // scrollBarSearch.style.display = "block"

            //reset to default background
            // underlayShows.style.backgroundImage = "url('https://image.tmdb.org/t/p/original/" + showData.backdrop_path + "')"

            return
          }

          //set custom season background image
          // let sn = null
          // if(showData.seasons[0].season_number == 1) { sn = season - 1}
          // else if(showData.seasons[season].season_number == season) { sn = season}
          // if(season != 0)
          // {
          //   let test = showData.seasons[sn].poster_path
          //   if(test != null) { underlayShows.style.backgroundImage = "url('https://image.tmdb.org/t/p/original/" + test + "')"}
          //   else { underlayShows.style.backgroundImage = "url('https://image.tmdb.org/t/p/original/" + showData.backdrop_path + "')"}
          // }

          seasonNumber = season

          //check season exists in local storage
          for(let s in localStorageData)
          {
            let item = localStorageData[s]
            // console.log(item)
            if(item.showId == show && item.season == season)
            {
              console.log("season#" + season + " exists in local storage")

              //set season exists
              seasonExists = true

              //set seasonData 
              seasonData = {showId: item.showId, showName: item.showName, season: item.season, episodes: item.episodes}
  
              //set vuex
              store.dispatch('actionSetSelectedSeason', seasonData)
  
              selectedSeasonOpacityStyling(season, null)
          
              return
            }
          }

          if(seasonExists != true) {fetchSeasonDataFromAPI(show, season, localStorageData) }
      }

      async function fetchSeasonDataFromAPI(show, season, localStorageData)
      {
        //variables
        url = BACKEND_API_URL + "/season?show=" + show + "&season=" + season

        //fetch data        
        await fetch(url, {method: 'get'})
        .then((response) => {
              console.log("show#" + show + " - season#" + season + " - fetched from API")
              return response.json()
        })
        .then((data) => {        
            // console.log(data)
                  
            //set variables
            seasonData = {showId: selectedShow.value.id, showName: selectedShow.value.name, season: data.season_number, episodes: data.episodes}

            //save to localStorage
            // localStorageData.push({showId: selectedShow.value.id, showName: selectedShow.value.name, season: season, episodes: data.episodes, searchString: "show" + selectedShow.value.id + "season" + season, savedAt: Date.now()})
            localStorageData.push({showId: selectedShow.value.id, showName: selectedShow.value.name, season: season, episodes: data.episodes})
            localStorage.setItem("sessionSeasons", JSON.stringify(localStorageData))

            //vuex
            store.dispatch('actionSetSelectedSeason', seasonData)
            selectedSeasonOpacityStyling(seasonData.season)
        })
      }

      function selectedSeasonOpacityStyling(season, numberOfEpisodes)
      {
        //elements
        let selectedDiv = document.getElementById("season#" + season)
        let seasons = document.getElementsByClassName("season")
        let seasonSpecials = document.getElementById("season#" + [0])

        //reset elements
        if(seasonSpecials != null && seasons != null)
        {
            for(let c = 0; c < seasons.length; c++)
            {
                if(document.getElementById("season#" + [c]))
                {
                  document.getElementById("season#" + [c]).style.opacity = ".50"
                  document.getElementById("season#" + [c]).style.backgroundColor = ""
                }
            }
        }
        else
        {
            for(let c = 1; c <= seasons.length; c++)
            {
                if(document.getElementById("season#" + [c]))
                {
                  document.getElementById("season#" + [c]).style.opacity = ".50"
                  document.getElementById("season#" + [c]).style.backgroundColor = ""
                }
            }
        }

        if(selectedDiv != null)
        {
          selectedDiv.style.opacity = "1"
        } 
      }

      function displayShowDetails()
      {
        //null check
        let showDetails = document.getElementById("showDetails")
        if(showDetails == null || showDetails == "null") { return }

        //elements
        let componentTopBar = document.getElementById("componentTopBar")
        let componentOptionsBar = document.getElementById("componentOptionsBar")
        let showDetailsTitle = document.getElementById("showDetailsTitle")
        let scrollBarSeasons = document.getElementById("scrollBarSeasons")
        let underlayShows = document.getElementById("underlayShows")
        let showInfo = document.getElementById("showInfo")
        let showPoster = document.getElementById("showPoster")
        let bottomBar = document.getElementById("bottomBar")
        // let scrollBarSearch = document.getElementById("scrollBarSearch")

        //variables
        let mq = window.matchMedia("(max-width: 1100px)")
        let lsSessionUserData = localStorage.getItem("sessionUserData")

        //reset show details
        if(showDetails.style.display == "" || showDetails.style.display == "none")
        {
          showDetails.style.display = "block"
          componentTopBar.style.display = "none"
          componentOptionsBar.style.display = "none"
          if(showPoster) { showPoster.style.display = "none" }
          bottomBar.style.display = "none"
          showDetailsTitle.innerText = "Exit Details"

          if(mq.matches == true) // mobile
          {
            showDetailsTitle.style.bottom = "0px"
            showDetailsTitle.style.padding = "3vh 0px 3vh 0px"
            // showDetailsTitle.style.backgroundColor = "black"
            // showDetailsTitle.style.borderTop = "1px solid white"
            underlayShows.style.opacity = "0.6"
          }
          else if(mq.matches == false) // desktop
          {
            showDetailsTitle.style.bottom = "3vh"
          }

          if(scrollBarSeasons) {scrollBarSeasons.style.display = "none"}
          // underlayShows.style.display = "block"
          showInfo.style.display = "none"
          // scrollBarSearch.style.display = "none"
        }
        else if(showDetails.style.display == "block")
        {
          showDetails.style.display = "none"
          componentTopBar.style.display = "block"
          if(showPoster) {showPoster.style.display = "inline-block" }
          bottomBar.style.display = "block"
          // scrollBarSeasons.style.display = "block"
          showDetailsTitle.innerText = "Details"

          //check if logged in
          if(lsSessionUserData != null) {componentOptionsBar.style.display = "block" }
          else if(lsSessionUserData == null) { componentOptionsBar.style.display = "none"}
    
          // scrollBarSearch.style.display = "block"
          
          if(mq.matches == true) // mobile
          { 
            // underlayShows.style.display = "none"
            underlayShows.style.opacity = "0"
            showDetailsTitle.style.bottom = "14vh"
            showDetailsTitle.style.padding = "0px"
            showDetailsTitle.style.borderTop = "0px solid white"
            // showDetailsTitle.style.backgroundColor = "transparent"
          }
          else if(mq.matches == false) // desktop
          { 
            underlayShows.style.display = "block"
            showDetailsTitle.style.bottom = "3vh"
          }

          showInfo.style.display = "block"
        }
      }

      function displayShowRating()
      {
        console.log("display rating value")
        let showRating = document.getElementById("showRating")
        let showRatingDetails = document.getElementById("showRatingDetails")

        if(showRating.style.display == "" || showRating.style.display == "none"){ showRating.style.display = "inline-block"}
        else if(showRating.style.display == "inline-block") { showRating.style.display = "none"}

        if(showRatingDetails.style.display == "" || showRatingDetails.style.display == "none"){ showRatingDetails.style.display = "inline-block"}
        else if(showRatingDetails.style.display == "inline-block") { showRatingDetails.style.display = "none"}
        
      }

      function formatDate(date)
      {
        let year = date.substring(0, 4)
        let month = date.substring(5, 7)
        let day = date.substring(8, 10)

        date = day + "-" + month + "-" + year 

        if (date.includes("-01-")) { return date = date.replace("-01-", "-Jan-") }
        else if (date.includes("-02-")) { return date = date.replace("-02-", "-Feb-") }
        else if (date.includes("-03-")) { return date = date.replace("-03-", "-Mar-") }
        else if (date.includes("-04-")) { return date = date.replace("-04-", "-Apr-") }
        else if (date.includes("-05-")) { return date = date.replace("-05-", "-May-") }
        else if (date.includes("-06-")) { return date = date.replace("-06-", "-Jun-") }
        else if (date.includes("-07-")) { return date = date.replace("-07-", "-Jul-") }
        else if (date.includes("-08-")) { return date = date.replace("-08-", "-Aug-") }
        else if (date.includes("-09-")) { return date = date.replace("-09-", "-Sep-") }
        else if (date.includes("-10-")) { return date = date.replace("-10-", "-Oct-") }
        else if (date.includes("-11-")) { return date = date.replace("-11-", "-Nov-") }
        else if (date.includes("-12-")) { return date = date.replace("-12-", "-Dec-") }
      }
       
      return {
        //variables
        showInfo,
        seasonInfo,
        // seasonInfoRefs,
        path,

        //vuex
        selectedShow,
        selectedShowPoster,

        //functions
        fetchShowSeason,
        displayShowDetails,
        displayShowRating,
        formatDate
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*** scrollbar  ***/
  #showInfo { scrollbar-width: none;}
  #showDetails { scrollbar-width: none;}
  #scrollBarSeasons { scrollbar-width: none;}
  #showDescription { scrollbar-width: none;}

  /*** elements ***/
  /* scrollbar */
  ::-webkit-scrollbar { height: 10px; } /* width */
  ::-webkit-scrollbar-track { background-color: transparent; } /* Track */
  ::-webkit-scrollbar-thumb { background-color: rgba(255, 255, 255, 0.1); } /* Handle */

  /*** ids ***/
  #showInfo 
  { 
    display: block; 
    position: absolute; 
    max-height: 44vh; 
    width: 40vw; 
    top: 49vh; 
    left: 34vw; 
    margin: 0px; 
    text-align: left; 
    z-index: 1; 
    opacity: 1; 
    text-shadow: 2px 1px black; 
    overflow-y: auto; 
    overflow-x: hidden; 
    color: white; 
  }
  #showStatus { } 
  #showNumberOfEpisodes { } 
  #showNumberOfSeasons { } 
  #showNextEpisode { } 
  #showLatestEpisode { } 
  #showDescription { } 
  #showNameText { position: fixed; width: 49vw; margin: -7vh 0px 0px 0px; padding: 0px; padding-bottom: 2px; font-size: 27px; }
  /* #showPoster { position: absolute; float: left; height: 320px; width: 230px; margin: 0px; margin-left: 30px; margin-top: -47px; margin-top: -8px; padding: 0px; z-index: 1; border: 2px solid white; } */
  #showDescription 
  { 
    max-height: 110px; 
    width: 80%; 
    margin: 0px 0px 20px 0px; 
    padding: 0px 0px 2px 0px;
    overflow-y: auto;
    opacity: 0.9;
  }
  #scrollBarSeasons 
  { 
    display: none; 
    position: absolute; 
    margin: auto; 
    width: 40vw; 
    bottom: 0px; 
    left: 31vw; 
    margin-left: -13px;
    overflow-y: hidden; 
    overflow-x: auto; 
    text-align: center;
    text-shadow: 2px 1px black;
    white-space: nowrap; 
    font-weight: bold;
    opacity: 1;
    z-index: 4; 
    font-size: 21px; 
    /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  }
  #scrollBarSeasons p { margin: 0; padding: 0; padding: 20px; padding: 0px 8px 34px 8px; white-space: normal; color: white; }
  #errorMessage { color: black; }
  #underlayShows { display: block; position: absolute; height: 100vh; width: 100vw; bottom: 0px; left: 0px; z-index: 0; opacity: 0.4; background-color: black; }
  #componentShow { user-select: none; }
  #showDetails 
  { 
    display: none;
    position: fixed; 
    height: 89vh; 
    width: 40vw; 
    top: 4vh; 
    left: 30vw;
    margin: 0px; 
    padding: 0px; 
    overflow-y: auto; 
    text-align: center; 
    opacity: 1; 
    z-index: 1;
    color: white;
    border-top: 0px solid rgba(255, 255, 255, 0.2); 
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  }
  #showSlogan { margin: 0px 0px 20px 0px;}
  #showStats { margin: 0px 0px 20px 0px;}
  #showDates { margin: 0px 0px 20px 0px;}
  #showProductionCountries { display: flex; flex-direction: column;}
  #showProductionCompanies { display: flex; flex-direction: column;}
  #showGenres { display: flex; flex-direction: column;}
  #showNetworks { display: flex; flex-direction: column;}
  #showCreatedBy { display: flex; flex-direction: column;}
  #showRuntimes { display: flex; flex-direction: column;}
  #showLanguages { display: flex; flex-direction: column;}
  #showGeneralInfo 
  { 
    margin: -4px 0px 0px 0px; 
    font-size: 16px; 
    overflow-y: hidden; 
    overflow-x: auto; 
    text-shadow: 2px 1px solid black; 
    user-select: none;
    opacity: 0.9;
  }
  #showDetailsTitle 
  { 
    position: fixed; 
    width: 100%; 
    bottom: 3vh; 
    left: 0vw; 
    margin: 0px; 
    padding: 0px; 
    text-align: center; 
    font-weight: bold;
    font-size: 18px;
    user-select: none; 
    z-index: 1;
    text-shadow: 2px 1px black;
    color: white;
  }
  #showSummaryTitle { font-size: 18px; }
  #showSloganTitle { font-size: 18px; }
  #showDatesTitle { font-size: 18px; }
  #showRating { display: none; width: auto; margin: 0px 0px 0px 5px;}
  #showRatingDetails { display: none; width: auto; font-size: 19px; margin: 0px 0px 0px 4px;}
  #showPoster 
  { 
    position: fixed; 
    display: inline-block;
    bottom: calc(64vh + 10px); 
    width: 20vh; /* 190px */
    margin: 0px; 
    z-index: 2; 
    border: 1px solid rgba(48, 46, 46, 0.4); /* #00000066 */
  }
  #showPosterContainer { display: flex; justify-content: center; }
  #showSummary { margin: 12px 0px 0px 0px; }
  #showSlogan { margin: 26px 0px 0px 0px; }
  #showDates { margin: 26px 0px 0px 0px; }
  #showTagline { width: 80%; }
  /* #showInfoPosterMissing { margin: auto; margin-top: 0px; margin-bottom: 15px; padding: 10px; min-height: 235px; text-align: left; color: white; background-color: black; } */
  /* #showInfoPosterMissing p { margin: 0px; padding-bottom: 4px; padding-left: 4px; } */

  /*** classes ***/
  .season { position: relative; display: inline-block; vertical-align: top; padding: 0px; user-select: none;}
  .seasonImage { margin: 0px; /* margin-top: 10px; */ padding: 0px; padding-bottom: 10px; }
  .seasonImageMissing { margin: 0px; margin-top: 10px; padding: 0px; padding-bottom: 87px; }
  .showInfoText { margin: 0px 0px 4px 0px; padding: 0px; text-shadow: 2px 1px black; opacity: 0.9; font-size: 17px; }
  .showDetailCategory { display: flex; flex-direction: column; margin: 0px 0px 20px 0px;}
  .showRatingStarCharacter { display: inline; width: auto; font-size: 19px; user-select: none;}
  
  /*** mobile ***/
  @media screen and (max-width: 1100px) {
    ::-webkit-scrollbar { width: 0px; height: 0px; } /* width */
    #showInfo { max-height: 67vh; width: 90vw; left: 4vw; top: 8vh; z-index: 2; font-size: 18px;}
    #scrollBarSeasons 
    { 
      /* position: fixed;
      display: flex; 
      flex-direction: column; 
      max-height: 28vh; 
      width: 13vw; 
      right: 0px; 
      left: auto; 
      top: 31vh; 
      margin: 0px; 
      padding: 0px; 
      font-weight: bold;
      opacity: 1;
      overflow-y: auto; 
      overflow-x: hidden; 
      z-index: 4;
      border-top: 0px solid white; */
      bottom: 17vh;
    }
    #scrollBarSeasons p { padding: 0px 1vw 10px 0px}
    #showDetails 
    { 
      position: fixed; 
      height: 66vh;
      width: 100vw; 
      top: 0vh; 
      left: 0vh; 
      margin: 0px; 
      padding: 33px 0px 20vh 0px; 
      font-size: 18px;
      opacity: 1; 
      border-top: 0px solid white; 
      border-bottom: 0px solid rgba(255, 255, 255, 0.4); 
      /* background-color: rgba(0, 0, 0, 0.3); */
    }
    /* #showNameText { position: fixed; top: calc(34vh - 3px); width: 90vw; margin: 0px 0px 11px 0px; text-align: left; font-size: 21px; white-space: nowrap; overflow-x: auto;} */
    #showNameText { position: relative; width: 90vw; margin: 0px; font-size: 24px; white-space: nowrap; overflow-x: auto;}
    #showDescription { max-height: 30vh; width: 100%; margin-top: -2px; white-space: nowrap; }
    #showSummary { margin: 4px 0px 0px 0px; }
    #showSlogan { margin: 26px 0px 0px 0px; }
    #showDates {  margin: 26px 0px 0px 0px; }
    #underlayShows { display: block; top: 0px; opacity: 0; }
    #showGeneralInfo { margin: -4px 0px 20px 0px; font-size: 18px;}
    #showDetailsTitle { bottom: 14vh; text-shadow: 2px 1px black; z-index: 3; font-size: 18px;}
    #showPoster 
    { 
      position: relative; 
      height: calc(93vh + 4px);  
      width: 100vw; 
      bottom: initial; 
      margin: 0px; 
      opacity: 0.8; 
      border: 0px;
    }
    #showTagline { width: 100%; margin-top: -2px; white-space: nowrap; overflow-x: auto; }
    #showNextEpisode { margin-top: -2px; }
    #showLatestEpisode { margin-top: -2px; }
    #showPremieredEpisode { margin-top: -2px; }

    .season {margin-left: 10px; font-size: 22px; font-weight: bold; text-shadow: 2px 1px black;}
    .showInfoText { width: 90%; font-size: 18px; }
    .showDetailCategory { margin: auto; margin-bottom: 30px; }
  }
</style>
